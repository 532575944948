import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    row:{
        display: 'flex', 
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
          marginBottom: '0px',
          flexWrap: 'wrap'
        },
    },
    appointmentRow:{
        display: 'flex', 
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '47%',
        marginBottom: '10px',
        [theme.breakpoints.down('sm')]: {
          marginBottom: '0px',
          flexWrap: 'nowrap'
        },
    },
    textFields: {
        fontSize: "16px",
        fontStyle: "normal",
        minWidth: "268px",
        fontFamily: "Arial,Helvetica,sans-serif",
        height: 50, 
        marginBottom: 15,
        [theme.breakpoints.down('sm')]: {
            minWidth: '50%',
        },
    },
    permissionLabel2: {
        fontWeight: 300,
    },
    inputContainerRight: {          
        width: '50%',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '10px',
        paddingLeft: '7.5px',
      },
    },
    inputContainerLeft: {         
         width: '50%',
        [theme.breakpoints.down('sm')]: {
          marginBottom: '10px',
          paddingRight: '7.5px'
        },
      },

    inputContainerCalendar: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 10,       
        [theme.breakpoints.down('sm')]: {
          marginBottom: '10px',
          paddingRight: '0px'
        },
      },
    emailInputContainer: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '10px',
      },
    },
    inputFields: {
        fontSize: "16px",
        fontStyle: "normal",
        minWidth: "279.3px",
        fontFamily: "Arial,Helvetica,sans-serif",
        height: 38,
        padding: "0px 5px 5px 11px",
    },
    bottomButtons: {
        marginTop: 25,
        marginBottom: 15,
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    linearProgressPadding: {
        padding: 7,
        margin: '25px',
    },
    progressModalTopMargins: {
        marginTop: 0,
        marginBottom: 35,
    },
    progressModalBottomMargins: {
        marginTop: 15,
        marginBottom: 0,
    },
    formControl: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 10,
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
    },
    notficationContainer: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            flexWrap: 'nowrap'
        },
    },
    checkBoxPadding: {
        paddingLeft: 15,
        flexDirection: 'row',
    },
    bottomAccessPermissions: {
        width: '100%',
        border: '1.5px solid #BCBCCB',
        borderRadius: "5px",
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        padding: 0,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        },
    },
    accessContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '55%',
        height: '432px',
        margin: 0,
        borderRight: '1.5px solid #BCBCCB',
    },
    permissionContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#dff2fa',
        width: '47%',
        height: '432px',
    },
    accessLabel: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 15,
        paddingTop: 15,
        paddingBottom: 5,
    },
    permissionLabel: {
        paddingLeft: 15,
        paddingTop: 15,
    },
    featureComingSoon: {
        paddingTop: 25,
        fontSize: 14,
        alignSelf: 'center'
    },
    moduleGranularAccessContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        // justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
    moduleGranularAccessItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '4px 15px 4px 15px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            fontSize:'13px',
            marginLeft:'2px'
        },
    },
    moduleGranularAccessItemRightCheckboxWithHelpIcon: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    moduleAccess: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%', 
        paddingLeft: 35,
        paddingTop: 4,
        paddingBottom: 4,
        '&:hover': {
            background: '#dff2fa',
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
    },
    moduleAccessSelected: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%', 
        paddingLeft: 35,
        paddingTop: 4,
        paddingBottom: 4,
        backgroundColor: '#dff2fa',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
    },
    moduleAccessTeleHealth: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '90%', 
        paddingLeft: 35,
        paddingTop: 4,
        paddingBottom: 4,
        '&:hover': {
        background: '#dff2fa',
        cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
    },
    iconAndLabel: {
        width: '70%',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            width: '58%',
            "& p": {
                fontSize: "0.8rem"
              }
        },       
    },
    iconColor: {
        color: '#C2E4F8',
        marginRight: 10,
        [theme.breakpoints.down('sm')]: {
            marginRight: 2,
        },
    },
    disabledIconColor: {
        color: '#000000',
        opacity: '0.4',
        marginRight: 10,
    },
    disabledTextColor: {
        color: '#000000',
        opacity: '0.55',
    },
    disabledCheck: {
        color: '#000000',
        opacity: '0.5',
        marginRight: 45,
        height: 20,
        width: 20,
    },
    permissionCheckMarkRightMargin: {
        margin: '0 15px 0 0',
        padding: 0,
    },
    permissionCheckMarkNoPadding: {
        margin: '0 45px 0 0',
        padding: 0,
    },
    permissionCheckMarkNoPaddingTelehealth: {
        margin: 0,
        padding: 0,
    },
    imageContainer: {
        height: '95px',
        // maxWidth: '174px',
        // minWidth: '174px',
        width: 174,
        backgroundColor: 'white',
        marginTop: '15px',
        alignSelf: 'center',
        justifySelf: 'center',
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        },
    },
    image: {
        height: '75px',
        maxWidth: '174px',
        minWidth: '174px',
        marginTop: '10px',
        alignSelf: 'center',
        justifySelf: 'center',
    },
    buttonContainer: {
      width: "100%",
      textAlign: 'center',
      display: 'inline-block',
      marginBottom: '15px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '15px'
      },
    },
    cancelButton: {
        marginLeft: '15px',
        color: 'white',
        background: '#E22C56',
        '&:hover': {
        background: '#971533',
        cursor: 'pointer',
        },
    },
    submitButton: {
        color: 'white',
        background: '#10D236',
        '&:hover': {
        background: '#0a8938',
        cursor: 'pointer',
        },
    },
    backdrop: {
        zIndex: 9999,
    },
    contentContainer: {
        padding: '5px 18px 0px 12px',
    },
    popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
      appointmentColorCircle: {
        marginRight: 20,
        borderRadius: '50%', 
        width: 30, 
        height: 30, 
      }
}));
