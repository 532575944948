import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  MenuItem,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList
} from '@mui/material';
import {
  ArrowDropDown as ArrowDropDownIcon
} from '@mui/icons-material';
import { LocalLanguageContext } from '../LocalLanguageProvider';
import { Locales } from '../LocalLanguageProvider/LangMap';
import GetLokaliseRawString from '../LokalisedContent/rawstring';


const LanguageSwitcher = ({ children }) => {
  let options = Object.keys(Locales)
  const [langState, setLangState] = useState(options[0]);
  const { setLangCode, langCode } = useContext(LocalLanguageContext);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  if (process.env.REACT_APP_STAGE === 'prod') {
    options = options.filter(e => e !== 'keys');
  }

  let optionsTrans = options.map(x => {
    return GetLokaliseRawString(`global_langSwitcher_${x}`, x);
  });

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    console.log(options[index])
    if (langState !== options[index]) {
      setLangState(options[index]);
      setLangCode(options[index]);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (langCode !== langState) {
      setLangState(langCode);
      const isLargeNumber = (element) => element === langCode;
      if (options.findIndex(isLargeNumber) >= 0) {
        setSelectedIndex(options.findIndex(isLargeNumber));
      }
    }
  }, [langCode, langState, options]);


  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    return;
  };

  return (
    <>
      <React.Fragment>
        <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
          <Button onClick={handleClick}>{optionsTrans[selectedIndex]}</Button>
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {optionsTrans.map((option, index) => (
                      <MenuItem
                        key={option}
                        // disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
      {children}
    </>
  );
};

export default LanguageSwitcher;
