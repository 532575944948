// display numbers
const pipeDisplay = (initialValue) => {
  const cleaned = ('' + initialValue).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = '';
    const number = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    return number
  }
  return null
}
export default pipeDisplay;