import React, { useState, useEffect } from 'react';
import '../clock.css';
import { makeStyles, withStyles } from '@mui/styles';
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  Typography,
  Button,
  IconButton
} from "@mui/material";
import {Close as CloseIcon} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  submitButtons:{
    margin: '5px',
    paddingLeft: '25px',
    paddingRight: '25px',
    color: 'white'
  },
  saveButton: {
    background: '#10D236',
    '&:hover': {
      background: '#0a8938',
      cursor: 'pointer'
    }
  },
  cancelButton:{
    background: '#E22C56',
    '&:hover': {
      background: '#971533',
      cursor: 'pointer'
    }
  },
  dialogContent: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
    },
  },
}));

const styles = {
    smallIcon: {
      width: 18,
      height: 18,
    }
  }

const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogTitle = withStyles(styles)((props) => {
    const useStyles = makeStyles((theme) => ({
      dialogHeader: {
        [theme.breakpoints.down('sm')]: {
          marginTop: 50,
        },
      },
      dialogButton: {
        position: "absolute",
        top: "8px",
        right: "8px",
      }
    }));
    const classes = useStyles();
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography {...other} className={classes.dialogHeader}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.dialogButton}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

const Confirmation = ({ isOpen, setCancel, setDelete}) => {
    const [openModel, setOpenModel] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const classes = useStyles();

    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    }
  
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      if(width <= 768){
        setIsMobile(true)
      }
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
      setOpenModel(isOpen);
    }, [isOpen])
  
    const closeModel = () => {
      setOpenModel(false);
      setCancel();
    }
  
    return (
      <Dialog open={openModel} onClose={closeModel} fullScreen={isMobile} >
        <DialogContent className={classes.dialogContent} classes={classes}>
        <DialogTitle onClose={closeModel}></DialogTitle>
        <div className="row">
          <label style={{ color: '#707070', margin: 'auto', textAlign: 'center', padding: '50px' }}>Are you sure you want to delete this receipt?</label>
        </div>
        <div className="icon cur-pointer" style={{display: 'flex', justifyContent: 'center'}}>
          <Button
            className={`${classes.submitButtons} ${classes.cancelButton}`}
            onClick={closeModel}
            variant='contained'
          >
            Cancel 
          </Button>
          <Button
            className={`${classes.submitButtons} ${classes.saveButton}`}
            onClick={setDelete}
            variant='contained'
          >
            Delete 
          </Button>
        </div>
        </DialogContent>
      </Dialog>
    )
  };

  export default Confirmation