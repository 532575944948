import {useEffect, useState} from 'react';
import {useQuery, useSubscription, gql} from '@apollo/client';
import { API, graphqlOperation } from 'aws-amplify';
import { createPaymentTags,updateStripeTransaction} from '../graphql/mutations';
import { paymentTagsByOrgId} from '../graphql/queries';
import {onCreatePaymentTags} from '../graphql/subscriptions';
import Session from '../session';

export const usePaymentTags = () => {
    const orgId = Session.OrganizationId;
    const paymentQuery = useQuery(gql(paymentTagsByOrgId), {
        variables: {
         organizationId : orgId
        }
      });
    const [paymentTags, setPaymentTags] = useState([]);

    const createPyamentTagFn = async (payload) => {
        const res = await API.graphql(graphqlOperation(createPaymentTags, {input: payload}));
        console.log('res on create payment Tag', res);
        return res;
    };

    const UpdateStripePyamentFn = async (payload) => {
      const res = await API.graphql(graphqlOperation(updateStripeTransaction, {input: payload}));
      console.log('res on Update stripePayment Tag', res);
      return res;
    };

    useEffect(() => {
        if(paymentQuery.data?.paymentTagsByOrgId?.items.length>0){
            setPaymentTags(paymentQuery.data.paymentTagsByOrgId.items);
        }
    }, [paymentQuery])

    const {data: onNewTag} = useSubscription(gql(onCreatePaymentTags), {
        variables: {
          filter:{
            organizationId: { eq:  Session.OrganizationId }
            }
          }
     });

     useEffect(() => {
        if (onNewTag) {
          const delta = onNewTag.onCreatePaymentTags
          if (delta.organizationId !== Session.OrganizationId) {
            return
          }
          else{
            paymentQuery.refetch();
          }
        }
        // eslint-disable-next-line
      }, [onNewTag]);
     
    

    return [paymentTags,createPyamentTagFn,UpdateStripePyamentFn];
}