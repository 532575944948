/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBanner = /* GraphQL */ `
  mutation CreateBanner(
    $input: CreateBannerInput!
    $condition: ModelBannerConditionInput
  ) {
    createBanner(input: $input, condition: $condition) {
      id
      type
      message
      buttonLabel
      buttonUrl
      createdAt
      updatedAt
    }
  }
`;
export const updateBanner = /* GraphQL */ `
  mutation UpdateBanner(
    $input: UpdateBannerInput!
    $condition: ModelBannerConditionInput
  ) {
    updateBanner(input: $input, condition: $condition) {
      id
      type
      message
      buttonLabel
      buttonUrl
      createdAt
      updatedAt
    }
  }
`;
export const deleteBanner = /* GraphQL */ `
  mutation DeleteBanner(
    $input: DeleteBannerInput!
    $condition: ModelBannerConditionInput
  ) {
    deleteBanner(input: $input, condition: $condition) {
      id
      type
      message
      buttonLabel
      buttonUrl
      createdAt
      updatedAt
    }
  }
`;
export const createFax = /* GraphQL */ `
  mutation CreateFax(
    $input: CreateFaxInput!
    $condition: ModelFaxConditionInput
  ) {
    createFax(input: $input, condition: $condition) {
      id
      ulid
      filename
      fileUlid
      faxSid
      orgNumber
      isViewed
      isDismissed
      to
      from
      contact
      status
      faxStatus
      mediaURL
      createdTimestamp
      pageCount
      fetchState
      tags
      createdAt
      updatedAt
    }
  }
`;
export const updateFax = /* GraphQL */ `
  mutation UpdateFax(
    $input: UpdateFaxInput!
    $condition: ModelFaxConditionInput
  ) {
    updateFax(input: $input, condition: $condition) {
      id
      ulid
      filename
      fileUlid
      faxSid
      orgNumber
      isViewed
      isDismissed
      to
      from
      contact
      status
      faxStatus
      mediaURL
      createdTimestamp
      pageCount
      fetchState
      tags
      createdAt
      updatedAt
    }
  }
`;
export const deleteFax = /* GraphQL */ `
  mutation DeleteFax(
    $input: DeleteFaxInput!
    $condition: ModelFaxConditionInput
  ) {
    deleteFax(input: $input, condition: $condition) {
      id
      ulid
      filename
      fileUlid
      faxSid
      orgNumber
      isViewed
      isDismissed
      to
      from
      contact
      status
      faxStatus
      mediaURL
      createdTimestamp
      pageCount
      fetchState
      tags
      createdAt
      updatedAt
    }
  }
`;
export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      ulid
      text
      createdTimestamp
      to
      from
      attachment
      threadId
      isDismissed
      createdAt
      updatedAt
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      ulid
      text
      createdTimestamp
      to
      from
      attachment
      threadId
      isDismissed
      createdAt
      updatedAt
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      ulid
      text
      createdTimestamp
      to
      from
      attachment
      threadId
      isDismissed
      createdAt
      updatedAt
    }
  }
`;
export const createSecureMessage = /* GraphQL */ `
  mutation CreateSecureMessage(
    $input: CreateSecureMessageInput!
    $condition: ModelSecureMessageConditionInput
  ) {
    createSecureMessage(input: $input, condition: $condition) {
      id
      ulid
      text
      createdTimestamp
      to
      from
      attachment
      threadId
      isDismissed
      senderUlid
      createdAt
      updatedAt
    }
  }
`;
export const updateSecureMessage = /* GraphQL */ `
  mutation UpdateSecureMessage(
    $input: UpdateSecureMessageInput!
    $condition: ModelSecureMessageConditionInput
  ) {
    updateSecureMessage(input: $input, condition: $condition) {
      id
      ulid
      text
      createdTimestamp
      to
      from
      attachment
      threadId
      isDismissed
      senderUlid
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecureMessage = /* GraphQL */ `
  mutation DeleteSecureMessage(
    $input: DeleteSecureMessageInput!
    $condition: ModelSecureMessageConditionInput
  ) {
    deleteSecureMessage(input: $input, condition: $condition) {
      id
      ulid
      text
      createdTimestamp
      to
      from
      attachment
      threadId
      isDismissed
      senderUlid
      createdAt
      updatedAt
    }
  }
`;
export const createSms = /* GraphQL */ `
  mutation CreateSms(
    $input: CreateSmsInput!
    $condition: ModelSmsConditionInput
  ) {
    createSms(input: $input, condition: $condition) {
      id
      ulid
      threadId
      thread {
        id
        threadName
        ulid
        users
        smsmessages {
          items {
            id
            ulid
            threadId
            message
            smsSid
            to
            from
            contact
            status
            createdTimestamp
            isDismissed
            senderUlid
            attachment
            unread
            createdAt
            updatedAt
          }
          nextToken
        }
        messageType
        createdTimestamp
        isDismissed
        newMessageCount
        updatedTimestamp
        orgId
        staffId
        recipientId
        organization {
          id
          ulid
          name
          businessHours
          businessNumber
          timezone
          orgType
          orgLogo
          orgPlan
          loggedInUsers {
            ulid
            session
          }
          stripeId
          stripeOnboarded
          smsThreads {
            nextToken
          }
          phoneNumbers {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      message
      smsSid
      to
      from
      contact
      status
      createdTimestamp
      isDismissed
      senderUlid
      attachment
      unread
      createdAt
      updatedAt
    }
  }
`;
export const updateSms = /* GraphQL */ `
  mutation UpdateSms(
    $input: UpdateSmsInput!
    $condition: ModelSmsConditionInput
  ) {
    updateSms(input: $input, condition: $condition) {
      id
      ulid
      threadId
      thread {
        id
        threadName
        ulid
        users
        smsmessages {
          items {
            id
            ulid
            threadId
            message
            smsSid
            to
            from
            contact
            status
            createdTimestamp
            isDismissed
            senderUlid
            attachment
            unread
            createdAt
            updatedAt
          }
          nextToken
        }
        messageType
        createdTimestamp
        isDismissed
        newMessageCount
        updatedTimestamp
        orgId
        staffId
        recipientId
        organization {
          id
          ulid
          name
          businessHours
          businessNumber
          timezone
          orgType
          orgLogo
          orgPlan
          loggedInUsers {
            ulid
            session
          }
          stripeId
          stripeOnboarded
          smsThreads {
            nextToken
          }
          phoneNumbers {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      message
      smsSid
      to
      from
      contact
      status
      createdTimestamp
      isDismissed
      senderUlid
      attachment
      unread
      createdAt
      updatedAt
    }
  }
`;
export const deleteSms = /* GraphQL */ `
  mutation DeleteSms(
    $input: DeleteSmsInput!
    $condition: ModelSmsConditionInput
  ) {
    deleteSms(input: $input, condition: $condition) {
      id
      ulid
      threadId
      thread {
        id
        threadName
        ulid
        users
        smsmessages {
          items {
            id
            ulid
            threadId
            message
            smsSid
            to
            from
            contact
            status
            createdTimestamp
            isDismissed
            senderUlid
            attachment
            unread
            createdAt
            updatedAt
          }
          nextToken
        }
        messageType
        createdTimestamp
        isDismissed
        newMessageCount
        updatedTimestamp
        orgId
        staffId
        recipientId
        organization {
          id
          ulid
          name
          businessHours
          businessNumber
          timezone
          orgType
          orgLogo
          orgPlan
          loggedInUsers {
            ulid
            session
          }
          stripeId
          stripeOnboarded
          smsThreads {
            nextToken
          }
          phoneNumbers {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      message
      smsSid
      to
      from
      contact
      status
      createdTimestamp
      isDismissed
      senderUlid
      attachment
      unread
      createdAt
      updatedAt
    }
  }
`;
export const createMsgs = /* GraphQL */ `
  mutation CreateMsgs(
    $input: CreateMsgsInput!
    $condition: ModelMsgsConditionInput
  ) {
    createMsgs(input: $input, condition: $condition) {
      id
      threadId
      message
      from
      attachment
      mediaUrl
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateMsgs = /* GraphQL */ `
  mutation UpdateMsgs(
    $input: UpdateMsgsInput!
    $condition: ModelMsgsConditionInput
  ) {
    updateMsgs(input: $input, condition: $condition) {
      id
      threadId
      message
      from
      attachment
      mediaUrl
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteMsgs = /* GraphQL */ `
  mutation DeleteMsgs(
    $input: DeleteMsgsInput!
    $condition: ModelMsgsConditionInput
  ) {
    deleteMsgs(input: $input, condition: $condition) {
      id
      threadId
      message
      from
      attachment
      mediaUrl
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const createRoomPermissions = /* GraphQL */ `
  mutation CreateRoomPermissions(
    $input: CreateRoomPermissionsInput!
    $condition: ModelRoomPermissionsConditionInput
  ) {
    createRoomPermissions(input: $input, condition: $condition) {
      id
      threadId
      roles
      roleactions
      users
      createdAt
      updatedAt
    }
  }
`;
export const updateRoomPermissions = /* GraphQL */ `
  mutation UpdateRoomPermissions(
    $input: UpdateRoomPermissionsInput!
    $condition: ModelRoomPermissionsConditionInput
  ) {
    updateRoomPermissions(input: $input, condition: $condition) {
      id
      threadId
      roles
      roleactions
      users
      createdAt
      updatedAt
    }
  }
`;
export const deleteRoomPermissions = /* GraphQL */ `
  mutation DeleteRoomPermissions(
    $input: DeleteRoomPermissionsInput!
    $condition: ModelRoomPermissionsConditionInput
  ) {
    deleteRoomPermissions(input: $input, condition: $condition) {
      id
      threadId
      roles
      roleactions
      users
      createdAt
      updatedAt
    }
  }
`;
export const createSmsThread = /* GraphQL */ `
  mutation CreateSmsThread(
    $input: CreateSmsThreadInput!
    $condition: ModelSmsThreadConditionInput
  ) {
    createSmsThread(input: $input, condition: $condition) {
      id
      threadName
      ulid
      users
      smsmessages {
        items {
          id
          ulid
          threadId
          thread {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          message
          smsSid
          to
          from
          contact
          status
          createdTimestamp
          isDismissed
          senderUlid
          attachment
          unread
          createdAt
          updatedAt
        }
        nextToken
      }
      messageType
      createdTimestamp
      isDismissed
      newMessageCount
      updatedTimestamp
      orgId
      staffId
      recipientId
      organization {
        id
        ulid
        name
        businessHours
        businessNumber
        timezone
        orgType
        orgLogo
        orgPlan
        loggedInUsers {
          ulid
          session
        }
        stripeId
        stripeOnboarded
        smsThreads {
          items {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          nextToken
        }
        phoneNumbers {
          items {
            id
            number
            type
            inUse
            nickname
            orgDisplayName
            displayNumber
            default
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSmsThread = /* GraphQL */ `
  mutation UpdateSmsThread(
    $input: UpdateSmsThreadInput!
    $condition: ModelSmsThreadConditionInput
  ) {
    updateSmsThread(input: $input, condition: $condition) {
      id
      threadName
      ulid
      users
      smsmessages {
        items {
          id
          ulid
          threadId
          thread {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          message
          smsSid
          to
          from
          contact
          status
          createdTimestamp
          isDismissed
          senderUlid
          attachment
          unread
          createdAt
          updatedAt
        }
        nextToken
      }
      messageType
      createdTimestamp
      isDismissed
      newMessageCount
      updatedTimestamp
      orgId
      staffId
      recipientId
      organization {
        id
        ulid
        name
        businessHours
        businessNumber
        timezone
        orgType
        orgLogo
        orgPlan
        loggedInUsers {
          ulid
          session
        }
        stripeId
        stripeOnboarded
        smsThreads {
          items {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          nextToken
        }
        phoneNumbers {
          items {
            id
            number
            type
            inUse
            nickname
            orgDisplayName
            displayNumber
            default
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSmsThread = /* GraphQL */ `
  mutation DeleteSmsThread(
    $input: DeleteSmsThreadInput!
    $condition: ModelSmsThreadConditionInput
  ) {
    deleteSmsThread(input: $input, condition: $condition) {
      id
      threadName
      ulid
      users
      smsmessages {
        items {
          id
          ulid
          threadId
          thread {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          message
          smsSid
          to
          from
          contact
          status
          createdTimestamp
          isDismissed
          senderUlid
          attachment
          unread
          createdAt
          updatedAt
        }
        nextToken
      }
      messageType
      createdTimestamp
      isDismissed
      newMessageCount
      updatedTimestamp
      orgId
      staffId
      recipientId
      organization {
        id
        ulid
        name
        businessHours
        businessNumber
        timezone
        orgType
        orgLogo
        orgPlan
        loggedInUsers {
          ulid
          session
        }
        stripeId
        stripeOnboarded
        smsThreads {
          items {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          nextToken
        }
        phoneNumbers {
          items {
            id
            number
            type
            inUse
            nickname
            orgDisplayName
            displayNumber
            default
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      ulid
      newnotif
      inapp
      vidnotif
      notifications {
        items {
          id
          userId
          user {
            id
            firstName
            lastName
            ulid
            newnotif
            inapp
            vidnotif
            currentJTI
            auth0Sub
            createdAt
            updatedAt
          }
          type
          createdTimestamp
          data
          form
          orgId
          isActive
          threadId
          createdAt
          updatedAt
        }
        nextToken
      }
      currentJTI
      auth0Sub
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      ulid
      newnotif
      inapp
      vidnotif
      notifications {
        items {
          id
          userId
          user {
            id
            firstName
            lastName
            ulid
            newnotif
            inapp
            vidnotif
            currentJTI
            auth0Sub
            createdAt
            updatedAt
          }
          type
          createdTimestamp
          data
          form
          orgId
          isActive
          threadId
          createdAt
          updatedAt
        }
        nextToken
      }
      currentJTI
      auth0Sub
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      ulid
      newnotif
      inapp
      vidnotif
      notifications {
        items {
          id
          userId
          user {
            id
            firstName
            lastName
            ulid
            newnotif
            inapp
            vidnotif
            currentJTI
            auth0Sub
            createdAt
            updatedAt
          }
          type
          createdTimestamp
          data
          form
          orgId
          isActive
          threadId
          createdAt
          updatedAt
        }
        nextToken
      }
      currentJTI
      auth0Sub
      createdAt
      updatedAt
    }
  }
`;
export const createStripeTransaction = /* GraphQL */ `
  mutation CreateStripeTransaction(
    $input: CreateStripeTransactionInput!
    $condition: ModelStripeTransactionConditionInput
  ) {
    createStripeTransaction(input: $input, condition: $condition) {
      id
      userFrom
      orgTo
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      tags
      createdAt
      updatedAt
    }
  }
`;
export const updateStripeTransaction = /* GraphQL */ `
  mutation UpdateStripeTransaction(
    $input: UpdateStripeTransactionInput!
    $condition: ModelStripeTransactionConditionInput
  ) {
    updateStripeTransaction(input: $input, condition: $condition) {
      id
      userFrom
      orgTo
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      tags
      createdAt
      updatedAt
    }
  }
`;
export const deleteStripeTransaction = /* GraphQL */ `
  mutation DeleteStripeTransaction(
    $input: DeleteStripeTransactionInput!
    $condition: ModelStripeTransactionConditionInput
  ) {
    deleteStripeTransaction(input: $input, condition: $condition) {
      id
      userFrom
      orgTo
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      tags
      createdAt
      updatedAt
    }
  }
`;
export const createStripePayouts = /* GraphQL */ `
  mutation CreateStripePayouts(
    $input: CreateStripePayoutsInput!
    $condition: ModelStripePayoutsConditionInput
  ) {
    createStripePayouts(input: $input, condition: $condition) {
      id
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateStripePayouts = /* GraphQL */ `
  mutation UpdateStripePayouts(
    $input: UpdateStripePayoutsInput!
    $condition: ModelStripePayoutsConditionInput
  ) {
    updateStripePayouts(input: $input, condition: $condition) {
      id
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteStripePayouts = /* GraphQL */ `
  mutation DeleteStripePayouts(
    $input: DeleteStripePayoutsInput!
    $condition: ModelStripePayoutsConditionInput
  ) {
    deleteStripePayouts(input: $input, condition: $condition) {
      id
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      ulid
      amount
      organizationId
      patientId
      type
      description
      currentStatus
      activity
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      ulid
      amount
      organizationId
      patientId
      type
      description
      currentStatus
      activity
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      ulid
      amount
      organizationId
      patientId
      type
      description
      currentStatus
      activity
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const createS3document = /* GraphQL */ `
  mutation CreateS3document(
    $input: CreateS3documentInput!
    $condition: ModelS3documentConditionInput
  ) {
    createS3document(input: $input, condition: $condition) {
      id
      ulid
      fileName
      fileUlid
      uploader
      accessibleOrgs
      createdTimestamp
      meta
      createdAt
      updatedAt
    }
  }
`;
export const updateS3document = /* GraphQL */ `
  mutation UpdateS3document(
    $input: UpdateS3documentInput!
    $condition: ModelS3documentConditionInput
  ) {
    updateS3document(input: $input, condition: $condition) {
      id
      ulid
      fileName
      fileUlid
      uploader
      accessibleOrgs
      createdTimestamp
      meta
      createdAt
      updatedAt
    }
  }
`;
export const deleteS3document = /* GraphQL */ `
  mutation DeleteS3document(
    $input: DeleteS3documentInput!
    $condition: ModelS3documentConditionInput
  ) {
    deleteS3document(input: $input, condition: $condition) {
      id
      ulid
      fileName
      fileUlid
      uploader
      accessibleOrgs
      createdTimestamp
      meta
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        firstName
        lastName
        ulid
        newnotif
        inapp
        vidnotif
        notifications {
          items {
            id
            userId
            type
            createdTimestamp
            data
            form
            orgId
            isActive
            threadId
            createdAt
            updatedAt
          }
          nextToken
        }
        currentJTI
        auth0Sub
        createdAt
        updatedAt
      }
      type
      createdTimestamp
      data
      form
      orgId
      isActive
      threadId
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        firstName
        lastName
        ulid
        newnotif
        inapp
        vidnotif
        notifications {
          items {
            id
            userId
            type
            createdTimestamp
            data
            form
            orgId
            isActive
            threadId
            createdAt
            updatedAt
          }
          nextToken
        }
        currentJTI
        auth0Sub
        createdAt
        updatedAt
      }
      type
      createdTimestamp
      data
      form
      orgId
      isActive
      threadId
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        firstName
        lastName
        ulid
        newnotif
        inapp
        vidnotif
        notifications {
          items {
            id
            userId
            type
            createdTimestamp
            data
            form
            orgId
            isActive
            threadId
            createdAt
            updatedAt
          }
          nextToken
        }
        currentJTI
        auth0Sub
        createdAt
        updatedAt
      }
      type
      createdTimestamp
      data
      form
      orgId
      isActive
      threadId
      createdAt
      updatedAt
    }
  }
`;
export const createCallHistory = /* GraphQL */ `
  mutation CreateCallHistory(
    $input: CreateCallHistoryInput!
    $condition: ModelCallHistoryConditionInput
  ) {
    createCallHistory(input: $input, condition: $condition) {
      id
      participants {
        userVideoId
        name
        userId
        role
        time_joined
        time_left
        totalDuration
      }
      patientId
      host
      roomName
      meetingId
      historyId
      meetingRoom {
        id
        isJoinable
        isCompleted
        historyId
        callHistory {
          id
          participants {
            userVideoId
            name
            userId
            role
            time_joined
            time_left
            totalDuration
          }
          patientId
          host
          roomName
          meetingId
          historyId
          meetingRoom {
            id
            isJoinable
            isCompleted
            historyId
            currentParticipants
            createdAt
            updatedAt
          }
          duration
          createdTimestamp
          createdAt
          updatedAt
        }
        currentParticipants
        createdAt
        updatedAt
      }
      duration
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateCallHistory = /* GraphQL */ `
  mutation UpdateCallHistory(
    $input: UpdateCallHistoryInput!
    $condition: ModelCallHistoryConditionInput
  ) {
    updateCallHistory(input: $input, condition: $condition) {
      id
      participants {
        userVideoId
        name
        userId
        role
        time_joined
        time_left
        totalDuration
      }
      patientId
      host
      roomName
      meetingId
      historyId
      meetingRoom {
        id
        isJoinable
        isCompleted
        historyId
        callHistory {
          id
          participants {
            userVideoId
            name
            userId
            role
            time_joined
            time_left
            totalDuration
          }
          patientId
          host
          roomName
          meetingId
          historyId
          meetingRoom {
            id
            isJoinable
            isCompleted
            historyId
            currentParticipants
            createdAt
            updatedAt
          }
          duration
          createdTimestamp
          createdAt
          updatedAt
        }
        currentParticipants
        createdAt
        updatedAt
      }
      duration
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteCallHistory = /* GraphQL */ `
  mutation DeleteCallHistory(
    $input: DeleteCallHistoryInput!
    $condition: ModelCallHistoryConditionInput
  ) {
    deleteCallHistory(input: $input, condition: $condition) {
      id
      participants {
        userVideoId
        name
        userId
        role
        time_joined
        time_left
        totalDuration
      }
      patientId
      host
      roomName
      meetingId
      historyId
      meetingRoom {
        id
        isJoinable
        isCompleted
        historyId
        callHistory {
          id
          participants {
            userVideoId
            name
            userId
            role
            time_joined
            time_left
            totalDuration
          }
          patientId
          host
          roomName
          meetingId
          historyId
          meetingRoom {
            id
            isJoinable
            isCompleted
            historyId
            currentParticipants
            createdAt
            updatedAt
          }
          duration
          createdTimestamp
          createdAt
          updatedAt
        }
        currentParticipants
        createdAt
        updatedAt
      }
      duration
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const createPaymentTags = /* GraphQL */ `
  mutation CreatePaymentTags(
    $input: CreatePaymentTagsInput!
    $condition: ModelPaymentTagsConditionInput
  ) {
    createPaymentTags(input: $input, condition: $condition) {
      id
      organizationId
      title
      color
      createdAt
      updatedAt
    }
  }
`;
export const updatePaymentTags = /* GraphQL */ `
  mutation UpdatePaymentTags(
    $input: UpdatePaymentTagsInput!
    $condition: ModelPaymentTagsConditionInput
  ) {
    updatePaymentTags(input: $input, condition: $condition) {
      id
      organizationId
      title
      color
      createdAt
      updatedAt
    }
  }
`;
export const deletePaymentTags = /* GraphQL */ `
  mutation DeletePaymentTags(
    $input: DeletePaymentTagsInput!
    $condition: ModelPaymentTagsConditionInput
  ) {
    deletePaymentTags(input: $input, condition: $condition) {
      id
      organizationId
      title
      color
      createdAt
      updatedAt
    }
  }
`;
export const createEfaxTags = /* GraphQL */ `
  mutation CreateEfaxTags(
    $input: CreateEfaxTagsInput!
    $condition: ModelEfaxTagsConditionInput
  ) {
    createEfaxTags(input: $input, condition: $condition) {
      id
      organizationId
      title
      color
      createdAt
      updatedAt
    }
  }
`;
export const updateEfaxTags = /* GraphQL */ `
  mutation UpdateEfaxTags(
    $input: UpdateEfaxTagsInput!
    $condition: ModelEfaxTagsConditionInput
  ) {
    updateEfaxTags(input: $input, condition: $condition) {
      id
      organizationId
      title
      color
      createdAt
      updatedAt
    }
  }
`;
export const deleteEfaxTags = /* GraphQL */ `
  mutation DeleteEfaxTags(
    $input: DeleteEfaxTagsInput!
    $condition: ModelEfaxTagsConditionInput
  ) {
    deleteEfaxTags(input: $input, condition: $condition) {
      id
      organizationId
      title
      color
      createdAt
      updatedAt
    }
  }
`;
export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment(
    $input: CreateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    createAppointment(input: $input, condition: $condition) {
      id
      text
      description
      appointmentType
      startDate
      endDate
      allDay
      organizationId
      assigneeId
      patientId
      status
      color
      copay
      willAttend
      appointmentNotes {
        items {
          id
          userName
          userId
          appointmentId
          appointmentNote
          createdTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment(
    $input: UpdateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    updateAppointment(input: $input, condition: $condition) {
      id
      text
      description
      appointmentType
      startDate
      endDate
      allDay
      organizationId
      assigneeId
      patientId
      status
      color
      copay
      willAttend
      appointmentNotes {
        items {
          id
          userName
          userId
          appointmentId
          appointmentNote
          createdTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment(
    $input: DeleteAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    deleteAppointment(input: $input, condition: $condition) {
      id
      text
      description
      appointmentType
      startDate
      endDate
      allDay
      organizationId
      assigneeId
      patientId
      status
      color
      copay
      willAttend
      appointmentNotes {
        items {
          id
          userName
          userId
          appointmentId
          appointmentNote
          createdTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAppointmentNotes = /* GraphQL */ `
  mutation CreateAppointmentNotes(
    $input: CreateAppointmentNotesInput!
    $condition: ModelAppointmentNotesConditionInput
  ) {
    createAppointmentNotes(input: $input, condition: $condition) {
      id
      userName
      userId
      appointmentId
      appointmentNote
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateAppointmentNotes = /* GraphQL */ `
  mutation UpdateAppointmentNotes(
    $input: UpdateAppointmentNotesInput!
    $condition: ModelAppointmentNotesConditionInput
  ) {
    updateAppointmentNotes(input: $input, condition: $condition) {
      id
      userName
      userId
      appointmentId
      appointmentNote
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppointmentNotes = /* GraphQL */ `
  mutation DeleteAppointmentNotes(
    $input: DeleteAppointmentNotesInput!
    $condition: ModelAppointmentNotesConditionInput
  ) {
    deleteAppointmentNotes(input: $input, condition: $condition) {
      id
      userName
      userId
      appointmentId
      appointmentNote
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const createMeetingRoom = /* GraphQL */ `
  mutation CreateMeetingRoom(
    $input: CreateMeetingRoomInput!
    $condition: ModelMeetingRoomConditionInput
  ) {
    createMeetingRoom(input: $input, condition: $condition) {
      id
      isJoinable
      isCompleted
      historyId
      callHistory {
        id
        participants {
          userVideoId
          name
          userId
          role
          time_joined
          time_left
          totalDuration
        }
        patientId
        host
        roomName
        meetingId
        historyId
        meetingRoom {
          id
          isJoinable
          isCompleted
          historyId
          callHistory {
            id
            patientId
            host
            roomName
            meetingId
            historyId
            duration
            createdTimestamp
            createdAt
            updatedAt
          }
          currentParticipants
          createdAt
          updatedAt
        }
        duration
        createdTimestamp
        createdAt
        updatedAt
      }
      currentParticipants
      createdAt
      updatedAt
    }
  }
`;
export const updateMeetingRoom = /* GraphQL */ `
  mutation UpdateMeetingRoom(
    $input: UpdateMeetingRoomInput!
    $condition: ModelMeetingRoomConditionInput
  ) {
    updateMeetingRoom(input: $input, condition: $condition) {
      id
      isJoinable
      isCompleted
      historyId
      callHistory {
        id
        participants {
          userVideoId
          name
          userId
          role
          time_joined
          time_left
          totalDuration
        }
        patientId
        host
        roomName
        meetingId
        historyId
        meetingRoom {
          id
          isJoinable
          isCompleted
          historyId
          callHistory {
            id
            patientId
            host
            roomName
            meetingId
            historyId
            duration
            createdTimestamp
            createdAt
            updatedAt
          }
          currentParticipants
          createdAt
          updatedAt
        }
        duration
        createdTimestamp
        createdAt
        updatedAt
      }
      currentParticipants
      createdAt
      updatedAt
    }
  }
`;
export const deleteMeetingRoom = /* GraphQL */ `
  mutation DeleteMeetingRoom(
    $input: DeleteMeetingRoomInput!
    $condition: ModelMeetingRoomConditionInput
  ) {
    deleteMeetingRoom(input: $input, condition: $condition) {
      id
      isJoinable
      isCompleted
      historyId
      callHistory {
        id
        participants {
          userVideoId
          name
          userId
          role
          time_joined
          time_left
          totalDuration
        }
        patientId
        host
        roomName
        meetingId
        historyId
        meetingRoom {
          id
          isJoinable
          isCompleted
          historyId
          callHistory {
            id
            patientId
            host
            roomName
            meetingId
            historyId
            duration
            createdTimestamp
            createdAt
            updatedAt
          }
          currentParticipants
          createdAt
          updatedAt
        }
        duration
        createdTimestamp
        createdAt
        updatedAt
      }
      currentParticipants
      createdAt
      updatedAt
    }
  }
`;
export const createBulkPatient = /* GraphQL */ `
  mutation CreateBulkPatient(
    $input: CreateBulkPatientInput!
    $condition: ModelBulkPatientConditionInput
  ) {
    createBulkPatient(input: $input, condition: $condition) {
      id
      success
      failure
      documentId
      completed
      createdAt
      updatedAt
    }
  }
`;
export const updateBulkPatient = /* GraphQL */ `
  mutation UpdateBulkPatient(
    $input: UpdateBulkPatientInput!
    $condition: ModelBulkPatientConditionInput
  ) {
    updateBulkPatient(input: $input, condition: $condition) {
      id
      success
      failure
      documentId
      completed
      createdAt
      updatedAt
    }
  }
`;
export const deleteBulkPatient = /* GraphQL */ `
  mutation DeleteBulkPatient(
    $input: DeleteBulkPatientInput!
    $condition: ModelBulkPatientConditionInput
  ) {
    deleteBulkPatient(input: $input, condition: $condition) {
      id
      success
      failure
      documentId
      completed
      createdAt
      updatedAt
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      ulid
      name
      businessHours
      businessNumber
      timezone
      orgType
      orgLogo
      orgPlan
      loggedInUsers {
        ulid
        session
      }
      stripeId
      stripeOnboarded
      smsThreads {
        items {
          id
          threadName
          ulid
          users
          smsmessages {
            nextToken
          }
          messageType
          createdTimestamp
          isDismissed
          newMessageCount
          updatedTimestamp
          orgId
          staffId
          recipientId
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      phoneNumbers {
        items {
          id
          number
          type
          inUse
          nickname
          orgDisplayName
          displayNumber
          default
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      ulid
      name
      businessHours
      businessNumber
      timezone
      orgType
      orgLogo
      orgPlan
      loggedInUsers {
        ulid
        session
      }
      stripeId
      stripeOnboarded
      smsThreads {
        items {
          id
          threadName
          ulid
          users
          smsmessages {
            nextToken
          }
          messageType
          createdTimestamp
          isDismissed
          newMessageCount
          updatedTimestamp
          orgId
          staffId
          recipientId
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      phoneNumbers {
        items {
          id
          number
          type
          inUse
          nickname
          orgDisplayName
          displayNumber
          default
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      ulid
      name
      businessHours
      businessNumber
      timezone
      orgType
      orgLogo
      orgPlan
      loggedInUsers {
        ulid
        session
      }
      stripeId
      stripeOnboarded
      smsThreads {
        items {
          id
          threadName
          ulid
          users
          smsmessages {
            nextToken
          }
          messageType
          createdTimestamp
          isDismissed
          newMessageCount
          updatedTimestamp
          orgId
          staffId
          recipientId
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      phoneNumbers {
        items {
          id
          number
          type
          inUse
          nickname
          orgDisplayName
          displayNumber
          default
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPhoneNumbers = /* GraphQL */ `
  mutation CreatePhoneNumbers(
    $input: CreatePhoneNumbersInput!
    $condition: ModelPhoneNumbersConditionInput
  ) {
    createPhoneNumbers(input: $input, condition: $condition) {
      id
      number
      type
      inUse
      nickname
      orgDisplayName
      displayNumber
      default
      organization {
        id
        ulid
        name
        businessHours
        businessNumber
        timezone
        orgType
        orgLogo
        orgPlan
        loggedInUsers {
          ulid
          session
        }
        stripeId
        stripeOnboarded
        smsThreads {
          items {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          nextToken
        }
        phoneNumbers {
          items {
            id
            number
            type
            inUse
            nickname
            orgDisplayName
            displayNumber
            default
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePhoneNumbers = /* GraphQL */ `
  mutation UpdatePhoneNumbers(
    $input: UpdatePhoneNumbersInput!
    $condition: ModelPhoneNumbersConditionInput
  ) {
    updatePhoneNumbers(input: $input, condition: $condition) {
      id
      number
      type
      inUse
      nickname
      orgDisplayName
      displayNumber
      default
      organization {
        id
        ulid
        name
        businessHours
        businessNumber
        timezone
        orgType
        orgLogo
        orgPlan
        loggedInUsers {
          ulid
          session
        }
        stripeId
        stripeOnboarded
        smsThreads {
          items {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          nextToken
        }
        phoneNumbers {
          items {
            id
            number
            type
            inUse
            nickname
            orgDisplayName
            displayNumber
            default
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePhoneNumbers = /* GraphQL */ `
  mutation DeletePhoneNumbers(
    $input: DeletePhoneNumbersInput!
    $condition: ModelPhoneNumbersConditionInput
  ) {
    deletePhoneNumbers(input: $input, condition: $condition) {
      id
      number
      type
      inUse
      nickname
      orgDisplayName
      displayNumber
      default
      organization {
        id
        ulid
        name
        businessHours
        businessNumber
        timezone
        orgType
        orgLogo
        orgPlan
        loggedInUsers {
          ulid
          session
        }
        stripeId
        stripeOnboarded
        smsThreads {
          items {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          nextToken
        }
        phoneNumbers {
          items {
            id
            number
            type
            inUse
            nickname
            orgDisplayName
            displayNumber
            default
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
