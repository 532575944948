const ROLES = {
  JOPE: 'jope',
  OWNER: 'owner',
  ADMIN: 'admin',
  USER: 'user',
  PATIENT: 'patient',
};

/**
 * Session storage
 */
class Session {
    //#region User properties
  
    static #customOrgSwitcher = 'customOrgSwitcher';
  
    /** User name */
    static get CustomOrgSwitcher(){
       return sessionStorage.getItem(this.#customOrgSwitcher);
    }
  
    /**
     * @param {boolean} value User name
     */
    static SetCustomOrgSwitcher(value){
      sessionStorage.setItem(this.#customOrgSwitcher, value);
  }

  //#region User properties
  
    static #useremail = 'useremail';
  
    /** User name */
    static get UserEmail(){
       return sessionStorage.getItem(this.#useremail);
    }
  
    /**
     * @param {string} value User name
     */
    static SetUserEmail(value){
      sessionStorage.setItem(this.#useremail, value);
  }

  //#region User properties
  
  static #username = 'username';
  
  /** User name */
  static get UserName(){
     return sessionStorage.getItem(this.#username);
  }

  /**
   * @param {string} value User name
   */
  static SetUserName(value){
    sessionStorage.setItem(this.#username, value);
  }








  static #loggedInUsers = 'loggedInUsers';
  
  /** User name */
  static get LoggedInUsers(){
     return sessionStorage.getItem(this.#loggedInUsers);
  }

  /**
   * @param {string} value User name
   */
  static SetLoggedInUsers(value){
    sessionStorage.setItem(this.#loggedInUsers, JSON.stringify(value));
  }








  static #userId = 'userid';
  
  /** User ulid */
  static get UserId(){
     return sessionStorage.getItem(this.#userId);
  }

  /**
   * @param {string} value User ulid
   */
  static SetUserId(value){
    sessionStorage.setItem(this.#userId, value);
  }

  static #organizationId = 'organizationId';
  
  /** Organization ulid */
  static get OrganizationId(){
    return sessionStorage.getItem(this.#organizationId);
  }

  /**
   * @param {string} value Organization ulid
   */
  static SetOrganizationId(value){
    sessionStorage.setItem(this.#organizationId, value);
  }

  static #accessibleOrgs = 'accessibleOrgs';
  
  /** Organization ulid */
  static get AccessibleOrgs(){
    return sessionStorage.getItem(this.#accessibleOrgs);
  }

  /**
   * @param {string} value Organization ulid
   */
  static SetAccessibleOrgs(value){
    sessionStorage.setItem(this.#accessibleOrgs, value);
  }

  static #homeOrg = 'homeOrg';
  
  /** Organization ulid */
  static get HomeOrg(){
    return sessionStorage.getItem(this.#homeOrg);
  }

  /**
   * @param {string} value Organization ulid
   */
  static SetHomeOrg(value){
    sessionStorage.setItem(this.#homeOrg, value);
  }
  
  static #organizationStripeId = 'organizationStripeId';
  
  /** Organization ulid */
  static get OrganizationStripeId(){
    return sessionStorage.getItem(this.#organizationStripeId);
  }

  /**
   * @param {string} value Organization ulid
   */
  static SetOrganizationStripeId(value){
    sessionStorage.setItem(this.#organizationStripeId, value);
  }

  static #roles = 'roles';

  /** roles array of string */
  static get Roles(){
    return sessionStorage.getItem(this.#roles);
  }

  /** roles array of string */
  static get IsAdmin(){
    return this.Roles.includes(ROLES.ADMIN);
  }

  /**
   * @param {string} value Organization ulid
   */
  static SetRoles(value){
    sessionStorage.setItem(this.#roles, value);
  }

  static #orgNumbers = 'orgNumbers';
  
  /** User name */
  static get orgNumbers(){
     return sessionStorage.getItem(this.#orgNumbers);
  }

  /**
   * @param {string} value User name
   */
  static SetOrgNumbers(value){
    sessionStorage.setItem(this.#orgNumbers, JSON.stringify(value));
  }

  static #userFaxNum = 'userFaxNum';
  
  /** User name */
  static get userFaxNum(){
     return sessionStorage.getItem(this.#userFaxNum);
  }

  /**
   * @param {string} value User name
   */
  static SetUserFaxNum(value){
    sessionStorage.setItem(this.#userFaxNum, JSON.stringify(value));
  }

  static #userSmsNum = 'userSmsNum';
  
  /** User name */
  static get userSmsNum(){
     return sessionStorage.getItem(this.#userSmsNum);
  }

  /**
   * @param {string} value User name
   */
  static SetUserSmsNum(value){
    sessionStorage.setItem(this.#userSmsNum, value);
  }
  
  static #avatar = 'avatar';

  /** User avatar url */
  static get AvatarUrl() {
    return sessionStorage.getItem(this.#avatar);
  }

  /**
   * @param {string} value avatar url
   */
  static SetAvatarUrl(value) {
    sessionStorage.setItem(this.#avatar, value);
  }

  static #tokentime = 'tokentime';

  /** Auth0 token expire time */
  static get TokenExpireTime() {
    return Number(sessionStorage.getItem(this.#tokentime));
  }

  /**
   * @param {string} value Expire time stamp
   */
  static SetTokenExpireTime(value) {
    sessionStorage.setItem(this.#tokentime, value);
  }

  static #token = 'token';

  /** Auth0 token */
  static get Token() {
    return sessionStorage.getItem(this.#token);
  }

  /**
   * @param {string} value JWT token
   */
  static SetToken(value) {
    sessionStorage.setItem(this.#token, value);
  }
  //#endregion End user properties

  //#region Organization properties
  
  static #orgType = 'orgtype';

  /** Organization type */
  static get OrgType() {
    return sessionStorage.getItem(this.#orgType);
  }

  /**
   * @param {string} value Organization type
   * @example medical, professional
   */
  static SetOrgType(value){
    sessionStorage.setItem(this.#orgType, value);
  }

  static #orgplan = 'orgplan';

  /**
   * Organization plan
   * @returns ["sms","appointments","video"]
   */
  static get OrgPlan() {
    
    return JSON.parse(sessionStorage.getItem(this.#orgplan)) || [];
  }

  /**
   * @param {string} value Organization plan
   * @example ["sms","appointments","video"]
   */
  static SetOrgPlan(value){
    sessionStorage.setItem(this.#orgplan, JSON.stringify(value));
  }

  static #businessHours = 'businesshours';

  /**
   * Business hours
   * @returns [{ Monday: [{ Finish: "1630", Start: "0900" }] }]
   */
  static get BusinessHours() {
    return JSON.parse(sessionStorage.getItem(this.#businessHours)) || [];
  }

  /**
   * @param {string} value business hours
   * @example [{ Monday: [{ Finish: "1630", Start: "0900" }] }]
   */
  static SetBusinessHours(value) {
    sessionStorage.setItem(this.#businessHours, JSON.stringify(value));
  }

  static #isStripeConnect = 'isStripeConnect';
  
  /** isStripeConnect  */
  static get IsStripeConnect(){
    if(sessionStorage.getItem(this.#isStripeConnect) === 'true'){return true}
    else { return false }
  }

  /**
   * @param {boolean} value IsStripeConnect
   */
  static SetIsStripeConnect(value){
    sessionStorage.setItem(this.#isStripeConnect, value);
  }

  static #orglogo = 'orglogo';

  /** User orglogo url */
  static get OrgLogoUrl() {
    return sessionStorage.getItem(this.#orglogo);
  }

  /**
   * @param {string} value avatar url
   */
  static SetOrgLogoUrl(value) {
    sessionStorage.setItem(this.#orglogo, value);
  }

  static #orgname = 'orgname';

  /** Organization name */
  static get OrgName() {
    return sessionStorage.getItem(this.#orgname);
  }

  /**
   * @param {string} value Organization name
   */
  static SetOrgName(value) {
    sessionStorage.setItem(this.#orgname, value);
  }

  static #businessnumber = 'businessnumber';

  /** Organization business number */
  static get BusinessNumber() {
    return sessionStorage.getItem(this.#businessnumber);
  }

  /**
   * @param {string} value Business number
   */
  static SetBusinessNumber(value) {
    sessionStorage.setItem(this.#businessnumber, value);
  }
  
  //#endregion End Oraganization properties

  static setUserDetails(user) {
    // Session.SetUserId(user.ulid);
    // Session.SetUserName(`${user.firstName} ${user.lastName}`);
    // Session.SetOrganizationId(user.OrganizationId);
    Session.SetRoles(user.role);
    // Session.SetAvatarUrl(user.avatarUrl || '/user2.png');
  }

  static #patients = 'patients';

  /**
   * Business hours
   * @returns [{ Monday: [{ Finish: "1630", Start: "0900" }] }]
   */
  static get patients() {
    return JSON.parse(sessionStorage.getItem(this.#patients)) || [];
  }

  /**
   * @param {string} value business hours
   * @example [{ Monday: [{ Finish: "1630", Start: "0900" }] }]
   */
  static Setpatients(value) {
    sessionStorage.setItem(this.#patients, JSON.stringify(value));
  }

  static #contacts = 'contacts';

  /**
   * Business hours
   * @returns [{ Monday: [{ Finish: "1630", Start: "0900" }] }]
   */
  static get contacts() {
    return JSON.parse(sessionStorage.getItem(this.#contacts)) || [];
  }

  /**
   * @param {string} value business hours
   * @example [{ Monday: [{ Finish: "1630", Start: "0900" }] }]
   */
  static Setcontacts(value) {
    sessionStorage.setItem(this.#contacts, JSON.stringify(value));
  }

  static #twilioNumbers = 'twilioNumbers';

  /**
   * Business hours
   * @returns [{ Monday: [{ Finish: "1630", Start: "0900" }] }]
   */
  static get twilioNumbers() {
    return JSON.parse(sessionStorage.getItem(this.#twilioNumbers)) || [];
  }

  /**
   * @param {string} value business hours
   * @example [{ Monday: [{ Finish: "1630", Start: "0900" }] }]
   */
  static SetTwilioNumbers(value) {
    sessionStorage.setItem(this.#twilioNumbers, JSON.stringify(value));
  }

  static clear() {
    sessionStorage.clear();
  }
}

export default Session;
