// function to format whole int to floats

const Currency = value => {
  // Convert the value to a floating point number in case it arrives as a string.
  const numeric = parseFloat(value);
  // Specify the local currency.
  return numeric.toLocaleString('USD', { style: 'currency', currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export default Currency;
