import React, { Suspense, lazy, useState, useEffect, useContext } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import {
  IconButton,
  CircularProgress,
  Alert as MuiAlert,
  Menu,
  MenuItem,
  Backdrop,
  Button,
  Snackbar
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {MoreVertOutlined} from '@mui/icons-material';
import history from './utils/history';
import Session from "./session";
import { useAuth0 } from "./react-auth0-spa";
import TopBar from './components/constant/topBar';
import SideNav from './components/constant/sideNav';
import Footer from './components/constant/footer';
import BottomNav from './components/constant/bottomNav';
import {useSmsThreads} from './appsync-hooks/smsThreadHook';
import {useOrgPayments} from './appsync-hooks/paymentsHook';
import {useOrgPayouts} from './appsync-hooks/payoutsHook';
import {useFaxes} from './appsync-hooks/faxHook';
import {useOrganization} from './appsync-hooks/organizationHook';
import {useAppointments} from './appsync-hooks/appointmentsHook';
import { Firebase } from "./firebaseContext";
import ErrorBoundary from './utils/error-boundary';
import { LocalLanguageContext } from './components/LocalLanguageProvider';
import ProfileTab from './components/profile/ProfileTab';
import OrganizationTab from './components/profile/OrganizationTab';
import AccountTab from './components/profile/AccountTab';
import AdminTab from './components/timeClock/adminTab';
import TimeTab from './components/timeClock/timeTab';
import ReportsTab from './components/timeClock/reportsTab';
import UsersTab from './components/profile/UsersTab';
import PaymentReportsTab from './components/payment/reportsTab';
import PaymentsTab from './components/payment/paymentsTab';
import {useTwilioConversationClient} from './appsync-hooks/twilioConversation/clientInit';
import './styleOverrides.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Appointment = lazy(() => import('./views/Appointment'));
const Efax = lazy(() => import("./views/Efax"));
const SMS = lazy(() => import("./views/SMS"));
const InternalChat = lazy(() => import('./views/InternalChat'));
const SecureChat = lazy(() => import('./views/SecureChat'));
const Payment = lazy(() => import("./views/Payment"));
const TimeClock = lazy(() => import('./views/TimeClock'));
const Patients = lazy(() => import("./views/Patients"));
const Contacts = lazy(() => import("./views/Contacts"));
const Profile = lazy(() => import("./views/Profile"));
const SignUp = lazy(() => import("./views/SignUp"));
const Receipt = lazy(() => import("./views/Receipt"));
const StripeConnect = lazy(() => import("./views/StripeConnect"));
const Home = lazy(() => import("./views/Home"));
const CardManagment = lazy(() => import('./views/CardManagment'));
const Support = lazy(() => import("./views/Support"));
const UploadFileStream = lazy(() => import('./views/UploadFileStream'));
const AccountLinkCompleted = lazy(() => import('./components/payment/accountLinkComplete'));
const BannerController = lazy(() => import('./components/banners/BannerController'));

const PatientAppointments = lazy(() => import("./views/patientAppointments/PatientAppointments"));
const PatientProfile = lazy(() => import("./views/PatientProfile"));
const PatientChatMobile = lazy(() => import("./views/PatientChatMobile"));
const PatientChatDesktop = lazy(() => import("./views/PatientChatDesktop"));

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  root: { 
    display: 'flex',
    flexDirection: 'column',
    
    paddingBottom: '0px',
    height: '100vh',
  },
  top: {
    order: 0,
    zIndex: 1299
  },
  topLogOut: {
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 103,
  },
  center:{
    order: 1,
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    paddingBottom: '0px',
    backgroundColor: 'white',
    overflow: 'hidden'
  },
  sideBar: {
    order: 0,
  },
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    paddingBottom: '0px',
  },
  centerContent: {
    overflowY: 'auto',
    height: '100%',
    width: '100%'
  },
  bottomNavBar: {
    position: 'fixed',
    bottom: 0,
    width: '100%'
  },
  bottom: {
    order: 1,
    alignSelf: 'center',
    width: '100%',
    padding: '0px',
  },
  snackbarActionButton: {
    marginLeft: '10px',
    color: 'white !important',
    border: '1px solid white !important',
  },
}));

const App = () => {
  const [user, setUser] = useState(null);
  const [useremail, setUserEmail] = useState('');
  const [userPortalType, setUserPortalType] = useState(null);
  const [permissions, setPermissions] = useState({
    appointment: {viewable: false},
    payment: {viewable: false},
    secure_messaging: {viewable: true},
    sms: {viewable: false},
    chat: {viewable: false},
    efax: {viewable: false},
    patients: {viewable: false},
    contacts: {viewable: false},
    time_clock: {viewable: false}
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [hasNavBar, setHasNavBar] = useState(false);
  const [hasBottomNavBar, setHasBottomNavBar] = useState(false);
  const [hasTopLogOut, setHasTopLogOut] = useState(false);
  const [anchorElLogOut, setAnchorElLogOut] = useState(null);
  const { isAuthenticated, user: authUser, logout } = useAuth0();
  const [smsThreads, threadsLoaded] = useSmsThreads();
  const orgPayments = useOrgPayments();
  const orgPayouts = useOrgPayouts();
  const [receivedFaxQuery, sentFaxQuery] = useFaxes();
  const organization = useOrganization();
  const classes = useStyles();
  const { workerLang } = useContext(LocalLanguageContext);
  const firebaseMessaging = Firebase();
  const openLogOut = Boolean(anchorElLogOut);
  const [
    twilioConversationClient,
    twilioChat,
    twilioChatUnread,
    twilioOrgConversationClient,
    twilioSecureMessage,
    twilioSecureMessageUnread, 
    onMessageAddedHandler,
    resetUnread
  ] = useTwilioConversationClient()
  const hasSupportRole = Session.CustomOrgSwitcher;
  useAppointments();

  const LokalisedContent = (key, fallback) => {
    const translated_string = workerLang[key];
    return translated_string ? translated_string : fallback;
  };

  useEffect(() => {
    if(firebaseMessaging){
      setOpenSnackBar(true);
    }
  }, [firebaseMessaging]);

  useEffect(() => {
    if (authUser) {
      setPermissions(authUser["https://sbt-dashboard.com/Permissions"]);
      const userRoles = authUser["https://sbt-dashboard.com/roles"];
      if(userRoles && userRoles.includes("patient")){
        setUserPortalType("patient");
      } else {
        setUserPortalType("admin");
      }
    }
  }, [authUser]);

  useEffect(() => {
    setUser(Session.UserName);
    // eslint-disable-next-line
  }, [user, Session.UserName]);

  useEffect(() => {
    setUserEmail(Session.UserEmail);
    // eslint-disable-next-line
  }, [useremail, Session.UserEmail]);

  useEffect(() => {
    if (isMobile) return;
    const script = document.createElement('script');
    script.src = 'https://js-na1.hs-scripts.com/21260082.js';
    script.async = true;
    script.type = 'text/javascript';
    document.body.appendChild(script);
  }, []);
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleClickLogOut = (event) => {
    setAnchorElLogOut(event.currentTarget);
  };

  const handleCloseLogOut = () => {
    setAnchorElLogOut(null);
    logout({ returnTo: 'https://examroomlive.com/patients' });
  };

  return (
    <ErrorBoundary isUserAuthenticated={isAuthenticated}>
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        {
            <Alert 
              onClose={handleClose} 
              severity="info" 
              sx={{ width: '100%' }} 
              style={{}}
              action={
                <Button variant='outlined' className={classes.snackbarActionButton} onClick={() => {
                  if(firebaseMessaging?.body === 'Telehealth'){
                    window.open(firebaseMessaging?.path, "_blank");
                  } else {
                    window.location.href = firebaseMessaging?.path
                  }
                }}>
                  View
                </Button>
              }
            >
              {firebaseMessaging?.title || 'New Notification'}
            </Alert>
        }
      </Snackbar>
      <BrowserRouter history={history} >
        <Suspense 
          fallback={
            <Backdrop
              className={classes.backdrop}
              open={true}
            >
              <CircularProgress />
            </Backdrop>
          }
        >
          <div className={classes.root}>

            {
              hasNavBar
                && 
              <div id='SBTTopBar' className={classes.top}>
                <TopBar userPortalType={userPortalType} twilioConversationClient={twilioConversationClient}/>
              </div>
            }

            {
              isAuthenticated 
                &&
              userPortalType === "admin" 
                &&
              <BannerController/>
            }

            {
              hasTopLogOut
                && 
              <div id='SBTTopBar' className={classes.topLogOut}>
                <IconButton
                  onClick={handleClickLogOut}
                  size="large"
                  aria-controls={openLogOut ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openLogOut ? 'true' : undefined}
                >
                  <MoreVertOutlined fontSize="inherit" style={{paddingBottom: 3, color: 'white'}}/>
                </IconButton>
                <Menu
                  anchorEl={anchorElLogOut}
                  id="account-menu"
                  open={openLogOut}
                  onClose={() => {
                    setAnchorElLogOut(null);
                  }}
                >
                  <MenuItem onClick={handleCloseLogOut}>
                    {LokalisedContent('nav_bar_logout', 'Log Out')}
                  </MenuItem>
                </Menu>
              </div>
            }
          
            <div className={classes.center}>
              
              {
                hasNavBar
                  && 
                <div id='SBTSideNav' className={classes.sideBar}>
                  <SideNav twilioChat={twilioChat} twilioChatUnread={twilioChatUnread} twilioSecureMessageUnread={twilioSecureMessageUnread} />
                </div>
              }

              <div className={classes.centerContainer}>
              
                <div id='SBTCenterContent' className={classes.centerContent}>

                  {
                    userPortalType === null
                      &&
                    <Routes>
                      <Route path="signup" element={<SignUp />} />
                      <Route path="receipt" element={<Receipt />}/>
                      <Route
                        path="*"
                        element={
                          <Home />
                        }
                      />
                    </Routes>
                  }

                  {
                    userPortalType === "admin" 
                      &&
                    <Routes>
                      <Route 
                        path="/appointment/*"
                        element={
                          isAuthenticated
                            &&
                          permissions.appointment.viewable
                            &&
                          <Appointment
                            setHasNavBar={setHasNavBar}
                          />
                        } 
                      />
                      <Route 
                        path="/eFax/*" 
                        element={
                          isAuthenticated
                            &&
                          permissions.efax.viewable
                            &&
                          <Efax
                            receivedFaxes={receivedFaxQuery}
                            sentFaxes={sentFaxQuery}
                            orgDetails={organization}
                            setHasNavBar={setHasNavBar}
                          />
                        }
                      />
                      <Route 
                        path="/sms/*" 
                        element={
                          isAuthenticated
                            &&
                          permissions.sms.viewable 
                            &&
                          <SMS
                            smsThreads={smsThreads}
                            setHasNavBar={setHasNavBar}
                            threadsLoaded={threadsLoaded}
                          />
                        } 
                      />
                      <Route 
                        path="/chat/*" 
                        element={
                          isAuthenticated
                            &&
                          permissions.chat.viewable
                            && 
                          <InternalChat 
                            resetUnread={resetUnread} 
                            onMessageAddedHandler={onMessageAddedHandler} 
                            twilioChat={twilioChat} 
                            twilioConversationClient={twilioConversationClient} 
                            setHasNavBar={setHasNavBar}
                          />
                        }
                      />
                      <Route
                        path="/securemessage/*" 
                        element={
                          isAuthenticated
                            &&
                          permissions.secure_messaging.viewable
                            &&
                          <SecureChat 
                            resetUnread={resetUnread} 
                            onMessageAddedHandler={onMessageAddedHandler} 
                            twilioSecureMessage={twilioSecureMessage} 
                            twilioConversationClient={twilioOrgConversationClient} 
                            setHasNavBar={setHasNavBar}
                          />
                        } 
                      />
                      <Route 
                        path="/payment" 
                        element={
                          isAuthenticated
                            &&
                          permissions.payment.viewable
                            &&
                          <Payment 
                            orgPayments={orgPayments} 
                            orgPayouts={orgPayouts}
                            setHasNavBar={setHasNavBar}
                          />
                        } 
                      >
                        <Route 
                          index
                          element={
                            isAuthenticated
                              &&
                            <PaymentsTab orgPayments={orgPayments} />
                          } 
                        />
                        <Route 
                          path="reports" 
                          element={
                            isAuthenticated
                              &&
                            (!isMobile && permissions.payment.reports_options.viewable && Session.IsStripeConnect)
                              && 
                            <PaymentReportsTab 
                              orgPayments={orgPayments} 
                              orgPayouts={orgPayouts}
                            />
                          } 
                        />
                      </Route>
                      <Route 
                        path="timeclock"
                        element={
                          isAuthenticated
                            &&
                          permissions.time_clock.viewable
                            &&
                          <TimeClock
                            setHasNavBar={setHasNavBar}
                          />
                        } 
                      >
                        <Route 
                          index
                          element={
                            isAuthenticated
                              &&
                            <TimeTab />
                          } 
                        />
                        <Route 
                          path="reports" 
                          element={
                            isAuthenticated
                              &&
                            <ReportsTab />
                          } 
                        />
                        <Route 
                          path="admin" 
                          element={
                            isAuthenticated
                              &&
                            (permissions.time_clock.admin_reports_options.viewable && !isMobile)
                              &&
                            <AdminTab />
                          } 
                        />
                      </Route>
                      <Route 
                        path="/patients/*"
                        element={
                          isAuthenticated
                            &&
                          permissions.patients.viewable
                            &&
                          <Patients
                            setHasNavBar={setHasNavBar}
                            AuthUser={authUser}
                          />
                        }
                      />
                      <Route
                        path="/contacts/*"
                        element={
                          isAuthenticated
                            &&
                          permissions.contacts.viewable
                            &&
                          <Contacts
                            setHasNavBar={setHasNavBar}
                            AuthUser={authUser}
                          />
                        } 
                      />
                      <Route 
                        path="profile" 
                        element={
                          isAuthenticated
                            &&
                          <Profile
                            setHasNavBar={setHasNavBar}
                            AuthUser={authUser}
                          />
                        } 
                      >
                        <Route 
                          index 
                          element={
                            isAuthenticated
                              &&
                            <ProfileTab/>
                          } 
                        />
                        <Route 
                          path="org" 
                          element={
                            isAuthenticated
                              &&
                            <OrganizationTab/>
                          } 
                        />
                        <Route 
                          path="users" 
                          element={
                            isAuthenticated
                              &&
                            permissions.profile.manage_users.viewable
                              &&
                            <UsersTab AuthUser={authUser}/>
                          } 
                        />
                        <Route 
                          path="account" 
                          element={
                            isAuthenticated
                              &&
                            permissions.profile.manage_services.viewable
                              &&  
                            <AccountTab />
                          } 
                        />
                      </Route>
                      <Route
                        path="/support"
                        element={
                          isAuthenticated
                            &&
                          hasSupportRole 
                            &&
                          <Support
                            setHasNavBar={setHasNavBar}
                          />
                        }
                      />
                      <Route 
                        path="/accountlinkcomplete" 
                        element={
                          isAuthenticated
                            &&
                          permissions.payment.viewable
                            && 
                          <AccountLinkCompleted/>
                        } 
                      />
                      <Route 
                        path="/signup" 
                        element={<SignUp/>} 
                      />
                      <Route 
                        path="/stripeconnect"
                        element={
                          isAuthenticated
                            &&
                          <StripeConnect/>
                        }
                      />
                      <Route 
                        path="/card" 
                        element={<CardManagment/>} 
                      />
                      <Route 
                        path="/login"
                        element={<Home/>} 
                      />
                      <Route 
                        path="/streamupload" 
                        element={<UploadFileStream/>}
                      />
                      <Route 
                        path="/receipt" 
                        element={<Receipt/>}
                      />
                      <Route
                        path="/"
                        element={
                          isAuthenticated
                            ?
                          <Navigate to="/profile" replace />
                            :
                          <Home />
                        }
                      />
                    </Routes>
                  }

                  {
                    userPortalType === "patient"
                      && 
                    <Routes>
                      <Route 
                        path="/patient/appointments" 
                        element={
                          isAuthenticated
                            &&
                          <PatientAppointments setHasBottomNavBar={setHasBottomNavBar} setHasTopLogOut={setHasTopLogOut} setHasNavBar={setHasNavBar}/>
                        }
                      />
                      <Route
                        path="/patient/chat" 
                        element={isMobile ? 
                          <PatientChatMobile resetUnread={resetUnread} onMessageAddedHandler={onMessageAddedHandler} twilioSecureMessage={twilioSecureMessage} twilioConversationClient={twilioConversationClient} setHasBottomNavBar={setHasBottomNavBar} setHasTopLogOut={setHasTopLogOut} /> : <PatientChatDesktop resetUnread={resetUnread} onMessageAddedHandler={onMessageAddedHandler} twilioSecureMessage={twilioSecureMessage} twilioConversationClient={twilioConversationClient} setHasNavBar={setHasNavBar}/>
                        } 
                      />
                      <Route 
                        path="/patient/profile" 
                        element={
                          isAuthenticated
                            &&
                          <PatientProfile 
                            setHasBottomNavBar={setHasBottomNavBar} 
                            setHasTopLogOut={setHasTopLogOut} 
                            setHasNavBar={setHasNavBar}
                          />
                        }
                      />
                      <Route 
                        path="/patient/chat/:reschedule?" 
                        element={isMobile ? 
                          <PatientChatMobile resetUnread={resetUnread} onMessageAddedHandler={onMessageAddedHandler} twilioSecureMessage={twilioSecureMessage} twilioConversationClient={twilioConversationClient} setHasBottomNavBar={setHasBottomNavBar} setHasTopLogOut={setHasTopLogOut} /> : 
                          <PatientChatDesktop resetUnread={resetUnread} onMessageAddedHandler={onMessageAddedHandler} twilioSecureMessage={twilioSecureMessage} twilioConversationClient={twilioConversationClient} setHasNavBar={setHasNavBar}/>
                        } 
                      />
                      <Route
                        path="/"
                        element={
                          isAuthenticated
                            ? 
                          <Navigate to="/patient/profile" replace />
                            : 
                          <Home/>
                        }
                      />
                    </Routes>
                  }

                </div>

                {
                  hasBottomNavBar 
                    && 
                  isMobile 
                    && 
                  userPortalType === "patient"
                    && 
                  <div className={classes.bottomNavBar}>
                    <BottomNav />
                  </div>
                }

                {
                  !isMobile
                    &&
                  <div id='SBTFooter' className={classes.bottom}>
                    <Footer />
                  </div>
                }

              </div>
            </div>
          </div>
        </Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
