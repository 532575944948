import React, { useEffect, useState } from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {CircularProgress, Backdrop} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckoutForm from './stripeform';
import Table from './table';
import Session from "../../session";
import StripeConnectView from './stripeView';

const useStyles = makeStyles((theme) => ({
  paymentContainer: {
    width: '60vw', 
    paddingLeft: '100px', 
    [theme.breakpoints.down(768)]: {
      width: '100%',
      paddingLeft: 0, 
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
  },
}))

const PaymentsTab = ({orgPayments})=>{
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [,setReload] = useState(false);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);
  
  const handlerReload = (status) => {
    setReload(status);
  }
  useEffect(() => {
    if(orgPayments) {
      console.log(orgPayments)
      setIsLoading(false);
    }
  }, [orgPayments, setIsLoading]);

  return (
    <>
        {
          isLoading 
            ? 
          <Backdrop
            className={classes.backdrop}
            open={isLoading}
          >
            <CircularProgress />
          </Backdrop> :
          <>
          <Elements stripe={stripePromise}>
              <div className={classes.paymentContainer}>
              {Session.IsStripeConnect ? 
              <CheckoutForm handlerReload={handlerReload}/>
              : <StripeConnectView /> }
              </div>
          </Elements>
              {Session.IsStripeConnect && <Table data={orgPayments}/>}
          </>
        }
    </>
  );
};
// Stripe connect url should also do &state= which is genned by API to prevent CSRF

export default PaymentsTab;
