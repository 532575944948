export const isImage = url => url.match(/\.(jpeg|jpg|jfif|gif|png|HEIC)$/) != null;

export const isPdf = url => url.match(/\.(pdf)$/) != null;

export const ConvertMiles = value => {
  // Convert the value to a floating point number in case it arrives as a string.
  const numeric = parseFloat(value);
  // Specify the local currency.
  return numeric.toLocaleString('USD', { currency: "USD", maximumFractionDigits: 2 });
}

export const startAndEndOfWeek = date => {
  // If no date object supplied, use current date
  // Copy date so don't modify supplied date
  var now = date? new Date(date) : new Date();

  // set time to some convenient value
  now.setHours(0,0,0,0);

  // Get the previous Monday
  var monday = new Date(now);
  monday.setDate(monday.getDate() - monday.getDay() + 1);

  // Get next Sunday
  var sunday = new Date(now);
  sunday.setDate(sunday.getDate() - sunday.getDay() + 7);
  sunday.setHours(23, 59, 59)

  // Return array of date objects
  return [monday, sunday];
}

export const CalculateTime = events => {
  const sorted = events.sort((a,b) => a.timeStamp - b.timeStamp);
  let startTime = 0;
  let breakCount = 0;
  let totalTime = 0;
  sorted.map((x, index) => {
    if (x.event === ClockType.ClockIn) {
      startTime = Number(x.timeStamp);
    }
    
    if (x.event === ClockType.BreakEnd) {
      breakCount += Number(x.timeStamp) - Number(sorted[index - 1].timeStamp);
    }

    if (x.event === ClockType.ClockOut) {
      totalTime = Number(x.timeStamp) - startTime;
    }

    return x;
  });
  
  return Math.abs(totalTime - breakCount);
}

export const CalculateUpdate = events => {
  let startTime = 0;
  let breakCount = 0;
  let totalTime = 0;
  events.map((x, index) => {
    if (x.event === 'Clock In:' || x.event === 'Start Day:') {
      startTime = Number(x.epochTime);
    }
    
    if (x.event === 'End Break:') {
      breakCount += Number(x.epochTime) - Number(events[index - 1].epochTime);
    }

    if (x.event === 'Clock Out:') {    
      totalTime = Number(x.epochTime) - startTime;
    }

    return x;
  });
  if(totalTime === 0){
    return breakCount
  }
  return totalTime - breakCount;
}

export const WorkedTime = (events, nextExpectedActions = []) => {
  let breaks = 0;
  let worked = 0;
  let container = [];

  events.sort((a,b) => a.timeStamp - b.timeStamp).filter(a => a.event !== ClockType.Submit)
    .map((t, index) => {
      container.push(t);
        if (t.event === ClockType.BreakEnd) {
          const breakTime = CalculateTime([events[index - 1], t]);
          breaks += breakTime;
        }
  
        if (t.event === ClockType.ClockOut) {
          const complete = CalculateTime(container);
          worked += complete;
          container = [];
        }

        if (index === events.length - 1) {
          if (nextExpectedActions.includes(ClockType.BreakEnd)) {
            const generated = [
              { event: ClockType.BreakEnd, timeStamp: (~~(Date.now()/1000)) },
              { event: ClockType.ClockOut, timeStamp: (~~(Date.now()/1000)) }
            ];
            const complete = CalculateTime([...container, ...generated]);
            worked += complete;
          } else {
            if (nextExpectedActions.includes(ClockType.ClockOut)) {
              const generated = [
                { event: ClockType.ClockOut, timeStamp: (~~(Date.now()/1000)) }
              ];
              const complete = CalculateTime([...container, ...generated]);
              worked += complete;
            }
          }
        }

      return t;
    });

  
  return [worked, breaks];
}

export const trimReceipt = fileName => {
  if (fileName.length > 25) {
    if (fileName.lastIndexOf('.') !== -1) {
      const ext = fileName.substring(fileName.lastIndexOf('.'));      
      return fileName.substring(0, 25 - ext.length).trim() + ext;
    } else {
      return fileName.substring(0, 25).trim();
    }
  }

  return fileName;
}

export const ClockType = {
  ClockIn: 'CLOCK_IN',
  ClockOut: 'CLOCK_OUT',
  BreakStart: 'BREAK_IN',
  BreakEnd: 'BREAK_OUT',
  Submit: 'SUBMIT',
};