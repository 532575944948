
const contextReducer = (state, action) => {
    let contact;

    switch (action.type) {
        case 'DELETE_CONTACT':
            contact = null;

            return contact;
        case 'ADD_CONTACT':
            contact = action.payload;

            return contact;
        default: 
            return state;
    };
};

export default contextReducer;
