import React, { useState, useEffect }  from "react";
import {
  Dialog,
  Typography,
  DialogContent as MuiDialogContent,
  Snackbar,
  Alert as MuiAlert,
  Button,
  Checkbox,
  Backdrop,
  CircularProgress
} from '@mui/material';
import { 
  CancelOutlined as CancelOutlinedIcon,
  MonetizationOnOutlined as MonetizationOnOutlinedIcon,
  RemoveCircleOutlineOutlined as RemoveCircleOutlineOutlinedIcon
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles, makeStyles } from '@mui/styles';

import {useAPI} from '../../utils/api';
import Session from "../../session";
import { useAuth0 } from "../../react-auth0-spa";

const redTheme = createTheme({ 
    palette: { 
      primary: {
        main: '#F81010' 
      }
    }
  });

const greenTheme = createTheme({ 
    palette: { primary: {
      main: "#10D236",
      contrastText: "#fff" //button text white instead of black
    }, 
  },
    overrides: {
      MuiButton: {
        containedPrimary: {
          color: 'white',
        },
      },
    }
  })
const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: '50px',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '65%',
    },
    serviceContainer:{
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row',
      width: '100%',
    },
    servicesAvaliable: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '70%',
      border: 5,
    },
    includedServices: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '30%',
    },
    manageServiceButtons: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row',
      width: '30%',
      marginTop: 'auto'
    },
    checkMarkNoPadding: {
        margin: '0 5px 0 0',
        padding: 0,
    },
    logoWidth: {
      width: '23vw',
      alignSelf: 'center',
      marginTop: 20
    },
    reminderHeader: {
        fontSize: 8, 
        lineHeight: 1, 
        color: '#4D4F5C'
    },
    reminderText: {
        fontSize: 8, 
        marginLeft: 10, 
        lineHeight: 1, 
        marginRight: 10,
        color: '#4D4F5C'
    },
    backdrop: {
        zIndex: 9999,
    },
  }))

const AccountServicesModals = ({
    modalOpen,
    handlerModal,
  }) => {
    const classes = useStyles();
    const api = useAPI();

    const { logout } = useAuth0();
    const [acknowledgeCheck, setAcknowledgeCheck] = useState(false)
    const [severity, setSeverity] = useState('success');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);
    const [dueAmount, SetDueAmount] = useState(0);

    const [modalState, setModalState] = useState(1);
    // const [updatedServices, setUpdatedServices] = useState(false);

    const handleClose = () => { 
        handlerModal(false);
        setAcknowledgeCheck(false);
        setModalState(1);
    }

    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    }

    useEffect(() => {
      // Create an scoped async function in the hook
      async function anyNameFunction() {
        const result = await api.getProratedDue(Session.OrganizationId);
        console.log(result);
        if(result.status === 200 && result.data !== null){
          SetDueAmount(result.data);
        }
       // setRows(result.data);
        //setIsLoader(false);
      }
      // Execute the created function directly
      anyNameFunction();
      //setIsLoader(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        if(width <= 768){
          setIsMobile(true)
         // window.FreshworksWidget('hide');
        }
        return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);


    useEffect(() => {
        setIsOpen(modalOpen);
    }, [modalOpen]);
    
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const DialogContent = withStyles(() => ({
        root: {
            width: "100%",
            display: 'flex',
            flexDirection: 'column'
        }
    }))(MuiDialogContent);

    const handleDelete = async () => {
      console.log('Enter handle Dete')
        setLoading(true);
        if(dueAmount > 0){
          const InstantInvoice = await api.createInstantInvoice(Session.OrganizationId);
          console.log(InstantInvoice)
          if(InstantInvoice.status === 200 && InstantInvoice.data !== null && (InstantInvoice.data.paid || InstantInvoice.data === 0)){
            console.log(InstantInvoice);
            console.log('Go to final Process')
            await finalProcess();
          }
        }else{
         await finalProcess();
        }
    }

   const finalProcess = async () =>{
    const subscription = await api.archiveOrg();
    if (subscription.status === 200) {
      console.log('deleted')
        logout({ returnTo: 'https://examroomlive.com/subscription-cancellation/' })
        handleClose();
    } else {
        setSeverity('error');
        setSnackBarMessage("Org Archival Failed!");
        setOpenSnackBar(true);
    }
    setLoading(false);
   }

    return (
        <>
        {loading  && <>
                <Backdrop
                    className={classes.backdrop}
                    open={true}
                >
                    <CircularProgress />
                </Backdrop>
            </>}
            <Snackbar
                open={openSnackBar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={6000}
            >
                <Alert 
                severity={severity}
                sx={{ width: '100%' }}
                >
                {snackBarMessage}
                </Alert>
            </Snackbar>
            {modalState === 1 && <Dialog 
                open={modalIsOpen} 
                disableBackdropClick={true} 
                disableEscapeKeyDown
                fullScreen={isMobile}
                onClose={handleClose} 
                fullWidth
                maxWidth="md"
            >
                <DialogContent>
                  {isMobile ? <>
                    <div style={{display: 'flex', alignSelf: 'center', alignItems: 'center' }}> 
                    <RemoveCircleOutlineOutlinedIcon fontSize='small' style={{color: "#F81010"}}/> 
                    <Typography style={{margin: 10}} align='center' variant='h6'> Are you sure </Typography>
                    </div>
                     <Typography style={{ color:"#47AFEB", alignSelf: 'center', lineHeight: 1.2, marginBottom: 10}} align='center' variant='subtitle1'> We are sad to see you go. If there is anything we can do to support you in anyway please contact us. </Typography>
                  <Typography style={{ color: "#707070", alignSelf: 'center', lineHeight: 1.2, marginBottom: 5}} variant='subtitle1'> What does canceling your account mean? </Typography>
                  <div style={{display: 'flex', alignSelf: 'center', marginBottom: 5}}> 
                    <CancelOutlinedIcon style={{color: "#F81010"}}/> 
                    <Typography style={{ alignSelf: 'center', color: "#707070"}} align='center' variant='subtitle2'>Your Organization will lose access to the ExamRoom Live Platform</Typography>
                  </div>
                  <div style={{display: 'flex', alignSelf: 'center', marginBottom: 5}}> 
                    <CancelOutlinedIcon style={{color: "#F81010"}}/> 
                    <Typography style={{ alignSelf: 'center', color: "#707070"}} align='center' variant='subtitle2'>All data and history will be removed from your account</Typography>
                  </div>
                  <div style={{display: 'flex', alignSelf: 'center', marginBottom: 5}}> 
                    <CancelOutlinedIcon style={{color: "#F81010"}}/> 
                    <Typography style={{ alignSelf: 'center', color: "#707070"}} align='center' variant='subtitle2'>All SMS and Efax numbers associated with your account will be deleted</Typography>
                  </div>
                  <div style={{display: 'flex', alignSelf: 'center', marginBottom: 10}}> 
                    <CancelOutlinedIcon style={{color: "#F81010"}}/> 
                    <Typography style={{ alignSelf: 'center', color: "#707070"}} align='center' variant='subtitle2'>Any outstanding bills for usage overages will be due immediately</Typography>
                  </div>
                  <Typography style={{ color: "#F81010", alignSelf: 'center', lineHeight: 1.2}} align='center' variant='subtitle2'>If you would still like to cancel your service.</Typography>
                  <Typography style={{ color: "#F81010", alignSelf: 'center', lineHeight: 1.2, marginBottom: 10}} align='center' variant='subtitle2'>Please continue below.</Typography>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <ThemeProvider theme={redTheme}>
                      <Button variant="contained" color="primary" onClick={() => {setModalState(2)}}>          
                          <Typography variant="subtitle2">Cancel my Account</Typography>
                      </Button>
                    </ThemeProvider>  
                    <ThemeProvider theme={greenTheme}>
                      <Button color="primary" variant="contained" style={{marginRight: 5}}  onClick={handleClose}>          
                        <Typography variant="subtitle2">Keep Active</Typography>
                      </Button>
                    </ThemeProvider>
                  </div>
                  
                  </>:<>
                  <div style={{display: 'flex', alignSelf: 'center', alignItems: 'center' }}> 
                    <RemoveCircleOutlineOutlinedIcon fontSize='large' style={{color: "#F81010"}}/> 
                    <Typography style={{margin: 10}} align='center' variant='h4'> Are you sure </Typography>
                  </div>
                  <Typography style={{ color:"#47AFEB", width: '70%', alignSelf: 'center', lineHeight: 1.2, marginBottom: 10}} align='center' variant='subtitle1'> We are sad to see you go. If there is anything we can do to support you in anyway please contact us. </Typography>
                  <Typography style={{ width: '65%', color: "#707070", alignSelf: 'center', lineHeight: 1.2, textAlign: 'left', marginBottom: 5}} variant='subtitle1'> What does canceling your account mean? </Typography>
                  <div style={{display: 'flex', width: '65%', alignSelf: 'center', marginLeft: 30, marginBottom: 5}}> 
                    <CancelOutlinedIcon style={{color: "#F81010"}}/> 
                    <Typography style={{ alignSelf: 'center', color: "#707070", marginLeft: 5}} align='center' variant='subtitle2'>Your Organization will lose access to the ExamRoom Live Platform</Typography>
                  </div>
                  <div style={{display: 'flex', width: '65%', alignSelf: 'center', marginLeft: 30, marginBottom: 5}}> 
                    <CancelOutlinedIcon style={{color: "#F81010"}}/> 
                    <Typography style={{ alignSelf: 'center', color: "#707070", marginLeft: 5}} align='center' variant='subtitle2'>All data and history will be removed from your account</Typography>
                  </div>
                  <div style={{display: 'flex', width: '65%', alignSelf: 'center', marginLeft: 30, marginBottom: 5}}> 
                    <CancelOutlinedIcon style={{color: "#F81010"}}/> 
                    <Typography style={{ alignSelf: 'center', color: "#707070", marginLeft: 5}} align='center' variant='subtitle2'>All SMS and Efax numbers associated with your account will be deleted</Typography>
                  </div>
                  <div style={{display: 'flex', width: '65%', alignSelf: 'center', marginLeft: 30, marginBottom: 10}}> 
                    <CancelOutlinedIcon style={{color: "#F81010"}}/> 
                    <Typography style={{ alignSelf: 'center', color: "#707070", marginLeft: 5}} align='center' variant='subtitle2'>Any outstanding bills for usage overages will be due immediately</Typography>
                  </div>
                  <Typography style={{ color: "#F81010", alignSelf: 'center', lineHeight: 1.2}} align='center' variant='subtitle2'>If you would still like to cancel your service.</Typography>
                  <Typography style={{ color: "#F81010", alignSelf: 'center', lineHeight: 1.2, marginBottom: 10}} align='center' variant='subtitle2'>Please continue below.</Typography>
                  <div style={{display: 'flex', width: '40%', justifyContent: 'space-between', alignSelf: 'flex-end',}}>
                    <ThemeProvider theme={redTheme}>
                      <Button variant="contained" color="primary" onClick={() => {setModalState(2)}}>          
                          <Typography variant="subtitle2">Cancel my Subscription</Typography>
                      </Button>
                    </ThemeProvider>  
                    <ThemeProvider theme={greenTheme}>
                      <Button color="primary" variant="contained" style={{marginRight: 5}}  onClick={handleClose}>          
                        <Typography variant="subtitle2">Keep Active</Typography>
                      </Button>
                    </ThemeProvider>
                  </div>
                  </>}
                </DialogContent>
            </Dialog>}
            {modalState === 2 && <Dialog 
                open={modalIsOpen} 
                disableBackdropClick={true} 
                disableEscapeKeyDown
                fullScreen={isMobile}
                onClose={handleClose} 
                fullWidth
                maxWidth="md"
            >
                <DialogContent>
                  {isMobile ? <>
                    <div style={{display: 'flex', alignSelf: 'center', alignItems: 'center' }}> 
                    <RemoveCircleOutlineOutlinedIcon fontSize='small' style={{color: "#F81010"}}/> 
                    <Typography style={{margin: 10}} align='center' variant='h6'> Final Confirmation </Typography>
                  </div>
                  <Typography style={{ color:"#47AFEB", alignSelf: 'center', marginBottom: 30}} align='center' variant='h6'>Your account will be removed from ExamRoom Live</Typography>
                  {dueAmount > 0 ?
                  <>
                  <div style={{display: 'flex', alignSelf: 'center', marginBottom: 30}}> 
                    <MonetizationOnOutlinedIcon style={{color: "#10D236"}}/> 
                    <Typography style={{ color: "#707070", alignSelf: 'center', marginLeft: 5}} align='center' variant='subtitle2'>{`You have a usage of $${(dueAmount / 100).toFixed(2)} due immediately.`}</Typography>
                  </div></>
                   : null
                  }
                  <div style={{display: 'flex', flexDirection: 'row', marginTop: 15, alignItems: 'center', alignSelf: 'center'}}>
                            <Checkbox 
                                checked={acknowledgeCheck}
                                disabled={loading}
                                style={{margin: '0 5px 0 0', padding: 0}}
                                onChange={() => setAcknowledgeCheck(!acknowledgeCheck)} 
                                name="Timeclock" 
                            />
                            {dueAmount > 0 ?
                            <>
                            <div>
                              <Typography align='left' variant='subtitle2' style={{ color: "#707070",fontSize: 13}}>{`I agree to pay $${(dueAmount / 100).toFixed(2)} immediately, and cancel my subscription to ExamRoom Live. I understand that all acount information will be removed`}</Typography>
                            </div>
                            </>: <div style={{display: 'flex', flexDirection: 'row', marginTop: 15, alignItems: 'center', alignSelf: 'center'}}>
                      <Typography align='left' variant='subtitle2' style={{ color: "#707070", fontSize: 13, marginLeft: 29}}>I agree to cancel my subscription to ExamRoom Live. I understand that all account information will be removed.</Typography>
                      </div>
                            }
                        </div>
                      
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
                    <ThemeProvider theme={redTheme}>
                      <Button variant="contained" color="primary" disabled={!acknowledgeCheck} onClick={handleDelete}>          
                          <Typography variant="subtitle2" >Cancel my Account</Typography>
                      </Button>
                    </ThemeProvider>  
                    <ThemeProvider theme={greenTheme}>
                      <Button color="primary" variant="contained" style={{marginRight: 5}}  onClick={handleClose}>          
                        <Typography variant="subtitle2">Keep Active </Typography>
                      </Button>
                    </ThemeProvider>
                  </div>
                  </> : <>
                  <div style={{display: 'flex', alignSelf: 'center', alignItems: 'center' }}> 
                    <RemoveCircleOutlineOutlinedIcon fontSize='large' style={{color: "#F81010"}}/> 
                    <Typography style={{margin: 10}} align='center' variant='h4'> Final Confirmation </Typography>
                  </div>
                  <Typography style={{ color:"#47AFEB", width: '70%', alignSelf: 'center', marginBottom: 30}} align='center' variant='h5'>Your account will be removed from ExamRoom Live</Typography>
                  {dueAmount > 0 ?
                  <>
                  <div style={{display: 'flex', alignSelf: 'center', marginBottom: 30}}> 
                    <MonetizationOnOutlinedIcon style={{color: "#10D236"}}/> 
                    <Typography style={{ color: "#707070", alignSelf: 'center', marginLeft: 5}} align='center' variant='subtitle2'>{`You have a usage of $${(dueAmount / 100).toFixed(2)} due immediately.`}</Typography>
                  </div></>
                   : null
                  }
                  <div style={{display: 'flex', flexDirection: 'row', marginTop: 15, alignItems: 'center', alignSelf: 'center', width: '60%'}}>
                            <Checkbox 
                                checked={acknowledgeCheck}
                                disabled={loading}
                                style={{margin: '0 5px 0 0', padding: 0}}
                                onChange={() => setAcknowledgeCheck(!acknowledgeCheck)} 
                                name="Timeclock" 
                            />
                            {dueAmount > 0 ?
                            <>
                            <div>
                              <Typography align='left' variant='subtitle2' style={{ color: "#707070",fontSize: 13}}>{`I agree to pay $${(dueAmount / 100).toFixed(2)} immediately, and cancel my subscription to ExamRoom Live. I understand that all acount information will be removed`}</Typography>
                            </div>
                            </>: <div style={{display: 'flex', flexDirection: 'row', marginTop: 15, alignItems: 'center', alignSelf: 'center', width: '60%'}}>
                      <Typography align='left' variant='subtitle2' style={{ color: "#707070", fontSize: 13, marginLeft: 29}}>I agree to cancel my subscription to ExamRoom Live. I understand that all account information will be removed.</Typography>
                      </div>
                            }
                        </div>
                      
                <div style={{display: 'flex', width: '40%', justifyContent: 'space-between', alignSelf: 'flex-end', marginTop: 10}}>
                    <ThemeProvider theme={redTheme}>
                      <Button variant="contained" color="primary" disabled={!acknowledgeCheck} onClick={handleDelete}>          
                          <Typography variant="subtitle2" >Cancel my Subscription</Typography>
                      </Button>
                    </ThemeProvider>  
                    <ThemeProvider theme={greenTheme}>
                      <Button color="primary" variant="contained" style={{marginRight: 5}}  onClick={handleClose}>          
                        <Typography variant="subtitle2">Keep Active </Typography>
                      </Button>
                    </ThemeProvider>
                  </div>
                  </>}
                </DialogContent>
            </Dialog>}
        </>)
  };

export default AccountServicesModals;
