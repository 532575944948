import { useState, useEffect } from 'react';
import { useQuery, gql, useSubscription } from '@apollo/client';
import { appointmentsByOrgId } from '../graphql/queries';
import { subscribeToAppointmentByOrgId } from '../graphql/subscriptions';
import Session from '../session';
import { API, graphqlOperation } from 'aws-amplify';
import { createAppointment , updateAppointment, deleteAppointment, createAppointmentNotes } from '../graphql/mutations';

export const useAppointments = () => {
  const orgId = Session.OrganizationId;
  const [appointments, setAppointments] = useState([]);

  const appointmentData = useQuery(gql(appointmentsByOrgId), {
    variables: {
      organizationId: orgId,
      limit: 10000,
    }
  });

  const {data: subscriptionUpdate} = useSubscription(gql(subscribeToAppointmentByOrgId), {
    variables: {
      organizationId: orgId,
    }
  });

  useEffect(() => {
    if (subscriptionUpdate) {
      appointmentData.refetch()
    }
  }, [subscriptionUpdate, appointmentData]);

  useEffect(() => {
    if (appointmentData.data?.appointmentsByOrgId?.items.length > 0) {
      setAppointments(appointmentData.data.appointmentsByOrgId.items);
    }
  }, [appointmentData]);

  const appointmentByPatients = async (payload) => {
    //payload = {patient: patientid}
    const res = await API.graphql(graphqlOperation(appointmentsByOrgId, {input: payload}));
    console.log('res on create appointment', res);
    return res;
  };
    
  const addAppointmentFN = async (payload) => {
    const res = await API.graphql(graphqlOperation(createAppointment, {input: payload}));
    console.log('res on create appointment', res);
    return res;
  };

  const updateAppointmentFN = async (payload) => {
    console.log('payload on updated appt func', payload);
    const res = await API.graphql(graphqlOperation(updateAppointment, {input: payload}));
    console.log('res on update appointment', res);
    return res;
  };

  const deleteAppointmentFN = async (id) => {
    const res =await API.graphql(graphqlOperation(deleteAppointment, {input: {id}}));
    console.log('res on delete appointment', res);
    return res;
  };

  const createAppointmentNotesFN = async (payload) => {
    const res =await API.graphql(graphqlOperation(createAppointmentNotes, {input: payload}));
    console.log('res on create appointment notes', res);
    return res;
  };


  return {appointments, addAppointmentFN, updateAppointmentFN, deleteAppointmentFN, createAppointmentNotesFN, appointmentByPatients};
};
