export const Locales = {
    en_US: 'English',
    ar_DZ: 'Arabic',
    ja_JP: 'Japanese',
    es_US: 'Spanish',
    ru_RU: 'Russian',
    ko_KR: 'Korean',
    zh_CN: 'Chinese',
    keys: 'Keys',
  };
  