import {useEffect, useState} from 'react';
import { useQuery, gql, useSubscription} from '@apollo/client';

import Session from '../session';
import { payoutsByOrg } from '../graphql/queries';
import { 
  onCreateStripePayouts, 
  onUpdateStripePayouts 
} from '../graphql/subscriptions';
  
export const useOrgPayouts = () => {
  
    const [orgPayments, setOrgPayments] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);

    // const [getStripeTransactions, {
    //   data: listStripeTransactionsQuery, loading: transactionLoading
    // }] = useLazyQuery(gql(transactionsByOrg), {
    //   onCompleted: (r) => setOrgPayments(r.transactionsByOrg.items)
    // });

    const {subscribeToMore, ...listStripePayoutsQuery} = useQuery(gql(payoutsByOrg), {
      variables: {
        stripeId: Session.OrganizationStripeId,
        sortDirection: "DESC",
        limit:150,
      }
    });
    

  const checkForDuplicates = (array) => {
    return array.length !== orgPayments.length;
  }

  useEffect(() => {
      const idArray = orgPayments.map(x => x.id);
      
      const uniqueMessages = [...new Set(idArray)];
      // console.log('unique Messages');
      const hasDuplicates = checkForDuplicates(uniqueMessages);
      if(hasDuplicates){
        let uniqueMessageObjects = uniqueMessages.map(x => {
          var [msgdata] = orgPayments.filter(n => n.id === x)
          return msgdata
        });
        setOrgPayments(uniqueMessageObjects);
      }
    // eslint-disable-next-line
  }, [orgPayments]);


  // useEffect(() => {
  //   getStripeTransactions({
  //     variables: {
  //       orgTo: Session.OrganizationId,
  //       sortDirection: "DESC"
  //     },
  //   });
  // }, [getStripeTransactions]);

  // useEffect(() => {
  //   if (listStripeTransactionsQuery) {
  //     setOrgPayments(listStripeTransactionsQuery.data.transactionsByOrg.items);
  //     setInitialLoad(false);
  //   }
  //   // eslint-disable-next-line
  // }, [listStripeTransactionsQuery, initialLoad]);
    
  const {data: onNewTransaction} = useSubscription(gql(onCreateStripePayouts), {
    variables: {
      filter:{
          orgTo: { eq:  Session.OrganizationId }
        }
      }
  });

  const {data: onUpdatedTransaction} = useSubscription(gql(onUpdateStripePayouts), {
    variables: {
      filter:{
          orgTo: { eq:  Session.OrganizationId }
        }
      }
  });

  useEffect(() => {
    if (onNewTransaction) {
      const delta = onNewTransaction.onCreateStripePayouts
      console.log('delta onNewSMS', delta);
        orgPayments.forEach(x => {
          if (x.id !== delta.id);
          setOrgPayments([...orgPayments, delta]);
        });
    }
    // eslint-disable-next-line
  }, [onNewTransaction]);


  useEffect(() => {
    if (onUpdatedTransaction) {
      const delta = onUpdatedTransaction.onUpdateStripePayouts
        let temp = orgPayments.map(x => {
          if (x.id === delta.id) {
            console.log('update id')
            x = delta
          }
          return x
        });
        console.log(temp)
        setOrgPayments(temp);
    }
    // eslint-disable-next-line
  }, [onUpdatedTransaction]);

  if(initialLoad && !listStripePayoutsQuery.loading && !listStripePayoutsQuery.error){
    setOrgPayments(listStripePayoutsQuery.data.payoutsByOrg.items);
    setInitialLoad(false);
  }
  
  return orgPayments;
};