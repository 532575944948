import React, { useEffect, useState } from "react"
import GoogleMapReact from 'google-map-react';
import { ClockType } from "./util";
import { GOOGLE_MAP_KEY } from "../../config";
import {LocationOff as LocationOffIcon} from '@mui/icons-material';

const Colors = {
  red: '#D2103D',
  pink: '#EB889F',
  green: '#10D236',
  blue: '#3B86FF'
}

const getEventColor = event => {
  switch (event) {
    case ClockType.ClockIn:
      return Colors.green;
    case ClockType.ClockOut:
      return Colors.blue;
    case ClockType.BreakStart:
      return Colors.red;
    case ClockType.BreakEnd:
      return Colors.pink;
    default:
      break;
  }
}

const getEventTitle = event => {
  switch (event) {
    case ClockType.ClockIn:
      return 'Clock in';
    case ClockType.ClockOut:
      return 'Clock out';
    case ClockType.BreakStart:
      return 'Started break';
    case ClockType.BreakEnd:
      return 'Ended break';
    default:
      break;
  }
}

const MapDetailsAdmin = ({ timesheet }) => {
  const [points, setPoints] = useState([]);
  const [center, setCenter] = useState({});
  const [renderMap, setRenderMap] = useState(false)
  const [zoom] = useState(13);

  var head = document.getElementsByTagName('head')[0];

  // Save the original method
  var insertBefore = head.insertBefore;

  // Replace it!
  head.insertBefore = function(newElement, referenceElement) {

    if (newElement.href && newElement.href.indexOf('https://fonts.googleapis.com/css?family=Roboto') === 0) {

      console.info('Prevented Roboto from loading!');
      return;
    }

    insertBefore.call(head, newElement, referenceElement);
  };

  useEffect(() => {
    let pointarr = []
    if (timesheet) {
      setPoints(timesheet.events.filter((x) => {
        if (!x.location) {
          return false; // skip
        }
        return true;
      }).map((t, i) => {
        if (t.event === ClockType.Submit) {
          return {}
        }
        if (!t.location) {
          return {}
        } else {
          let [lat, lng] = t.location.coordinates;
          lat = Number.parseFloat(lat.toFixed(7));
          lng = Number.parseFloat(lng.toFixed(7));
          pointarr.push({lat,lng});
          return {
            key: t.ulid,
            lat: lat,
            lng: lng,
            name: getEventTitle(t.event),
            color: getEventColor(t.event)
          }
        }
      }));
    }
    if (pointarr.length > 0) {
      setRenderMap(true)
    }
    
    return () => {
      setPoints([]);
    }
  }, [timesheet])

  useEffect(() => {
    if (points.length > 0) {
      const [firstClockin] = points;
      setCenter(firstClockin);
    }
    
    return () => {
      setCenter({});
    }
  }, [points])  

  const getMapOptions = (maps) => {
    return {
      disableDefaultUI: true,
      mapTypeControl: false,
      zoomControl: true,
      streetViewControl: false,
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_BOTTOM,
        style: maps.ZoomControlStyle.SMALL
      },
      styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
    };
  };

  return (
      <>
        {renderMap ? 
          <div style={{ height: '420px', width: '100%'}}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
            defaultCenter={center}
            defaultZoom={zoom}
            options={getMapOptions}
            style={{ margin: 'auto', width: '80%', height: '80%', position: 'relative'}}
          >
            {points.map(p => p && (
              <Marker
                lat={p.lat}
                lng={p.lng}
                name={p.name}
                color={p.color}
                key={p.key}
              />
            ))}
          </GoogleMapReact>
          </div>

          :
          <div style={{ height: '300px', padding: '100px', marginTop: '10px', marginRight: '8px', textAlign:'center', backgroundColor: 'rgb(224,241,250)', color:'#707070', borderRadius: '25px'}}>
            <LocationOffIcon />
            Location Not Provided
          </div>
        }
    </>
  );
}

const Marker = (props) => {
  const { color, name, key } = props;
  return (
    <div key={key}>
      <div
        className="pin bounce"
        style={{ backgroundColor: color, cursor: 'pointer' }}
        title={name}
      />
      <div className="pulse" />
    </div>
  );
};

export default MapDetailsAdmin;
