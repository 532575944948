/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBanner = /* GraphQL */ `
  query GetBanner($id: ID!) {
    getBanner(id: $id) {
      id
      type
      message
      buttonLabel
      buttonUrl
      createdAt
      updatedAt
    }
  }
`;
export const listBanners = /* GraphQL */ `
  query ListBanners(
    $filter: ModelBannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBanners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        message
        buttonLabel
        buttonUrl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFax = /* GraphQL */ `
  query GetFax($id: ID!) {
    getFax(id: $id) {
      id
      ulid
      filename
      fileUlid
      faxSid
      orgNumber
      isViewed
      isDismissed
      to
      from
      contact
      status
      faxStatus
      mediaURL
      createdTimestamp
      pageCount
      fetchState
      tags
      createdAt
      updatedAt
    }
  }
`;
export const listFaxs = /* GraphQL */ `
  query ListFaxs(
    $filter: ModelFaxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFaxs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ulid
        filename
        fileUlid
        faxSid
        orgNumber
        isViewed
        isDismissed
        to
        from
        contact
        status
        faxStatus
        mediaURL
        createdTimestamp
        pageCount
        fetchState
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      ulid
      text
      createdTimestamp
      to
      from
      attachment
      threadId
      isDismissed
      createdAt
      updatedAt
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ulid
        text
        createdTimestamp
        to
        from
        attachment
        threadId
        isDismissed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSecureMessage = /* GraphQL */ `
  query GetSecureMessage($id: ID!) {
    getSecureMessage(id: $id) {
      id
      ulid
      text
      createdTimestamp
      to
      from
      attachment
      threadId
      isDismissed
      senderUlid
      createdAt
      updatedAt
    }
  }
`;
export const listSecureMessages = /* GraphQL */ `
  query ListSecureMessages(
    $filter: ModelSecureMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSecureMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ulid
        text
        createdTimestamp
        to
        from
        attachment
        threadId
        isDismissed
        senderUlid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSms = /* GraphQL */ `
  query GetSms($id: ID!) {
    getSms(id: $id) {
      id
      ulid
      threadId
      thread {
        id
        threadName
        ulid
        users
        smsmessages {
          items {
            id
            ulid
            threadId
            message
            smsSid
            to
            from
            contact
            status
            createdTimestamp
            isDismissed
            senderUlid
            attachment
            unread
            createdAt
            updatedAt
          }
          nextToken
        }
        messageType
        createdTimestamp
        isDismissed
        newMessageCount
        updatedTimestamp
        orgId
        staffId
        recipientId
        organization {
          id
          ulid
          name
          businessHours
          businessNumber
          timezone
          orgType
          orgLogo
          orgPlan
          loggedInUsers {
            ulid
            session
          }
          stripeId
          stripeOnboarded
          smsThreads {
            nextToken
          }
          phoneNumbers {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      message
      smsSid
      to
      from
      contact
      status
      createdTimestamp
      isDismissed
      senderUlid
      attachment
      unread
      createdAt
      updatedAt
    }
  }
`;
export const listSmss = /* GraphQL */ `
  query ListSmss(
    $filter: ModelSmsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSmss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ulid
        threadId
        thread {
          id
          threadName
          ulid
          users
          smsmessages {
            nextToken
          }
          messageType
          createdTimestamp
          isDismissed
          newMessageCount
          updatedTimestamp
          orgId
          staffId
          recipientId
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        message
        smsSid
        to
        from
        contact
        status
        createdTimestamp
        isDismissed
        senderUlid
        attachment
        unread
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMsgs = /* GraphQL */ `
  query GetMsgs($id: ID!) {
    getMsgs(id: $id) {
      id
      threadId
      message
      from
      attachment
      mediaUrl
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const listMsgss = /* GraphQL */ `
  query ListMsgss(
    $filter: ModelMsgsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMsgss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        threadId
        message
        from
        attachment
        mediaUrl
        createdTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRoomPermissions = /* GraphQL */ `
  query GetRoomPermissions($id: ID!) {
    getRoomPermissions(id: $id) {
      id
      threadId
      roles
      roleactions
      users
      createdAt
      updatedAt
    }
  }
`;
export const listRoomPermissionss = /* GraphQL */ `
  query ListRoomPermissionss(
    $filter: ModelRoomPermissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoomPermissionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        threadId
        roles
        roleactions
        users
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSmsThread = /* GraphQL */ `
  query GetSmsThread($id: ID!) {
    getSmsThread(id: $id) {
      id
      threadName
      ulid
      users
      smsmessages {
        items {
          id
          ulid
          threadId
          thread {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          message
          smsSid
          to
          from
          contact
          status
          createdTimestamp
          isDismissed
          senderUlid
          attachment
          unread
          createdAt
          updatedAt
        }
        nextToken
      }
      messageType
      createdTimestamp
      isDismissed
      newMessageCount
      updatedTimestamp
      orgId
      staffId
      recipientId
      organization {
        id
        ulid
        name
        businessHours
        businessNumber
        timezone
        orgType
        orgLogo
        orgPlan
        loggedInUsers {
          ulid
          session
        }
        stripeId
        stripeOnboarded
        smsThreads {
          items {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          nextToken
        }
        phoneNumbers {
          items {
            id
            number
            type
            inUse
            nickname
            orgDisplayName
            displayNumber
            default
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSmsThreads = /* GraphQL */ `
  query ListSmsThreads(
    $filter: ModelSmsThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSmsThreads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        threadName
        ulid
        users
        smsmessages {
          items {
            id
            ulid
            threadId
            message
            smsSid
            to
            from
            contact
            status
            createdTimestamp
            isDismissed
            senderUlid
            attachment
            unread
            createdAt
            updatedAt
          }
          nextToken
        }
        messageType
        createdTimestamp
        isDismissed
        newMessageCount
        updatedTimestamp
        orgId
        staffId
        recipientId
        organization {
          id
          ulid
          name
          businessHours
          businessNumber
          timezone
          orgType
          orgLogo
          orgPlan
          loggedInUsers {
            ulid
            session
          }
          stripeId
          stripeOnboarded
          smsThreads {
            nextToken
          }
          phoneNumbers {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      ulid
      newnotif
      inapp
      vidnotif
      notifications {
        items {
          id
          userId
          user {
            id
            firstName
            lastName
            ulid
            newnotif
            inapp
            vidnotif
            currentJTI
            auth0Sub
            createdAt
            updatedAt
          }
          type
          createdTimestamp
          data
          form
          orgId
          isActive
          threadId
          createdAt
          updatedAt
        }
        nextToken
      }
      currentJTI
      auth0Sub
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        ulid
        newnotif
        inapp
        vidnotif
        notifications {
          items {
            id
            userId
            type
            createdTimestamp
            data
            form
            orgId
            isActive
            threadId
            createdAt
            updatedAt
          }
          nextToken
        }
        currentJTI
        auth0Sub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStripeTransaction = /* GraphQL */ `
  query GetStripeTransaction($id: ID!) {
    getStripeTransaction(id: $id) {
      id
      userFrom
      orgTo
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      tags
      createdAt
      updatedAt
    }
  }
`;
export const listStripeTransactions = /* GraphQL */ `
  query ListStripeTransactions(
    $filter: ModelStripeTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStripeTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userFrom
        orgTo
        stripeId
        object
        objectId
        amount
        fee
        net
        amountRefunded
        refundedTimestamp
        metadata
        livemode
        lastEventId
        raw
        createdTimestamp
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStripePayouts = /* GraphQL */ `
  query GetStripePayouts($id: ID!) {
    getStripePayouts(id: $id) {
      id
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const listStripePayoutss = /* GraphQL */ `
  query ListStripePayoutss(
    $filter: ModelStripePayoutsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStripePayoutss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stripeId
        object
        objectId
        amount
        fee
        net
        amountRefunded
        refundedTimestamp
        metadata
        livemode
        lastEventId
        raw
        createdTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      ulid
      amount
      organizationId
      patientId
      type
      description
      currentStatus
      activity
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ulid
        amount
        organizationId
        patientId
        type
        description
        currentStatus
        activity
        createdTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getS3document = /* GraphQL */ `
  query GetS3document($id: ID!) {
    getS3document(id: $id) {
      id
      ulid
      fileName
      fileUlid
      uploader
      accessibleOrgs
      createdTimestamp
      meta
      createdAt
      updatedAt
    }
  }
`;
export const listS3documents = /* GraphQL */ `
  query ListS3documents(
    $filter: ModelS3documentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listS3documents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ulid
        fileName
        fileUlid
        uploader
        accessibleOrgs
        createdTimestamp
        meta
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userId
      user {
        id
        firstName
        lastName
        ulid
        newnotif
        inapp
        vidnotif
        notifications {
          items {
            id
            userId
            type
            createdTimestamp
            data
            form
            orgId
            isActive
            threadId
            createdAt
            updatedAt
          }
          nextToken
        }
        currentJTI
        auth0Sub
        createdAt
        updatedAt
      }
      type
      createdTimestamp
      data
      form
      orgId
      isActive
      threadId
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          firstName
          lastName
          ulid
          newnotif
          inapp
          vidnotif
          notifications {
            nextToken
          }
          currentJTI
          auth0Sub
          createdAt
          updatedAt
        }
        type
        createdTimestamp
        data
        form
        orgId
        isActive
        threadId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCallHistory = /* GraphQL */ `
  query GetCallHistory($id: ID!) {
    getCallHistory(id: $id) {
      id
      participants {
        userVideoId
        name
        userId
        role
        time_joined
        time_left
        totalDuration
      }
      patientId
      host
      roomName
      meetingId
      historyId
      meetingRoom {
        id
        isJoinable
        isCompleted
        historyId
        callHistory {
          id
          participants {
            userVideoId
            name
            userId
            role
            time_joined
            time_left
            totalDuration
          }
          patientId
          host
          roomName
          meetingId
          historyId
          meetingRoom {
            id
            isJoinable
            isCompleted
            historyId
            currentParticipants
            createdAt
            updatedAt
          }
          duration
          createdTimestamp
          createdAt
          updatedAt
        }
        currentParticipants
        createdAt
        updatedAt
      }
      duration
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const listCallHistorys = /* GraphQL */ `
  query ListCallHistorys(
    $filter: ModelCallHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCallHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        participants {
          userVideoId
          name
          userId
          role
          time_joined
          time_left
          totalDuration
        }
        patientId
        host
        roomName
        meetingId
        historyId
        meetingRoom {
          id
          isJoinable
          isCompleted
          historyId
          callHistory {
            id
            patientId
            host
            roomName
            meetingId
            historyId
            duration
            createdTimestamp
            createdAt
            updatedAt
          }
          currentParticipants
          createdAt
          updatedAt
        }
        duration
        createdTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPaymentTags = /* GraphQL */ `
  query GetPaymentTags($id: ID!) {
    getPaymentTags(id: $id) {
      id
      organizationId
      title
      color
      createdAt
      updatedAt
    }
  }
`;
export const listPaymentTagss = /* GraphQL */ `
  query ListPaymentTagss(
    $filter: ModelPaymentTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentTagss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationId
        title
        color
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEfaxTags = /* GraphQL */ `
  query GetEfaxTags($id: ID!) {
    getEfaxTags(id: $id) {
      id
      organizationId
      title
      color
      createdAt
      updatedAt
    }
  }
`;
export const listEfaxTagss = /* GraphQL */ `
  query ListEfaxTagss(
    $filter: ModelEfaxTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEfaxTagss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationId
        title
        color
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      text
      description
      appointmentType
      startDate
      endDate
      allDay
      organizationId
      assigneeId
      patientId
      status
      color
      copay
      willAttend
      appointmentNotes {
        items {
          id
          userName
          userId
          appointmentId
          appointmentNote
          createdTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        description
        appointmentType
        startDate
        endDate
        allDay
        organizationId
        assigneeId
        patientId
        status
        color
        copay
        willAttend
        appointmentNotes {
          items {
            id
            userName
            userId
            appointmentId
            appointmentNote
            createdTimestamp
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppointmentNotes = /* GraphQL */ `
  query GetAppointmentNotes($id: ID!) {
    getAppointmentNotes(id: $id) {
      id
      userName
      userId
      appointmentId
      appointmentNote
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const listAppointmentNotess = /* GraphQL */ `
  query ListAppointmentNotess(
    $filter: ModelAppointmentNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointmentNotess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        userId
        appointmentId
        appointmentNote
        createdTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeetingRoom = /* GraphQL */ `
  query GetMeetingRoom($id: ID!) {
    getMeetingRoom(id: $id) {
      id
      isJoinable
      isCompleted
      historyId
      callHistory {
        id
        participants {
          userVideoId
          name
          userId
          role
          time_joined
          time_left
          totalDuration
        }
        patientId
        host
        roomName
        meetingId
        historyId
        meetingRoom {
          id
          isJoinable
          isCompleted
          historyId
          callHistory {
            id
            patientId
            host
            roomName
            meetingId
            historyId
            duration
            createdTimestamp
            createdAt
            updatedAt
          }
          currentParticipants
          createdAt
          updatedAt
        }
        duration
        createdTimestamp
        createdAt
        updatedAt
      }
      currentParticipants
      createdAt
      updatedAt
    }
  }
`;
export const listMeetingRooms = /* GraphQL */ `
  query ListMeetingRooms(
    $filter: ModelMeetingRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isJoinable
        isCompleted
        historyId
        callHistory {
          id
          participants {
            userVideoId
            name
            userId
            role
            time_joined
            time_left
            totalDuration
          }
          patientId
          host
          roomName
          meetingId
          historyId
          meetingRoom {
            id
            isJoinable
            isCompleted
            historyId
            currentParticipants
            createdAt
            updatedAt
          }
          duration
          createdTimestamp
          createdAt
          updatedAt
        }
        currentParticipants
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBulkPatient = /* GraphQL */ `
  query GetBulkPatient($id: ID!) {
    getBulkPatient(id: $id) {
      id
      success
      failure
      documentId
      completed
      createdAt
      updatedAt
    }
  }
`;
export const listBulkPatients = /* GraphQL */ `
  query ListBulkPatients(
    $filter: ModelBulkPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBulkPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        success
        failure
        documentId
        completed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      ulid
      name
      businessHours
      businessNumber
      timezone
      orgType
      orgLogo
      orgPlan
      loggedInUsers {
        ulid
        session
      }
      stripeId
      stripeOnboarded
      smsThreads {
        items {
          id
          threadName
          ulid
          users
          smsmessages {
            nextToken
          }
          messageType
          createdTimestamp
          isDismissed
          newMessageCount
          updatedTimestamp
          orgId
          staffId
          recipientId
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      phoneNumbers {
        items {
          id
          number
          type
          inUse
          nickname
          orgDisplayName
          displayNumber
          default
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ulid
        name
        businessHours
        businessNumber
        timezone
        orgType
        orgLogo
        orgPlan
        loggedInUsers {
          ulid
          session
        }
        stripeId
        stripeOnboarded
        smsThreads {
          items {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          nextToken
        }
        phoneNumbers {
          items {
            id
            number
            type
            inUse
            nickname
            orgDisplayName
            displayNumber
            default
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhoneNumbers = /* GraphQL */ `
  query GetPhoneNumbers($id: ID!) {
    getPhoneNumbers(id: $id) {
      id
      number
      type
      inUse
      nickname
      orgDisplayName
      displayNumber
      default
      organization {
        id
        ulid
        name
        businessHours
        businessNumber
        timezone
        orgType
        orgLogo
        orgPlan
        loggedInUsers {
          ulid
          session
        }
        stripeId
        stripeOnboarded
        smsThreads {
          items {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          nextToken
        }
        phoneNumbers {
          items {
            id
            number
            type
            inUse
            nickname
            orgDisplayName
            displayNumber
            default
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPhoneNumberss = /* GraphQL */ `
  query ListPhoneNumberss(
    $filter: ModelPhoneNumbersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhoneNumberss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        type
        inUse
        nickname
        orgDisplayName
        displayNumber
        default
        organization {
          id
          ulid
          name
          businessHours
          businessNumber
          timezone
          orgType
          orgLogo
          orgPlan
          loggedInUsers {
            ulid
            session
          }
          stripeId
          stripeOnboarded
          smsThreads {
            nextToken
          }
          phoneNumbers {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const faxByOrg = /* GraphQL */ `
  query FaxByOrg(
    $ulid: String
    $createdTimestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFaxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    faxByOrg(
      ulid: $ulid
      createdTimestamp: $createdTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ulid
        filename
        fileUlid
        faxSid
        orgNumber
        isViewed
        isDismissed
        to
        from
        contact
        status
        faxStatus
        mediaURL
        createdTimestamp
        pageCount
        fetchState
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const faxBySid = /* GraphQL */ `
  query FaxBySid(
    $faxSid: String
    $sortDirection: ModelSortDirection
    $filter: ModelFaxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    faxBySid(
      faxSid: $faxSid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ulid
        filename
        fileUlid
        faxSid
        orgNumber
        isViewed
        isDismissed
        to
        from
        contact
        status
        faxStatus
        mediaURL
        createdTimestamp
        pageCount
        fetchState
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const chatsByThreadId = /* GraphQL */ `
  query ChatsByThreadId(
    $threadId: String
    $sortDirection: ModelSortDirection
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatsByThreadId(
      threadId: $threadId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ulid
        text
        createdTimestamp
        to
        from
        attachment
        threadId
        isDismissed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const secureMessagesByThreadId = /* GraphQL */ `
  query SecureMessagesByThreadId(
    $threadId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSecureMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    secureMessagesByThreadId(
      threadId: $threadId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ulid
        text
        createdTimestamp
        to
        from
        attachment
        threadId
        isDismissed
        senderUlid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const smssByThreadId = /* GraphQL */ `
  query SmssByThreadId(
    $threadId: String
    $createdTimestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSmsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    smssByThreadId(
      threadId: $threadId
      createdTimestamp: $createdTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ulid
        threadId
        thread {
          id
          threadName
          ulid
          users
          smsmessages {
            nextToken
          }
          messageType
          createdTimestamp
          isDismissed
          newMessageCount
          updatedTimestamp
          orgId
          staffId
          recipientId
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        message
        smsSid
        to
        from
        contact
        status
        createdTimestamp
        isDismissed
        senderUlid
        attachment
        unread
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const smssBysmsSid = /* GraphQL */ `
  query SmssBysmsSid(
    $smsSid: String
    $sortDirection: ModelSortDirection
    $filter: ModelSmsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    smssBysmsSid(
      smsSid: $smsSid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ulid
        threadId
        thread {
          id
          threadName
          ulid
          users
          smsmessages {
            nextToken
          }
          messageType
          createdTimestamp
          isDismissed
          newMessageCount
          updatedTimestamp
          orgId
          staffId
          recipientId
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        message
        smsSid
        to
        from
        contact
        status
        createdTimestamp
        isDismissed
        senderUlid
        attachment
        unread
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const msgsByThreadId = /* GraphQL */ `
  query MsgsByThreadId(
    $threadId: String
    $sortDirection: ModelSortDirection
    $filter: ModelMsgsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    msgsByThreadId(
      threadId: $threadId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        threadId
        message
        from
        attachment
        mediaUrl
        createdTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const smsThreadsByUlid = /* GraphQL */ `
  query SmsThreadsByUlid(
    $ulid: String
    $updatedTimestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSmsThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    smsThreadsByUlid(
      ulid: $ulid
      updatedTimestamp: $updatedTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        threadName
        ulid
        users
        smsmessages {
          items {
            id
            ulid
            threadId
            message
            smsSid
            to
            from
            contact
            status
            createdTimestamp
            isDismissed
            senderUlid
            attachment
            unread
            createdAt
            updatedAt
          }
          nextToken
        }
        messageType
        createdTimestamp
        isDismissed
        newMessageCount
        updatedTimestamp
        orgId
        staffId
        recipientId
        organization {
          id
          ulid
          name
          businessHours
          businessNumber
          timezone
          orgType
          orgLogo
          orgPlan
          loggedInUsers {
            ulid
            session
          }
          stripeId
          stripeOnboarded
          smsThreads {
            nextToken
          }
          phoneNumbers {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByUlid = /* GraphQL */ `
  query UserByUlid(
    $ulid: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUlid(
      ulid: $ulid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        ulid
        newnotif
        inapp
        vidnotif
        notifications {
          items {
            id
            userId
            type
            createdTimestamp
            data
            form
            orgId
            isActive
            threadId
            createdAt
            updatedAt
          }
          nextToken
        }
        currentJTI
        auth0Sub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transactionsByOrg = /* GraphQL */ `
  query TransactionsByOrg(
    $orgTo: String
    $createdTimestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStripeTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByOrg(
      orgTo: $orgTo
      createdTimestamp: $createdTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userFrom
        orgTo
        stripeId
        object
        objectId
        amount
        fee
        net
        amountRefunded
        refundedTimestamp
        metadata
        livemode
        lastEventId
        raw
        createdTimestamp
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const payoutsByOrg = /* GraphQL */ `
  query PayoutsByOrg(
    $stripeId: String
    $createdTimestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStripePayoutsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    payoutsByOrg(
      stripeId: $stripeId
      createdTimestamp: $createdTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stripeId
        object
        objectId
        amount
        fee
        net
        amountRefunded
        refundedTimestamp
        metadata
        livemode
        lastEventId
        raw
        createdTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const s3documentByUploader = /* GraphQL */ `
  query S3documentByUploader(
    $uploader: String
    $createdTimestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelS3documentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    s3documentByUploader(
      uploader: $uploader
      createdTimestamp: $createdTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ulid
        fileName
        fileUlid
        uploader
        accessibleOrgs
        createdTimestamp
        meta
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const paymentTagsByOrgId = /* GraphQL */ `
  query PaymentTagsByOrgId(
    $organizationId: String
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentTagsByOrgId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        title
        color
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const efaxTagsByOrgId = /* GraphQL */ `
  query EfaxTagsByOrgId(
    $organizationId: String
    $sortDirection: ModelSortDirection
    $filter: ModelEfaxTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    efaxTagsByOrgId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        title
        color
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const appointmentsByOrgId = /* GraphQL */ `
  query AppointmentsByOrgId(
    $organizationId: String
    $startDate: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentsByOrgId(
      organizationId: $organizationId
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        description
        appointmentType
        startDate
        endDate
        allDay
        organizationId
        assigneeId
        patientId
        status
        color
        copay
        willAttend
        appointmentNotes {
          items {
            id
            userName
            userId
            appointmentId
            appointmentNote
            createdTimestamp
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const phoneNumberByNumber = /* GraphQL */ `
  query PhoneNumberByNumber(
    $number: String
    $sortDirection: ModelSortDirection
    $filter: ModelPhoneNumbersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    phoneNumberByNumber(
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        type
        inUse
        nickname
        orgDisplayName
        displayNumber
        default
        organization {
          id
          ulid
          name
          businessHours
          businessNumber
          timezone
          orgType
          orgLogo
          orgPlan
          loggedInUsers {
            ulid
            session
          }
          stripeId
          stripeOnboarded
          smsThreads {
            nextToken
          }
          phoneNumbers {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchAppointments = /* GraphQL */ `
  query SearchAppointments(
    $filter: SearchableAppointmentFilterInput
    $sort: SearchableAppointmentSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAppointments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        text
        description
        appointmentType
        startDate
        endDate
        allDay
        organizationId
        assigneeId
        patientId
        status
        color
        copay
        willAttend
        appointmentNotes {
          items {
            id
            userName
            userId
            appointmentId
            appointmentNote
            createdTimestamp
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
