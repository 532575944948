import React, { useState, useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {Button} from '@mui/material';
import { ClockType } from '../util';
import { toHHMMSS } from '../../constant/timer';
import BottomCircleButton from './bottomCircleButton';
import ClockTime from './clockTime';
import { useAPI } from '../../../utils/api';
import { getFullDate } from '../../../utils/dateTimeFormat';

const useStyles = makeStyles((theme) => ({
    titleDate: {
      textAlign: 'center',
      color: '#707070',
      fontSize: '29px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '21px',
      },
    },
    breakOverText: {
      marginTop: 2, 
      marginBottom: 2, 
      padding: 2, 
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px',
      },
    },
    breakTimeText:{
      margin: -5, 
      paddingTop: -3, 
      fontSize: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px',
      },
    },
    breakButton: {
      width: '109px',
      height: '30px',
      color: '#FFFFFF',
      marginTop: 'auto',
      marginLeft: 'calc(50% - 209px)',
      justifyContent: 'center',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        order: 2,
        marginTop: '20px',
        marginLeft: '0'
      },
    },
    clockViewContainer: {
      display: 'flex', 
      margin: '18px auto 33px auto', 
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
      },
    }
}));

const ClockState = ({ events, nextExpectedActions, handleClockEvent, delayState }) => {
    const [timestamp, setTimestamp] = useState(0);
    const [title, setTitle] = useState('');
    const [localEvent, setLocalEvent] = useState(ClockType.ClockIn);
    const classes = useStyles();
  
    useEffect(() => {
      const getTime = () => {
        if (events.length > 0) {            
          if (nextExpectedActions.includes(ClockType.BreakEnd)) {
            const started = events[events.length - 1];
            const breakend = (~~(Date.now()/1000)) - started.timeStamp;
            setTimestamp(breakend);
          }
        } else {
          setTimestamp(0);
        }
      }
  
      const timer = setInterval(() => {
        getTime();
      }, 1000);
      
      return () => {
        clearInterval(timer);
        setTimestamp(0);
      }
    }, [events, nextExpectedActions]);
  
    useEffect(() => {
      if (nextExpectedActions.includes(ClockType.ClockOut)) {
        setTitle('Clock Out');
        setLocalEvent(ClockType.ClockOut);
      } else if (nextExpectedActions.includes(ClockType.BreakEnd)) {
        setTitle('Break Over');
        setLocalEvent(ClockType.BreakEnd);
      } else {
        setTitle('Clock In');
        setLocalEvent(ClockType.ClockIn);
      }
    }, [nextExpectedActions, events])
  
    return (
      <BottomCircleButton
        event={title}
        delayState={delayState}
        onClick={() => handleClockEvent(localEvent)} 
        children={
          <div className="icon cur-pointer" style={{position: 'absolute', top: 0}}>
            <p className={classes.breakOverText}>{title}</p>
            {localEvent === ClockType.BreakEnd && <p className={classes.breakTimeText}>{toHHMMSS(timestamp)}</p>}
          </div>
        }
      />
    );
};

const ClockView = ({ events, location, entryId, nextExpectedActions, setNextExpectedActions, setEntryId, addEvents }) => {
    const api = useAPI();
    const classes = useStyles();
    const [delayState, setDelayState] = useState(false);

    const handleClockEvent = async (type) => {
      let res;
      console.log(res)
      let eventType;
        try {
          setDelayState(true);
          setTimeout(() => {
            setDelayState(false);
          }, 1000);
          console.log(entryId)
          if (type === ClockType.ClockIn) {
            res = await api.createTimesheetEvent({ location, entryId });
            if (!entryId && res) {
              console.log("writing entry ID")
              setEntryId(res.event.TimeSheetEntryId);
            }
          } else if (type === ClockType.BreakStart) {
            if (entryId) {
              eventType = 'startbreak';
              res = await api.addTimesheetEvent( eventType, { location, entryId });
            }
          } else if (type === ClockType.BreakEnd) {
            if (entryId) {
              eventType = 'endbreak';
              res = await api.addTimesheetEvent( eventType, { location, entryId });
            }        
          } else if (type === ClockType.ClockOut) {
            if (entryId) {
              eventType = 'clockout';
              res = await api.addTimesheetEvent( eventType, { location, entryId });
            }
          }
          console.log('res in handle clock event', res);
          if (res) {
            if(res.expectedNextEvent) {
              setNextExpectedActions(res.expectedNextEvent);
              addEvents(res.event);
            }
          }
        } catch (error) {
          console.log('error', error);
        }
    };
  
    const handleStartBreak = async () => {
      await handleClockEvent(ClockType.BreakStart);
    }
  
    return (
      <>
        <div className={classes.titleDate}>{getFullDate(~~(Date.now()/1000))}</div>
        <div className={classes.clockViewContainer}>
        {!nextExpectedActions.includes(ClockType.BreakEnd) ? (
            <Button 
              disabled={!nextExpectedActions.includes(ClockType.ClockOut) || delayState} 
              onClick={handleStartBreak}
              variant='contained'
              color='primary'
              className={classes.breakButton}
            >
              Break
            </Button>
          ) : (
            <div style={{width: 109, marginLeft: 'calc(50% - 209px)'}}></div>
          )}
          <div style={{order: 1}}>
            <ClockTime nextExpectedActions={nextExpectedActions} events={events} />
            <ClockState nextExpectedActions={nextExpectedActions} events={events} handleClockEvent={handleClockEvent} delayState={delayState}/>
          </div>
        </div>
      </>
    );
};

export default ClockView;
