import { useState, useEffect, useCallback } from "react"
import { Client as ConversationsClient } from "@twilio/conversations";
import Session from '../../session';
import { useAPI } from '../../utils/api';

export const useTwilioConversationClient = () => {
    const api = useAPI();
    const [clientSubscribedConversations, setClientSubscribedConversations] = useState([]);

    const [initializing, setInitializing] = useState(false);
    const [clientInit, setClientInit] = useState(false);
    const [client, setClient] = useState();

    const [chat, set_chat] = useState([]);
    const [chat_unread, set_chat_unread] = useState(0);
    const [chat_map, set_chat_map] = useState([]);
    const [chat_update_channel, set_chat_update_channel] = useState('');

    const [orgInitializing, setOrgInitializing] = useState(false);
    const [orgClientInit, setOrgClientInit] = useState(false);
    const [orgClient, setOrgClient] = useState();

    const [secure_messages, set_secure_messages] = useState([]);
    const [secure_message_unread, set_secure_message_unread] = useState(0);
    const [sec_mes_map, set_sec_mes_map] = useState([]);
    const [sec_mes_update_channel, set_sec_mes_update_channel] = useState('');

    // Message Handler
    const onMessageAddedHandler = (msg, currentChannel) => {
        const msgChannel = msg.conversation?.channelState
        if (msgChannel?.attributes?.type === "chat") {
            set_chat_update_channel(msgChannel.uniqueName)
            if (msgChannel?.uniqueName === currentChannel) { resetUnread(currentChannel, "chat") };
        } else if (msgChannel?.attributes?.type === "secure_message") {
            if (Session.Roles === 'patient' || msg.author !== Session.OrganizationId) { set_sec_mes_update_channel(msgChannel.uniqueName) }
            if (msgChannel?.uniqueName === currentChannel) { resetUnread(currentChannel, "secure_message") };
        }
    }

    // Update chat channel once triggered
    useEffect(() => {
        if (chat_update_channel !== '') {
            let temp = [...chat_map];
            const a = temp.findIndex(x => x?.conversationSid === chat_update_channel);
            if (a >= 0) {
                temp[a].unread++;
                set_chat_map(temp);
            }
            let tempunreadtot = 0;
            if (temp.length > 0) { tempunreadtot = temp.map(x=>x.unread)?.reduce((prev, curr) => prev + curr) }
            set_chat_unread(tempunreadtot);
        }
        set_chat_update_channel('');
    },[chat_map, chat_update_channel])

    // Update Secure Message channel once triggered
    useEffect(() => {
        if (sec_mes_update_channel !== '') {
            let temp = [...sec_mes_map];
            const a = temp.findIndex(x => x?.conversationSid === sec_mes_update_channel);
            if (a >= 0) {
                temp[a].unread = 1 + (temp[a].unread || 0);
                set_sec_mes_map(temp);
            }
            let tempunreadtot = 0;
            if (temp.length > 0) { tempunreadtot = temp.map(x=>x.unread)?.reduce((prev, curr) => prev + curr) }
            set_secure_message_unread(tempunreadtot);
        }
        set_sec_mes_update_channel('');
    },[sec_mes_map, sec_mes_update_channel])

    useEffect(() => {
        let tot = 0;
        if (sec_mes_map.length > 0) { tot = sec_mes_map.map(x=>x.unread)?.reduce((prev, curr) => prev + curr) }
        set_secure_message_unread(tot);
    }, [sec_mes_map])

    useEffect(() => {
        let tot = 0;
        if (chat_map.length > 0) { tot = chat_map.map(x=>x.unread)?.reduce((prev, curr) => prev + curr) }
        set_chat_unread(tot);
    }, [chat_map])

    useEffect(() => {
        if (clientSubscribedConversations.length > 0) {
            const new_secure_messages = clientSubscribedConversations.filter(c=>c.channelState?.attributes?.type === "secure_message");
            const new_chats = clientSubscribedConversations.filter(c=>c.channelState?.attributes?.type === "chat");
            if (secure_messages.length === 0) { set_secure_messages(new_secure_messages) }
            if (chat.length === 0) { set_chat(new_chats) }
        }
    }, [clientSubscribedConversations, secure_messages.length, chat.length])

    const pagination_fn = useCallback((paginator, items) => {
        const filtered_current_items = clientSubscribedConversations.map(cs => cs.entityName);
        const listed_new_items = items.map(uni => uni);
        const unique_new_items  = listed_new_items.filter(eN => !filtered_current_items.includes(eN.entityName));
        let appendo = clientSubscribedConversations.concat(unique_new_items);
        setClientSubscribedConversations(appendo);
        if (paginator.hasNextPage) { pagination_fn(paginator, paginator.nextPage()) }
    }, [clientSubscribedConversations])

    // Initialize Individual Client
    useEffect(() => {
        const initialize = async () => {
            try {
                const token = await api.getTwilioSecureToken(Session.UserId);
                const initedClient = await ConversationsClient.create(token.data);
                if (initedClient) {
                    setClient(initedClient);
                    setInitializing(true);
                }
            } catch(err) {
                console.log(err)
                setClientInit(true)
            }
        }
        const orgId = Session.OrganizationId;
        if (orgId && !initializing && !clientInit) { initialize() }
    }, [api, initializing, clientInit])

    // Initialize Org Client
    useEffect(() => {
        const initialize = async () => {
            try {
                const orgtoken = await api.getTwilioSecureToken(Session.OrganizationId);
                const initedOrgClient = await ConversationsClient.create(orgtoken.data);
                if (initedOrgClient) {
                    setOrgClient(initedOrgClient);
                    setOrgInitializing(true);
                }
            } catch(err) {
                console.log(err)
                setOrgClientInit(true)
            }
        }
        const orgId = Session.OrganizationId;
        if (orgId && !orgInitializing && !orgClientInit) { initialize() }
    }, [api, orgInitializing, orgClientInit])

    // Client Event Handlers / Token Refresh 
    useEffect(() => {
        // Handle Client Event Listeners
        if (client && !clientInit) {
            if (client.connectionState === 'connected') {
                setInitializing(false);
                setClientInit(true);
                client.getSubscribedConversations().then(function (paginator) {
                    pagination_fn(paginator, paginator.items)
                })
            }
            const refresh = async (client) => {
                const token = await api.getTwilioSecureToken(Session.UserId);
                client.updateToken(token.data);
            }
            client.on("tokenAboutToExpire", async () => { await refresh(client) });
            client.on("tokenExpired", async () => { await refresh(client) });
            client.on("messageAdded", async (msg) => { onMessageAddedHandler(msg) });
            client.on("conversationAdded", async (e) => {
                const un = e.channelState.uniqueName;
                const temp = chat_map
                temp.push({                    
                    conversationSid: un,
                    unread: 0
                })
                set_chat_map(temp)
                set_chat([])
                await refresh(client)
                client.getSubscribedConversations().then(function (paginator) {
                    pagination_fn(paginator, paginator.items)
                })
            });
            client.on("conversationUpdated", async (e) => {
                if (e.updateReasons?.includes("lastReadMessageIndex")){
                    set_chat([])
                    await refresh(client)
                    client.getSubscribedConversations().then(function (paginator) {
                        pagination_fn(paginator, paginator.items)
                    })
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, api, pagination_fn, clientInit]);

    // Org Client Event Handlers / Token Refresh 
    useEffect(() => {
        // Handle Client Event Listeners
        if (Session.Roles !== 'patient' && orgClient && !orgClientInit) {
            if (orgClient.connectionState === 'connected') {
                setOrgInitializing(false);
                setOrgClientInit(true);
                orgClient.getSubscribedConversations().then(function (paginator) {
                    pagination_fn(paginator, paginator.items)
                })
            }
            const refresh = async (orgClient) => {
                const token = await api.getTwilioSecureToken(Session.OrganizationId);
                orgClient.updateToken(token.data);
            }
            orgClient.on("tokenAboutToExpire", async () => { await refresh(orgClient) });
            orgClient.on("tokenExpired", async () => { await refresh(orgClient) });
            orgClient.on("messageAdded", async (msg) => { onMessageAddedHandler(msg) });
            orgClient.on("conversationAdded", async () => {
                set_secure_messages([])
                await refresh(orgClient)
                orgClient.getSubscribedConversations().then(function (paginator) {
                    pagination_fn(paginator, paginator.items)
                })
            });
            orgClient.on("conversationUpdated", async (e) => {
                if (e.updateReasons?.includes("lastReadMessageIndex")){
                    set_secure_messages([])
                    await refresh(orgClient)
                    orgClient.getSubscribedConversations().then(function (paginator) {
                        pagination_fn(paginator, paginator.items)
                    })
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgClient, api, pagination_fn, orgClientInit]);

    const resetUnread = (un, type) => {
        switch (type) {
            case 'secure_message': {
                let temp = sec_mes_map;
                const affect = temp.findIndex(x => x?.conversationSid === un);
                if (affect >= 0) {
                    temp[affect].unread = 0;
                    set_sec_mes_map(temp)
                }
                let tot = 0;
                if (temp.length > 0) { tot = temp.map(x=>x.unread)?.reduce((prev, curr) => prev + curr) }
                if (tot < 0) { tot = 0 } 
                set_secure_message_unread(tot)
                break;
            }
            case 'chat': {
                let temp = chat_map;
                const affect = temp.findIndex(x => x?.conversationSid === un);
                if (affect >= 0) {
                    temp[affect].unread = 0;
                    set_chat_map(temp)
                }
                let tot = 0;
                if (temp.length > 0) { tot = temp.map(x=>x.unread)?.reduce((prev, curr) => prev + curr) }
                if (tot < 0) { tot = 0 } 
                set_chat_unread(tot)
                break;
            }
            default: {
                console.log('Error in msg type')
            }
        }
    }

    // init Secure Message Calculation for Sec Mes Map 
    useEffect(() => {
        const calcSecMess = async () => {
            let unread = 0;
            const aps = await secure_messages.map(async k => {
                let template = {
                    conversationSid: '',
                    unread: 0
                }
                template.conversationSid = k.channelState.uniqueName
                const channel_last_index = k.channelState?.lastMessage?.index;
                const pat_last_index = k.channelState?.lastReadMessageIndex || 0;
                let tempunread = channel_last_index - pat_last_index || 0;
                if (tempunread < 0) { tempunread = 0}
                unread = unread + tempunread;
                template.unread = tempunread
                return template
            })
            const apsresolved = await Promise.all(aps)
            if (apsresolved.length > 0) {
                set_sec_mes_map(apsresolved)
                if (unread < 0) unread = 0;
                set_secure_message_unread(unread);
            }
        }
        calcSecMess()
    }, [secure_messages]);

    // init Chat Calculation for Sec Mes Map 
    useEffect(() => {
        const calcChat = async () => {
            let unread = 0;
            const aps = await chat.map(async k => {
                let template = {
                    conversationSid: '',
                    unread: 0
                }
                template.conversationSid = k.channelState.uniqueName
                const channel_last_index = k.channelState?.lastMessage?.index;
                const pat_last_index = k.channelState?.lastReadMessageIndex || 0;
                let tempunread = channel_last_index - pat_last_index || 0;
                unread = unread + tempunread;
                if (tempunread < 0) { tempunread = 0}
                template.unread = tempunread
                return template
            })
            const apsresolved = await Promise.all(aps)
            if (apsresolved.length > 0) {
                set_chat_map(apsresolved)
                if (unread < 0) unread = 0;
                set_chat_unread(unread);
            }
        }
        calcChat()
    }, [chat]);

    return [client, chat_map, chat_unread, orgClient, sec_mes_map, secure_message_unread, onMessageAddedHandler, resetUnread];
};