import React, { useState, useEffect, useCallback } from 'react';
import TimeSheetDetails from './adminTimesheet';
import AddEntryModal from './timeTab/addEntryModal'
import Session from '../../session';
import { ClockType, ConvertMiles, startAndEndOfWeek, WorkedTime } from './util';
import axios from 'axios';
import useSWR from 'swr';
import { API_URL } from '../../config';
import { toHHMMSS } from '../constant/timer';
import Currency from '../../utils/formatCurrency';
import { useAPI } from '../../utils/api';
import { makeStyles } from '@mui/styles';
import {
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  Snackbar,
  Alert as MuiAlert,
  CircularProgress,
  Backdrop,
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  Checkbox,
  Tooltip,
  Button
} from '@mui/material';

import {
  RunningWithErrorsOutlined,
  ScheduleSendOutlined,
  PublishedWithChanges,
  Add as AddIcon
} from '@mui/icons-material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {DateRangePicker, LocalizationProvider} from '@mui/x-date-pickers-pro';
import moment from 'moment';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '80%'
  },
  table: {
    minWidth: 650,
  },
  tabs: {
    display: 'flex',
    color: '#707070',
    background: '#dff2fa',
    borderRadius: '5px',
    height: '25px',
    width: 'fit-content',
    margin: 'auto',
    border: '1px solid #e8e8e8',
  },
  tab: {
    background: 'transparent',
    border: 'none',
    display: 'flex',
    width: '150px',
    '& > div': {
      margin: 'auto',
      color: '#707070',
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.8',
    },
  },
  listItemText: {
    fontSize: '12px'
  },
  activetab : {
    background: '#9ed8f2',
    borderRadius: 5,
    border: 'none',
    display: 'flex',
    width: '150px',
    '& > div': {
      margin: 'auto',
      color: '#707070'
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.8'
    },
  },
  detailtable: {
    borderRadius: '5px',
    overflow: 'hidden',
    marginBottom: '25px',
  },
  topDetailtable: {
    borderRadius: '5px',
    overflow: 'hidden',
    marginTop: '55px',
    width: '43vh',
  },
  activitytable: {
    background: '#dff2fa',
    margin: 'auto',
    marginLeft: '24px',
    borderRadius: '5px',
    overflow: 'hidden',
    width: '100%',
    marginBottom: '25px',
  },
  innerActivitytable: {
    overflow: 'hidden',
    width: '100%',
  },
  topTableContainer: {
    display: 'flex',  
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
  },
  topTableCellsLeft: {
    padding : '7px',
    paddingLeft: '15px',
    fontFamily: 'Helvetica',
  },
  topTableCellsRight: {
    padding : '7px',
    paddingRight: 0,
    fontFamily: 'Helvetica',
  },
  bottomTableHeaderCells: {
    fontFamily: 'Helvetica',
  },
  noHoverLightRow: {
    background: 'white',
  },
  tableRowLight: {
    background: 'white',
    '&:hover': {
      background: '#dff2fa',
      cursor: 'pointer',
    },
  },
  tableRowDark: {
    background: '#EBEBEB',
    '&:hover': {
      background: '#dff2fa',
      cursor: 'pointer',
    },
  },
  tableRowLightBlue: {
    background: '#dff2fa',
    fontWeight: 'bold',
    borderTop: '2px solid #666666',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  topTableRowLightBlue: {
    background: '#dff2fa',
  },
  tableRowBlue: {
    background: '#dff2fa',
    '&:hover': {
      background: '#47afeb',
      cursor: 'pointer',
      opacity: '0.8',
    },
  },
  tableRowLightGreen: {
    background: '#caf7d3',
  },
  viewButton: {
    color: '#fff',
    fontWeight: 'Bold',
  },
  popupIndicator: {
    padding: 2,
    marginRight: -2,
    transform: 'rotate(90deg)',
  },
  popupIndicatorOpen: {
    padding: 2,
    marginRight: -2,
    transform: 'rotate(-90deg)',
  },
}));

const TabType = {
  Current: 'current',
  LastWeek: 'lastweek',
  Custom: 'custom',
};

const AdminTab = () => {
  const [tab, setTab] = useState(TabType.Current);
  const [loading, setLoading] = useState(true);
  const [timeSheet, setTimesheet] = useState([]);
  const [tempTimeSheet, setTempTimesheet] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  
  const [entryModal, setEntryModal] = useState(false);
  const [totalTimeWorked, setTotalWorkedTime] = useState(0);
  const [totalMileage, setTotalMileage] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [severity, setSeverity] = useState('success'); 

  const [reloadTimeSheets, setReloadTimeSheets] = useState(false);
  const [filterTimeSheet, setFilterTimeSheets] = useState(false);
  const api = useAPI();
  const classes = useStyles();

  let weekBefore = new Date();
  weekBefore.setDate(weekBefore.getDate() - 7);

  const [value, setValue] = useState([weekBefore, Date.now()]);
  const [tabValue, setTabValue] = useState(0);
  const [checkBoxSelectedList, setCheckBoxSelectedList] = useState([]);

  const staffUrl = `${API_URL}/v3/orgs/${Session.OrganizationId}/getAllStaffUsers`;

  const fetcher = url => axios.get(url, {
    headers: {
        'Authorization': `Bearer ${Session.Token}`,
        'Content-Type': 'application/json',
    }
  }).then(res => res.data);

  const { data: staffData } = useSWR(() => staffUrl, fetcher);

  useEffect(() => {
    if (staffData) {
      if (staffData.length > 0) {
        setEmployees([
          ...staffData,
        ])
      }
    }
    setLoading(false); 
  }, [staffData]);

  const employeeSheet = useCallback((data) => {
    return data.map(current => {
      const [worked] = WorkedTime(current.TimeSheetClockEvents || []);
      // const nextActions = current.nextExpectedActions;
      const submitted = current.TimeSheetClockEvents.some(x => x.event === ClockType.Submit);
      if (!submitted) { return {}; }
      let fileUlids = [];
      let clockInEvents = current.TimeSheetClockEvents.map(item => ({...item})).sort((a,b) => a.timeStamp - b.timeStamp).filter(a => a.event === ClockType.ClockIn)
      if (current.TimeSheetExpense && current.TimeSheetExpense.fileUlids
        && current.TimeSheetExpense.fileUlids.length > 0) {
        fileUlids = current.TimeSheetExpense.fileUlids;
      }
      return {
        autoSubmitted: current.autoSubmitted || false,
        autoSubmitType: current.autoSubmitType,
        // autoSubmitTypes: [0],
        fileUlids,
        mileageEdited: current.mileageEdited,       
        notesEdited: current.notesEdited,
        dateEdited: current.dateEdited,
        events: current.TimeSheetClockEvents || [],
        totalHours: worked,
        edited: current.edited,
        adminCreated: current.adminCreated,
        notes: current.notes,
        ulid: current.ulid,
        user: current.user,
        createdAt: current.createdAt,
        date: new Date(clockInEvents[0].timeStamp * 1000).toDateString(),
        miles: Number(current.mileage),
        expenses: Number(current.TimeSheetExpense && current.TimeSheetExpense.amount ? current.TimeSheetExpense.amount : ''),
        expensesEdited: (current.TimeSheetExpense && current.TimeSheetExpense.edited) ? current.TimeSheetExpense.edited : false
      };
    });
  }, [])

  // create state for filter

  const handlerReload = () => {
    setLoading(true);
    setReloadTimeSheets(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };


  useEffect(() => {
    const getTimeSheet = async () => {
      try {
        const res = await api.getTimesheetsByOrg();
        if (!res) {
          console.log('Something went wrong');
          return;
        }
        const filterEmployee = employeeSheet(res || []);
        filterEmployee.sort((a,b) => new Date(a.date) - new Date(b.date))
        setTimesheet(filterEmployee);
        setTempTimesheet(filterEmployee);
      } catch (error) {
        console.log('Something went wrong please try again');
      } finally {
        setReloadTimeSheets(false);
        setFilterTimeSheets(true);
      }
    }
    getTimeSheet();
    
  }, [employeeSheet, api, reloadTimeSheets]);

  useEffect(() => {
    let filtered = timeSheet;
    if (tab === TabType.Custom) {
      if (startDate) {
        filtered = filtered.filter(d => new Date(d.date) >= new Date(startDate));
      }

      if (endDate) {
        const end = new Date(endDate);
        end.setDate(end.getDate());
        filtered = filtered.filter(d => new Date(d.date) <= end);
      }
    } else if (tab === TabType.LastWeek) {
      const lastweek = new Date();
      lastweek.setDate(lastweek.getDate() - 7);
      const [monday, sunday] = startAndEndOfWeek(lastweek);

      filtered = filtered.filter(d => new Date(d.date) >= monday && new Date(d.date) <= sunday);
    } else {
      const [monday, sunday] = startAndEndOfWeek();
      filtered = filtered.filter(d => new Date(d.date) >= monday && new Date(d.date) <= sunday);
    }
    
    if (selectedEmployee.length > 0) {
      const ulidmap = selectedEmployee.map(x => x.ulid)
      filtered = filtered.filter(x => ulidmap.includes(x.user))
    }

    setTempTimesheet(filtered);

    let timeWorked = 0;
    let mileage = 0;
    let expenses = 0;
    
    filtered.map(x => {
      timeWorked += x.totalHours;
      mileage += x.miles;
      expenses += x.expenses;
      return x;
    });

    setTotalExpenses(expenses);
    setTotalMileage(mileage);
    setTotalWorkedTime(timeWorked);
    setFilterTimeSheets(false);
    if(employees) setLoading(false);    
    return () => { setTempTimesheet([]) }
          // eslint-disable-next-line 
  }, [tab, timeSheet, startDate, endDate, selectedEmployee, filterTimeSheet ]);

  const currentDate = new Date()

  const handleSnackbar = (message, severity) => {
    setSnackBarMessage(message);
    setSeverity(severity);
    setOpenSnackBar(true);
  };

  const handleEntryModal = (status) => {
    setEntryModal(status);
  };

  return (
    <>

      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert 
          onClose={handleClose} 
          severity={severity}
          sx={{ width: '100%' }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Backdrop
        className={classes.backdrop}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      <Tabs
        className={classes.tabs}
        value={tabValue}
        indicatorColor="primary"
        textColor="white"
        centered
      >
        <Tab className={tab === TabType.Current ? classes.activetab : classes.tab } label="Current" onClick={() => {setTab(TabType.Current); setTabValue(0);}}/>
        <Tab className={tab === TabType.LastWeek ? classes.activetab : classes.tab} label="Last Week" onClick={() => {setTab(TabType.LastWeek);setTabValue(1);}}/>
        <Tab className={tab === TabType.Custom ? classes.activetab : classes.tab} label="Custom" onClick={() => { 
          setTab(TabType.Custom);
          setTabValue(2);
          setStartDate(value[0]);
          setEndDate(value[1]);
          }}/>
      </Tabs>

      {tab === TabType.Custom && (
            <div style={{
              display: "flex",
              justifyContent: 'center',
              alignItems: 'center',
              border: "1px solid #e8e8e",
              paddingTop: "35px",
              paddingBottom: '-20px',
              borderRadius: "5px"
            }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={value}
              maxDate={currentDate}
              onChange={date => {
                setStartDate(date[0])
                setEndDate(date[1])
                setValue(date)}
              }
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} helperText="" onChange={(e,v) => console.log(e,v)}/>
                  {/* <DateRangeDelimiter> to </DateRangeDelimiter> */}
                  <Box sx={{ mx: 1 }}> to </Box>
                  <TextField {...endProps} helperText="" onChange={(e,v) => console.log(e,v)}/>
                </>
              )}
            />
          </LocalizationProvider>
          </div>
      )}

        <div style={{
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: "35px",
          paddingBottom: '-20px',
        }}>
          <div style={{display: "flex",
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '43vh'}}>
            <span style={{fontFamily: 'Helvetica',paddingRight: '10px'}}>
              Filter:
            </span>
            <CheckBoxList
              list={employees}
              setSelectedEmployee={userid => setSelectedEmployee(userid)}
              checkBoxSelectedList={checkBoxSelectedList}
              setCheckBoxSelectedList={setCheckBoxSelectedList}
            />
          </div>
        </div>

      <div className={classes.topTableContainer}>
        <Table className={classes.topDetailtable} aria-label="simple table">
          <TableBody>
            <TableRow className={classes.topTableRowLightBlue}>
              <TableCell className={classes.topTableCellsLeft}>Time Worked: </TableCell>
              <TableCell className={classes.topTableCellsRight} align='center'>{toHHMMSS(totalTimeWorked)}</TableCell>
            </TableRow>
            <TableRow className={classes.noHoverLightRow}>
              <TableCell className={classes.topTableCellsLeft}>Mileage: </TableCell>
              <TableCell className={classes.topTableCellsRight} align='center'>{ConvertMiles(totalMileage)}</TableCell>
            </TableRow>
            <TableRow className={classes.tableRowLightGreen}>
              <TableCell className={classes.topTableCellsLeft}>Expenses: </TableCell>
              <TableCell className={classes.topTableCellsRight} align='center'>{Currency(totalExpenses || 0)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Button variant="outlined" startIcon={<AddIcon />} color="primary" style={{marginLeft: 'auto'}} onClick={() => handleEntryModal(true)}>Add Entry</Button>
      </div>
        <AddEntryModal open={entryModal} handleEntryModal={handleEntryModal} employees={employees} handlerReload={handlerReload} handleSnackbar={handleSnackbar}/>   
        <TableView timesheets={tempTimeSheet} employees={employees} handlerReload={handlerReload} handleSnackbar={handleSnackbar}/>
    </>
  );
};

const TableView = ({ timesheets, employees, handlerReload, handleSnackbar }) => {
  const [employeeList, setEmployeeList] = useState([]);
  const classes = useStyles();

  const employeeSheet = useCallback((data) => {
    const filtered = data.filter((value, index, self) => {
      const recent = self.find(x => x.user === value.user);
      return self.indexOf(recent) === index
    });

    return filtered.map(current => {    
      const userData = data.filter(x => x.user === current.user);
      const emp = employees.find(x => x.ulid === current.user);
      if (!emp) { return undefined }
      return {
        autoSubmitted: userData.map(x => x.autoSubmitted).reduce((a,b) => a + b),
        autoSubmitTypes: userData.map(x => x.autoSubmitType),
        ulid: current.user,
        user: `${emp.firstName} ${emp.lastName}`,
        totalHours: userData.map(x => x.totalHours).reduce((a,b) => a + b),
        miles: userData.map(x => x.miles).reduce((a,b) => a + b),
        expenses: userData.map(x => x.expenses).reduce((a,b) => a + b),
        hidden: true,
        edited: userData.map(x => x.edited).reduce((a,b) => a + b),
        adminCreated: userData.map(x => x.adminCreated).reduce((a,b) => a + b)
      };
    });
  }, [employees]);

  useEffect(() => {
    if (employees.length > 0) {
      setEmployeeList(employeeSheet(timesheets));      
    }
    return () => {
      setEmployeeList([]);
    }
  }, [timesheets, employees, employeeSheet]);

  const HoverAbleRow = ({row, index}) => {
    const [hovered, setHovered] = useState(false);
    
    return (
      <TableBody>
        <TableRow
          onMouseOver={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          key={row.ulid} 
          onClick={() => {
            row.hidden = !row.hidden;
            setEmployeeList([...employeeList]);
          }}
          onClickAway
          className={!row.hidden ? classes.tableRowLightBlue : (index % 2 ? classes.tableRowLight : classes.tableRowDark)}
        >
          <TableCell className={classes.bottomTableHeaderCells} style={{height: '54px', fontWeight: 'inherit'}}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'nowrap',
            }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
            }}>{row.user}</div>
            {row.hidden && row.autoSubmitted ? 
              <>
              {row.autoSubmitTypes.includes(1) ?
                <Tooltip
                  title={
                    <>
                      <p> Employee forgot to click 'Submit Time', so it was auto-submitted for them. </p>
                    </>
                  }
                  placement="right" 
                  arrow
                >
                  <ScheduleSendOutlined 
                  style={{
                    color:'green',
                    paddingLeft: '6px'
                  }} /> 
                </Tooltip>
              : ''
              }
              {row.autoSubmitTypes.includes(2) || row.autoSubmitTypes.includes(3) || row.autoSubmitTypes.includes(4) ?
                <Tooltip
                title={
                  <>
                    <p> Employee Clocked in, but forgot to clock out. </p>
                  </>
                }
                placement="right" 
                arrow
              >
              <RunningWithErrorsOutlined 
                    style={{
                      color:'red',
                      paddingLeft: '6px'
                    }} /> 
              </Tooltip>
              : ''
              }
              </>
              : ''
            }
            {(row.hidden && row.edited > 0) && 
              <Tooltip
                title={
                  <>
                    <p>Edited by Admin</p>
                  </>
                }
                placement="right" 
                arrow
              >
                <PublishedWithChanges 
                style={{
                  color:'green',
                  paddingLeft: '6px'
                }} /> 
              </Tooltip>
            }
            {(row.hidden && row.adminCreated > 0) && 
              <Tooltip
                title={
                  <>
                    <p>Created by Admin</p>
                  </>
                }
                placement="right" 
                arrow
              >
                <PublishedWithChanges 
                style={{
                  color:'red',
                  paddingLeft: '6px'
                }} /> 
              </Tooltip>
            }
            </div>

          </TableCell>
          <TableCell className={classes.bottomTableHeaderCells} style={{height: '54px', fontWeight: 'inherit'}} align="right">{toHHMMSS(row.totalHours)}</TableCell>
          <TableCell className={classes.bottomTableHeaderCells} style={{height: '54px', fontWeight: 'inherit'}} align="right">{ConvertMiles(row.miles)}</TableCell>
          <TableCell className={classes.bottomTableHeaderCells} style={{height: '54px', fontWeight: 'inherit'}} align="right">{Currency(row.expenses || 0)}</TableCell>
          <TableCell className={classes.bottomTableHeaderCells} style={{height: '54px', fontWeight: 'inherit'}} align="right">
            {!row.hidden ?
              <img className={classes.popupIndicatorOpen} src={'/arrow.png'} alt="arrow" /> :
              (row.hidden && hovered ?
                <img className={classes.popupIndicator} src={'/arrow.png'} alt="arrow" />
                : ''
              )
            }
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  return (
    <TableContainer>
      <Table className={classes.detailtable} aria-label="simple table">
          <colgroup>
              <col style={{width:'20%'}}/>
              <col style={{width:'20%'}}/>
              <col style={{width:'20%'}}/>
              <col style={{width:'20%'}}/>
              <col style={{width:'10%'}}/>
          </colgroup>
        <TableHead>
          <TableRow className={classes.noHoverLightRow}>
            <TableCell className={classes.bottomTableHeaderCells} align="left">Employee</TableCell>
            <TableCell className={classes.bottomTableHeaderCells} align="right">Total Time</TableCell>
            <TableCell className={classes.bottomTableHeaderCells} align="right">Miles</TableCell>
            <TableCell className={classes.bottomTableHeaderCells} align="right">Expenses</TableCell>
            <TableCell className={classes.bottomTableHeaderCells} align="right"></TableCell>
          </TableRow>
        </TableHead>
          {employeeList.map((row, index) => row && (
            <>
              <HoverAbleRow row={row} index={index}/>
              {!row.hidden &&
                <TimeSheetData timesheets={timesheets} userUlid={row.ulid} handlerReload={handlerReload} handleSnackbar={handleSnackbar}/>
              }
            </>
          ))}
      </Table>
    </TableContainer>
  );
};

const TimeSheetData = ({ timesheets, userUlid, handlerReload, handleSnackbar }) => {
  const [timesheetList, setTimesheetList] = useState([]);
  const [modelOpen, setModelOpen] = useState(false);
  const [entryDetails, setEntryDetails] = useState();
  const classes = useStyles();

  useEffect(() => {
    setTimesheetList(timesheets.filter(x => x.user === userUlid));
    return () => {
      setTimesheetList([]);
    }
  }, [timesheets, userUlid]);

  const handleDetails = (current) => {
    setModelOpen(true);
    setEntryDetails(current);
  };

  const HoverAbleInnerRow = ({row}) => {
    const [hovered, setHovered] = useState(false);
    
    return (
      <TableRow
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        key={row.ulid} 
        className={classes.tableRowBlue}
        onClick={() => { handleDetails(row)}
        }
      >
        <TableCell className={classes.bottomTableHeaderCells} style={{width: '256px'}} align="right">
        <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'nowrap',
                paddingLeft: '25px',
                whiteSpace: 'nowrap',
            }}>
            {moment(row.date).format('dd l')}
            {row.autoSubmitted && 
              <>
              {row.autoSubmitType === 1 ? 
                <Tooltip
                title={
                  <>
                    <p> Employee forgot to click 'Submit Time', so it was auto-submitted for them. </p>
                  </>
                }
                placement="right" 
                arrow
              >
                <ScheduleSendOutlined 
                style={{
                  color:'green',
                  paddingLeft: '6px'
                }} /> 
              </Tooltip>
              : 
              <Tooltip
              title={
                <>
                  <p> Employee Clocked in, but forgot to clock out. </p>
                </>
              }
              placement="right" 
              arrow
            >
            <RunningWithErrorsOutlined 
                  style={{
                    color:'red',
                    paddingLeft: '6px'
                  }} /> 
            </Tooltip>
            
              }
              </>
            }
            {row.edited === true && 
              <Tooltip
                title={
                  <>
                    <p> Edited by Admin </p>
                  </>
                }
                placement="right" 
                arrow
              >
                <PublishedWithChanges 
                style={{
                  color:'green',
                  paddingLeft: '6px'
                }} /> 
              </Tooltip>
            }
            {row.adminCreated > 0 && 
              <Tooltip
                title={
                  <>
                    <p> Created by Admin </p>
                  </>
                }
                placement="right" 
                arrow
              >
                <PublishedWithChanges 
                style={{
                  color:'red',
                  paddingLeft: '6px'
                }} /> 
              </Tooltip>
            }
            </div>
        </TableCell>
        <TableCell className={classes.bottomTableHeaderCells} style={{width: '263px'}} align="right">{toHHMMSS(row.totalHours)}</TableCell>
        <TableCell className={classes.bottomTableHeaderCells} style={{width: '184px'}} align="right">{ConvertMiles(row.miles)}</TableCell>
        <TableCell className={classes.bottomTableHeaderCells} style={{width: '161px'}} align="right">{Currency(row.expenses || 0)}</TableCell>
        <TableCell className={classes.bottomTableHeaderCells} style={{width: '116px'}} align="right">
          {hovered
            ?
            <div className={classes.viewButton} style={{width: '16px'}} >
              View
            </div>
            :
            <div className={classes.viewButton} style={{width: '16px'}} >
            </div>
          }
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      {timesheetList.map((t) => t && (
        <HoverAbleInnerRow row={t} />
      ))}

      {modelOpen && 
        <TimeSheetDetails modalIsOpen={modelOpen} setModelOpen={val => setModelOpen(val)} details={entryDetails} handlerReload={handlerReload} handleSnackbar={handleSnackbar}/>
      } 
    </>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


const CheckBoxList = ({ list, setSelectedEmployee, checkBoxSelectedList, setCheckBoxSelectedList }) => {
  const [setShow] = useState(false);
  const checkBoxRef = React.createRef();
  const classes = useStyles();

  const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  
  useEffect(() => {
    const handleClickOutside = event => {
      if (checkBoxRef.current && !checkBoxRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [checkBoxRef, setShow]);


  useEffect(() => {
    setSelectedEmployee(checkBoxSelectedList);
  }, [checkBoxSelectedList, setSelectedEmployee]);

  const handleChange = (event) => {
    if(event.target.value.includes(null)){
      setCheckBoxSelectedList([]);
    } else {
      setCheckBoxSelectedList(event.target.value);
    }
  };

  const calcRender = (selected) => {
    if (selected.length === 0) {
      return 'All Selected'
    } else if (selected.length === list.length) {
      return 'All Selected'
    } else {
      return 'Filtering'
    }
  };

  return (
    <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
        <Select
          labelId="demo-mutiple-name-label"
          id="demo-mutiple-name"
          multiple
          displayEmpty={'First Load'}
          value={checkBoxSelectedList}
          onChange={handleChange}
          MenuProps={MenuProps}
          style={{fontSize: '12px'}}
          renderValue={(selected) => calcRender(selected)}
        >
          <MenuItem value={null}>
            <Checkbox color='primary' checked={checkBoxSelectedList.length === 0} />
            <ListItemText primary='All Employees' />
          </MenuItem>
          {list.map((item) => (
            <MenuItem key={item.ulid} value={item}>
              <Checkbox color='primary' checked={checkBoxSelectedList.indexOf(item) > -1} />
              <ListItemText classes={{primary:classes.listItemText}} primary={`${item.firstName} ${item.lastName}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
};

export default React.memo(AdminTab);
