import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './constant.css';
import {
  Menu,
  MenuItem,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  EventAvailable,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
  LanguageOutlined as LanguageOutlinedIcon,
  ChatBubbleOutlineOutlined as ChatBubbleOutlineOutlinedIcon
} from '@mui/icons-material';

import { LocalLanguageContext } from '../LocalLanguageProvider';
import { Locales } from '../LocalLanguageProvider/LangMap';
import LokalisedContent from '../LokalisedContent';
import GetLokaliseRawString from '../LokalisedContent/rawstring';
import {useAPI} from '../../utils/api';
import Session from '../../session'

const useStyles = makeStyles({
    root: {
      width: '100%',
      height: '50px',
      padding: '0',
      margin: '0'
    },
    content: {
      whiteSpace:  'nowrap'
    },
    footer: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '14px',
      display: 'flex',
      color: '#4f5f6f',
      justifyContent: 'center',
      alignItems: 'center',
      height: '54px'
    },
    bottomNav: {
      position: 'fixed',
      bottom: 0,
      left: 0,
    },
});


const BottomNav = () => {
  let options = Object.keys(Locales)
  const [langState, setLangState] = useState(options[0]);
  const { setLangCode, langCode } = useContext(LocalLanguageContext);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const location = useLocation();
  const classes = useStyles();
  const [anchorElLang, setAnchorElLang] = useState(null);
  const openLang = Boolean(anchorElLang);
  // const [anchorElLogOut, setAnchorElLogOut] = useState(null);
  // const openLogOut = Boolean(anchorElLogOut);   
  const api = useAPI();   

  if (process.env.REACT_APP_STAGE === 'prod') {
    options = options.filter(e => e !== 'keys');
  };

  let optionsTrans = options.map(x => {
    return GetLokaliseRawString(`global_langSwitcher_${x}`, x);
  });

  const handleClickLang = (event) => {
    setAnchorElLang(event.currentTarget);
  };
  const handleCloseLang = () => {
    setAnchorElLang(null);
  };
  // const handleClickLogOut = (event) => {
  //   setAnchorElLogOut(event.currentTarget);
  // };
  // const handleCloseLogOut = () => {
  //   setAnchorElLogOut(null);
  // };

  useEffect(() => {
    if (langCode !== langState) {
      setLangState(langCode);
      const isLargeNumber = (element) => element === langCode;
      if (options.findIndex(isLargeNumber) >= 0) {
        setSelectedIndex(options.findIndex(isLargeNumber));
      }
    }
  }, [langCode, langState, options]);

  const handleMenuItemClick = async (event, index) => {
    setSelectedIndex(index);
    if (langState !== options[index]) {
      setLangState(options[index]);
      setLangCode(options[index]);
      const payload = {
        preferredLang: options[index]
      };
      const editedPatient = await api.editPatient({payload, user: Session.UserId});
      console.log(editedPatient)
    }
  };
    
  return (
    <BottomNavigation 
      value={location.pathname}
    >
      <div style={{width: '45px', display: 'inline-flex',}}>
        <IconButton
          onClick={handleClickLang}
          size="medium"
          aria-controls={openLang ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openLang ? 'true' : undefined}
        >
          <LanguageOutlinedIcon fontSize="inherit" style={{paddingBottom: 3}}/>
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorElLang}
        id="account-menu"
        open={openLang}
        onClose={handleCloseLang}
        onClick={handleCloseLang}
      >
        {optionsTrans.map((option, index) => (
          <MenuItem
            key={option}
            // disabled={index === 2}
            selected={index === selectedIndex}
            onClick={event => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      <BottomNavigationAction
        component={Link}
        to="/patient/appointments"
        label="Appointments"
        value="/patient/appointments"
        icon={ <EventAvailable /> }
        className={classes.content}
      />
      <BottomNavigationAction
        component={Link}
        to="/patient/chat"
        label={LokalisedContent('patient_chat_header', 'Chat')}
        value="/patient/chat"
        icon={<ChatBubbleOutlineOutlinedIcon/>}
        className={classes.content}
      />
      <BottomNavigationAction
        component={Link}
        to="/patient/profile"
        label={LokalisedContent('patient_profile_header', 'Profile')}
        value="/patient/profile"
        icon={<PersonOutlineOutlinedIcon/>}
        className={classes.content}
      />
      <div style={{width: '45px', display: 'inline-flex',}}>
        {/* <IconButton
          onClick={handleClickLogOut}
          size="medium"
          aria-controls={openLogOut ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openLogOut ? 'true' : undefined}
        >
          <MoreVertOutlinedIcon fontSize="inherit" style={{paddingBottom: 3}}/>
        </IconButton> */}
      </div>
      {/* <Menu
        anchorEl={anchorElLogOut}
        id="account-menu"
        open={openLogOut}
        onClose={handleCloseLogOut}
        onClick={handleCloseLogOut}
      >
        <MenuItem>
          Log Out
        </MenuItem>
      </Menu> */}
    </BottomNavigation>
  );
}


export default BottomNav;
