const capitalize = (input) => {
  if (!input) {
    return;
  }
  const raw = input.split(" ");
  const capped = raw.map((x) => {
    return x.charAt(0).toUpperCase() + x.slice(1);
  });

  const concatted = capped.join(" ");

  return concatted;
};

module.exports = { capitalize };
