import React, { useState, useEffect } from 'react';
import Session from "../../../session";
import { trimReceipt } from '../util';
import { ulid } from 'ulid';
import { uploadInS3 } from '../../../utils/s3upload';
import '../clock.css';
import { withStyles, makeStyles } from '@mui/styles';
import {
  Dialog,
  CircularProgress,
  Backdrop,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  Typography,
  Button,
  TextField,
  IconButton,
  Box
} from "@mui/material";
import {
  Close as CloseIcon,
  Image as ImageIcon
} from "@mui/icons-material";
import PropTypes from 'prop-types';
import { Document, Page } from "react-pdf";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const styles = {
  smallIcon: {
    width: 18,
    height: 18,
  }
}

const useStyles = makeStyles((theme) => ({
  buttonDiv: {
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: '18px',
      display: 'flex',
      justifyContent: 'space-around'
    },
  },
  inputButtons:{
    margin: "0px 0px 0px 15px", 
    align: "right",
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '30%',
      margin: "0px 0px 0px 0px", 
    },
  },
  saveButton: {
    background: '#10D236',
    '&:hover': {
      background: '#0a8938',
      cursor: 'pointer'
    }
  },
  disabledButton: {
    background: '#e0e0e0',
    color: 'white',
    '&:hover': {
      // background: '#e0e0e0',
      cursor: 'pointer'
    }
  },
  cancelButton:{
    background: '#E22C56',
    '&:hover': {
      background: '#971533',
      cursor: 'pointer',
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  paper: {
    maxWidth: '650px',
    minWidth: '650px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      minWidth: '100%',
    },
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          onClick={onClose}
          className="icon cur-pointer"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const AddReceipt = ({ 
  receiptName,
  setReceiptName,
  entryId, 
  fileUlids, 
  setFileUlids,
  api,
  openReceipt,
  setOpenReceipt,
  SetLocalFiles,
  fileType,
  displayImage,
  attachFile,
  setAttachFile,
  clearPickeFileOnClick
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    if(width <= 768){
      setIsMobile(true)
      // window.FreshworksWidget('hide');
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
       <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
    
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  
  const uploadFile = async () => {
    const fileFormat = attachFile.name.substring(attachFile.name.lastIndexOf('.'));
    const formData = new FormData();
    formData.append('file', attachFile);
  
    const fileUlid = `${Session.OrganizationId}/files/${ulid()}${fileFormat}`;
    const puturl = await api.getPutObjectURL(fileUlid);
    console.log(puturl.data)
    if (puturl.status === 200) {
      await uploadInS3(puturl.data.url, attachFile);
    }
  
    const resp2 = await api.linkS3Object({
      name: receiptName,
      ulid: fileUlid,
      accessibleOrgs: [Session.OrganizationId],
      meta: {
        hidden: true,
        type: 'adhoc'
      },
      uploader: Session.UserId,
    });
    console.log(resp2)
    console.log('resp2')
    if (resp2.status === 200 && entryId) {
      console.log(entryId)
      await api.putTimesheetExpense({ entryId,
        fileUlids: [...fileUlids, resp2.data.fileUlid]
      });
  
      setFileUlids([...fileUlids, resp2.data.fileUlid]);
    }
  }
    
  const saveReceipt = async () => {
    setLoading(true);
    try {
      console.log('create')
      await uploadFile();
      const input = document.querySelector('#primary[type=file]');
      input.value = null;
      setAttachFile(null);
      setOpenReceipt(false);
      clearPickeFileOnClick('')
    } catch (error) {
      //We need to add snackbars here;

      // ErrorToast('Something went wrong to upload receipt');
    } finally {
      setLoading(false);
    }
  }
  
  return (
    <Dialog 
      open={openReceipt} 
      onClose={() => {
        setOpenReceipt(false);
        setReceiptName('');
        setAttachFile(null);
        SetLocalFiles(null);
      }}
      classes={{paper: classes.paper}}
      className={classes.dialog}
      fullScreen={isMobile}
    >
      <DialogTitle onClose={() => setOpenReceipt(false)}>Add Receipt</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Backdrop
            className={classes.backdrop}
            open={loading}
          >
            <CircularProgress />
          </Backdrop>
          {fileType === 'application/pdf' ?
            <div style={{ margin:'5px', maxWidth:'100%', maxHeight:'470px', objectFit: 'contain' }}>
              <Document
                file={displayImage}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                loading={(<div style={{ padding: '28% 40%', display: 'inline-block', margin: 'auto' }}>
                  <FontAwesomeIcon icon={faSpinner} pulse size="2x" fixedWidth />
                </div>)}
                error={<div style={{ padding: '28% 40%', display: 'inline-block', margin: 'auto' }}>
                  <FontAwesomeIcon icon={faSpinner} pulse size="2x" fixedWidth />
                </div>}
              >
                {Array.from(
                new Array(numPages),
                (_, index) => (
                  <Page
                    style={{
                      height: '100px'
                    }}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />  
                  ),
                )}
              </Document>
            </div>
          : <>
            {fileType === 'image/heic' ?
              <div style={{ margin:'5px', maxWidth:'100%', maxHeight:'280px', objectFit: 'contain' }}>
                <ImageIcon style={{ fontSize: 40 }} />
              </div>
            :
              <img style={{margin:'5px', maxWidth:'100%', maxHeight:'280px', objectFit: 'contain'}} src={displayImage} alt='attachment'></img>
            }
            </>
          }
          </div>
          <div className="row" style={{ marginTop: "10px", marginBottom: "10px", float: "right", width: '100%' }}>
            <div style={{ maxWidth: "350px", width: "100%" }}>
              <TextField
                value={receiptName}
                error={!receiptName.length > 0}
                onChange={(e) => {
                  const trimed = trimReceipt(e.target.value);
                  console.log(trimed);
                  setReceiptName(e.target.value);
                }}
                onBlur={(e) => trimReceipt(e.target.value)}
                variant="outlined"
                label="Name Receipt"
                maxLength="25"
                input="text"
                fullWidth
                inputProps={{
                  style: {
                    width: '100%',
                    background: '#dff2fa',
                    fontSize: '11px',
                  },
                  maxLength: 25
                }}
              />
            </div>
            <div className={classes.buttonDiv}>
              <Button 
                variant='contained'
                disabled={!receiptName.length > 0} 
                onClick={saveReceipt}
                className={!receiptName.length > 0 ? `${classes.inputButtons} ${classes.disabledButton}` : `${classes.inputButtons} ${classes.saveButton}`}
              >
                Save
              </Button>
              <Button 
                variant='contained'
                disabled={!receiptName.length > 0}
                className={!receiptName.length > 0 ? `${classes.inputButtons} ${classes.disabledButton}` : `${classes.inputButtons} ${classes.cancelButton}`}
                onClick={() => {
                  setOpenReceipt(false);
                  setReceiptName('');
                  setAttachFile(null);
                  SetLocalFiles(null);
                }}
              >
                Cancel
              </Button>
            </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddReceipt;
