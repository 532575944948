import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {
  PersonAddAlt1,
  CheckCircle as CheckCircleIcon,
  CircleOutlined as CircleIcon
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import useSWR, { trigger } from 'swr';
import Session from '../../session';
import { API_URL } from '../../config';
import axios from "axios";
import AddUserModal from './AddUserModal';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import './profileStyles.css'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    border: '1.5px solid #BCBCCB',
    borderRadius: "5px",
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
    padding: '10px',
    paddingBottom: '30px',
  },
  topButton: {
    alignSelf: 'flex-start',
    margin: '10px 0 15px 0',
    height: '40px',
    color: 'white',
    background: '#10D236',
    '&:hover': {
      background: '#0a8938',
      cursor: 'pointer',
    },
  },
  tableRowLight: {
    color: '#707070',
    background: 'white',
    '&:hover': {
      background: '#dff2fa',
      cursor: 'pointer',
    }
  },
  tableRowDark: {
    color: '#707070',
    background: '#EBEBEB',
    '&:hover': {
      background: '#dff2fa',
      cursor: 'pointer',
    }
  },
  viewButton: {
    color: '#47AFEB',
    fontWeight: 'Bold',
  },
  bottomTableHeaderCells: {
    fontFamily: 'Helvetica',
    width:'7%',
    padding: 3,
  },
  bottomTableNameHeader: {
    fontFamily: 'Helvetica',
    width:'18%',
    padding: 3,
    paddingLeft: 10
  },
  noHoverLightRow: {
    background: 'white',
  },
  detailtable: {
    borderRadius: '5px',
    overflow: 'hidden',
    marginBottom: '25px',
  },
}));

const UsersTab = ({AuthUser}) => {
  const classes = useStyles();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [staffUsers, setStaffUsers] = useState([]);
  const [reload, setReload] = useState(true);

  const staffUrl = `${API_URL}/v3/orgs/${Session.OrganizationId}/staff`;

  const fetcher = url => axios.get(url, {
    headers: {
        'Authorization': `Bearer ${Session.Token}`,
        'Content-Type': 'application/json',
    }
  }).then(res => res.data);

  const { data: staffData, errorStaff } = useSWR(() => staffUrl, fetcher);

  useEffect(() => {
    if (staffData && !errorStaff) {
      setStaffUsers(staffData);
      setReload(false);
    }
  }, [staffData, errorStaff]);

  const handleUserDetails = (user) => {
    setUserDetails(user);
    setAddUserModalOpen(true);
  };

  const handleAddUserModal = (status) => {
    setUserDetails(null);
    setAddUserModalOpen(status);
  };

  useEffect(() => {
    if(reload) {
      trigger(staffUrl);
      setReload(false);
    } 
  }, [reload, staffUrl,]);

  const handlerReload = (status) =>{
    setReload(status);
  };

  const HoverAbleDiv = ({row, index}) => {
    console.log('ROW:')
    console.log(row)
    const [hovered, setHovered] = useState(false);
    
    const moduleCheckMark = (permission) => {
      return (
        <>
          {
            <CheckCircleIcon 
              style={{ 
                color: permission ? "#47AFEB" : "#d3d3d3", 
                height: 25, 
                paddingTop: 7, 
                paddingBottom: 0
              }}
            />
          }
        </>
      )
    };

    // TODO: What is the conditions for a greyed checkmark vs empty Circle?
    const moduleCheckMarkOrEmpty = (permission) => {
      return (
        <>
          {
            permission ? 
            <CheckCircleIcon 
              style={{ 
                color: permission ? "#47AFEB" : "#d3d3d3", 
                height: 25, 
                paddingTop: 7, 
                paddingBottom: 0
              }}
            /> : <CircleIcon              
              style={{ 
              color: "#d3d3d3", 
              height: 25, 
              paddingTop: 7, 
              paddingBottom: 0
            }}/>
          }
        </>
      )
    };
    
    return (
      <TableBody>
        <TableRow
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          key={row.ulid} 
          onClick={() => handleUserDetails(row)} 
          className={index % 2 ? classes.tableRowLight : classes.tableRowDark} 
        >
          <TableCell className={classes.bottomTableNameHeader}>{`${row.firstName} ${row.lastName}`}</TableCell>
          <TableCell className={classes.bottomTableHeaderCells} align="center">{row.role}</TableCell>
          <TableCell className={classes.bottomTableHeaderCells} align="center">{moduleCheckMark(row.permissions.appointment?.viewable)}</TableCell>
          <TableCell className={classes.bottomTableHeaderCells} align="center">{moduleCheckMark(row.permissions.efax?.viewable)}</TableCell>
          <TableCell className={classes.bottomTableHeaderCells} align="center">{moduleCheckMarkOrEmpty(row.permissions.sms?.viewable)}</TableCell>
          <TableCell className={classes.bottomTableHeaderCells} align="center">{moduleCheckMark(row.permissions.chat?.viewable)}</TableCell>
          <TableCell className={classes.bottomTableHeaderCells} align="center">{moduleCheckMark(row.permissions.payment?.viewable)}</TableCell>
          <TableCell className={classes.bottomTableHeaderCells} align="center">{moduleCheckMark(row.permissions.time_clock?.viewable)}</TableCell>
          <TableCell className={classes.bottomTableHeaderCells} align="center">{moduleCheckMark(row.permissions.patients?.viewable)}</TableCell>
          <TableCell className={classes.bottomTableHeaderCells} align="center">{moduleCheckMark(row.permissions.contacts?.viewable)}</TableCell>
          <TableCell className={classes.bottomTableHeaderCells} align="center">{moduleCheckMark(row.permissions.telehealth?.viewable)}</TableCell>
          <TableCell className={classes.bottomTableHeaderCells} align="center">{moduleCheckMark(row.permissions.secure_messaging?.viewable)}</TableCell>
          <TableCell className={classes.bottomTableHeaderCells} align="center">
            {hovered
              &&
              <div className={classes.viewButton}>
                Details
              </div>
            }
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  return (
    <div className={classes.root}>
      <Button 
        variant='contained'
        className={classes.topButton}
        startIcon={<PersonAddAlt1 />}
        onClick={() => handleAddUserModal(true)}
      >
        Add User
      </Button>
      <TableContainer>
        <Table className={classes.detailtable} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.noHoverLightRow}>
              <TableCell className={classes.bottomTableHeaderCells}>User</TableCell>
              <TableCell className={classes.bottomTableHeaderCells} align="center">Role</TableCell>
              <TableCell className={classes.bottomTableHeaderCells} align="center">Appointment</TableCell>
              <TableCell className={classes.bottomTableHeaderCells} align="center">eFax</TableCell>
              <TableCell className={classes.bottomTableHeaderCells} align="center">SMS</TableCell>
              <TableCell className={classes.bottomTableHeaderCells} align="center">Chat</TableCell>
              <TableCell className={classes.bottomTableHeaderCells} align="center">Payment</TableCell>
              <TableCell className={classes.bottomTableHeaderCells} align="center">Time Clock</TableCell>
              <TableCell className={classes.bottomTableHeaderCells} align="center">Patient</TableCell>
              <TableCell className={classes.bottomTableHeaderCells} align="center">Contacts</TableCell>
              <TableCell className={classes.bottomTableHeaderCells} align="center">TeleHealth</TableCell>
              <TableCell className={classes.bottomTableHeaderCells} align="center">Secure Message</TableCell>
              <TableCell className={classes.bottomTableHeaderCells} align="center"></TableCell>
            </TableRow>
          </TableHead>
          {staffUsers.map((row, index) => (
            <HoverAbleDiv row={row} index={index} key={index}/>
          ))}
        </Table>
      </TableContainer>
      <Elements stripe={stripePromise}>

      <AddUserModal 
        modalOpen={addUserModalOpen}
        handlerModal={handleAddUserModal}
        userDetails={userDetails}
        handlerReload={handlerReload}
        AuthUser={AuthUser}
      />
        </Elements>
    </div>
  );
}

export default UsersTab;
