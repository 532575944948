import moment from 'moment';

let weekday = Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";

//////////// Made Date time function 
const TimeFormat = (unFormateDate, fullTime = false) => {
    var today = new Date(Date.now());
    var date = new Date(unFormateDate * 1000);

    var equalYear = today.getFullYear() === date.getFullYear();
    var equalMonth = today.getMonth() === date.getMonth();
    var equalToday = equalYear && equalMonth && today.getDate() === date.getDate();
    var equalYesterday = equalYear && equalMonth && (today.getDate() - 1) === date.getDate();
    var equalThisWeek = date >= new Date().setDate(today.getDate() - 6) && date <= new Date().setDate(today.getDate() + 6);

    if (equalToday) {
        return `Today ${date.toLocaleString([], { hour: '2-digit', minute: '2-digit' })}`;
    } else {
        if (equalYesterday) {
            return "Yesterday " + date.toLocaleString([], { hour: '2-digit', minute: '2-digit' });
        } else if (equalThisWeek) {
            return weekday[date.getDay()] + " " + date.toLocaleString([], { hour: '2-digit', minute: '2-digit' });
        } else {
            var Month = date.toLocaleString("en-us", { month: "long" }),
                Time = date.toLocaleTimeString("en-us", { hour: "2-digit", minute: "2-digit" });

            return Month + " " + date.getDate() + (equalYear ? '' : ", " + date.getFullYear()) + " " + Time;
            //return date.toLocaleString([], { hour12: true });
        }
    }
}

const getFullDate = (unFormateDate) => {
    var date = new Date(unFormateDate * 1000);
    var Month = date.toLocaleString("en-us", { month: "long" });
    return `${weekday[date.getDay()]}, ${Month} ${date.getDate()}, ${date.getFullYear()}`;
}

const BunchTime = (unFormateDate) => {
    var today = new Date(Date.now());
    var date = new Date(unFormateDate * 1000);

    var equalYear = today.getFullYear() === date.getFullYear();
    var equalMonth = today.getMonth() === date.getMonth();
    var equalToday = equalYear && equalMonth && today.getDate() === date.getDate();
    var equalYesterday = equalYear && equalMonth && (today.getDate() - 1) === date.getDate();
    var equalThisWeek = date >= new Date().setDate(today.getDate() - 6) && date <= new Date().setDate(today.getDate() + 6);

    if (equalToday) {
        return "Today";
    } else {
        if (equalYesterday) {
            return "Yesterday";
        } else if (equalThisWeek) {
            return weekday[date.getDay()];
        } else {
            var Month = date.toLocaleString("en-us", { month: "long" });
                // Time = date.toLocaleTimeString("en-us", { hour: "2-digit", minute: "2-digit" });

            return Month + " " + date.getDate() + (equalYear ? '' : ", " + date.getFullYear());
        }
    }
}

const getDate = (unFormateDate) => {
    const date = new Date(unFormateDate);
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yy = date.getFullYear();    
    
    return `${mm}/${dd}/${yy}`;
}

const getDateWithWeek = (unFormateDate) => {
    const date = new Date(unFormateDate * 1000);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0');;
    let yy = date.getFullYear().toString().substring(2);
    const day = weekday[date.getDay()]
    
    return `${day.substring(0,2)} ${mm}/${dd}/${yy}`;
}

const getTime = (unFormateDate) => {
    let date = moment(unFormateDate);
    const time = date.format("LT")

    return `${time}`;
}

const getDateTime = (unFormateDate) => `${getDate(unFormateDate)} ${getTime(unFormateDate)}`;

const getFullDuration = (d) => {
    d = Number(d);
    const h = Math.floor(d / 3600),
    m = Math.floor(d % 3600 / 60),
    s = Math.floor(d % 3600 % 60);

    if (m === 0 && h === 0) {
        return `${s} Second(s)`;
    }

    if (h === 0) {
        return `${m}${s > 0 ? `:${String(s).padStart(2, '0')}` : ''} Minute(s)`
    }

    return `${h}${m > 0 ? `:${String(m).padStart(2, '0')}` : ''} Hour(s)`
}

const getDuration = seconds => {
    seconds = Number(seconds || 0);
    const h = Math.floor(seconds / 3600),
    m = Math.floor(seconds % 3600 / 60),
    s = Math.floor(seconds % 3600 % 60);

    if (m === 0 && h === 0) {
        return `${s} Second(s)`;
    }

    if (h === 0) {
        return `${m}${s > 0 ? `:${String(s).padStart(2, '0')}` : ''} Minute(s)`;
    }

    return `${h}${m > 0 ? `:${String(m).padStart(2, '0')}` : ''} Hour(s)`;
}

const getHHMMSSDuration = seconds => {
    seconds = Number(seconds || 0);
    const h = Math.floor(seconds / 3600),
    m = Math.floor(seconds % 3600 / 60),
    s = Math.floor(seconds % 3600 % 60);
    return `${h}:${m}:${s}`
}

const startAndEndOfWeek = date => {
  // If no date object supplied, use current date
  // Copy date so don't modify supplied date
  var now = date? new Date(date) : new Date();

  // set time to some convenient value
  now.setHours(0,0,0,0);

  // Get the previous Monday
  var monday = new Date(now);
  monday.setDate(monday.getDate() - monday.getDay() + 1);

  // Get next Sunday
  var sunday = new Date(now);
  sunday.setDate(sunday.getDate() - sunday.getDay() + 7);
  sunday.setHours(23, 59, 59)

  // Return array of date objects
  return [monday, sunday];
}

export { startAndEndOfWeek, TimeFormat, BunchTime, getDateTime, getTime, getDate, getDuration, getHHMMSSDuration, getFullDate, getFullDuration, getDateWithWeek };
