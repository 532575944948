import React from 'react';
// import { withSnackbar } from 'notistack';
import { withStyles } from '@mui/styles';
import {Container, Typography, Button} from '@mui/material';


const styles  = theme => ({
    gridContainer:{
        borderRadius: '30px',
        display: 'flex',
        backgroundColor: 'white',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
          height: '100vh',
        },
      },
    topHeader: {
        paddingBotttom: '10px',
        position: 'relative',
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    errorLogo: {
        marginBottom: '60px',
        maxHeight: '350px',
        maxWidth: '600px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '10px',
            marginTop: '20px',
            maxWidth: '270px',
        },
    },
    mistakeText: {
        color: '#47AFEB',
        width: '75%', 
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '35px'
          },
    },
    messageErrorText: {
        color: "#4D4F5C",
        width: '80%', 
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '25px'
          },
    },
    helpText: {
            color: "#4D4F5C",
            width: '80%', 
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                fontSize: '8px'
              },
    },
    returnButton: {
        textTransform: 'none',
        fontSize: '18px',
        minHeight: '50px',
        minWidth: '150px',
        margin: '50px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            minWidth: '100px',
          },
    },
    footer: {
        marginTop: '60px',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '14px',
        display: 'flex',
        color: '#4f5f6f',
        justifyContent: 'center',
        alignItems: 'center',
        height: '54px'
      }
});

class ErrorBoundary extends React.Component {
    state = {
        error: '',
        errorInfo: '',
        hasError: false,
    }
    

    static getDerivedStateFromError(error) {
        return {hasError: true, error};
    }


    componentDidCatch(error, errorInfo) {
        console.log('error in errorBoundary componentDidCatch', error);
        this.setState({errorInfo});
    }

    render() {
        const {hasError} = this.state;
        const {isUserAuthenticated} = this.props;
        const {classes} = this.props;

        if (hasError && isUserAuthenticated) {
            return (
                <Container maxWidth="lg" className={classes.gridContainer}>
                    <div className={classes.topHeader}>
                    <img src='/error_404_logo.svg' alt="error_logo" className={classes.errorLogo} />
                    </div>
                    <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant="h3" className={classes.mistakeText}>Oops!</Typography>

                        <Typography variant="h3" className={classes.messageErrorText}>This page had an appointment scheduled, we're sure everything will be just fine..</Typography>

                        <Button variant="contained" color='primary' className={classes.returnButton} onClick={() => window.location.href = `http://${process.env.REACT_APP_URL}`}>Return Home</Button>

                        <Typography variant="subtitle1" className={classes.helpText}>If you want to leave us feedback or send in your general comments about ExamRoomLive.com, please click on the <span style={{color: "black"}}>“Help”</span> button located on the bottom left side of the page.</Typography>

                        <Typography variant="subtitle1" className={classes.helpText}>Thanks!</Typography>
                    </div>
                    <div className={classes.footer}>
                        <div>Powered by</div>
                        <div>
                            <img src="/ExamRoomLive_IOS_512x512_Logo.png" alt="footer-logo" />
                        </div>
                        <div>© {new Date().getFullYear()}</div>
                    </div>
                    </Container>
            );
        }

        return this.props.children;
    }
};

// export default withSnackbar(withStyles(styles)(ErrorBoundary));
export default withStyles(styles)(ErrorBoundary);
