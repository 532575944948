import React from "react";
import './paymentStyle.css';
import styled from "styled-components";
import Session from "../../session";
import {useAPI} from '../../utils/api'

const Span = styled.span`
text-align: center;
margin: 10px 0;
color: #4D4F5C;
font-family: 'Roboto', sans-serif;
`;
const BtnSpan = styled.span`
text-align: center;
margin: 10px 0 0 0;
color: #4D4F5C;
font-family: 'Roboto', sans-serif;
`;
const SmallSpan = styled.span`
text-align: center;
margin: 0;
font-size: 10px;
color: #4D4F5C;
font-family: 'Roboto', sans-serif;
`;
const Button = styled.button`
  padding: 10px 20px;
  color: #FFFFFF;
  background: #10D236 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-weight: 800;
  opacity: 1;
  border: none;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

const StripeConnectView = () => {
  const api = useAPI();

  const generateLink = async () => {
    const resp = await api.accountLinkStripeConnect(Session.OrganizationStripeId)
    const url = resp.url
    console.log(url)
    window.open(url, "_self");
  }


  return (
    <>
      <div className="cen">
        <img src="/stripe-brands.svg" alt='avtar' />
        <Span>Ready to start accepting payments?</Span>
        <Span><img src="/cc-visa-brands.svg" alt='avtar' />
        <img style={{margin: '0 5px'}} src="/cc-mastercard-brands.svg" alt='avtar' />
        <img src="/cc-amex-brands.svg" alt='avtar' /></Span>
        <Span>Just fill out a quick questionnaire to create your <br/> Stripe payment processing account and you’re ready to go.</Span>
        <BtnSpan><Button onClick={generateLink}>Start Now</Button></BtnSpan>
        <SmallSpan>*** You will be directed to our payment partner <br/> Stripe to complete your application.</SmallSpan>
      </div>
    </>
  );
};


export default StripeConnectView;