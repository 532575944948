import React from 'react';
import './constant.css';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
      width: '100%',
      height: '50px',
      padding: '0',
      margin: '0'
    },
    footer: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '14px',
      display: 'flex',
      color: '#4f5f6f',
      justifyContent: 'center',
      alignItems: 'center',
      height: '54px'
    }
  });

const Footer = () => {
    const classes = useStyles();  
    return (
      <div className={classes.root}>
      <div className={classes.footer}>
          <div>Powered by</div>
        <div>
          <img src="/ExamRoomLive_IOS_512x512_Logo.png" alt="footer-logo" />
        </div>
          <div>© {new Date().getFullYear()}</div>
      </div>
      </div>
    );
  }


export default Footer;


