import React, {useState, useEffect} from "react";
import LogoHeader from "./logo";
import './constant.css'
import Session from "../../session";
import {useAPI} from '../../utils/api'
import LanguageSwitcher from "../LanguageSwitcher";
import {isMobile} from 'react-device-detect';

import { makeStyles,styled } from '@mui/styles';
import { TextField, Autocomplete, Checkbox, Box, Tooltip, 
  IconButton, Avatar, Menu, MenuItem, Divider, ListItemIcon,
  ListItem,ListItemText,Collapse,List, Badge,Stack } from '@mui/material';
import { 
  Home as HomeIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  Logout  as LogoutIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { useAuth0 } from "../../react-auth0-spa";
import { LOGOUT_URL } from "../../config";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  topDiv: {
    width: 'calc(100% - 174px)',
    marginLeft: '174px',
    display: 'flex',
    justifyContent: 'flex-end',
    height: '60px',
    padding: '0px',
    background: 'linear-gradient(0.25turn, transparent, transparent, #f5f5f5), url("/jc_bkgrnd.png") no-repeat center',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      zIndex:11,
      position:"sticky",
      top:0,
    },
  },
  header: {
    '&:focus': {
      outline: 'none'
    },
  },
  orgSwitcher: {
    paddingTop: '12px',
  },
  accountMenu:{
    paddingTop:'8px',
    marginRight:'25px',
  },
  menuBadge:{
    marginRight:'8px'
  },
  paystp :{
    position: 'absolute',
    left: 4,
    border: '1px solid #fdfafa',
    padding: '4px',
    borderRadius: '100%',
  },
  menuitemlist:{
    fontFamily:'sans-serif',
    marginLeft:'10px'
  }
}));

export default function TopBar({userPortalType, twilioConversationClient}) {
  const { logout } = useAuth0();
  const api = useAPI();
  const [orgData, setOrgData] = useState([]);
  const [currentName, setCurrentName] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [chatClient, setChatClient] = useState('');
  const [user, setUser] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const open = Boolean(anchorEl);
  // eslint-disable-next-line 
  useEffect(async () => {
    if (orgData.length === 0 && Session.CustomOrgSwitcher) {
      const ld = await api.getOrgSwitcherData();
      setOrgData(ld.data);
    }
  }, [orgData, api])

  useEffect(() => {
    if (orgData.length > 0) {
      const [mapped] = orgData.filter(n => n.ulid === Session.OrganizationId);
      console.log(mapped)
      setCurrentName(mapped.name)
    }
  }, [orgData]);

  useEffect(() => {
    if(twilioConversationClient !== undefined){
      setChatClient(twilioConversationClient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twilioConversationClient]);

  useEffect(() => {
    if(chatClient !== '' && chatClient !== null && chatClient !== undefined ) {
      chatClient.getUser(Session.UserId).then(function (obj) {
        console.log(obj.attributes.status);
        setUser(obj);
      });
    }
  }, [chatClient]);

  useEffect(() =>{
    if(user !== '' && user !== null && user !== undefined)
    if(user.attributes.hasOwnProperty('status') && user.attributes.status !== '') {
      setUserStatus(user.attributes.status);
    }
    else if(user.isOnline){
      setUserStatus('Available')
    }else{
      setUserStatus('Appear Offline');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const orgSwitcher = (i, v) => {
    Session.SetCustomOrgSwitcher(true);
    Session.SetOrganizationId(v.ulid);
    Session.SetOrganizationStripeId(v.stripeId);
    window.location.reload(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const StyledBadge = styled(Badge)(({ obj }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: obj,
      boxShadow: `0 0 0 1px #f5f5f5`,
    }
  }));

  const setStatus = (status) => {
    user.updateAttributes({status: status});
    setUserStatus(status);
  }

  const setLogoutStatus = () =>{
    user.updateAttributes('Appear Offline');
  }

  const goProfile = () => {
    if(window.location.pathname !== '/profile'){
      window.location = `${window.location.origin}/profile`;
    }    
  }

  const BadgeAvatars = () => {
    return (
      <Stack direction="row" spacing={2}>
        <StyledBadge
          obj={GetStatusColor(userStatus)}
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
        >
          <Avatar alt={Session.UserName} src={Session.AvatarUrl} />
        </StyledBadge>
      </Stack>
    );
  }

  const GetStatusColor = (status) => {
    if(status === 'Available'){
      return '#10D236';
    }else if(status === 'Unavailable'){
      return 'red';
    }else if(status === 'Break'){
      return '#D2B410';
    }else if(status === 'Out of Office'){
      return 'red';
    }else{
      return '#C8C9CA';
    }
  };

  const GetStatusIcon = (status) => {
    if (status === 'Unavailable') {
      return (
        <img style={{height: '22px', width: '22px', marginRight: '10px', marginBottom: '5px'}} alt="img" src="/forbidden.png"/>
      );
    } else if(status === 'Break') {
      return (
        <img style={{height: '22px', width: '22px', marginRight: '10px', marginBottom: '5px'}} alt="img" src="/coffee.png"/>
      );
    } else if(status === 'Out of Office') {
      return (
        <img style={{height: '22px', width: '22px', marginRight: '10px', marginBottom: '5px'}} alt="img" src="/palm-tree.png"/>
      );
    }
  };

  const MultiLevel = () => {
    const [open, setOpen] = useState(false);
    const handleClick = () => {
      setOpen((prev) => !prev);
    };
  
    return (
      <>
        <ListItem button onClick={handleClick}>
          <ListItemText primary={'Set Status'} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <MenuItem onClick={() => setStatus('Available')} className={classes.menuitemlist} id="Available" key={0}>           
              <span style={{background: '#10D236'}} className={classes.paystp}/>
              Available
            </MenuItem>
            <MenuItem onClick={() => setStatus('Unavailable')} className={classes.menuitemlist} id="Unavailable" key={1}>
              <span style={{background: 'red'}} className={classes.paystp}/>
              Unavailable
              <img style={{height:14, width:14, marginLeft:5}} alt="img" src="/forbidden.png"/>
            </MenuItem>
            <MenuItem onClick={() => setStatus('Break')} className={classes.menuitemlist} id="Break" key={2}>
              <span style={{background: '#D2B410'}} className={classes.paystp}></span>
              Break
              <img style={{height:14, width:14, marginLeft:5}} alt="img" src="/coffee.png"/>
            </MenuItem>
            <MenuItem onClick={() => setStatus('Out of Office')} className={classes.menuitemlist} id="Out of Office" key={3}>
              <span style={{background: 'red'}} className={classes.paystp}></span>
              Out of Office
              <img style={{height:14, width:14, marginLeft:5}} alt="img" src="/palm-tree.png"/>
            </MenuItem>
            <MenuItem onClick={() => setStatus('Appear Offline')} className={classes.menuitemlist} id="Appear Offline" key={4}>
              <span style={{background: '#C8C9CA'}} className={classes.paystp}></span>
              Appear Offline
            </MenuItem>
          </List>
        </Collapse>
      </>
    );
  };
  
  return (
    <>
      <header className={classes.header}>
        <LogoHeader />
      </header>

      <div className={classes.topDiv}>        
        <div className={classes.orgSwitcher}>
          {
            Session.CustomOrgSwitcher 
              && 
            Session.AccessibleOrgs 
              &&  
            (
              <div style={{height: "10px"}}>
                <Autocomplete
                  disablePortal
                  size="small"
                  className={classes.selectRoot}
                  id="combo-box-demo"
                  onChange={orgSwitcher}
                  options={orgData}
                  sx={{ width: 250 }}
                  getOptionLabel={(option) => {
                    console.log(option)
                    return (option.name)
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={Session.OrganizationId === option.ulid}
                      />
                      {option.name}
                      {Session.HomeOrg === option.ulid && <HomeIcon style={{ marginLeft: 8 }} />}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      label={currentName}
                      value="test"
                    />
                  )}
                />
              </div>
            )
          }
          {userPortalType === 'patient' && <LanguageSwitcher />}
        </div>
        {
          (userPortalType !== 'patient' && userStatus !== '')
            &&
          <div className={classes.accountMenu}>
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    <Tooltip title="Account settings">
                      <IconButton
                        style={{borderRadius:0}}
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                      >
                        {!isMobile && Session.UserName}
                        {!isMobile && <ExpandMoreIcon />}
                        {
                          userStatus !== ''
                            && 
                          GetStatusIcon(userStatus)
                        }
                        <BadgeAvatars/>
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <MultiLevel/>
                    <Divider />
                    <MenuItem onClick={()=>{handleClose();
                    goProfile();}}>
                      <ListItemIcon>
                      <Avatar alt="Avatar" src={Session.AvatarUrl} sx={{ width: 32, height: 32 }}></Avatar>
                      </ListItemIcon>
                      Profile
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={()=> {handleClose();
                    setLogoutStatus();
                    logout({ returnTo: LOGOUT_URL + '?logout=true' });
                  }}>
                      <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </>            
          </div>
        }
      </div>
    </>
  )
}
