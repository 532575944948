const genId = () => {
const datenow = Math.floor(Date.now() / 1000);
const rndm = Math.floor(Math.random() * 100000);
const toap = datenow.toString() + rndm.toString();
const toint = parseInt(toap, 10);
const res = (toint.toString(16)).toUpperCase()
return res
}

export default genId;
