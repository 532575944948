import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DateWithoutSeconds from "../../utils/dateWithoutSec";
import classNames from "classnames";
import Session from "../../session";
import { BlockPicker } from 'react-color'
import {
  Autocomplete,
  Button,
  Tooltip,
  Box,
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  ClickAwayListener,
  Popover,
  createFilterOptions,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { withStyles, makeStyles } from '@mui/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {
  Search as SearchIcon,
  Info as InfoIcon,
  LocalOffer,
  Add
} from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider,DateRangePicker} from '@mui/x-date-pickers-pro';

import PaymentModal from './payModal';
import ConsoleLog from '../../utils/console';
import { StatusRefund, StatusFailed, StatusSuccess } from '../styled/paymentsCommon';
import {usePaymentTags} from '../../appsync-hooks/paymentTagsHook';

const mobileTheme = createTheme({
  typography: {
    fontSize: 10
  },
  palette: {
    primary: {
      main: '#47afeb',
      dark: '#005184',
      contrastText: '#fff',
    },
  },
});

const H1 = styled.h1`
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
  margin-left: 30px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .cellAlign': {
      paddingLeft: '10px',
    },
    '& .toolTipAlign': {
    },
    '& .buttonAlign': {
    },
    '& .hoverOver': {
      '&:hover': {
        backgroundColor: '#BBE1F7'
      },
    },
  },
  headingContainer:{
    display: 'flex',
    [theme.breakpoints.down(768)]: {
      flexDirection: 'column'
    },
  },
  inputContainer: {
    width: '100%',
    paddingTop: '0px',
    paddingBottom: '5px',
    flexWrap: 'nowrap',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  tooltipIcon: {
    '&:hover': {
      cursor: 'auto'
    }
  },
  searchTransactions: {
    width: '100%',
    marginRight: 10,
    [theme.breakpoints.down(768)]: {
      width: '100%'
    },
  },
  labelRoot: {
    top: '-12.5px',
    "&$labelFocused": {
      top: 0
    }
  },
  labelZero: {
    top: 0,
    "&$labelFocused": {
      top: 0
    }
  },
  labelFocused: {
    top: 0
  },
  tag: {
    height: 24,
    position: "relative",
    zIndex: 0,
    "& .MuiChip-label": {
      color: "#fff",
    },
    "& .MuiChip-deleteIcon": {
      color: "#cfd7df",
    },
    "&:after": {
      content: '""',
      right: 10,
      top: 6,
      height: 12,
      width: 12,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
    },
  },
  grayTag: {
    backgroundColor: '#666666',
    "& .MuiChip-deleteIcon": {
      color: "#666666",
    },
  },
  redTag: {
    backgroundColor: '#85200E',
    "& .MuiChip-deleteIcon": {
      color: "#85200E",
    },
  },
  yellowTag: {
    backgroundColor: '#BF9021',
    "& .MuiChip-deleteIcon": {
      color: '#BF9021',
    },
  },
  purpleTag: {
    backgroundColor: '#674EA7',
    "& .MuiChip-deleteIcon": {
      color: "#674EA7",
    },
  },
  greenTag: {
    backgroundColor: '#34A853',
    "& .MuiChip-deleteIcon": {
      color: '#34A853',
    },
  }
}));

const ShortTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 120
  },
}))(Tooltip);

const InfoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor : '#47afeb'
  },
  arrow: {
    color: '#47afeb'
  },
}))(Tooltip);

const RedTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor : '#85200e'
  },
  arrow: {
    color: '#85200e'
  },
}))(Tooltip);

const PurpleTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor : '#674ea7'
  },
  arrow: {
    color: '#674ea7'
  },
}))(Tooltip);

const GTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor : '#34a853'
  },
  arrow: {
    color: '#34a853'
  },
}))(Tooltip);

const GyTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor : '#666666'
  },
  arrow: {
    color: '#666666'
  },
}))(Tooltip);

const YwTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor : '#bf9021'
  },
  arrow: {
    color: '#bf9021'
  },
}))(Tooltip);

const LimitTags = ({data, tagList}) => {
  const [paymentTags,createPyamentTagFn,UpdateStripePyamentFn] = usePaymentTags();
  console.log(paymentTags);
  const [currentSelected, setCurrentSelected] = useState(null);
  const classes = useStyles();
  const filter = createFilterOptions();
  const [selectedColor, setSelectedColor] = useState('#666666');
  const [open, toggleOpen] = React.useState(false);
  const [selected, setSelected] = useState(data.tag === null ? [] : data.tag);
  const [tempSelected, setTempSelected] = useState('');
  const [dialogValue, setDialogValue] = React.useState({
    title: '',
    id: ''
  });
  const DialogClose = () => {
    setDialogValue({
      title: '',
      id: '',
    });
    toggleOpen(false);
  };

  useEffect(() => {
    setCurrentSelected(data);
  }, [data])


  useEffect(() => {
    if(tagList && tagList.length > 0 && currentSelected !== null){
      let tagPayload = [];
      for (let index = 0; index < currentSelected.tag.length; index++) {
      tagPayload.push(currentSelected.tag[index].id);
      }
      if(tempSelected !== ''){
        const findNew = paymentTags.find(x=>x.title === tempSelected.title);
        if(findNew!== null){
          tagPayload.push(findNew.id)
          setSelected(oldArray => [...oldArray, findNew]);
        }
      }
      UpdateStripePyamentFn({id:currentSelected.id, tags:tagPayload});
      setTempSelected('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagList])
  

  const HandleAddNewTag = async () => {
    const payload = {
      organizationId: Session.OrganizationId,
      title: dialogValue.title,
      color: selectedColor
    };
    setTempSelected(payload);
    await createPyamentTagFn(payload);
    toggleOpen(false);
  }

  const handlColorChange = (color) => {
    if(color.hex){
    setSelectedColor(color.hex);
     }
  }
  

  let blockStyles={
    default: { 
      input:{
        display: 'none'
      },
      label:{
        display: 'none'
      }
    },
  };

  return (
    <>
    <Autocomplete
      clearOnBlur={false}
      filterSelectedOptions={true}
      multiple
      size="small"
      id="multiple-limit-tags"
      options={tagList}
      value={selected}
      onChange={(event, newValue) => {
        if(newValue && event.code === 'Enter' && newValue.filter(x=>!x.hasOwnProperty('id')).length>0){
          const nv = newValue.filter(x=>!x.hasOwnProperty('id'));
          setDialogValue({
            title: nv[0],
            id: '',
          });
          toggleOpen(true);
          return
        }
        if (newValue.length <= 5) {
          if(newValue && newValue.filter(x=>x.isNew).length>0){
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                title: event.target.innerText.substring('4'),
                id: '',
              });
            });
        }
         else if (newValue && newValue.inputValue) {
          toggleOpen(true);
          setDialogValue({
            title: newValue.inputValue,
            id: '',
          });
        } else {
         if(newValue && newValue.length>0){
          let tagPayload = []
          for (let index = 0; index < newValue.length; index++) {
            let obj = newValue[index];
            tagPayload.push(obj.id);
          }
         UpdateStripePyamentFn({id:currentSelected.id, tags:tagPayload});
         }else{
          UpdateStripePyamentFn({id:currentSelected.id, tags:[]});
         }           
         setSelected(newValue.filter(x=>x.isNew !== true));
        }
        } else {
          return;
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add ${params.inputValue}`,
            isNew:true
          });
        }
        return filtered;
      }}
      getOptionLabel={(options) => options.title}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} placeholder={selected && selected.length>0 ? null : 'Select Tags'} 
        inputProps={{ ...params.inputProps, maxLength: 10}}/> 
      )}
      sx={{ width: '300px' }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            classes={{
              root:classNames(classes.tag,{
                [classes.redTag]: option.color === '#85200e',
                [classes.grayTag]: option.color === '#666666',
                [classes.yellowTag]: option.color === '#bf9021',
                [classes.purpleTag]: option.color === '#674ea7',
                [classes.greenTag]: option.color === '#34a853',
              })
            }}
            label={`${option.title}`}
            {...getTagProps({ index })}
          />
        ))
      }
    />
    <Dialog open={open} onClose={DialogClose} 
     sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "278px",  // Set your width here
        },
      },
    }}>
        <DialogTitle>Add a new tag</DialogTitle>
        <DialogContent
          sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
        >
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={dialogValue.title}
            onChange={(event) =>
              setDialogValue({
                ...dialogValue,
                title: event.target.value,
              })
            }
            inputProps={{
              maxLength: 10,
            }}
            label="Tag Name"
            type="text"
            variant='outlined'
          />
          <DialogContentText sx={{paddingBottom:'10px', alignSelf: 'baseline'}}>
          Select tag color
          </DialogContentText>
          <BlockPicker 
           triangle="hide"
           styles={blockStyles}
           color={selectedColor ? selectedColor : '#666666'}
           onChangeComplete={handlColorChange}
           colors={['#666666','#85200E','#BF9021','#674EA7','#34A853']}
          />
        </DialogContent>
        <DialogActions>
          <Button style={{backgroundColor:'#E22C56', color:'white',maxHeight:'32px'}} onClick={DialogClose}>Cancel</Button>
          <Button style={{backgroundColor:'#10D236', color:'white',maxHeight:'32px'}} disabled={dialogValue.title !== '' ? false: true} onClick={HandleAddNewTag}>Save</Button>
        </DialogActions>
    </Dialog></>
  );
}

const BasicPopover = ({data,list}) => {
  let obj = data;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <span style={{cursor:'pointer'}} aria-describedby={obj.id} onClick={(e) => {handleClick(e,obj)}}>
      {obj.tag && obj.tag.length > 0 && obj.tag.map((el,index) => {
          return <>{el.color === '#85200e' ? <RedTooltip title={el.title} arrow><LocalOffer style={{margin: '15px 0px 0px 5px', color:`${el.color}`}}/></RedTooltip> 
          : el.color === '#674ea7'? <PurpleTooltip title={el.title} arrow><LocalOffer style={{margin: '15px 0px 0px 5px', color:`${el.color}`}}/></PurpleTooltip>
          : el.color === '#34a853'? <GTooltip title={el.title} arrow><LocalOffer style={{margin: '15px 0px 0px 5px', color:`${el.color}`}}/></GTooltip>
          : el.color === '#666666'? <GyTooltip title={el.title} arrow><LocalOffer style={{margin: '15px 0px 0px 5px', color:`${el.color}`}}/></GyTooltip>
          : <YwTooltip title={el.title} arrow><LocalOffer style={{margin: '15px 0px 0px 5px', color:`${el.color}`}}/></YwTooltip> }</>
        })}
      {
        obj.tag.length === 0
          &&
        <>
        <Tooltip title="Add Tag" arrow>
          <span>
            <LocalOffer   style={{margin: '15px 0px 0px 5px', color:'darkgray'}}/>
            <Add style={{color:'#47AFEB'}}/>
          </span>
          </Tooltip>
        </>
      }
      </span>
      <Popover
        id={obj.id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>
          <LimitTags data={data} tagList={list}/>
        </Typography>
      </Popover>
    </>
  );
}


const Table = (data) => {
  const classes = useStyles();
  const [modelOpen, setModelOpen] = useState(false);
  const [response, setResponse] = useState({});
  const [rows, setRows] = useState([]);
  const [tempRows, setTempRows] = useState([]);
  // const [dateFilteredData, setDateFilteredData] = useState([]);
  //const [paymentData, setpaymentData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [openTooltip, setOpenTooltip] = useState(false);

  const [searchFiltered, setSearchFiltered] = useState([]);
  const [dateRangeFiltered, setDateRangeFiltered] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filter, setFilter] = useState(undefined);
  const [paymentTags] = usePaymentTags();
  const [tagList, setTagList] = useState([]);
 
  const [filterValue, setfilterValue] = useState([]);
  

  let weekBefore = new Date();
  weekBefore.setDate(weekBefore.getDate() - 7);
  const [value, setValue] = useState([
    // weekBefore, Date.now()
    null, null
  ]);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(()=>{
    if(paymentTags && paymentTags.length >0){
      setTagList(paymentTags);
    }
  },[paymentTags]);

  useEffect(() => {
    if(filterValue.length > 0) {
    let filteredTag = tempRows;
    if (startDate) {
      console.log('filtered date date [0]', filteredTag[0].time);
      console.log('startdate in payment', startDate);
      filteredTag = filteredTag.filter(d => new Date(DateWithoutSeconds(d.time)) >= new Date(startDate));
    }
    if (endDate) {
      const end = new Date(endDate);
      end.setDate(end.getDate() + 1);
      filteredTag = filteredTag.filter(d => new Date(DateWithoutSeconds(d.time)) <= end);
    }
      let temFiltered = [];
      filterValue.forEach(el=>{
        filteredTag.forEach(ft=>{
          const findEle = ft.tag.find(x=>x.id === el.id);
          if(findEle !== null && findEle !== undefined){
            temFiltered.push(ft);
          }
        });
      });
    temFiltered = temFiltered.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
     setRows(temFiltered);
    }else{
      setRows(tempRows);
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [filterValue])


  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    if(width <= 768){
      setIsMobile(true)
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    let openTooltipNew = !openTooltip
    setOpenTooltip(openTooltipNew);
  };
  const modelOpen1 = (id) => {
    let modalData = tempData.find(x => x.id === id);
    setResponse(modalData);
    handlerModel(true);
  }

  const handlerModel = (status) => {
    setModelOpen(status);
  }
  const handlerReload = (status) => {
    ConsoleLog(status);
  }
  
  // Filter

  useEffect(() => {
    let paymentData1;
    let newItems = [];
    if (data && data.data) {
      paymentData1 = data.data;
      paymentData1 = paymentData1.filter(d => d.object.includes('charge'));
      if(tagList && tagList.length>0)
      {
      for (let index = 0; index < paymentData1.length; index++) {
        if(paymentData1[index].tags && paymentData1[index].tags.length>0){
          let newTags = [];
          for (let j = 0; j < paymentData1[index].tags.length; j++) {
            const color = tagList.find(x=>x.id === paymentData1[index].tags[j]);
            if(color !== null){
              newTags.push(color);
            }
          }
          paymentData1[index]['colorTags'] = newTags;
        }else{
          paymentData1[index]['colorTags'] = [];
        }
        console.log(paymentData1);
      }
    }
      const tempData = paymentData1.sort((a, b) => b.createdTimestamp - a.createdTimestamp).map((item, i) => {
        let new_res;
        if (typeof item.raw === 'string') { new_res = JSON.parse(item.raw); }
        if (typeof item.raw === 'object') { new_res = item.raw; }
        let newItem = new_res.data.object;
        newItems.push(newItem);
        let status;
        if (newItem.refunded) {
          status = { name: 'Refunded', time: DateWithoutSeconds(newItem.refunds.data[0].created), title: '' }
        } else if (newItem.status === 'failed') {
          status = { name: 'Failed', time: '', title: newItem.outcome.seller_message }
        }
        else {
          status = { name: 'Paid', time: '', title: '' }
        }
        return ({
          'id': newItem.id, 'time': newItem.created, status, 
          'customerName': newItem.metadata.Name !== 'null' ? newItem.metadata.Name : '',
          // 'amount': (newItem.amount / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 
          'amount': newItem.amount, 
          'confirmation':newItem.metadata.Confirmation,
          'tag': item.hasOwnProperty('colorTags') ? item.colorTags : []
        })
      });
      // setRows(tempData);
      let filterData = tempData.map(n => n.id)
      setSearchFiltered(filterData)
      setTempRows(tempData);
      setTempData(newItems);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, tagList]);

  const TagFilter = React.memo(() => {
    return (
     // tagList.length > 0 ?
     <>
     <Autocomplete
       clearOnBlur={false}
       filterSelectedOptions={true}
       multiple
       size="medium"
       id="multiple-limit-tags"
       options={tagList}
       value={filterValue}
       onChange={(event, newValue) => {
         if(newValue){
           setfilterValue(newValue);
         }
       }}
       getOptionLabel={(options) => options.title}
       freeSolo
       renderInput={(params) => (
         <TextField {...params} label="Tag" placeholder={filterValue && filterValue.length>0 ? null : 'Search Tags'} /> 
       )}
       sx={{ width: '90%' }}
       renderTags={(value, getTagProps) =>
         value.map((option, index) => (
           <Chip
             classes={{
               root:classNames(classes.tag,{
                 [classes.redTag]: option.color === '#85200e',
                 [classes.grayTag]: option.color === '#666666',
                 [classes.yellowTag]: option.color === '#bf9021',
                 [classes.purpleTag]: option.color === '#674ea7',
                 [classes.greenTag]: option.color === '#34a853',
               })
             }}
             label={`${option.title}`}
             {...getTagProps({ index })}
           />
         ))
       }
     />
     </>
    //  : null
   );
   });
 

  const handleSearch = (event) => {
    setFilter(event.target.value);
    if (event.target.value === "") {
      let filterData = tempRows.map(n => n.id)
      setSearchFiltered(filterData)
    } else {
      const lowercasedFilter = event.target.value.toLowerCase();
      console.log(lowercasedFilter)
      const regex = new RegExp(`^${lowercasedFilter}`, 'g');
      let filterData = tempRows.filter(
        (val) => 
          val['customerName']?.toLowerCase().includes(lowercasedFilter) || 
          val['amount']?.toString().replace(/,/g, '').match(regex) || 
          val['confirmation']?.toLowerCase().match(regex)
      )
      filterData = filterData.map(n => n.id)
      setSearchFiltered(filterData)
    }
  };

  useEffect(() => {
    let filteredDateData = tempRows;
    if (startDate) {
      console.log('filtered date date [0]', filteredDateData[0].time);
      console.log('startdate in payment', startDate);
      filteredDateData = filteredDateData.filter(d => new Date(DateWithoutSeconds(d.time)) >= new Date(startDate));
    }
    if (endDate) {
      const end = new Date(endDate);
      end.setDate(end.getDate() + 1);
      filteredDateData = filteredDateData.filter(d => new Date(DateWithoutSeconds(d.time)) <= end);
    }
    // setRows(filteredDateData);
    filteredDateData = filteredDateData.map(n => n.id)
    setDateRangeFiltered(filteredDateData)
  }, [startDate, endDate, tempRows]);

  useEffect(() => {
    if (tempRows.length > 0) {
      // Data = Master Data Recieved, filtered between tabs (recieved and sent)
      // TempRow = Row After formatted for Data for rendering

      // !! Working Filters =>
      // Filter Search
      // Filter Dates
      // Filter SelectedNumbers
      // !! Done Filter
      console.log(searchFiltered)
      console.log(dateRangeFiltered)

      // Row = Master Send to Component
      let tabMasterList = tempRows;
      tabMasterList = tabMasterList.filter(x => 
        searchFiltered.includes(x.id) && 
        dateRangeFiltered.includes(x.id)
      )
      setRows(tabMasterList)
    }
  }, [tempRows, setRows, searchFiltered, dateRangeFiltered]);
    
  const columns = [
    {
      field: 'tag',
      headerName: 'Tags',
      sortable: false,
      flex: 1,
      align:'left',
      headerAlign :'left',
      disableColumnMenu: true,
      renderCell: ( value ) => {
        return <div>
         <BasicPopover data={value.row} list={tagList}/>
        </div>
      }
    },
    {
      field: 'time',
      headerName: 'Time',
      flex: 1,
      disableColumnMenu: true,
      align:'left',
      headerAlign :'left',
      valueGetter: ({ value }) => DateWithoutSeconds(value),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      disableColumnMenu: true,
      align:'left',
      headerAlign :'left',
      cellClassName: 'toolTipAlign',
      sortComparator: (v1, v2, param1, param2) => {
        return param2.api.getRow(param2.id).status.name.length - param1.api.getRow(param1.id).status.name.length
      },
      renderCell: ({ value }) => {
        return value.name === 'Refunded' ? <ShortTooltip title={`Refunded: ${value.time}`} placement="left" arrow><StatusRefund>Refunded</StatusRefund></ShortTooltip> :
          (value.name === 'Failed') ? <ShortTooltip title={`Reason: ${value.title}`} placement="left" arrow><StatusFailed>Failed</StatusFailed></ShortTooltip> :
            <StatusSuccess>Paid</StatusSuccess>
      }
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      flex: 1,
      disableColumnMenu: true,
      align:'left',
      headerAlign :'left',
    },
    {
      field: 'amount',
      headerName: 'Amount',
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
      type: 'number',
      align:'left',
      headerAlign :'left',
      valueFormatter: ({ value }) => { 
        return `$${(value / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` 
      }
    },
    {
      align:'left',
      headerAlign :'left',
      field: 'details',
      headerName: 'Details',
      sortable: false,
      flex: 1,
      cellClassName: 'buttonAlign',
      disableColumnMenu: true,
      renderCell: (parmas) => {
        return <div className="icon cur-pointer"><Button variant="contained" color='primary' onClick={() => modelOpen1(parmas.id)} >Details</Button></div>
      }
    }
  ];

  const mobileColumns = [
    {
      field: 'time',
      headerName: 'Time',
      flex: 2,
      disableColumnMenu: true,
      valueGetter: ({ value }) => DateWithoutSeconds(value),
    },
    {
      field: 'customerName',
      headerName: 'Name',
      flex: 1.5,
      disableColumnMenu: true,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      sortable: true,
      flex: 1.5,
      disableColumnMenu: true,
      type: 'number',
      valueFormatter: ({ value }) => { 
        return `$${(value / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` 
      }
    },
  ];

  return (
    <>
    <Grid container>
    <Grid item sm={9}>
    <H1> Payment History </H1>
    </Grid>
    <Grid item sm={3}>
          <div class={classes.inputContainer}>
          <LocalizationProvider dateAdapter={AdapterDateFns} style={{width:'90%'}}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={value}
              onChange={date => {
                setStartDate(date[0])
                setEndDate(date[1])
                setValue(date)
              }
              }
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} helperText="" onChange={(e, v) => console.log(e, v)} />
                  <Box sx={{ mx: 1 }}> to </Box>
                  <TextField {...endProps} helperText="" onChange={(e, v) => console.log(e, v)} />
                </>
              )}
            />
          </LocalizationProvider>
          </div>
    </Grid>
    </Grid>
    <Grid container>
    <Grid item sm={6}>
    </Grid>
    <Grid item sm={3}>
    {!isMobile ?
          <TagFilter/> : null}
    </Grid>
    <Grid style={{paddingTop: isMobile ? '5%' : 0}} item xs={12} sm={3}>
    <div class={classes.inputContainer}>
          
          <TextField 
            className={classes.searchTransactions}
            value={filter} 
            onChange={handleSearch}
            id="outlined-adornment-weight"
            label="Search Transactions"
            autoComplete='off'
            variant="outlined"
            InputProps={{
              style: {
                fontSize: '16px',
                fontStyle: 'normal',
                fontFamily: 'Arial,Helvetica,sans-serif',
                height: 56,
                padding: '5px',
              },
            endAdornment:
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }}
          />
          {isMobile ? 
              <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <InfoTooltip 
                  placement="top" 
                  arrow 
                  open={openTooltip}
                  onClose={handleTooltipClose}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Search by:</Typography>
                      {"Confirmation #"}<br></br> {' First/ Last Name'}<br></br>{'Amount'}
                    </React.Fragment>
                  }>
                  <IconButton onClick={handleTooltipOpen}> 
                      <InfoIcon 
                        style={{fontSize: '30px', color:'#47afeb', cursor: 'pointer'}}
                      />
                  </IconButton>
                </InfoTooltip>
            </div>
          </ClickAwayListener>
          :
          <InfoTooltip arrow placement="top" title={
            <React.Fragment>
              <Typography color="inherit">Search by:</Typography>
              {"Confirmation #"}<br></br> {' First/ Last Name'}<br></br>{'Amount'}
            </React.Fragment>
          }>
          <InfoIcon 
              style={{fontSize: '30px', color:'#47afeb', cursor: 'pointer', marginBottom:'10px'}}
          />
          </InfoTooltip>}
    </div>
    </Grid>
    </Grid>
      <div>
        {/* <div className={classes.headingContainer}>
        <H1> Payment History </H1>
        <div style={{ marginLeft: 'auto', marginRight: 10, paddingBottom: 4 }}>
          <div class={classes.inputContainer}>
          <LocalizationProvider dateAdapter={AdapterDateFns} style={{width:'90%'}}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={value}
              onChange={date => {
                setStartDate(date[0])
                setEndDate(date[1])
                setValue(date)
              }
              }
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} helperText="" onChange={(e, v) => console.log(e, v)} />
                  <Box sx={{ mx: 1 }}> to </Box>
                  <TextField {...endProps} helperText="" onChange={(e, v) => console.log(e, v)} />
                </>
              )}
            />
          </LocalizationProvider>
          </div>

          <div class={classes.inputContainer}>
          <TagFilter/>
          <TextField 
            className={classes.searchTransactions}
            value={filter} 
            onChange={handleSearch}
            id="outlined-adornment-weight"
            label="Search Transactions"
            autoComplete='off'
            variant="outlined"
            InputProps={{
              style: {
                fontSize: '16px',
                fontStyle: 'normal',
                fontFamily: 'Arial,Helvetica,sans-serif',
                height: 56,
                padding: '5px',
              },
            endAdornment:
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }}
          />
          {isMobile ? 
              <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <InfoTooltip 
                  placement="top" 
                  arrow 
                  open={openTooltip}
                  onClose={handleTooltipClose}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Search by:</Typography>
                      {"Confirmation #"}<br></br> {' First/ Last Name'}<br></br>{'Amount'}
                    </React.Fragment>
                  }>
                  <IconButton onClick={handleTooltipOpen}> 
                      <InfoIcon 
                        style={{fontSize: '30px', color:'#47afeb', cursor: 'pointer'}}
                      />
                  </IconButton>
                </InfoTooltip>
            </div>
          </ClickAwayListener>
          :
          <InfoTooltip arrow placement="top" title={
            <React.Fragment>
              <Typography color="inherit">Search by:</Typography>
              {"Confirmation #"}<br></br> {' First/ Last Name'}<br></br>{'Amount'}
            </React.Fragment>
          }>
          <InfoIcon 
              style={{fontSize: '30px', color:'#47afeb', cursor: 'pointer'}}
          />
          </InfoTooltip>}
          </div>
        </div>
        </div> */}
        <div style={{ height: 650, width: '100%' }} className={classes.root}>
          {isMobile ? 
          <ThemeProvider theme={mobileTheme}>
            <DataGridPro
              rows={rows}
              columns={mobileColumns}
              pageSize={10}
              disableSelectionOnClick
              onRowClick={(parmas) => modelOpen1(parmas.id)}
              getCellClassName={(params) => 'cellAlign'}
              getRowClassName={(params) => `hoverOver`}
              pagination
            />
          </ThemeProvider> 
          : <DataGridPro
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            getCellClassName={(params) => 'cellAlign'}
            getRowClassName={(params) => `hoverOver`}
            pagination
          />}
        </div>

      </div>
        {
          modelOpen
            &&
          <PaymentModal 
            modalOpen={modelOpen}
            response={response}
            handlerModal={handlerModel}
            handlerReload={handlerReload}
            isReload={false}
          />
        }
      
    </>
  )
}
export default Table;
