import React, { useState, useEffect, useRef } from 'react';
import { 
  TextField,
  Tooltip,
  Button,
  CircularProgress,
  Backdrop,
  Checkbox,
  Typography,
  IconButton,
  Snackbar,
  Alert as MuiAlert
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import moment from 'moment-timezone';
import { useAuth0 } from "../../react-auth0-spa";
import GoogleMaps from "../../components/GoogleMapsAutoComplete";
import pipeDisplay from "../../utils/formatDisplay";
import NumberFormattedInput from "../constant/numberFormattedInput";
import NumberFormat from "react-number-format";
import Session from '../../session';
import axios from "axios";
import {useAPI} from '../../utils/api';
import useSWR, { trigger } from 'swr';
import { API_URL } from '../../config';
import OrgLogo from '../constant/orgLogo';
import { updateOrganization } from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { isValidPhoneNumber, isValidEmail } from "../../utils/formats";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const redTheme = createTheme({ 
  palette: { 
    primary: {
      main: '#F81010' 
    }
  }
});

const greenTheme = createTheme({ 
  palette: { primary: {
    main: "#10D236",
    contrastText: "#fff" //button text white instead of black
  }, 
},
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: 'white',
      },
    },
  }
})

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    border: '1.5px solid #BCBCCB',
    borderRadius: "5px",
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
    padding: '10px',
    paddingBottom: '30px'
  },
  profileFields: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  noBorder: {
    border: "none",
  },
  orgMainInfo: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  inputFields: {
    marginTop: '25px',
    width: '50%',
  },
  inputFieldContainer: {
    borderRadius: "5px",
    marginLeft: '30px',
    marginTop: '12.5px',
    marginBottom: '17.5px',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
      width: '100%',
    },
    
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%'
  },
  boldText:{
    fontWeight: 500
  },
  editIcon:{
    position: 'absolute',
    top: 30,
    right: 30
  },
  avatar: {
    cursor: 'pointer',
    width: '250px',
    [theme.breakpoints.down('lg')]: {
      width: '200px',
    },
    [theme.breakpoints.down('md')]: {
      width: '175px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '150px',
    },

  },
  officeHoursContainer:{
    width: '25%',
    marginTop: '37.5px',
    padding: '10px',
    borderRadius: "5px",
    marginBottom: 'auto',
    border: '1.5px solid #BCBCCB',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '15px',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
  },
  editOfficeHoursContainer:{
    marginTop: '25px',
    width: '27%',
  },
  addressContainer: {
    width: '100%',
    marginLeft: '30px',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px'
    },
  },
  addressInput: {
    width: '500px',
    [theme.breakpoints.down('lg')]: {
      width: '350px',
    },
    [theme.breakpoints.down('md')]: {
      width: '300px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '200px',
    },
    marginRight: '5px'
  },
  suiteInput: {
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      width: '30%',
    },
  },
  timeContainer:{
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
  },
  timeEditContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '55%'
  },
  hoursTextHeaderContainer: {
    marginLeft: '5px',
    marginBottom: '5px'
  },
  hoursTextHeader: {
    fontSize: '14px',
    color: '#4D4F5C',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
  },
  dayText:{
    lineHeight: 1.2,
    fontSize: '12px',
    marginLeft: '5px',
    color: '#4D4F5C',
    width: '45%',
    textAlign: 'left'
  },
  datePicker:{
    width: '100%'
  },
  rightText: {
    lineHeight: 1.2,
    fontSize: '12px',
    color: '#4D4F5C',
    textAlign: 'right',
    width: '45%'
  },
  leftText:{
    lineHeight: 1.2,
    fontSize: '12px',
    color: '#4D4F5C',
    textAlign: 'left',
    width: '45%'
  },
  dashText:{
    lineHeight: 1.2,
    fontSize: '12px',
    color: '#4D4F5C',
    textAlign: 'center',
    width: '10%'
  },
  officeHoursRow: {
    marginLeft: '5px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  orgInfoBanner: {
    borderRadius: '5px',
    backgroundColor: '#c5e6f9',
    width: '90%',
    display: 'flex',
    padding: '10px',
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '10px'
    }
  },
  numbersText:{
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  greytext:{
    color: '#4D4F5C'
  },
  messageText:{
    width: '20%',
    marginRight: '20px',
    color: '#4D4F5C',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px', 
    }
  },
  dash: {
    width: '10%',
    textAlign: 'center'
  },
  checkboxInput: {
    transform: "scale(0.5)",
    padding: '0px'
  },
  leftContainer:{
    display: 'flex',
    flexDirection: 'column',
    width: '66%',
    [theme.breakpoints.down('md')]: {
      width:'100%'
      },
  },
  editButtons: {
    textTransform: 'none',
    marginLeft: '10px',
    marginRight: '10px',
    fontSize: '13px',
    height: '26px',
    width: '75px',
    [theme.breakpoints.down('md')]: {
      width:'65px',
      height: '25px',
      fontSize: '11px',
      },
    [theme.breakpoints.down('sm')]: {
      height: '25px',
      marginLeft: '3px',
      marginRight: '3px',
      fontSize: '10px',
      },
  },
  bannerNumbers:{
    color: '#4D4F5C',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px', 
    }

  },
  paymentCaption:{
    lineHeight: 1.2,
    color: '#4D4F5C',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px', 
    }
  },
  examLogo: {
    marginLeft: '10px',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
    }
  },
  inputTopContainer:{
    display: 'flex',
    flexDirection: 'row'
  },
  numberContainer: {
    width: '35%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '30px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '2px',
      width: '50%'
    }
  },
  paymentMessage: {
    textAlign: 'center',
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%', 
    }
  },
  stripeButtonContainer:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '100%', 
      flexDirection: 'row'
    }
  },
  stripeButton:{
    width: '125px',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px',
      width: '100px',
      height: '30px',
      fontSize: '10px'
    }
  },
  saveButtonContainer: {
    width: '100%',
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  timeField:{
    width: '55%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  phoneNumberContainer:{
    width: '70%',
    borderRadius: '5px',
    overflow: 'hidden',
    border: '1.5px solid #BCBCCB',
    display: 'flex',
    flexDirection: 'row'
  },
  phoneNumberContainerHalf: {
    width: '55%',
    borderRadius: '5px',
    overflow: 'hidden',
    border: '1.5px solid #BCBCCB',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  horizontalLine:{
    borderLeft: '1.5px solid #BCBCCB'
  },
  numberListContainer:{
    margin: '10px',
    width: '45%',
    display: 'flex',
    flexDirection: 'column'
  },
  phoneNumberHeader: {
    color: '#47AFEB'
  },
  phoneListContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  orgLogo: {
    width: '250px',
    [theme.breakpoints.down('md')]: {
      width: '200px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '150px',
    },
  },
  labelRoot: {
    top: '-10.5px',
    "&$labelFocused": {
      top: 1
    }
  },
  labelZero: {
    top: 0,
    "&$labelFocused": {
      top: 0
    }
  },
  labelFocused: {
    top: 0
  },
}))

const bHr = [{ "Monday": [] }, { "Tuesday": [] }, { "Wednesday": [] }, { "Thursday": [] }, { "Friday": [] }, { "Saturday": [] }, { "Sunday": [] }];

const OrganizationTab = () => {
  const api = useAPI();
  const { user } = useAuth0();
  const classes = useStyles();
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [officePhone, setOfficePhone] = useState('')
  const [address, setAddress] = useState('')
  const [suiteUnit, setSuiteUnit] = useState('')
  
  const [reqName, setReqName] = useState(false)
  const [reqEmail, setReqEmail] = useState(false)
  const [reqOfficePhone, setReqOfficePhone] = useState(false)
  const [reqSuiteUnit, setReqSuiteUnit] = useState(false)

  const [nameUpdate, setNameUpdate] = useState(false)
  const [emailUpdate, setEmailUpdate] = useState(false)
  const [officePhoneUpdate, setOfficePhoneUpdate] = useState(false)
  const [addressUpdate, setAddressUpdate] = useState(false)
  const [suiteUnitUpdate, setSuiteUnitUpdate] = useState(false)
  const [orgUpdate, setOrgUpdate] = useState(false)
  const [businesshoursUpdate, setBusinesshoursUpdate] = useState(false)

  const [modelOpenLogo, setModelOpenLogo] = useState(false);
  const [isSaveing, setIsSaving] = useState(false)

  const [userFaxNum, setUserFaxNum] = useState([])
  const [userSmsNum, setUserSmsNum] = useState({})

  const [sendFile, setSentFile] = useState(null);
  const [edit, setEdit] = useState(false)
  const [isLogoLoading, setIsLogoLoading] = useState(false);
  const [businessHr, setBusinessHr] = useState(bHr);
  const [displayHr, setDisplayHr] = useState(bHr);
  const [errorUpdating, setErrorUpdating] = useState(false);
  const [, setRand] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const [reload, setReload] = useState(true);
  const fileInput = useRef(null);
  const refField1 = useRef();

  // Snackbar stuff
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const permissions = user["https://sbt-dashboard.com/Permissions"];

  const selfUrl = `${API_URL}/v3/users/self`;

  const fetcher = url => axios.get(url, {
    headers: {
        'Authorization': `Bearer ${Session.Token}`,
        'Content-Type': 'application/json',
    }
  }).then(res => res.data);

  const { data: selfData, errorSelf } = useSWR(() => selfUrl, fetcher);

  useEffect(() => {
    if (selfData && !errorSelf) {
      const org = selfData.org;
      if(org){
        setOrgData(org);
      }
      setReload(false);
    }
  }, [selfData, errorSelf]);

  useEffect(() => {
    if(reload) {
      trigger(selfUrl);
      setReload(false);
    } 
  }, [reload, selfUrl]);

  const generateLink = async () => {
    const resp = await api.accountLinkStripeConnect(Session.OrganizationStripeId)
    const url = resp.url
    console.log(url)
    window.open(url, "_self");
  }

  const generateExpressLink = async () => {
    const resp = await api.generateStripeExpressLogin(Session.OrganizationStripeId)
    const url = resp.url
    console.log(url)
    window.open(url, "_self");
  }

  const hoursConverter = (src) => { 
    return JSON.parse(JSON.stringify(src)); 
  };

  useEffect(() => {
    let updateArrayCheck = [ nameUpdate, emailUpdate, officePhoneUpdate, addressUpdate, suiteUnitUpdate, businesshoursUpdate]
    let checker = (arr) => arr.every(v => v === false);
    if(checker(updateArrayCheck)){
      setOrgUpdate(false)
    }
  }, [ nameUpdate, emailUpdate, officePhoneUpdate, addressUpdate, suiteUnitUpdate, businesshoursUpdate])

  useEffect(() => {
    if(Session.orgNumbers){
      let jsonOrgNumber = JSON.parse(Session.orgNumbers)
      let filteredSMS = jsonOrgNumber.filter((number) => {
        return number.inUse === true && number.type === 'sms'
      })
      setUserSmsNum(filteredSMS)
      
      let filteredFax = jsonOrgNumber.filter((number) => {
        return number.inUse === true && number.type === 'fax'
      })
      setUserFaxNum(filteredFax)
    }
    setName(orgData?.name || '');
    setEmail(orgData?.orgDisplayEmail || '');
    if (orgData?.businessNumber) {
      let stripped = orgData.businessNumber.split("+1");
      setOfficePhone(stripped[1]);
    } else {
      setOfficePhone('')
    }
    setAddress(orgData?.mailingAddress || "");
    setSuiteUnit(orgData?.suiteUnit || '');
    if(orgData?.businessHours){
      setBusinessHr(orgData.businessHours);
      // const target = hoursConverter(org.businessHours);
      timeZoneOffset(orgData.businessHours);
    }
    // eslint-disable-next-line
  }, [orgData]);

  const timeZoneOffset = (businessHoursRaw) => {
    const orgTimeZone = orgData?.timezone;
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const diff = (moment().tz(orgTimeZone).utcOffset() - moment().tz(currentTimeZone).utcOffset()) / 60;
    console.log('orgTimeZone in offSet Function', orgTimeZone);
    console.log('currentTimeZone in offSet Function', currentTimeZone);
    console.log('diff in offSet Function', diff);
    if(diff === 0){
      console.log('orgTimeZone in offSet Function', orgTimeZone);
      setDisplayHr(businessHoursRaw);
      return;
    }

    const newBusinessHours = businessHoursRaw.map(x => {
      let {Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday} = x;
      
      if(Monday){
        let mondayTimes = Monday[0];
        const minHours = mondayTimes?.Start.substring(0, 2);
        const minMinute = mondayTimes?.Start.substring(3, 5);
        const maxHours = mondayTimes?.Finish.substring(0, 2);
        const maxMinute = mondayTimes?.Finish.substring(3, 5);
        
        if(minMinute !== undefined && minHours !== undefined) {
          const StartMinusDiff = minHours - diff;
          const Start2 = `0${StartMinusDiff}:${minMinute}`;
          x.Monday[0].Start = Start2;
        }

        if(maxMinute !== undefined && maxHours !== undefined) {
          const maxMinusDiff = maxHours - diff;
          const Finish2 = `${maxMinusDiff}:${maxMinute}`;
          x.Monday[0].Finish = Finish2;
        }

      }

      if(Tuesday){
        let tuesdayTimes = Tuesday[0];
        const minHours = tuesdayTimes?.Start.substring(0, 2);
        const minMinute = tuesdayTimes?.Start.substring(3, 5);
        const maxHours = tuesdayTimes?.Finish.substring(0, 2);
        const maxMinute = tuesdayTimes?.Finish.substring(3, 5);

        if(minMinute !== undefined && minHours !== undefined) {
          const StartMinusDiff = minHours - diff;
          const Start2 = `0${StartMinusDiff}:${minMinute}`;
          x.Tuesday[0].Start = Start2;
        }

        if(maxMinute !== undefined && maxHours !== undefined) {
          const maxMinusDiff = maxHours - diff;
          const Finish2 = `${maxMinusDiff}:${maxMinute}`;
          x.Tuesday[0].Finish = Finish2;
        }
      }

      if(Wednesday){
        let wednesdayTimes = Wednesday[0];
        const minHours = wednesdayTimes?.Start.substring(0, 2);
        const minMinute = wednesdayTimes?.Start.substring(3, 5);
        const maxHours = wednesdayTimes?.Finish.substring(0, 2);
        const maxMinute = wednesdayTimes?.Finish.substring(3, 5);

        if(minMinute !== undefined && minHours !== undefined) {
          const StartMinusDiff = minHours - diff;
          const Start2 = `0${StartMinusDiff}:${minMinute}`;
          x.Wednesday[0].Start = Start2;
        }

        if(maxMinute !== undefined && maxHours !== undefined) {
          const maxMinusDiff = maxHours - diff;
          const Finish2 = `${maxMinusDiff}:${maxMinute}`;
          x.Wednesday[0].Finish = Finish2;
        }

      }

      if(Thursday){
        let thursdayTimes = Thursday[0];
        const minHours = thursdayTimes?.Start.substring(0, 2);
        const minMinute = thursdayTimes?.Start.substring(3, 5);
        const maxHours = thursdayTimes?.Finish.substring(0, 2);
        const maxMinute = thursdayTimes?.Finish.substring(3, 5);

        if(minMinute !== undefined && minHours !== undefined) {
          const StartMinusDiff = minHours - diff;
          const Start2 = `0${StartMinusDiff}:${minMinute}`;
          x.Thursday[0].Start = Start2;
        }

        if(maxMinute !== undefined && maxHours !== undefined) {
          const maxMinusDiff = maxHours - diff;
          const Finish2 = `${maxMinusDiff}:${maxMinute}`;
          x.Thursday[0].Finish = Finish2;
        }

      }

      if(Friday){
        let fridayTimes = Friday[0];
        const minHours = fridayTimes?.Start.substring(0, 2);
        const minMinute = fridayTimes?.Start.substring(3, 5);
        const maxHours = fridayTimes?.Finish.substring(0, 2);
        const maxMinute = fridayTimes?.Finish.substring(3, 5);

        if(minMinute !== undefined && minHours !== undefined) {
          const StartMinusDiff = minHours - diff;
          const Start2 = `0${StartMinusDiff}:${minMinute}`;
          x.Friday[0].Start = Start2;
        }

        if(maxMinute !== undefined && maxHours !== undefined) {
          const maxMinusDiff = maxHours - diff;
          const Finish2 = `${maxMinusDiff}:${maxMinute}`;
          x.Friday[0].Finish = Finish2;
        }

      }

      if(Saturday){
        let saturdayTimes = Saturday[0];
        const minHours = saturdayTimes?.Start.substring(0, 2);
        const minMinute = saturdayTimes?.Start.substring(3, 5);
        const maxHours = saturdayTimes?.Finish.substring(0, 2);
        const maxMinute = saturdayTimes?.Finish.substring(3, 5);

        if(minMinute !== undefined && minHours !== undefined) {
          const StartMinusDiff = minHours - diff;
          const Start2 = `0${StartMinusDiff}:${minMinute}`;
          x.Saturday[0].Start = Start2;

        }
        
        if(maxMinute !== undefined && maxHours !== undefined) {
          const maxMinusDiff = maxHours - diff;
          const Finish2 = `${maxMinusDiff}:${maxMinute}`;
          x.Saturday[0].Finish = Finish2;
        }
        
      }

      if(Sunday){
        let sundayTimes = Sunday[0];
        const minHours = sundayTimes?.Start.substring(0, 2);
        const minMinute = sundayTimes?.Start.substring(3, 5);
        const maxHours = sundayTimes?.Finish.substring(0, 2);
        const maxMinute = sundayTimes?.Finish.substring(3, 5);

        if(minMinute !== undefined && minHours !== undefined) {
          const StartMinusDiff = minHours - diff;
          const Start2 = `0${StartMinusDiff}:${minMinute}`;
          x.Sunday[0].Start = Start2;
        }
        
        if(maxMinute !== undefined && maxHours !== undefined) {
          const maxMinusDiff = maxHours - diff;
          const Finish2 = `${maxMinusDiff}:${maxMinute}`;
          x.Sunday[0].Finish = Finish2;
        }

      }
      
      return x;
    });
    console.log('businessHoursRaw', businessHoursRaw);
    setDisplayHr(newBusinessHours);
  };

  const random = () => (Math.floor(Math.random() * (99999 - 99)) + 99);

  const handlerReload = (status) =>{
    setReload(status);
  };

  const handleCheckBox = (e, item) => {
    if (e.target.checked) {
      var objFn = { "Start": "08:00", "Finish": "18:00" }
      item[Object.keys(item)[0]].push(objFn);
    }
    else { item[Object.keys(item)[0]].pop(); }
    setOrgUpdate(true)
    setBusinesshoursUpdate(true)
    setRand(random());
  };

  const handleStartBusinesHr = (time, item) => {
    if (!time) {
      return;
    }
    if (item[Object.keys(item)[0]].length > 0) {
      item[Object.keys(item)[0]][0].Start = time;
    }
    else {
      var objSt = { "Start": time, "Finish": "18:00" }
      item[Object.keys(item)[0]].push(objSt);
    }
    setOrgUpdate(true);
    setBusinesshoursUpdate(true);
    setRand(random());
  };

  const handleFinishBusinesHr = (time, item) => {
    if (!time) {
      return;
    }
    if (item[Object.keys(item)[0]].length > 0) {
      item[Object.keys(item)[0]][0].Finish = time;
    }
    else {
      var objFn = { "Start": "08:00", "Finish": time }
      item[Object.keys(item)[0]].push(objFn);
    }
    setOrgUpdate(true);
    setBusinesshoursUpdate(true);
    setRand(random());
  };

  const tConvert = (time) => {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1); 
      time[5] = +time[0] < 12 ? ' AM' : ' PM';
      time[0] = +time[0] % 12 || 12;
      if(time[0] < 10) time[0] = "0" + time[0]
    }
    return time.join('');
  };

  const handleCancel = () => {
    setEdit(false);
    setAddress(orgData.mailingAddress || "");
    setSuiteUnit(orgData.suiteUnit || '');
    setName(orgData.name || '');
    setEmail(orgData.orgDisplayEmail || '');
    if (orgData.businessNumber) {
      let stripped = orgData.businessNumber.split("+1");
      setOfficePhone(stripped[1]);
    } else {
      setOfficePhone('');
    }
    const newTarget = hoursConverter(displayHr);
    setBusinessHr(newTarget);
    setBusinesshoursUpdate(false);
    setReqName(false);
    setReqEmail(false);
    setReqOfficePhone(false);
    setReqSuiteUnit(false);
    setRand(random());
  };

  const editData = () => { 
    let tempEdit = edit
    setEdit(!tempEdit); 
  };

  const handleOpenFile = (e) => {
    setSentFile(e.target.files[0]);
    if (e.target.files.length > 0)
      handlerLogoModel(true);
  };

  const openLogoModel = () => { fileInput.current.click(); };

  const handlerLogoModel = (st) => { setModelOpenLogo(st); };

  const imageHandler = (item) => {
    if (item) { upload_logo(item) }
  };

  const upload_logo = async (isUploadLogo) => {
    setIsLogoLoading(true);
    const puturl = await api.getSignedPublicS3Key(isUploadLogo.name);
    if (puturl.status === 200) {
      const orgUrl = puturl.data.url;
      uploadInS3(orgUrl, isUploadLogo)
    }
  };

  const uploadInS3 = (signedUrl, file) => new Promise(resolve => {
    const instance = axios.create();

    instance.defaults.headers.common['Content-Type'] = file.type;

    const myHeaders = {
      headers: {
        'Content-Type': file.type
      }
    };

    instance.put(signedUrl, file, myHeaders)
      .then(async (uploadRes) => {
        const logoPath = signedUrl.substring(0, signedUrl.indexOf("?"));
        
        try {
          await api.updateOrg({
            ulid: Session.OrganizationId,
            orgLogo: logoPath,
          });
        
          await API.graphql(graphqlOperation(updateOrganization, {
            input: {
              id: Session.OrganizationId,
              orgLogo: logoPath,
            }
          }));
          setSeverity('success');
          setSnackBarMessage('Successfully updated organization picture!');
          setOpenSnackBar(true);  
        } catch (err) {
          console.log('appsync update orgLogog error: ', err);
        }
        resolve(uploadRes);
        handlerReload(true);
        setIsLogoLoading(false);
      })
      .catch((err) => {
        resolve(err)
        setIsLogoLoading(false);
      });
  });

  const checkName = () => {
    if (name.length === 0) {
      setReqName(true);
    } else {
      setReqName(false);
    }
  };

  const validateHours = () => {
    let validTimes = true
    businessHr.map(item => {
      if(item[Object.keys(item)[0]].length !== 0){
        let startTime = item[Object.keys(item)[0]][0].Start
        let finishTime = item[Object.keys(item)[0]][0].Finish
        if(startTime > finishTime){
          validTimes = false
        }
      }
      return null
    })
    if(validTimes){
      return true
    } else {
      setSeverity('error');
      setSnackBarMessage('Invalid Time Range in Office Hours');
      setOpenSnackBar(true);
      return false
    }
  }
  
  const handleSave = async() => {
    if(validateHours()){
      try{
        validateHours()
        setIsSaving(true)
        const payload = {
          ulid: orgData.ulid,
          ...nameUpdate && {name: name},
          ...emailUpdate && {orgDisplayEmail: email},
          ...officePhoneUpdate && {businessNumber:'+1' + officePhone.replace(/[- .()]/g, "")},
          ...addressUpdate && {mailingAddress: address},
          ...suiteUnitUpdate && {suiteUnit: suiteUnit},
          ...businesshoursUpdate && {businessHours: businessHr}
        }
        console.log('payload on save time', payload);
        const res = await api.updateOrg(payload);
        console.log('res on save time', res);
        if(res.status === 200){
          setSeverity('success');
          setSnackBarMessage('Successfully updated Organization!');
          setOpenSnackBar(true);
          handlerReload(true)    
          setErrorUpdating(false)
          setNameUpdate(false)
          setEmailUpdate(false)
          setOfficePhoneUpdate(false)
          setAddressUpdate(false)
          setSuiteUnitUpdate(false)
          setOrgUpdate(false)
          setIsSaving(false)
          setEdit(false)
          setBusinesshoursUpdate(false)
        }
      } catch(error) {
        setErrorUpdating(true)
        setSeverity('error');
        setSnackBarMessage(error.response?.data?.message);
        setOpenSnackBar(true);
      }
    };
  };

  const handleNameChange = (string) => {
    setErrorUpdating(false)
    let currentName = orgData.name ? orgData.name : ''
    if (currentName !== string){   
      setNameUpdate(true);
      setOrgUpdate(true);
    } else {
      setNameUpdate(false);
    }
    setName(string);
  };
  
  const handleEmailChange = (string) => {
    setErrorUpdating(false)
    let currentEmail = orgData.orgDisplayEmail ? orgData.orgDisplayEmail : ''
    if (currentEmail !== string){    
      setEmailUpdate(true)
      setOrgUpdate(true)
    } else {
      setEmailUpdate(false)
    }
    setEmail(string)
  };

  const handleOfficePhoneChange = (number) => {
    setErrorUpdating(false)
    let stripped = orgData.businessNumber ? orgData.businessNumber.split("+1") : '';
    let currentPhoneNum = stripped ? stripped[1] : ''
    let inputNumber = number.value ? number.value : ''
    if (currentPhoneNum !== inputNumber){     
      setOfficePhoneUpdate(true)
      setOrgUpdate(true)
    } else {
      setOfficePhoneUpdate(false)
    }
  };

  const handleAddressChange = (string) => {
    setErrorUpdating(false)
    let currentAddress = orgData.mailingAddress ? orgData.mailingAddress : ''
    let input = string ? string : ''
    if(string){
      setReqSuiteUnit(false)
    } else if(!string && suiteUnit){
      setReqSuiteUnit(true)
    }
    if (currentAddress !== input){        
      setAddressUpdate(true)
      setOrgUpdate(true)
    } else {
      setAddressUpdate(false)
    }
    setAddress(string)
  };

  const handleSuiteUnits = (string) => {
    setErrorUpdating(false)
    let currentSuiteUnits = orgData.suiteUnit ? orgData.suiteUnit : ''
    if(address || !string){
      setReqSuiteUnit(false)
    } else{
      setReqSuiteUnit(true)
    }
    if (currentSuiteUnits !== string){        
      setSuiteUnitUpdate(true)
      setOrgUpdate(true)
    } else {
      setSuiteUnitUpdate(false)
    }
    setSuiteUnit(string)
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };
  
  return (
    <>
    <Backdrop
      className={classes.backdrop}
      open={reload}
    >
      <CircularProgress />
    </Backdrop>
    {<div className={classes.root}>
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert 
          onClose={handleClose} 
          severity={severity}
          sx={{ width: '100%' }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>

      <div className={classes.profileFields}>
        {!edit && 
        <IconButton disabled={!permissions.profile?.manage_services?.viewable} aria-label="edit organization info" className={classes.editIcon}>
          <EditIcon onClick={editData} style={{ color: "#47AFEB" }}/>
        </IconButton>}
        <div className={classes.orgMainInfo}>
          <div className={classes.leftContainer}>
            <div className={classes.inputTopContainer}>
              <div className={classes.inputFields}>
                <div className={classes.inputFieldContainer}>
                  <TextField
                    onKeyPress={(e) => { if (e.key === 'Enter') {refField1.current.focus() } }}
                    onChange={(e) => handleNameChange(e.target.value)}
                    value={name}
                    error={reqName}
                    onBlur={checkName}
                    variant="outlined"
                    fullWidth
                    disabled={!edit || isSaveing}
                    name="officename"
                    label={reqName ? "Office Name is Required" : "Office Name"}
                    inputProps={{ maxLength: 30 }}
                    classes={{notchedOutline:classes.input}}
                    InputProps={{
                      style: {
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontFamily: "Arial,Helvetica,sans-serif",
                        height: 32
                      }}
                    }
                    InputLabelProps={{
                      classes: {
                        root: (name ? classes.labelZero : classes.labelRoot),
                        focused: classes.labelFocused
                      }
                    }}
                  />
                </div>
                <div className={classes.inputFieldContainer}>
                  <TextField
                    inputRef={refField1}
                    onChange={(e) => {
                      handleEmailChange(e.target.value)
                      setReqEmail(!isValidEmail(e.target.value))
                    }}
                    value={email}
                    error={reqEmail}
                    variant="outlined"
                    fullWidth
                    disabled={!edit|| isSaveing}
                    name="firstname"
                    label={reqEmail ? "Email is Required" : "Email"}
                    classes={{notchedOutline:classes.input}}
                    InputProps={{
                      style: {
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontFamily: "Arial,Helvetica,sans-serif",
                        height: 32
                      }}
                    }
                    InputLabelProps={{
                      classes: {
                        root: (email ? classes.labelZero : classes.labelRoot),
                        focused: classes.labelFocused
                      }
                    }}
                  />
                </div>
                <div className={classes.inputFieldContainer}>
                  
                <NumberFormat
                    customInput={NumberFormattedInput}
                    className={classes.sendInputSms}
                    error={reqOfficePhone}
                    value={officePhone}
                    label={reqOfficePhone ? "Invalid Office Number" : "Office Number"}
                    onChange={(e) => setOfficePhone(e.target.value)}
                    onValueChange={(value) => { 
                      handleOfficePhoneChange(value);
                        if (isValidPhoneNumber(value.value)) {
                          setReqOfficePhone(false);
                        } else {
                          setReqOfficePhone(true)
                        } }}
                    format="(###) ###-####"
                    variant="outlined"
                    mask=" "
                    disabled={!edit || isSaveing}
                    name="firstname"
                    InputProps={{
                      style: {
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontFamily: "Arial,Helvetica,sans-serif",
                        height: 32
                      }}
                    }
                    InputLabelProps={{
                      classes: {
                        root: (officePhone ? classes.labelZero : classes.labelRoot),
                        focused: classes.labelFocused
                      }
                    }}
                  />
                </div>
              </div>
              <div className={classes.avatarContainer}>
                {
                  isLogoLoading ? 
                  <Backdrop
                    className={classes.backdrop}
                    open={isLogoLoading}
                  >
                    <CircularProgress />
                  </Backdrop> : (<><input type='file' style={{ display: 'none'}} id='logo_pic' ref={fileInput} accept="image/x-png,image/gif,image/jpeg" onChange={handleOpenFile} />
                  <Tooltip title="Update Logo" arrow>
                    <img alt="avtar" src={Session.OrgLogoUrl}  data-type="info" data-tip='Update logo' onClick={openLogoModel} className={classes.avatar}/>
                  </Tooltip></>)
                }
              </div>
            </div>
            
          <div className={classes.addressContainer}>
            <div className={classes.addressInput}>
              <div>
                <GoogleMaps value={address} setValue={handleAddressChange} disabledValue={!edit || isSaveing}/>
              </div>
            </div>
            <div className={classes.suiteInput}>
              <TextField
                onChange={(e) => handleSuiteUnits(e.target.value)}
                value={suiteUnit}
                variant="outlined"
                fullWidth
                disabled={!edit || isSaveing}
                error={reqSuiteUnit}
                label={reqSuiteUnit ? 'Must Include Address for Suite' : 'Suite, Unit, Etc.'}
                inputProps={{ maxLength: 10 }}
                InputProps={{
                  style: {
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontFamily: "Arial,Helvetica,sans-serif",
                    height: 32
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: (suiteUnit ? classes.labelZero : classes.labelRoot),
                    focused: classes.labelFocused
                  }
                }}
              />
            </div>
          </div>
        </div>
            {edit ? (
              <div className={classes.editOfficeHoursContainer}>
              <div className={classes.hoursTextHeaderContainer}>
                <Typography variant="subtitle2" className={classes.hoursTextHeader}>
                  Office Hours:
                </Typography>
              </div>
              {businessHr.map((item,i) => {
                let startTime='07:30'
                let endTime='17:00'
                let isChecked = false
                if (item[Object.keys(item)[0]].length > 0) {
                  isChecked = true;
                  startTime = item[Object.keys(item)[0]][0].Start;
                  endTime = item[Object.keys(item)[0]][0].Finish;
                }
                return (
                  <div key={i} className={classes.officeHoursRow}>
                    <>
                      <Checkbox
                          disabled={isSaveing}
                          defaultChecked
                          color="primary"
                          className={classes.checkboxInput}
                          checked={isChecked} 
                          onChange={(e) => handleCheckBox(e, item)} 
                        />
                        <Typography variant="subtitle2" className={classes.dayText}>{Object.keys(item)[0]}: </Typography>
                        <div className={classes.timeEditContainer}>
                          <div className={classes.timeField}>
                            <TextField
                              disabled={isSaveing}
                              id="time"
                              type="time"
                              value={startTime}
                              onChange={(e) => { 
                                handleStartBusinesHr(e.target.value, item) }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                style: {
                                  fontStyle: "normal",
                                  fontFamily: "Arial,Helvetica,sans-serif",
                                  padding: 2,
                                  width: '60px'
                                }}
                              }
                              inputProps={{
                                style: {
                                  fontSize: '10px',
                                  padding: 2
                                }
                              }}
                            />
                          </div>
                          <div className={classes.dash}>-</div>
                          <div className={classes.timeField}>
                            <TextField
                              disabled={isSaveing}
                              id="time"
                              type="time"
                              value={endTime}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => { 
                                handleFinishBusinesHr(e.target.value, item) 
                              }}
                              InputProps={{
                                style: { fontStyle: "normal",
                                  fontFamily: "Arial,Helvetica,sans-serif",
                                  padding: 2,
                                  width: '60px'
                                }}
                              }
                              inputProps={{
                                style: {
                                  fontSize: '10px',
                                  padding: 2
                                }
                              }}
                            />
                           </div>
                        </div>
                      </>
                  </div>
              )
              })}
            </div>)
            : 
            (
              <div className={classes.officeHoursContainer}>
              <div className={classes.hoursTextHeaderContainer}>
                <Typography variant="subtitle2" className={classes.hoursTextHeader}>
                  Office Hours:
                </Typography>
              </div>
              {displayHr.map((item,i) => {
                var st = '';
                var fin = '';
                if (item[Object.keys(item)[0]].length > 0) {
                  var st_1 = item[Object.keys(item)[0]][0].Start;
                  var fin_1 = item[Object.keys(item)[0]][0].Finish;
                  st = tConvert(st_1.substring(0, 2) + ':' + st_1.substring(st_1.length - 2))
                  fin = tConvert(fin_1.substring(0, 2) + ':' + fin_1.substring(fin_1.length - 2))
                  
                }
                return (
                  <div key={i} className={classes.officeHoursRow}>
                    {st.length > 0 ? 
                      <>
                        <Typography variant="subtitle2" className={classes.dayText}>{Object.keys(item)[0]}: </Typography>
                        <div className={classes.timeContainer}>
                          <Typography variant="subtitle2" className={classes.leftText}>{st}  </Typography>
                          <Typography variant="subtitle2" className={classes.dashText}>-</Typography> 
                          <Typography variant="subtitle2" className={classes.rightText}> {fin}</Typography>
                        </div>
                      </> 
                      : 
                      <>
                        <Typography variant="subtitle2" className={classes.dayText}>{Object.keys(item)[0]}: </Typography>
                        <div className={classes.timeContainer}>
                          <Typography variant="subtitle2" className={classes.leftText}>CLOSED </Typography>  
                        </div>
                      </>
                    }
                  </div>
              )
              })}
            </div>)}
        </div>
       {!edit && <>
        <div className={classes.orgInfoBanner}>
          <div className={classes.examLogo}>
            <img src='/ExamRoomLive_Logo_org.svg' alt='' />
          </div>

          <div className={classes.numberContainer}>
            <div className={classes.numbersText}>{userFaxNum[0] && <>
              <Typography className={classes.messageText}>eFax: </Typography>
              <Typography className={classes.bannerNumbers}>{pipeDisplay(userFaxNum[0].number)}</Typography> </>}
            </div>
            <div className={classes.numbersText}>{userSmsNum[0] && <>
                <Typography className={classes.messageText}>SMS:</Typography>
                <Typography className={classes.bannerNumbers}>{pipeDisplay(userSmsNum[0].number)}</Typography> </>}
            </div>
          </div>

          <div className={classes.paymentMessage}>
            <Typography variant="body1" style={{color: '#4D4F5C'}}>Payment Center: </Typography>
            <Typography variant="caption" className={classes.paymentCaption}>*** You will be directed to our payment partner Stripe to complete your application</Typography>
          </div>
          
          <div className={classes.stripeButtonContainer}>
            {Session.IsStripeConnect ?
                (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.stripeButton}
                    // href={manageurl}
                    onClick={generateExpressLink}
                    startIcon={<img src="/cc-stripe-brands.svg" alt='avtar' />}
                    target="_blank"
                    >
                    Manage
                  </Button>
                ) : (
                  <ThemeProvider theme={greenTheme}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.stripeButton}
                      startIcon={<img src="/cc-stripe-brands.svg" alt='avtar' />}
                      // href={stripeconurl}
                      onClick={generateLink}
                      target="_blank"
                      >
                      Start Now
                    </Button>
                  </ThemeProvider>
                )

            }
          </div>
        </div>
          {userFaxNum.length > 1 && userSmsNum.length > 1 ? (
          
         <div className={classes.phoneNumberContainer}>
            <div className={classes.numberListContainer}>
              <Typography variant="body1" className={classes.phoneNumberHeader}>eFax:</Typography>
              {userFaxNum.map((number, index) => {
                  return (index === 0 ? (null) : <div className={classes.phoneListContainer}>
                                                  <Typography variant="body1" style={{ color: '#4D4F5C', marginRight: 20}}>{`${pipeDisplay(number.number)}`}</Typography> 
                                                  <Typography variant="body1" style={{ color: '#4D4F5C',}}>{`${number.nickname} `}</Typography>
                                                </div>)
              })}
            </div>
            <div className={classes.horizontalLine} />
            <div className={classes.numberListContainer}>
              <Typography variant="body1" className={classes.phoneNumberHeader}>SMS:</Typography>
              {userSmsNum.map((number, index) => {
                  return (index === 0 ? (null) : <div className={classes.phoneListContainer}>
                  <Typography variant="body1" style={{ color: '#4D4F5C', marginRight: 20}}>{`${pipeDisplay(number.number)}`}</Typography> 
                  <Typography variant="body1" style={{ color: '#4D4F5C',}}>{`${number.nickname} `}</Typography>
                </div>)
              })}
            </div>
          </div>
          ) : (null)}
          {userFaxNum.length > 1 && userSmsNum.length <= 1 ? (
         <div className={classes.phoneNumberContainerHalf}>
            <div className={classes.numberListContainer}>
              <Typography variant="body1" className={classes.phoneNumberHeader}>eFax:</Typography>
              {userFaxNum.map((number, index) => {
                  return (index === 0 ? (null) : <div className={classes.phoneListContainer}>
                  <Typography variant="body1" style={{ color: '#4D4F5C', marginRight: 20, whiteSpace: 'nowrap'}}>{`${pipeDisplay(number.number)}`}</Typography> 
                  <Typography variant="body1" style={{ color: '#4D4F5C', whiteSpace: 'nowrap'}}>{`${number.nickname} `}</Typography>
                </div>)
              })}
            </div>
          </div>
          ) : (null)}
          
          {userFaxNum.length <= 1 && userSmsNum.length > 1 ? (
         <div className={classes.phoneNumberContainerHalf}>
            <div className={classes.numberListContainer}>
              <Typography variant="body1" className={classes.phoneNumberHeader}>SMS:</Typography>
              {userSmsNum.map((number, index) => {
                  return (index === 0 ? (null) : <div className={classes.phoneListContainer}>
                  <Typography variant="body1" style={{ color: '#4D4F5C', marginRight: 20}}>{`${pipeDisplay(number.number)}`}</Typography> 
                  <Typography variant="body1" style={{ color: '#4D4F5C',}}>{`${number.nickname} `}</Typography>
                </div>)
              })}
            </div>
          </div>
          ) : (null)}
        </>
        }
        
        {edit &&
            <div className={classes.saveButtonContainer}>
              
              <ThemeProvider theme={greenTheme}>
                <Button variant="contained" className={classes.editButtons} onClick={handleSave} disabled={reqName || reqEmail || reqOfficePhone || reqSuiteUnit || !orgUpdate || isSaveing || errorUpdating}>{isSaveing ? (<CircularProgress color="inherit" size="20px"/>) : 'Save'}</Button>
              </ThemeProvider>
              <ThemeProvider theme={redTheme}>
                <Button variant="contained" className={classes.editButtons} onClick={handleCancel} disabled={isSaveing}>Cancel</Button>
              </ThemeProvider>
            </div>}
      </div>
      {modelOpenLogo &&
        <OrgLogo modelOpen={modelOpenLogo} logo_file={sendFile} handlerModel={handlerLogoModel} imageHandler={imageHandler} />}
    </div>}
    </>
  );
}

export default OrganizationTab;
