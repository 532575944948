import React, { useState, useEffect } from "react";
import {  getDuration, getFullDuration, getTime } from "../../utils/dateTimeFormat";
import { S3Document, DisplayFile } from './s3Document';
import Session from "../../session";
import Currency from "../../utils/formatCurrency";
import { ConvertMiles, ClockType, CalculateTime, CalculateUpdate } from "./util";
import MapDetailsAdmin from "./googleMapsAdmin";
import {useAPI} from '../../utils/api';
import { createTheme, ThemeProvider, } from '@mui/material/styles';
import { withStyles, makeStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import {
  CircularProgress,
  TextField,
  Backdrop,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  Typography,
  Button,
  IconButton,
  Grid,
  Tabs,
  Tab,
  TableContainer,
  Tooltip
} from '@mui/material';
import {
  ScheduleSendOutlined,
  RunningWithErrorsOutlined,
  Close as CloseIcon,
  Edit as EditIcon,
  Receipt as ReceiptIcon
} from '@mui/icons-material';
import moment from 'moment';
import NumberFormat from "react-number-format";
import NumberFormattedInput from "../constant/numberFormattedInput";

const redTheme = createTheme({ 
  palette: { 
    primary: {
      main: '#F81010' 
    }
  }
})
const greenTheme = createTheme({ 
  palette: { primary: {
    main: "#10D236",
    contrastText: "#fff"
  }, 
},
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: 'white',
      },
    },
  }
}) 

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: '8px'
  },
  titleDate: {
    color: '#707070',
    fontSize: '18px',
  },
  tab: {
    color: '#707070',
    width: '100px',
  },
  labelContainer: {
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  tableCell: {
    color: '#707070',
    fontSize: '12px',
    padding: '15px',
    '&:hover': {
      background: '#dff2fa'
    },
    '&:active': {
      background: '#dff2fa'
    }
  },
  nonSelectedTab: {
    padding: '0 2px',
    margin: 0,
    height: '100%',
    borderBottom: '1px solid lightgray',
    maxWidth: '100%',
  },
  selectedTab: {
    padding: '0 2px',
    margin: 0,
    height: '100%',
    borderBottom: '1px solid lightgray',
    maxWidth: '100%',
    backgroundColor: 'rgb(223, 242, 250)'
  },
  activityTable: {
    borderCollapse: 'collapse',
    margin: '5px 0px 3px 5px',
    fontSize: '11px',
    '& tbody tr td': {
      height: '10px',
      width: '120px',
      paddingBottom: '3px',
    },
  },
  dataWidth: {
    width: '50%',
    verticalAlign: 'top',
    textAlign: 'left'
  },
  feildWidth: {
    maxWidth: 95,
    minWidth: 95,
    verticalAlign: 'top',
    textAlign: 'left'
  },
  uneditedFeildWidth:{
    paddingLeft: 15,
    maxWidth: 120,
    minWidth: 120,
    verticalAlign: 'top',
    textAlign: 'left'
  },
  notesWidth: {
    fontSize: 12,
    maxWidth: 375,
    minWidth: 375,
    verticalAlign: 'top',
    textAlign: 'left'
  },
  activityDataWidth: {
    maxWidth: 85,
    minWidth: 85,  
  },
  tabContainer: {
    borderBottom: '1px solid gray',
    width: '80%',
    alignSelf: 'center'
  },
  saveButtonContainer: {
    position: 'absolute',
    bottom: '15px',
    right: '15px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  backdrop: {
    zIndex: 9999,
    position: "absolute",
  },
  editButtons: {
    textTransform: 'none',
    marginLeft: '10px',
    marginRight: '10px',
    fontSize: '13px',
    height: '26px',
    width: '75px',
    [theme.breakpoints.down('md')]: {
      width:'65px',
      height: '25px',
      fontSize: '11px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '25px',
      marginLeft: '3px',
      marginRight: '3px',
      fontSize: '10px',
    },
  },
  editSummaryField: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  editNotesField: {
    width: '250px',
    maxHeight: 240,
    display: 'flex',
    alignItems: 'center',
  },
  editIcon: {
    height: 10,
    width: 10, 
    color: '#10D236'
  },
  tooltipInfo: {
    margin: 0,
  },
}));

const styles = {
  smallIcon: {
    width: 18,
    height: 18,
  }
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, edit, current, handleChangeDate, date, dateUpdate, ...other } = props;
  return (
    <MuiDialogTitle disableTypography style={{paddingBottom: 0, flexDirection: 'row', display: 'flex', alignItems: 'center'}} {...other}>
      <Typography variant="h6" style={{width: '50%'}}>
        {children}</Typography>
      {edit ? (<div style={{display: 'flex', alignItems: 'center'}}>
        {(current.dateEdited && dateUpdate) && <Tooltip
          arrow 
          title={
            <>
              <p style={{margin: 0}}>{`Updated by ${dateUpdate.user} on ${moment(dateUpdate.createdAt).format('L[,] h:mm:ss a')}`}</p>
              <p style={{margin: 0}}>{`Changed: '${dateUpdate.previousValue ? (moment(dateUpdate.previousValue).isValid() ? (moment(dateUpdate.previousValue).format('LL')): (moment(dateUpdate.previousValue * 1000).format('LL'))): 'None'}' to '${dateUpdate.newValue ? (moment(dateUpdate.newValue).isValid() ? (moment(dateUpdate.newValue).format('LL')): (moment(dateUpdate.newValue * 1000).format('LL'))) : 'None'}'`}</p>
            </>
          }
          classes={classes}
          >
          <EditIcon style={{
              height: 15,
              width: 15, 
              color: '#10D236',
              marginRight: 3}}/> 
        </Tooltip>}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            maxDate={new Date()}
            value={date}
            onChange={(value)=> {
              handleChangeDate(value)
            }}
              views={['year', 'month', 'day']}
              InputProps={{
                  style: {
                      // fontSize: "16px",
                      fontStyle: "normal",
                      fontFamily: "Arial,Helvetica,sans-serif",
                      height: 22,
                      width: 150
                  },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              renderInput={(params) => (
                  <TextField 
                      {...params} 
                      margin="normal"
                      style={{marginTop: 0, marginBottom: 0}} 
                  />
              )}
          />
        </LocalizationProvider>
      </div>) 
      :
        <div style={{display: 'flex', alignItems: 'center'}}>
        {(current.dateEdited && dateUpdate) && <Tooltip
        arrow 
        title={
          <>
            <p style={{margin: 0}}>{`Updated by ${dateUpdate.user} on ${moment(dateUpdate.createdAt).format('L[,] h:mm:ss a')}`}</p>
            <p style={{margin: 0}}>{`Changed: '${dateUpdate.previousValue ? (moment(dateUpdate.previousValue).isValid() ? (moment(dateUpdate.previousValue).format('LL')): (moment(dateUpdate.previousValue * 1000).format('LL'))): 'None'}' to '${dateUpdate.newValue ? (moment(dateUpdate.newValue).isValid() ? (moment(dateUpdate.newValue).format('LL')): (moment(dateUpdate.newValue * 1000).format('LL'))) : 'None'}'`}</p>
          </>
        }
        classes={classes}
        >
        <EditIcon style={{
            height: 15,
            width: 15, 
            color: '#10D236',
            marginRight: 3}}/> 
      </Tooltip>} 
        <Typography variant="body1" style={{color: '#707070'}}>
          {moment(new Date(current.date)).format('dddd, MMM Do YYYY')}
        </Typography>
      </div>}
      {onClose ? (
        <IconButton
          aria-label="close"
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          onClick={onClose}
          className="icon cur-pointer"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const TabType = {
  Activity: 0,
  Receipts: 1,
  Location: 2,
  History: 3
}

const Receipts = ({ receiptName, isOpen, current, handlePreviewFile, selectedFileUlid, setSelectedFileUlid, closeModal, details, handlerReload, handleSnackbar }) => {
  const classes = useStyles();
  const api = useAPI();
  const [vertTab, setVertTab] = useState();
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [updatedHoursWorked, setUpdatedHoursWorked] = useState()

  const [dateUpdate, setDateUpdate] = useState();
  const [mileageUpdate, setMileageUpdate] = useState();
  const [expenseUpdate, setExpenseUpdate] = useState();
  const [notesUpdate, setNotesUpdate] = useState();

  const [editsMade, setEditsMade] = useState(false)
  const [eventsChanged, setEventsChanged] = useState(false);
  const [mileageChanged, setMileageChange] = useState(false);
  const [expenseChanged, setExpenseChanged] = useState(false);
  const [notesChanged, setNotesChanged] = useState(false);
  const [dateChanged, setDateChanged] = useState(false);

  const [history, setHistory] = useState([]);
  const [date, setDate] = useState();
  const [expenses, setExpenses] = useState();
  const [mileage, setMileage] = useState();
  const [notes, setNotes] = useState();
  const [edit, setEdit] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TabType.Activity);
  const [editEvents, setEditEvents] = useState([]);
  const [submitEvent, setSubmitEvent] = useState([])
  const [, setRand] = useState(null);
  const random = () => (Math.floor(Math.random() * (99999 - 99)) + 99);
  
  useEffect(() => {
    setOpenModel(isOpen)
  }, [isOpen])


  useEffect(() => {
    if(eventsChanged || mileageChanged || expenseChanged || notesChanged || dateChanged){
      setEditsMade(true)
    }else{
      setEditsMade(false)
    }
  }, [eventsChanged, mileageChanged, expenseChanged, notesChanged, dateChanged])


  //Checks if events are in chronological order or else these are invalid
  const validTimeActivities = () => {
    let validTimes = true
    editEvents.forEach((event, index) => {
      if(index > 0){
        if(editEvents[index - 1].timeStamp >= event.timeStamp){
          validTimes = false
        }
      }
    })
    return validTimes
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }  

  const handleConfirm = () => { 
    let validTimes = validTimeActivities();
    if(validTimes){
      setConfirmationModal(true)
    }else{
      if(handleSnackbar){
        handleSnackbar('Invalid Timeclock Activities', 'error')
      }
    }
  }

  //Handles update for timeclock entry
  const handleSave = async() => {
    setLoading(true);
    try{
      let dateObject;
      let tempEvents = current.events.map(item => ({...item})).sort((a,b) => a.timeStamp - b.timeStamp).filter(a => a.event !== ClockType.Submit)
      let tempEditEvents = editEvents.map(item => ({...item}))
      //Creates update timestamp using moments to be sent to backend
      
      if(dateChanged){
        //Update to timestamp to correct date if date was changed
        tempEvents.map(obj => {
          let editedDate = moment(date, 'ddd MMMM Do YYYY');
          let oldDateMoment = moment(obj.timeStamp * 1000);
          editedDate.seconds(oldDateMoment.seconds());
          editedDate.minutes(oldDateMoment.minutes());
          editedDate.hours(oldDateMoment.hours());
          obj.timeStamp = editedDate.unix();
          return true;
        })
        tempEditEvents.map(obj => {
          let editedDate = moment(date, 'ddd MMMM Do YYYY');
          let oldDateMoment = moment(obj.timeStamp * 1000);
          editedDate.seconds(oldDateMoment.seconds());
          editedDate.minutes(oldDateMoment.minutes());
          editedDate.hours(oldDateMoment.hours());
          obj.timeStamp = editedDate.unix();
          return true;
        })
        dateObject = {
          updatedActivites: tempEvents
        }
      }
      let updatedEvents = [];
      if(eventsChanged){
        tempEditEvents.map((obj, index) => {
          if(obj.timeStamp !== tempEvents[index].timeStamp){
            updatedEvents.push(obj);
          }
          return true;
        })
      }
      let body = {
        ...(expenseChanged) && {expenses: expenses},
        ...(mileageChanged) && {mileage: mileage},
        ...(notesChanged) && {notes: notes},
        ...(dateChanged) && {date: dateObject},
        ...(eventsChanged)&& { updatedEvents: updatedEvents },
        userId: Session.UserId,
        orgId: Session.OrganizationId
      }
      let entryId = current.ulid
      let response = await api.editTimeSheetEntry({entryId, body})
      console.log(response)
      if(response.data.statusCode === 200) {
        if(handleSnackbar){
          handleSnackbar('Timesheet Entry Updated', 'success');
        }
        handlerReload();
        closeModal();
      }else{
        setLoading(false);
        if(handleSnackbar){
          handleSnackbar('Error updating Timeclock Entry', 'error')
        }
        setConfirmationModal(false);
      }
    }catch(err){
      console.log(err)
      setLoading(false);
      if(handleSnackbar){
        handleSnackbar(err.response?.data?.message, 'error')
      }
      setConfirmationModal(false);
    }
  }

  const handleCancel = () => {
    if(current.notes){
      setNotes(current.notes)
    }else{
      setNotes()
    }
    if(current.expenses){
      setExpenses(current.expenses)
    }else{
      setExpenses()
    }
    if(current.miles){
      setMileage(current.miles)
    }else{
      setMileage()
    }
    if(current.date){
      setDate(current.date)
    }
    if (current.events) {
      let tempEvents = current.events.map(item => ({...item})).filter(a => a.event !== ClockType.Submit)
      setEditEvents(tempEvents)
    }
    if(current.totalHours){
      setUpdatedHoursWorked(current.totalHours)
    }
    setEdit(false);
    setEditsMade(false);
    setEventsChanged(false);
    setMileageChange(false);
    setExpenseChanged(false);
    setNotesChanged(false);
    setDateChanged(false);
  }

  //Update values in TimeClockEvent object to have correct timestamp values and check if value was update
  const handleChange = (ulid, newValue) => {
    let updatedActivies = editEvents;
    let objIndex = editEvents.findIndex((obj => obj.ulid === ulid));
    let hours = parseInt(newValue.slice(0,2), 10);
    let minutes = parseInt(newValue.slice(3,5), 10);
    let seconds = parseInt(newValue.slice(6,8), 10);
    let eventTime = moment(current.date, 'ddd MMMM Do YYYY');
    eventTime.hours(hours);
    eventTime.minutes(minutes);
    eventTime.seconds(seconds);
    updatedActivies[objIndex].timeStamp = eventTime.unix();
    setRand(random());
    let updatedEvents = []
    let tempEvents = current.events.map(item => ({...item})).filter(a => a.event !== ClockType.Submit)
    updatedActivies.map((obj, index) => {
      if(obj.timeStamp !== tempEvents[index].timeStamp){
        updatedEvents.push(obj);
      }
      return true
    })
    if(updatedEvents.length > 0 ){
      setEventsChanged(true);
    }else{
      setEventsChanged(false);
    }
    setEditEvents(updatedActivies);
  }

  const handleChangeMileage = (mileage) => {
    let currentMileage = current.miles ? parseFloat(current.miles) : '';
    let updatedMileage = mileage ? parseFloat(mileage) : '';
    if(currentMileage !== updatedMileage){
      setMileageChange(true)
    }else{
      setMileageChange(false)
    }
    setMileage(mileage)
  }

  const handleChangeExpense = (expenses) => {
    let currentExpense = current.expenses ? parseFloat(current.expenses) : '';
    let updatedExpnese = expenses ? parseFloat(expenses) : '';
    if(currentExpense !== updatedExpnese){
      setExpenseChanged(true)
    }else{
      setExpenseChanged(false);
    }
    setExpenses(expenses)
  }

  const handleChangeNotes = (notes) => {
    let currentNotes = current.notes ? current.notes : '';
    if(currentNotes !== notes){
      setNotesChanged(true)
    }else{
      setNotesChanged(false)
    }
    setNotes(notes)
  }

  const handleChangeDate = (date) => {
    let currentDate = current.date ? current.date : '';
    if(new Date(currentDate).toDateString() !== new Date(date).toDateString()){
      setDateChanged(true)
    }else{
      setDateChanged(false)
    }
    setDate(date)
  }


  //Grabs all history items for the TimeClockEntry and looks for neweest history and sets them as states
  const grabTimeSheetHistory = async(entryId) => {
    setLoading(true);
    let timeSheetHistory = await api.getTimeSheetHistory(entryId);
    setHistory(timeSheetHistory.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)));
    if(current.mileageEdited){
      const temp = [...timeSheetHistory];
      let mileageUpdates = temp.filter(a => a.field === 'mileage').sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
      setMileageUpdate(mileageUpdates[0]);
    }
    if(current.expensesEdited){
      const temp = [...timeSheetHistory];
      let expenseUpdates = temp.filter(a => a.field === 'expenses').sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
      setExpenseUpdate(expenseUpdates[0]);
    }
    if(current.notesEdited){
      const temp = [...timeSheetHistory];
      let notesUpdates = temp.filter(a => a.field === 'notes').sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
      setNotesUpdate(notesUpdates[0]);
    }
    if(current.dateEdited){
      const temp = [...timeSheetHistory];
      let dateUpdates = temp.filter(a => a.field === 'date').sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
      setDateUpdate(dateUpdates[0]);
    }
  }

  // eslint-disable-next-line
  useEffect(async() => {
    if(current.ulid){
      await grabTimeSheetHistory(current.ulid);
    }
    if(current.notes){
      setNotes(current.notes);
    }
    if(current.expenses){
      setExpenses(current.expenses);
    }
    if(current.miles){
      setMileage(current.miles);
    }
    if(current.date){
      setDate(current.date);
    }
    if(current.events) {
      let tempEvents = current.events.map(item => ({...item})).filter(a => a.event !== ClockType.Submit);
      let submitEvent = current.events.find(a => a.event === ClockType.Submit);
      setEditEvents(tempEvents);
      setSubmitEvent(submitEvent);
    }
    if(current.fileUlids && current.fileUlids.length > 0) {
      setSelectedFileUlid(current.fileUlids[0]);
      setVertTab(0);
    }
    setLoading(false);
  // eslint-disable-next-line
  }, [current]);


  useEffect(() => {
    if (current && current.fileUlids && current.fileUlids.length > 0) {
      setSelectedFileUlid(current.fileUlids[0]);
    }
  }, [current, setSelectedFileUlid]);

  return (
    <>
      <Backdrop
          className={classes.backdrop}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
    <Dialog
      open={openModel} 
      onClose={closeModal}
      fullWidth={true}
      disableBackdropClick
      disableEscapeKeyDown
      className={classes.dialog}
    >

    {!loading && <> 
      <DialogTitle onClose={closeModal} current={current} edit={edit} date={date} handleChangeDate={handleChangeDate} dateUpdate={dateUpdate}> Entry Details</DialogTitle>
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          centered
          className={classes.tabContainer}
        >
          <Tab label='Activity' onClick={() => setSelectedTab(TabType.Activity)} disabled={edit}/>
          <Tab label='Receipts' onClick={() => setSelectedTab(TabType.Receipts)} disabled={edit}/>
          <Tab label='Location' onClick={() => setSelectedTab(TabType.Location)} disabled={edit}/>
          <Tab label='History' onClick={() => setSelectedTab(TabType.History)} disabled={edit}/>
        </Tabs>
        
      <MuiDialogContent style={{padding: '0 15px 15px 15px', minHeight: '445px', maxHeight: '445px'}}>
      <div className={classes.root}>
          <Grid container spacing={1}>
        
          {selectedTab === TabType.Activity &&  <Grid item xs={12} >

          <strong className="sub-header">Summary: </strong>
          <table className={classes.activityTable}>
              <tbody>
                <tr>
                  <td className={classes.uneditedFeildWidth}>Time Worked:</td>
                  <td className={classes.dataWidth}>{!edit ? getDuration(current.totalHours >= 86400 ? 86400 : current.totalHours) : (updatedHoursWorked > 0 ? getDuration(updatedHoursWorked) : null)}</td>
                </tr>
                <tr>
                  {edit ? 
                  (<>
                    
                    <td className={current.mileageEdited ? classes.feildWidth : classes.uneditedFeildWidth}>{(current.mileageEdited && mileageUpdate) && <Tooltip 
                        arrow
                        title={
                          <>
                            <p className={classes.tooltipInfo}>{`Updated by ${mileageUpdate.user} on ${moment(mileageUpdate.createdAt).format('L[,] h:mm:ss a')}`}</p>
                            <p className={classes.tooltipInfo}>{`Changed: '${mileageUpdate.previousValue ? mileageUpdate.previousValue : 'None'}' to '${mileageUpdate.newValue ? mileageUpdate.newValue : 'None'}'`}</p>
                          </>
                        }
                        classes={classes}
                        >
                          <EditIcon className={classes.editIcon} /> 
                        </Tooltip>} Mileage: </td>
                    <div className={classes.editSummaryField}>
                      <NumberFormat
                        editInput
                        customInput={NumberFormattedInput}
                        type='tel'
                        allowNegative={false}
                        decimalScale={2}
                        allowLeadingZeros={false}
                        value={mileage}
                        onValueChange={(value) => {
                          const val = parseFloat(value.value)
                          if ((val >= 0 && val < 10000) || value.value === '') {
                            handleChangeMileage(value.value);
                          }else{
                            setRand(random());
                          }
                        }}
                        thousandSeparator={true}
                        styleProps={{
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontFamily: "Arial,Helvetica,sans-serif",
                          height: 20,
                          width: 70
                        }}
                      />
                      Miles
                    </div>
                  </>) : 
                    (<>
                      <td className={current.mileageEdited ? classes.feildWidth : classes.uneditedFeildWidth}>{(current.mileageEdited && mileageUpdate) && <Tooltip
                        arrow 
                        title={
                          <>
                            <p className={classes.tooltipInfo}>{`Updated by ${mileageUpdate.user} on ${moment(mileageUpdate.createdAt).format('L[,] h:mm:ss a')}`}</p>
                            <p className={classes.tooltipInfo}>{`Changed: '${mileageUpdate.previousValue ? mileageUpdate.previousValue : 'None'}' to '${mileageUpdate.newValue ? mileageUpdate.newValue : 'None'}'`}</p>
                          </>
                        }
                        classes={classes}
                        >
                          <EditIcon className={classes.editIcon} /> 
                        </Tooltip>} Mileage: </td>
                      <td className={classes.dataWidth}>{ConvertMiles(current.miles)} Miles</td>
                    </>)
                  }
                </tr>
                <tr>
                  {edit ? 
                  (<>
                    <td className={current.expensesEdited ? classes.feildWidth : classes.uneditedFeildWidth}> {(current.expensesEdited && expenseUpdate) && <Tooltip 
                      arrow
                      title={
                        <>
                          <p className={classes.tooltipInfo}>{`Updated by ${expenseUpdate.user} on ${moment(expenseUpdate.createdAt).format('L[,] h:mm:ss a')}`}</p>
                          <p className={classes.tooltipInfo}>{`Changed: '${expenseUpdate.previousValue ? expenseUpdate.previousValue : 'None'}' to '${expenseUpdate.newValue ? expenseUpdate.newValue : 'None'}'`} </p>
                        </>
                      }
                      classes={classes}
                      >
                        <EditIcon className={classes.editIcon} /> 
                      </Tooltip>} Expenses:</td>
                    <div className={classes.editSummaryField}>
                      <NumberFormat
                        editInput
                        customInput={NumberFormattedInput}
                        type='tel'
                        allowNegative={false}
                        decimalScale={2}
                        allowLeadingZeros={false}
                        value={expenses}
                        prefix={'$'}
                        onValueChange={(value) => {
                          const val = parseFloat(value.value)
                          if ((val >= 0 && val < 10000) || value.value === '') {
                            handleChangeExpense(value.value);
                          }else{
                            setRand(random());
                          }
                        }}
                        thousandSeparator={true}
                        styleProps={{
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontFamily: "Arial,Helvetica,sans-serif",
                          height: 20,
                          width: 70
                        }}
                      />
                    </div>
                  </>) : 
                    (<>
                      <td className={current.expensesEdited ? classes.feildWidth : classes.uneditedFeildWidth}> {(current.expensesEdited && expenseUpdate) && <Tooltip 
                        arrow
                        title={
                          <>
                            <p className={classes.tooltipInfo}>{`Updated by (${expenseUpdate.user}) ${moment(expenseUpdate.createdAt).format('L[,] h:mm:ss a')}`}</p>
                            <p className={classes.tooltipInfo}>{` Changed: '${expenseUpdate.previousValue ? expenseUpdate.previousValue : 'None'}' to '${expenseUpdate.newValue ? expenseUpdate.newValue : 'None'}'`} </p>
                          </>
                        }
                        classes={classes}
                        >
                          <EditIcon className={classes.editIcon} /> 
                        </Tooltip>} Expenses:</td>
                      <td className={classes.dataWidth}>{Currency(current.expenses || 0)}</td>
                    </>)
                  }
                </tr>
                <tr style={{height: 80}}>
                  {edit ? 
                  (<>
                    <td className={current.notesEdited ? classes.feildWidth : classes.uneditedFeildWidth}>{(current.notesEdited && notesUpdate) && (<Tooltip 
                      arrow
                      title={
                        <>
                          <p className={classes.tooltipInfo}>{`Updated by ${notesUpdate.user} on ${moment(notesUpdate.createdAt).format('L[,] h:mm:ss a')}`}</p>
                          <p className={classes.tooltipInfo}>{`Changed: '${notesUpdate.previousValue ? notesUpdate.previousValue : 'None'}' to '${notesUpdate.newValue ? notesUpdate.newValue : 'None'}'`}</p>
                        </>
                      }
                      classes={classes}
                      >
                        <EditIcon className={classes.editIcon} /> 
                      </Tooltip>)} Notes:</td>
                    <div className={classes.editNotesField}>
                      <TextField 
                      value={notes}
                      onChange={(e) => {
                        handleChangeNotes(e.target.value)
                      }}
                      multiline
                      style={{paddingRight: 5}}
                      rows={4}
                      InputProps={{
                        style: {
                          width: '375px',
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontFamily: "Arial,Helvetica,sans-serif",
                          padding: "5px 5px 5px 13px",
                        }}
                      }
                      />
                    </div>
                  </>) : 
                    (<>
                      <td className={current.notesEdited ? classes.feildWidth : classes.uneditedFeildWidth}>{(current.notesEdited && notesUpdate) && (<Tooltip 
                        arrow
                        title={
                          <>
                            <p className={classes.tooltipInfo}>{`Updated by ${notesUpdate.user} on ${moment(notesUpdate.createdAt).format('L[,] h:mm:ss a')}`}</p>
                            <p className={classes.tooltipInfo}>{`Changed: '${notesUpdate.previousValue ? notesUpdate.previousValue : 'None'}' to '${notesUpdate.newValue ? notesUpdate.newValue : 'None'}'`}</p>
                          </>
                        }
                        classes={classes}
                        >
                          <EditIcon className={classes.editIcon} /> 
                        </Tooltip>)} Notes:</td>
                        <div className={classes.notesWidth}>{current.notes}</div>
                    </>)
                  }
                </tr>
              </tbody>
            </table>
        <div style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }}>
            <strong className="sub-header">Activity: 
            {current.autoSubmitted ?
              <>
                {current.autoSubmitType === 1 ? 
                    <Tooltip
                    arrow
                    title={
                      <>
                        <p> Employee forgot to click 'Submit Time', so it was auto-submitted for them. </p>
                      </>
                    }
                    placement="right" 
                  >
                    <ScheduleSendOutlined 
                    style={{
                      color:'green',
                      paddingLeft: '6px'
                    }} /> 
                  </Tooltip>
                : 
                  <Tooltip
                  title={
                    <>
                      <p> Employee Clocked in, but forgot to clock out. </p>
                    </>
                  }
                  placement="right" 
                  arrow
                >
                <RunningWithErrorsOutlined 
                  style={{
                    color:'red',
                    paddingLeft: '6px'
                  }} /> 
                </Tooltip>
                }
              </>
              : ''
            }
          </strong>
        </div>



            <Activity current={current} edit={edit} handleChange={handleChange} events={current.events} history={history} submitEvent={submitEvent} setUpdatedHoursWorked={setUpdatedHoursWorked}/>

            <div className={classes.saveButtonContainer}>
              {edit ? 
                (<>
                  <ThemeProvider theme={greenTheme}>
                    <Button variant="contained" className={classes.editButtons} onClick={() => handleConfirm()} disabled={!editsMade}>Save</Button>
                  </ThemeProvider>
                  <ThemeProvider theme={redTheme}>
                    <Button variant="contained" className={classes.editButtons} onClick={() => handleCancel()}>Cancel</Button>
                  </ThemeProvider>
                </>) 
              :
                <ThemeProvider theme={greenTheme}>
                  <Button variant="contained" className={classes.editButtons} onClick={() => setEdit(true)}>Edit</Button>
                </ThemeProvider>
              }
            </div>
          </Grid>}
          {selectedTab === TabType.Receipts &&  
          <>  
            {current.fileUlids && current.fileUlids.length > 0 ?
            (<>
            <Grid item xs={6} style={{ textAlign: 'center', margin: '0 0 25px 0'}}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  aria-label="Vertical tabs"
                  indicatorColor="primary"
                  style={{padding: 0, width: '100%'}}
                  value={vertTab}
                >
                  {current.fileUlids.map((file, index) => {
                    return (
                      <Tab
                        key={index}
                        className={(file === selectedFileUlid && vertTab === index) ? classes.selectedTab : classes.nonSelectedTab}
                        label={
                          <>
                            <div style={{
                              display: 'flex',
                              justifyContent: 'space-between', 
                              width: '100%',
                              height: '100%',
                            }}>
                              <S3Document style={{fontSize: '12px', paddingTop: '3px', paddingLeft: '10px', fontFamily: 'arial', textTransform: 'none'}} id={"s3file" + index} fileUlid={file} />
                            </div>
                          </>
                        }
                        onClick={() => {
                          setSelectedFileUlid(file);
                          setVertTab(index);
                          handlePreviewFile("s3file" + index, file);
                        }}
                      />
                    );
                  })}
                </Tabs>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'center', margin: '0 0 25px 0'}}>
                  {selectedFileUlid
                    ? 
                    <div style={{display: 'flex', maxHeight: '300px', maxWidth: '100%',objectFit: 'contain' , justifyContent: 'center', paddingTop: '20px'}}>
                        <DisplayFile docUlid={selectedFileUlid} docName={receiptName} />
                      </div>
                    : 
                      <div style={{ height: '300px', padding: '100px', marginTop: '20px', textAlign:'center', backgroundColor: 'rgb(224,241,250)', color:'#707070', borderRadius: '25px'  }}>
                        <ReceiptIcon />
                        <br/>
                        Select a Receipt to Preview
                      </div>
                  }
              </Grid>
              </>) :
              (<Grid item xs={12} style={{ textAlign: 'center', margin: '10px 8px 0px 0px'}}>
                    <div style={{ height: '300px', padding: '100px', textAlign:'center', backgroundColor: 'rgb(224,241,250)', color:'#707070', borderRadius: '25px'  }}>
                        <ReceiptIcon />
                        <br/>
                        Receipts Not Provided
                      </div>
              </Grid>)
              }
          </>}

          {selectedTab === TabType.Location &&  
          <Grid item xs={12} style={{margin: '0px, 20px, 20px, 0px'}}> 
            <MapDetailsAdmin timesheet={details} />
          </Grid>}

          {selectedTab === TabType.History &&  
            <Grid item xs={12} style={{margin: 20}}> 
              {history &&
              history.map(h => {
                if(h.field === 'CLOCK_IN' || h.field === 'CLOCK_OUT' || h.field === 'SUBMIT' || h.field === 'BREAK_IN' || h.field === 'BREAK_OUT' ){
                  return (<div style={{fontFamily: "Arial,Helvetica,sans-serif", fontSize: 12}}>{`(${moment(h.createdAt).format('h:mm:ss a')}) (${h.user}) : Updated (${h.field ? capitalizeFirstLetter(h.field) : 'No Field'}) from '${getTime(h.previousValue * 1000)}' to '${getTime(h.newValue * 1000)}'`}</div>)
                } else if (h.field === 'date'){
                  return (<div style={{fontFamily: "Arial,Helvetica,sans-serif", fontSize: 12}}>
                    {`(${moment(h.createdAt).format('h:mm:ss a')}) (${h.user}) : Updated (${h.field ? capitalizeFirstLetter(h.field) : 'No Field'}) from '${h.previousValue ? (moment(h.previousValue).isValid() ? (moment(h.previousValue).format('LL')) : (moment(h.previousValue * 1000).format('LL'))) : 'None'}' to '${h.newValue ? (moment(h.newValue).isValid() ? (moment(h.newValue).format('LL')): (moment(h.newValue * 1000).format('LL'))) : 'None'}'`}
                    </div>)
                } else {
                  return (<div style={{fontFamily: "Arial,Helvetica,sans-serif", fontSize: 12}}>{`(${moment(h.createdAt).format('h:mm:ss a')}) (${h.user}) : Updated (${h.field ? capitalizeFirstLetter(h.field) : 'No Field'}) from '${h.previousValue ? h.previousValue : 'None'}' to '${h.newValue ? h.newValue : 'None'}'`}</div>)
                }
              })}
          </Grid>}

        </Grid>
      </div>
      </MuiDialogContent>
      </>}
    </Dialog>
    <Dialog
      open={confirmationModal}
      maxWidth="md"
    >
      <MuiDialogContent style={{padding: '0 5px 15px 15px'}}>
          <Typography style={{margin: 20}} align='center' variant='h4'>Are You Sure?</Typography>
            <Typography style={{margin: 20}} align='center' variant="subtitle2">Confirm that you want to make edits to this time entry.</Typography>
            <div style={{float: 'right', margin: 20}}>
                  <ThemeProvider theme={greenTheme}>
                    <Button variant="contained" className={classes.editButtons} onClick={() => handleSave()}>Yes</Button>
                  </ThemeProvider>
                  <ThemeProvider theme={redTheme}>
                    <Button variant="contained" className={classes.editButtons} onClick={() => setConfirmationModal(false)}>Cancel</Button>
                  </ThemeProvider>
            </div>
      </MuiDialogContent>
    </Dialog>
    </>
  )
}



const TimeSheetDetails = ({ details, modalIsOpen, setModelOpen, handlerReload, handleSnackbar }) => {
  const [current, setCurrent] = useState({});
  const [selectedFileUlid, setSelectedFileUlid] = useState('');
  const [receiptName, setReceiptName] = useState('');

  useEffect(() => {
    setCurrent(details);

    return () => {
      setCurrent({});
      setSelectedFileUlid('');
      setReceiptName('');
    }
  }, [details])

  const closeModal = () => setModelOpen(false);

  const handlePreviewFile = (id, fileUlid) => {
    setSelectedFileUlid('');
    const span = document.getElementById(id);
    const oldFileName = span.innerHTML;
    setReceiptName(oldFileName);
    setSelectedFileUlid(fileUlid);
  }

  return (
    <Receipts
      isOpen={modalIsOpen}
      current={current}
      selectedFileUlid={selectedFileUlid}
      handlePreviewFile={handlePreviewFile}
      closeModal={closeModal}
      details={details}
      receiptName={receiptName}
      setSelectedFileUlid={setSelectedFileUlid}
      handlerReload={handlerReload}
      handleSnackbar={handleSnackbar}
    />
  );
}


const Activity = ({ edit, handleChange, events, history, submitEvent, current, setUpdatedHoursWorked }) => {
  const [activities, setActivties] = useState([]);  
  const [editedActivities, setEditedActivities] = useState([]);
  const [, setRand] = useState(null);
  const random = () => (Math.floor(Math.random() * (99999 - 99)) + 99);
  const classes = useStyles();

  useEffect(() => {
    let container = [];
    let timeworked = 0;
    let sortedEvents = events.sort((a,b) => a.timeStamp - b.timeStamp).filter(a => a.event !== ClockType.Submit)
    const list = sortedEvents
    .map((t, index) => {
      let update
      if(history){
        const temp = [...history]
        let activityHistory = temp.filter(a => a.TimeSheetClockEventId === t.ulid).sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))
        update = activityHistory[0]
      }
      container.push(t);

      let event, status;
      if (index === 0) {
        event = 'Start Day:';
      } else {
        if (t.event === ClockType.ClockIn) {
          event = 'Clock In:';
        }

        if (t.event === ClockType.BreakStart) {
          event = 'Start Break:';
        }
  
        if (t.event === ClockType.BreakEnd) {
          event = 'End Break:';
          const breakTime = CalculateTime([sortedEvents[index - 1], t]);
          status = getFullDuration(breakTime);
        }
  
        if (t.event === ClockType.ClockOut) {
          event = 'Clock Out:';
          timeworked += CalculateTime(container)
          status = `${getFullDuration(CalculateTime(container))} worked`;
          container = [];
        }

        if (t.event === ClockType.Submit) {
          event = 'Submit:';
        }
      }
      return {
        edited: t.edited,
        ulid: t.ulid,
        timeStamp: moment.unix(t.timeStamp).format('HH:mm:ss a'),
        time: getTime(t.timeStamp),
        epochTime: t.timeStamp,
        event,
        status,
        update: update
      }
    });
    setUpdatedHoursWorked(timeworked)
    setActivties(list);     
    setEditedActivities(list);
    // eslint-disable-next-line
  }, [events, edit, history])

  //Update specfic event in form and update the timestamp in our activites object
  const updateActivities = (ulid, newValue) =>{
    let updatedActivies = editedActivities
    let objIndex = updatedActivies.findIndex((obj => obj.ulid === ulid))    
    let timeworked = 0;
    let hours = parseInt(newValue.slice(0,2), 10);
    let minutes = parseInt(newValue.slice(3,5), 10);
    let seconds = parseInt(newValue.slice(6,8), 10);
    let eventTime = moment(current.date, 'ddd MMMM Do YYYY');
    eventTime.hours(hours);
    eventTime.minutes(minutes);
    eventTime.seconds(seconds);
    updatedActivies[objIndex].epochTime = eventTime.unix();
    setEditedActivities(updatedActivies)    
    let container = [];
    const list = updatedActivies.map((t, index) => {
      let update
      if(history){
        const temp = [...history]
        let activityHistory = temp.filter(a => a.TimeSheetClockEventId === t.ulid).sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))
        update = activityHistory[0]
      }
      container.push(t);

      let status = t.status;
        if (t.event === 'End Break:') {
          const breakTime = CalculateUpdate([editedActivities[index - 1], t]);
          status = getFullDuration(breakTime);
        }

        if (t.event === 'Clock Out:') {
          status = `${getFullDuration(CalculateUpdate(container))} worked`;
          timeworked += CalculateUpdate(container)
          container = [];
        }
      return {
        edited: t.edited,
        ulid: t.ulid,
        timeStamp: moment.unix(t.epochTime).format('HH:mm:ss a'),
        time: getTime(t.epochTime),
        epochTime: t.epochTime,
        event: t.event,
        status: status,
        update: update
      }
    });
    setUpdatedHoursWorked(timeworked)
    setEditedActivities(list);
    setRand(random());
  }

  return (
    <TableContainer style={{ maxHeight: 250, margin: 0 }}>
      <table className={classes.activityTable}>
        <tbody>
          {
            !edit ? 
            <>
            {activities.map(t => {
              return (
                <tr key={t.ulid}>
                  <td className={(t.edited && t.update) ? classes.feildWidth : classes.uneditedFeildWidth}>{(t.edited && t.update) && <Tooltip 
                        arrow
                        title={
                          <>
                            <p className={classes.tooltipInfo}>{`Updated by ${t.update.user} on ${moment(t.update.createdAt).format('L[,] h:mm:ss a')}`}</p>
                            <p className={classes.tooltipInfo}>{`Changed: '${moment.unix(t.update.previousValue).format('h:mm:ss a')}' to '${moment.unix(t.update.newValue).format('h:mm:ss a')}'`}</p>
                          </>
                        }
                        classes={classes}
                        >
                          <EditIcon className={classes.editIcon} /> 
                        </Tooltip>}{` ${t.event}`}</td>
                  <td className={classes.activityDataWidth}>{moment.unix(t.epochTime).format('hh:mm a')}</td>
                  <td className={classes.activityDataWidth}>{t.status}</td>
                </tr>
              );})}
          </>
          :
          (editedActivities.map(t => {
            return (
              <tr key={t.ulid}>
                      <td className={(t.edited && t.update) ? classes.feildWidth : classes.uneditedFeildWidth}>{(t.edited && t.update) && <Tooltip 
                        arrow
                        title={
                          <>
                            <p className={classes.tooltipInfo}>{`Updated by ${t.update.user} on ${moment(t.update.createdAt).format('L[,] h:mm:ss a')}`}</p>
                            <p className={classes.tooltipInfo}>{`Changed: '${moment.unix(t.update.previousValue).format('h:mm:ss a')}' to '${moment.unix(t.update.newValue).format('h:mm:ss a')}'`}</p>
                          </>
                        }
                        classes={classes}
                        >
                          <EditIcon className={classes.editIcon} /> 
                        </Tooltip>}{` ${t.event}`}</td>                    
                      <TextField 
                      style={{paddingRight: 5}}
                      id="time"
                      type="time"
                      onChange={(e) => {
                        updateActivities(t.ulid, e.target.value);
                        handleChange(t.ulid, e.target.value);
                      }}
                      value={moment.unix(t.epochTime).format('HH:mm:ss')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        endAdornment: null,
                        style: {
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontFamily: "Arial,Helvetica,sans-serif",
                          height: '20px',
                          width: '75px'
                        }
                      }}
                      inputProps={{
                        step:'1',
                        style:{
                          textAlign: 'center',
                          fontSize: '10px',
                          padding: 2
                        },
                      }}
                      />    
                    {t.status?.charAt(0) !== '-' && <td className={classes.activityDataWidth}>{t.status}</td>}
                </tr>
            );
          }))
        }
                <tr key={submitEvent.ulid}>
                  <td className={classes.uneditedFeildWidth}>{`Submit: `}</td>
                  <td className={classes.activityDataWidth}>{moment.unix(submitEvent.timeStamp).format('hh:mm a')}</td>
                  <div style={{width: 135}}><td className={classes.activityDataWidth}>{submitEvent.status}</td></div>
                </tr>
        </tbody>
      </table>
    </TableContainer>
  )
}

export default TimeSheetDetails;
