import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
  Button
} from '@mui/material';
import DetailModal from './detailsModal'
import Currency from '../../utils/formatCurrency';

const useStyles = makeStyles((theme) => ({
    formControl: {
      width: '80%'
    },
    table: {
      minWidth: 650,
    },
    tabs: {
      display: 'flex',
      color: '#707070',
      background: '#dff2fa',
      borderRadius: '5px',
      height: '25px',
      width: 'fit-content',
      margin: 'auto',
      border: '1px solid #e8e8e8',
    },
    tab: {
      background: 'transparent',
      border: 'none',
      display: 'flex',
      width: '150px',
      '& > div': {
        margin: 'auto',
        color: '#707070',
      },
      '&:hover': {
        cursor: 'pointer',
        opacity: '0.8',
      },
    },
    listItemText: {
      fontSize: '12px'
    },
    activetab : {
      background: '#9ed8f2',
      borderRadius: 5,
      border: 'none',
      display: 'flex',
      width: '150px',
      '& > div': {
        margin: 'auto',
        color: '#707070'
      },
      '&:hover': {
        cursor: 'pointer',
        opacity: '0.8'
      },
    },
    detailtable: {
      borderRadius: '5px',
      overflow: 'hidden',
      marginBottom: '25px',
    },
    topDetailtable: {
      borderRadius: '5px',
      overflow: 'hidden',
      marginTop: '55px',
      marginBottom: '35px',
      width: '43vh',
    },
    activitytable: {
      background: '#dff2fa',
      margin: 'auto',
      marginLeft: '24px',
      borderRadius: '5px',
      overflow: 'hidden',
      width: '100%',
      marginBottom: '25px',
    },
    innerActivitytable: {
      overflow: 'hidden',
      width: '100%',
    },
    topTableContainer: {
      display: 'flex',  
      justifyContent: 'center',
      alignItems: 'center',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      position: "absolute",
    },
    topTableCellsLeft: {
      padding : '7px',
      paddingLeft: '15px',
      fontFamily: 'Helvetica',
    },
    topTableCellsRight: {
      padding : '7px',
      paddingRight: 0,
      fontFamily: 'Helvetica',
    },
    statusCell: {
      width: '164px'
    },
    bottomTableHeaderCells: {
      // minWidth: '150px',
      color: '#505050',
      fontFamily: 'Helvetica',
      fontWeight: 'bold',
    },
    bottomTableHeaderCellsBold: {
      // minWidth: '150px',
      color: '#505050',
      fontWeight: 'bold',
      fontFamily: 'Helvetica',
    },
    noHoverLightRow: {
      background: 'white',
    },
    tableRowLight: {
      background: 'white',
      '&:hover': {
        background: '#dff2fa',
        cursor: 'pointer',
      },
    },
    tableRowDark: {
      background: '#EBEBEB',
      '&:hover': {
        background: '#dff2fa',
        cursor: 'pointer',
      },
    },
    tableRowLightBlue: {
      background: '#9ed8f2',
      fontWeight: 'bold',
      borderTop: '2px solid #666666',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    topTableRowLightBlue: {
      background: '#dff2fa',
    },
    tableRowBlue: {
      background: '#dff2fa',
      '&:hover': {
        background: '#47afeb',
        cursor: 'pointer',
        opacity: '0.8',
      },
    },
    tableRowLightGreen: {
      background: '#caf7d3',
    },
    viewButton: {
      color: '#fff',
      fontWeight: 'Bold',
    },
    popupIndicator: {
      width: '10px',
      padding: 2,
      marginRight: -2,
      transform: 'rotate(90deg)',
    },
    popupIndicatorOpen: {
      width: '10px',
      padding: 2,
      marginRight: -2,
      transform: 'rotate(-90deg)',
    },
    buttonSuccess:{
      textAlign: 'center',
      borderRadius: '10px',
      backgroundColor: "#10D236",
      fontSize: '14px',
      fontWeight: 'bold',
      height: '25px',
      width: '90px',
      border: 'none',
      color: 'white'
    },
    buttonFailed: {
      textAlign: 'center',
      borderRadius: '10px',
      backgroundColor: "#F20634",
      fontSize: '14px',
      fontWeight: 'bold',
      height: '25px',
      width: '90px',
      border: 'none',
      color: 'white'
    },
    buttonRefunded: {
      textAlign: 'center',
      borderRadius: '10px',
      backgroundColor: "#BCBCCB",
      fontSize: '14px',
      fontWeight: 'bold',
      height: '25px',
      width: '90px',
      border: 'none',
      color: 'white'
    }
}));

const NestedTable = ({data, filterValue, outerValues, headers}) => {
  if (data && data.length > 0) {
    // eslint-disable-next-line array-callback-return
    data.map(el => {
      if (el.statustype === 'failed' || el.statustype === 'refunded') {
        el.fee = 0;
        el.net = 0;
      }
    })
  }
  return (
    <>
      <OuterTableView data={data} filterValue={filterValue} outerValues={outerValues} headers={headers}/>
    </>
  );
};

// Outer Table
const OuterTableView = ({ data, filterValue, outerValues, headers }) => {
  const [outerVal, setOuterVal] = useState([]);
  const classes = useStyles();
  useEffect(() => {
    const val = outerValues.map(o => {
      return {
        key: o,
        hidden: true
      }
    })
    setOuterVal(val);
  }, [outerValues])

  const HoverAbleRow = ({row, index}) => {
    const [, setHovered] = useState(false);
    const [hidden, setHidden] = useState(true)
    const [filtered, setFiltered] = useState([])

    useEffect(() => {
      if(data.length === 0) {
        return
      }
      const ff = data.filter(n => n[filterValue] === row.key)
      setFiltered(ff)
    }, [row.key])

    useEffect(() => {
      setHidden(row.hidden)
    }, [row])

    return (
      <TableBody>
        <TableRow
          onMouseOver={() => {
            setHovered(true)}}
          onMouseLeave={() => setHovered(false)}
          key={row.id}
          onClick={() => {
            setHidden(!hidden)
          }}
          // onClickAway
          className={!hidden ? classes.tableRowLightBlue : (index % 2 ? classes.tableRowLight : classes.tableRowDark)}
        >
          {headers.map((tb, i) => {
            let textval;
            i === 0 ? textval = row.key : textval = ''
            if (tb.summable) {
              if (!textval) {
                textval = parseInt(0, 10);
              }
              filtered.forEach(n => {
                const inter = n[tb.value];
                textval += parseInt(inter, 10);
              })
              if (tb.type === "currency") {
                textval = Currency((textval / 100) || 0)
              }
            }
            return (
              <TableCell 
                className={!hidden ? classes.bottomTableHeaderCellsBold : classes.bottomTableHeaderCells}
                style={{width: `calc(100% / ${headers.length})`}}
                align={"center"}>
                  {textval}
              </TableCell>
            )
          })}
          <TableCell className={classes.bottomTableHeaderCells} align="center">{}
            {!hidden ?
              <img className={classes.popupIndicatorOpen} src={'/arrow.png'} alt="arrow" /> :
              <img className={classes.popupIndicator} src={'/arrow.png'} alt="arrow" />
            }
          </TableCell>
        </TableRow>
        <InnerDetails data={data} filterValue={filterValue} outerValue={row.key} headers={headers} isHidden={hidden}/>
      </TableBody>
    );
  };

  return (
    <TableContainer>
      <Table className={classes.detailtable} aria-label="simple table">
        <TableHead>
          <TableRow key={'Header'} className={classes.noHoverLightRow}>
            {headers.map((tb, i) => {
              return (
                <TableCell className={classes.bottomTableHeaderCells} align={"center"}>{tb.header}</TableCell>
              )
            })}
                <TableCell className={classes.bottomTableHeaderCells} align={"center"}></TableCell>
          </TableRow>
        </TableHead>
        {outerVal.map((n, i) => {
          return (
            <HoverAbleRow row={n} index={i}/>
          )
        })}
      </Table>
    </TableContainer>
  );
};

// Inner Table
const InnerDetails = ({ data, filterValue, outerValue, headers, isHidden }) => {
  const [innerData, setInnerData] = useState([]);
  const [modelOpen, setModelOpen] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [entryDetails, ] = useState();
  const classes = useStyles();

  useEffect(() => {
    setHidden(isHidden)
  }, [isHidden])

  useEffect(() => {
    const filtered = data.filter(n => n[filterValue] === outerValue)
    setInnerData(filtered)
  }, [data, filterValue, outerValue])

  const handleDetails = (current) => {
    console.log(current)
    // setModelOpen(true);
  };
  const HoverAbleInnerRow = ({row}) => {
    const [, setHovered] = useState(false);
    return (
      <TableRow
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        key={row.id}
        className={classes.tableRowBlue}
        onClick={() => handleDetails(row)}
      >
      {headers.map((tb, i) => {
        let textval;
        let statustype
        i === 0 ? textval = row.inner : textval = row[tb.value]
        if (tb.type === "status") {
          statustype = row.statustype
        }
        if (textval === "null") { textval = null }
        if (tb.type === "currency") {
          textval = Currency((textval / 100) || 0)
        }
        return (<>
          {tb.type === 'status' ?
            <TableCell className={classes.bottomTableHeaderCells} align={"center"}>
                {statustype === 'success' ? 
                <Button variant="contained" class={classes.buttonSuccess}>
                    {textval}
                </Button>
                : statustype === 'refunded' ? 
                <Button variant="contained" class={classes.buttonRefunded}>
                    {textval}
                </Button>
                :
                <Button variant="contained" class={classes.buttonFailed}>
                    {textval}
                </Button>
                }
            </TableCell> : 
            <TableCell className={classes.bottomTableHeaderCells} align={i === 0 ? "right" : "center"}>{textval}</TableCell>
          }
        </>)
      })}
        
        <TableCell className={classes.bottomTableHeaderCells} align="center">
            <div className={classes.viewButton}>
            </div>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
    {!hidden ? <>
      {innerData.map((t) => t && (
        <HoverAbleInnerRow row={t} />
      ))}
      {modelOpen && 
        <DetailModal
          modalIsOpen={modelOpen} 
          setModelOpen={val => setModelOpen(val)} 
          details={entryDetails} 
        />
      } 
    </>
    : <></>}
    </>
  );
};

export default NestedTable