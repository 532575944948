import React, { useState, useEffect }  from "react";
import {useAPI} from '../../utils/api';
import { 
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  Button,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  CircularProgress,
  Backdrop
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles, withStyles } from '@mui/styles';
import {Close as CloseIcon} from '@mui/icons-material'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateWithoutSeconds from "../../utils/dateWithoutSec";
import Session from "../../session";
import { faPrint, faMoneyCheckAlt } from "@fortawesome/free-solid-svg-icons";
import { capitalize } from "../../utils/capitalizeNames";
import { FE_URL } from "../../config";
import './paymentStyle.css';

const theme = createTheme({
  palette: {
    primary: {
      // Main blue color for the app. This is set as the main primary color.
      main: '#00CC00',
      // ignore all color variations except main
      dark: '#038c03',
      // ignore all color variations except main
      contrastText: '#fff',
    },
    secondary: {
      // Main blue color for the app. This is set as the main primary color.
      main: '#F20634',
      // ignore all color variations except main
      dark: '#9e0220',
      // ignore all color variations except main
      contrastText: '#fff',
    },
  },
});

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingTop: 10,
    paddingLeft: 15,
  },
});

const useStyles = makeStyles((theme) =>({
  table: {
    minWidth: 315,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    position: "absolute",
  },
  button: {
    margin: theme.spacing(1),
  },
  bottomButtonsContainer: {
    display: 'flex',
    float: 'right',
    paddingLeft: 100, 
    paddingRight: 0, 
    paddingTop: 3,
    [theme.breakpoints.down(768)]: {
      paddingLeft: 0,
    },
  },
  refundDiv: {
    padding: '0 30px',
  },
  refundButton: {
    opacity: 1,
    flexDirection: 'row',
    backgroundColor: 'darkgray',
    color: 'white',
    '&:hover': {
      background: '#838383',
      cursor: 'pointer',
    },
  },
  confirmationContainer: {
    maxHeight: '190px', 
    width: 320, 
    marginLeft: '140px', 
    marginBottom: '15px',
    [theme.breakpoints.down(768)]: {
      width: '100%',
      marginLeft: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
  },
  buttonContainer: {
    paddingLeft: '48px', 
    paddingTop: '35px',
    [theme.breakpoints.down(768)]: {
      paddingLeft: 0,
    },
  }
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton 
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          className="icon cur-pointer">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const PaymentModal = ({
    modalOpen,
    response,
    handlerModal,
    handlerReload,
    isReload
}) => {
  const api = useAPI();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isRefunded, setIsRefunded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload,setReload] = useState(false);
  const [refundConfirmation, setRefundConfirmation] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    if(width <= 768){
      setIsMobile(true)
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
      setIsOpen(modalOpen);
      if(response.refunded === true){
        setIsRefunded(true)
      }
  }, [modalOpen, response])

  const closeModal = () => { 
    if(isReload || reload)
    handlerReload(true);
    handlerModal(false);
  }

  const Print = () => {

    //go to route recipt/charge
    let url = `${FE_URL}/receipt?orgId=${Session.OrganizationId}&chargeId=${response.id}`
    window.open(`${url}`);
  }

  const Refund = async () => {
    setLoading(true);
    const charge_id = response.id;

    const payload = {
      "OrganizationId": `${Session.OrganizationId}`
    };

    const refundPayment = await api.paymentCenterRefund({payload, user: charge_id});
    
    if(refundPayment !== undefined){
      if(refundPayment.status === 200){
        setTimeout(function(){
          setLoading(false);
          setIsRefunded(true);
        }, 2000);
        if(!isReload) { setReload(true); }
        setRefundConfirmation(false)
      }
    };
  };

  const classes = useStyles();

  const optimisticTime = DateWithoutSeconds(Date.now() /1000);
    
  return (
    <Dialog 
      open={modalIsOpen} 
      onClose={closeModal} 
      fullWidth
      fullScreen={isMobile}
    >
              <Backdrop
          className={classes.backdrop}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
      <DialogTitle onClose={closeModal}>
        Payment Confirmation
      </DialogTitle>
      <DialogContent>
        <div style={{minHeight: 220}}>
          {!refundConfirmation ? <TableContainer className={classes.table} style={{minHeight: 220}}>
            <Table className={classes.table} size="small" aria-label="simple table" style={{minHeight: 220, marginBottom: '15px'}}>
              <TableBody>
                  <TableRow >
                    <TableCell component="th" scope="row" style={{borderBottom: 'none'}}>
                    Status:
                    </TableCell>
                    <TableCell align="left" style={{borderBottom: 'none', fontSize: '16px', fontStyle: 'normal', color: `${(response.status === 'succeeded') ? 'green' : 'red'}`, fontFamily: 'Arial,Helvetica,sans-serif'}}>
                      {
                      isRefunded ? 'Refunded' : 
                      (response.status === 'succeeded') ? 'Successful' : capitalize(response.status)
                      }
                    </TableCell>
                  </TableRow>
                  {response.payment_method_details?.card && <TableRow >
                    <TableCell component="th" scope="row" style={{borderBottom: 'none'}}>
                    Card:
                    </TableCell>
                    <TableCell align="left" style={{borderBottom: 'none', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Arial,Helvetica,sans-serif'}}>{`${capitalize(response.payment_method_details?.card?.network)} ending in ${response.payment_method_details?.card?.last4}`}</TableCell>
                  </TableRow>}
                  <TableRow >
                    <TableCell component="th" scope="row" style={{borderBottom: 'none'}}>
                    Confirmation:
                    </TableCell>
                    <TableCell align="left" style={{borderBottom: 'none', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Arial,Helvetica,sans-serif'}}>{response.metadata.Confirmation}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell component="th" scope="row" style={{borderBottom: 'none'}}>
                    Time:
                    </TableCell >
                    <TableCell align="left" style={{borderBottom: 'none', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Arial,Helvetica,sans-serif'}}>{DateWithoutSeconds(response.created)}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell component="th" scope="row" style={{borderBottom: 'none'}}>
                    Amount:
                    </TableCell>
                    <TableCell align="left" style={{borderBottom: 'none', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Arial,Helvetica,sans-serif'}}>${(response.amount/100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</TableCell>
                  </TableRow>
                  {isRefunded && <TableRow >
                    <TableCell component="th" scope="row" style={{borderBottom: 'none'}}>
                    Refund Issued:
                    </TableCell>
                    <TableCell align="left" style={{borderBottom: 'none', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Arial,Helvetica,sans-serif'}}>{response.refunds.data[0] !== undefined ? DateWithoutSeconds(response.refunds.data[0].created) : optimisticTime}</TableCell>
                  </TableRow>}
                  <TableRow >
                    <TableCell component="th" scope="row" style={{borderBottom: 'none'}}>
                    Name:
                    </TableCell>
                    <TableCell align="left" style={{borderBottom: 'none', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Arial,Helvetica,sans-serif'}}>{response.metadata.Name !== 'null' ? response.metadata.Name : ''}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell component="th" scope="row" style={{borderBottom: 'none'}}>
                    Comments:
                    </TableCell>
                    <TableCell align="left" style={{borderBottom: 'none', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Arial,Helvetica,sans-serif'}}>{response.metadata.Comments}</TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer> :
          <div className={classes.confirmationContainer}>
            <h5 style={{marging: 0, paddingLeft: '18px',}}>{`Are you sure you would like to issue a refund?`}</h5>
            <div className={classes.buttonContainer}>
            <ThemeProvider theme={theme}>
              <Button variant="contained" color='secondary' style={{marginRight: 6}} onClick={() => setRefundConfirmation(false)}>Cancel</Button>
              <Button variant="contained" color='primary' style={{marginLeft: 6}}  onClick={Refund}>Confirm</Button>
            </ThemeProvider>
            </div>
          </div>}
        </div >
        {!refundConfirmation && <div className={classes.bottomButtonsContainer}>
          {response.status === 'succeeded' &&
            <div className="icon cur-pointer">
              <Button variant="contained" color='primary' onClick={Print} startIcon={<FontAwesomeIcon icon={faPrint} fixedWidth size="xs" />}>View receipt</Button>
            </div>}
          
          {response.status === "succeeded" ? (isRefunded ?
            <div className={classes.refundDiv}> 
              <Button 
                disabled
                variant="contained"
                style={{
                  cursor: 'not-allowed',
                  opacity: 0.4,
                  flexDirection: 'row',
                  backgroundColor: 'green',
                  color: 'white'
                }}
              > 
                Refund Issued
              </Button>
            </div> 
          :
            <div className={classes.refundDiv}> 
              <Button 
                variant="contained"
                className={classes.refundButton}
                onClick={() => setRefundConfirmation(true)}
                startIcon={<FontAwesomeIcon icon={faMoneyCheckAlt} />}
              > 
                Refund
              </Button>
            </div>
          ) : ''}
        </div>}
      </DialogContent>
      
    </Dialog>
  );
};

export default PaymentModal;