import React, { useCallback } from "react";
import {TextField} from '@mui/material';
const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;
const CurrencyInput = ({
  max = Number.MAX_SAFE_INTEGER,
  onValueChange,
  height,
  currencyLabel,
  disabled = false,
  fullWidth = false,
  value
}) => {
  const valueAbsTrunc = Math.trunc(Math.abs(value));
  if (
    value !== valueAbsTrunc ||
    !Number.isFinite(value) ||
    Number.isNaN(value)
  ) {
    console.log(`invalid value property`);
  }
  const handleKeyDown = useCallback(
    e => {
      const { key, keyCode } = e;
      if (
        (value === 0 && !VALID_FIRST.test(key)) ||
        (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
      ) {
        return;
      }
      const valueString = value.toString();
      let nextValue;
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString = value === 0 ? key : `${valueString}${key}`;
        nextValue = Number.parseInt(nextValueString, 10);
      } else {
        const nextValueString = valueString.slice(0, -1);
        nextValue =
          nextValueString === "" ? 0 : Number.parseInt(nextValueString, 10);
      }
      if (nextValue > max) {
        return;
      }
      onValueChange(nextValue);
      // onChange(nextValue)
    },
    [ max, 
      onValueChange, 
      // onChange,
      value]
  );
  const handleChange = useCallback(() => {
    // DUMMY TO AVOID REACT WARNING
  }, []);

  const valueDisplay = (value / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });

  return (
    <TextField
      disabled={disabled}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      fullWidth={fullWidth}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{ inputMode: 'numeric' }}
      label={currencyLabel}
      variant='outlined'
      value={valueDisplay}
      InputProps={{
        style: {
          fontSize: '16px',
          fontStyle: 'normal',
          fontFamily: 'Arial,Helvetica,sans-serif',
          height: height,
          padding: '5px',
        }
      }}
    />
  );
};

export default CurrencyInput;
