const axios = require('axios');
const uploadInS3 = (signedUrl, file) => new Promise(resolve => {
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = file.type;
    const myHeaders = {
      headers: {
        'Content-Type': file.type
      }
    }
    instance.put(signedUrl, file, myHeaders)
      .then((uploadRes) => {
        console.log('dont')
        resolve(uploadRes);
      })
      .catch((err) => {
        console.log('err')
        resolve(err)
      });
});
module.exports = { uploadInS3 }