import React, { useEffect, useState } from 'react';
import useLocalLanguageContext from '../../utils/useLocalLanguageContext';

export default function LokalisedContent(key, fallback) {
  const { workerLang } = useLocalLanguageContext();
  const [renderText, setRenderText] = useState();

  useEffect(() => {
    if (workerLang[key]) {
      setRenderText(workerLang[key])
    } else {
      setRenderText(fallback)
    }
  }, [workerLang, key, fallback])


  return <>{renderText}</>;
}
