import  React, {Component} from 'react';
import ReactModal from "react-modal";
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import { ModalButton, DisableButton } from '../styled/commonStyle';

class UserAvatar extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();

        this.state = {
            src: null,
            crop: {
                unit: "%",
                width: 30,
                aspect: 1 / 1
            },
            croppedAvatarUrl: null,
            displayButton : false,
            croppedAvatar: null,
            modalIsOpen: false,
        }
    }
    componentWillMount(){
        const val = this.props.modelOpen;
        this.setState({modalIsOpen:val});
        if(val)
        this.handleFile(this.props.logo_file)
    }

    random = () =>  (Math.floor(Math.random() * (99999 - 99)) + 99);
    handleFile = (item) => {
        this.setState({displayButton: true});
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            this.setState({src: fileReader.result })
        }   
        if(item)
        fileReader.readAsDataURL(item)
    }
    onAvatarChange = (crop) => {this.setState({ crop });}
    
    handle_Submit = async(e) => {
        e.preventDefault();
       this.props.avatarHandler(this.state.croppedAvatar);
       this.setState({displayButton: false});
       this.closeModal()
       }
    
    
    onComplete = crop => {
        if (this.imageRef && crop.width && crop.height) {
            const croppedAvatarUrl = this.getCroppedImg(this.imageRef, crop)
            this.setState({ croppedAvatarUrl })
        }
    }
    
    onLoaded = image => { this.imageRef = image }
    
    getCroppedImg(image, crop) {
        const canvas = document.createElement("canvas");
        const scaleY = image.naturalHeight / image.height;
        const scaleX = image.naturalWidth / image.width;
        canvas.height = crop.height;
        canvas.width = crop.width;
        const ctx = canvas.getContext("2d");
        
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
         )

        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                this.dataURLto_File(reader.result, `userLogo${this.random()}.png`)
            }
        })
    }
    dataURLto_File(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
                
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        let croppedAvatar = new File([u8arr], filename, {type:mime});
        this.setState({croppedAvatar: croppedAvatar }) 
    }
     closeModal = () => { this.props.handlerModel(false); }
    
render() {
    const { crop,modalIsOpen,src,displayButton } = this.state
    return (  
        <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={this.closeModal}
        className="model">
        <span className="model-close" onClick={this.closeModal}></span>
        <div className="main-cont">
  
          <h3 className="title">Update User Avatar 
          </h3>
          <div className="logoBox">
       
            {src && (
                <ReactCrop
                  src={src}
                  crop={crop}
                  onComplete={this.onComplete}
                  onImageLoaded={this.onLoaded}
                  onChange={this.onAvatarChange}
                 /> 
            )}
        </div>
        <div className="mg-tp-17">
            {!displayButton ? 
            <DisableButton disabled={true} className="btnSave" >Save</DisableButton> :
            <ModalButton className="btnSave" onClick={this.handle_Submit}>Save</ModalButton> }
            <ModalButton className="btnCancel mg-lt-15" onClick={this.closeModal}>Close</ModalButton>
          </div>
        </div>
      </ReactModal>
    )
}
}
export default UserAvatar;