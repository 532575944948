import React, { useState, useEffect } from 'react';
import { useAPI } from '../../utils/api';
import Session from "../../session";
import { pdfjs } from "react-pdf";
import ErrorToast from '../constant/errorToast';
import { ClockType, trimReceipt } from './util';
import './clock.css';
import { makeStyles } from '@mui/styles';
import {AttachFile as AttachFileIcon} from '@mui/icons-material';
import {
  CircularProgress,
  Backdrop,
  Tooltip,
  Button,
  TextField,
  IconButton
} from '@mui/material';
import NumberFormat from "react-number-format";
import NumberFormattedInput from "../constant/numberFormattedInput";
import Receipts from './timeTab/receiptsModule';
import ClockView from './timeTab/clockView';
import ActivityTable from './timeTab/activityTable';
import SubmitTimeSheetModal from './timeTab/submitTimesheetModal';
import AddReceipt from './timeTab/addReceipt'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  body: {
    color: '#707070',
    fontSize: 'small',
    borderBottom: 'none',
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      verticalAlign: 'top',
      textAlign: 'left',
      fontSize: '12px'
    },
  },
  tableRow: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '5px',
    },
  },
  table: {
    minWidth: 100,
    maxWidth: 300,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
  },
  textFieldContainer: {
    display: 'flex', 
    justifyContent: 'space-between',
    marginTop: '15px',
    // [theme.breakpoints.down('sm')]: {
    //   flexWrap: 'wrap'
    // },
  },
  mileageField: {
    width: '100%',
    marginTop: 15,
    maxWidth: 200,
    [theme.breakpoints.down('sm')]: {
      minWidth: 145,
      maxWidth: 145,  
      marginTop: 0,
    },
  },
  expenseField: {
    width: '100%',
    maxWidth: 200,
    [theme.breakpoints.down('sm')]: {
      minWidth: 145,
      maxWidth: 145,    
      marginTop: 0,
    },
  },
  inputField: {
    background: '#dff2fa',
    height: 50,
    top: '1px',
    float: 'right',
    maxWidth: 280,
    [theme.breakpoints.down('sm')]: {
      minWidth: 145,
      maxWidth: 145,   
    },
  },
  fileAttachment: {
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      float: 'left'
    },
  },
  fileAttachmentIcon: {
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      float: 'left',
      width: 50
    },
  },
  viewReceiptButton:{
    width: '100px',
    border: "none",
    background: "transparent",
    color: "#2CABE2",
    textDecoration: "underline",
    fontSize: "11px",
    float: "left",
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      paddingLeft: 10
    },
  },
  timeClockContainer:{
    margin: 'auto', 
    maxWidth: '850px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'initial',
    }, 
  }
}));

const TimeTab = () => {
  const api = useAPI();
  const classes = useStyles();
  const [location, setLocation] = useState(null);
  const [timesheet, setTimesheet] = useState([]);
  const [todayEvents, setTodayEvents] = useState([]);
  const [nextExpectedActions, setNextExpectedActions] = useState([ClockType.ClockIn]);
  const [entryId, setEntryId] = useState('');
  const [expense, setExpense] = useState('');
  const [mileage, setMileage] = useState('');
  const [note, setNote] = useState('');
  const [receiptName, setReceiptName] = useState('');
  const [attachFile, setAttachFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openReceipt, setOpenReceipt] = useState(false);
  const [openReceiptList, setOpenReceiptList] = useState(false);
  const [fileUlids, setFileUlids] = useState([]);
  const [openSubmitModel, setOpenSubmitModel] = useState(false);
  const [displayImage, setDisplayImage] = useState(null);
  const [localFiles, SetLocalFiles] = useState(null);
  const [fileType, setFileType] = useState('');
  const [mileageError, setMileageError] = useState(false);
  const [expenseError, setExpenseError] = useState(false);
  
  const checkLocationPermissions = async () => {
    const permissionStatus = await navigator?.permissions?.query({name: 'geolocation'});
    const hasPermission = permissionStatus?.state;
    if(hasPermission){
      navigator.geolocation.getCurrentPosition(position => {
        setLocation([position.coords.latitude, position.coords.longitude])
      });
    }
  };

  useEffect(() => {
    if(!location){
      checkLocationPermissions();
    }
  }, [location]);

  useEffect(() => {
    if (localFiles) {
      const tempURL = URL.createObjectURL(localFiles);
      setDisplayImage(tempURL);
      setFileType(localFiles.type);
    }
  }, [localFiles]);

  const getTimeSheet = async () => {
    setLoading(true);
    const res = await api.getTimesheetsByUser({ulid: Session.UserId});
    console.log(res);
    if (!res) {
      ErrorToast('Something went wrong');
      return;
    }
    setTimesheet(res || []);
    setLoading(false);
  }

  useEffect(() => {
    getTimeSheet();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parseDate = date => new Date(date).toDateString();

  const parseNumber = num => Number(num ? (num).toString().replace('$', '').replace(/,/g, '') : 0);

  useEffect(() => {
    if (timesheet.length > 0) {
      const todayTimesheetList = timesheet.filter(x => parseDate(x.createdAt) === parseDate(new Date()));
      console.log(todayTimesheetList)
      if (todayTimesheetList.length > 0) {
        const lastTimesheet = todayTimesheetList[todayTimesheetList.length - 1];
        if (lastTimesheet) {
          let lastevent;
          if (lastTimesheet.TimeSheetClockEvents && lastTimesheet.TimeSheetClockEvents.length > 0) {
            lastevent = lastTimesheet.TimeSheetClockEvents[lastTimesheet.TimeSheetClockEvents.length - 1];
          }

          if (lastevent && lastevent.event !== ClockType.Submit) {
            setEntryId(lastTimesheet.ulid);
            setMileage(lastTimesheet.mileage || '');
            setNote(lastTimesheet.notes || '');
            
            if (lastTimesheet.nextExpectedActions !== null) {
              setNextExpectedActions(lastTimesheet.nextExpectedActions);            
            }

            if (lastTimesheet.TimeSheetExpense) {
              setExpense(lastTimesheet.TimeSheetExpense.amount || '');
              setFileUlids(lastTimesheet.TimeSheetExpense.fileUlids || []);
            }

            if (lastTimesheet.TimeSheetClockEvents && lastTimesheet.TimeSheetClockEvents.length > 0) {
              setTodayEvents(lastTimesheet.TimeSheetClockEvents);
            }
          }
        }
      }
    }
  }, [timesheet]);


  const addOrEditMileage = async () => {
    if (!entryId) {
      return ErrorToast('Clock in first!');
    }
    if (mileageError) {
      return ErrorToast('Invalid Mileage');
    }
    await api.putTimesheetMileage({ entryId,  mileage: parseNumber(mileage) });
  };

  const addOrEditExpense = async () => {
    if (!entryId) {
      return ErrorToast('Clock in first!');
    }
    if (expenseError) {
      return ErrorToast('Invalid Expense');
    }
    await api.putTimesheetExpense({ entryId, amount: parseNumber(expense), fileUlids: [...fileUlids]});
  };

  const addOrEditNote = async () => {
    if (!entryId) {
      return ErrorToast('Clock in first!');
    }

    await api.putTimesheetNote({ entryId, note });
  };

  const submitDay = async () => {
    if (!entryId) {
      return ErrorToast('Clock in first!');
    }
    SetLocalFiles(null)
    setOpenSubmitModel(true);
  };

  const onPickedFile = files => {
    if (!entryId) {
      return ErrorToast('Clock in first!');
    }
    if (files.length > 0) {
      setReceiptName(trimReceipt(files[0].name));
      setAttachFile(files[0]);
    }
    SetLocalFiles(files[0]);
    setOpenReceipt(true);
  };

  const clearPickeFileOnClick = (event) => {
    event.target.value = ''
    const input = document.querySelector('#primary[type=file]');
    input.value = null;
  };

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      <ClockView
          events={todayEvents}
          location={location}
          entryId={entryId}
          setEntryId={en => setEntryId(en)}
          nextExpectedActions={nextExpectedActions}
          setNextExpectedActions={expect => setNextExpectedActions(expect)}
          addEvents={tevent => setTodayEvents([...todayEvents, tevent])}
        />
      <div className={classes.timeClockContainer}>
        <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={classes.mileageField}>
            <NumberFormat
              numericKeyboard
              customInput={NumberFormattedInput}
              label='Mileage'
              type='tel'
              allowNegative={false}
              decimalScale={2}
              allowLeadingZeros={false}
              disabled={(entryId === '')}
              tooltip={{
                active: (entryId === '') ? true : false,
              }}
              title="Please clock in first"
              value={mileage}
              onValueChange={(value) => {
                const val = parseFloat(value.value)
                if ((val >= 0 && val < 10000) || value.value === '') {
                  setMileage(value.value);
                  setMileageError(false)
                } else {
                  setMileageError(true)
                }
              }}
              error={mileageError}
              onBlur={addOrEditMileage} 
              thousandSeparator={true}
              styleProps={{
                background: '#dff2fa',
                height: 50,
                top: '1px',
              }}
            />
          </div>
          <div className={classes.textFieldContainer}>
            <div className={classes.expenseField}>
              <NumberFormat
                numericKeyboard
                customInput={NumberFormattedInput}
                label='Expenses'
                type='tel'              
                value={expense}
                onValueChange={(value) => {
                  const val = parseFloat(value.value)
                  if ((val >= 0 && val < 10000) || value.value === '') {
                    setExpense(value.value);
                    setExpenseError(false)
                  } else {
                    setExpenseError(true)
                  }
                }}
                error={mileageError}
                onBlur={addOrEditExpense}
                thousandSeparator={true}
                allowNegative={false}
                allowLeadingZeros={false}
                decimalScale={2}
                prefix={'$'}
                styleProps={{
                  background: '#dff2fa',
                  height: 50,
                  top: '1px',
                  float: 'right',
                }}
                disabled={(entryId === '')}
                tooltip={{
                  active: (entryId === '') ? true : false,
                }}
                title="Please clock in first"
              />
            </div>
            <div className={classes.fileAttachmentIcon}>
              {entryId ? (
                <IconButton 
                  onClick={() => document.querySelector('#primary[type="file"]').click()}  
                  style={{
                    float: 'left',
                    marginLeft: '5px',
                    marginRight: '5px',
                  }}>
                  <AttachFileIcon />
                </IconButton>
              ) : (
                <IconButton 
                  disabled 
                  style={{
                    float: 'left',
                    marginLeft: '5px',
                    marginRight: '5px',
                  }}>
                  <AttachFileIcon />
                </IconButton>
              )}
              <input
                id="primary"
                type="file" 
                accept='image/png, image/jpeg, .pdf, image/heic' 
                multiple={false} 
                style={{ width: '0' }} 
                onChange={(e) => onPickedFile(e.target.files)} 
                onClick={(e) => clearPickeFileOnClick(e)}
              />
            </div>
            <div className={classes.fileAttachment}>
              {fileUlids.length > 0 ? 
                <Button 
                  onClick={() => setOpenReceiptList(true)}
                  size="small"
                  className={classes.viewReceiptButton}
                >
                  View Receipts
                </Button>
              : <div 
                  style={{
                    width: '100px',
                  }}
                ></div>}
            </div>
          </div>
        </div>
        <div className="row">
          <div style={{ width: '100%' }}>
            {(entryId === '') ? ( 
              <Tooltip title="Please clock in first" arrow placement="bottom"> 
                <TextField 
                  disabled={true} 
                  value={note} 
                  onChange={e => setNote(e.target.value)} 
                  onBlur={addOrEditNote}
                  variant="outlined"
                  multiline
                  fullWidth
                  label="Notes"
                  rows={4}
                  InputProps={{
                    style: {
                      width: '100%',
                      background: '#dff2fa',
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontFamily: "Arial,Helvetica,sans-serif",
                      padding: "5px 5px 5px 13px",
                    },
                  }}
                />
              </Tooltip>
            ) : (
              <TextField
                value={note} 
                onChange={e => setNote(e.target.value)} 
                onBlur={addOrEditNote}
                variant="outlined"
                multiline
                fullWidth
                label="Notes"
                rows={4}
                InputProps={{
                  style: {
                    width: '100%',
                    background: '#dff2fa',
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontFamily: "Arial,Helvetica,sans-serif",
                    padding: "5px 5px 5px 13px",
                  },
                }}
              />
            )}
          </div>
        </div>
        <div className="row" style={{display: 'flow-root'}}>
          {(!nextExpectedActions.includes(ClockType.Submit) || !entryId) ?
            <Tooltip title="Please make sure to clock out first" arrow placement='right'>
              <span style={{float: 'right'}}>
                <Button 
                  disabled
                  variant='contained'
                  style={{
                    fontSize: '11px',
                    width: '109px',
                    height: '30px',
                    border: 'none',
                    color: '#FFFFFF',
                  }}
                >
                  Submit Time
                </Button>
              </span>
            </Tooltip>
              :
                (<Button   
                  disabled={false} 
                  onClick={submitDay}
                  variant='contained'
                  className="icon cur-pointer"
                  color='primary'
                  style={{
                    fontSize: '11px',
                    width: '109px',
                    height: '30px',
                    border: 'none',
                    color: '#FFFFFF',
                    float: 'right',
                  }}
                >
                Submit Time
                </Button>)
          }
          
        </div>
      </div>

      <div className="row">
        <label style={{ color: '#707070', width: '100%' }}>Today's Activity</label>
        <hr style={{ width: '100%', margin: '20px 0px' }} />
        <ActivityTable events={todayEvents}/>
      </div>
      
      <AddReceipt 
        receiptName={receiptName}
        setReceiptName={setReceiptName}
        entryId={entryId}
        isOpen={openReceipt}
        fileUlids={fileUlids}
        setFileUlids={setFileUlids}
        setLoading={setLoading}
        api={api}
        openReceipt={openReceipt}
        setOpenReceipt={setOpenReceipt}
        SetLocalFiles={SetLocalFiles}
        fileType={fileType}
        displayImage={displayImage}
        attachFile={attachFile}
        setAttachFile={setAttachFile}
        clearPickeFileOnClick={clearPickeFileOnClick}
      />

      <Receipts
        entryId={entryId} 
        isOpen={openReceiptList} 
        fileUlids={fileUlids} 
        setParent={state => setOpenReceiptList(state)} 
        setFileUlids={setFileUlids} 
        setLoading={load => setLoading(load)}
        getTimeSheet={getTimeSheet}
        api={api}
      />

      <SubmitTimeSheetModal
        modalIsOpen={openSubmitModel} 
        setModelOpen={state => setOpenSubmitModel(state)} 
        fileUlids={fileUlids}
        location={location}
        entryId={entryId}
        mileage={parseNumber(mileage)}
        expense={parseNumber(expense)}
        note={note}
        api={api}
        events={todayEvents}
        nextExpectedActions={nextExpectedActions}
        setFileUlids={setFileUlids}
        setNextExpectedActions={next => setNextExpectedActions(next)}
        setParent={state => {
          setOpenSubmitModel(state);
        }}
        setSubmitEvent={() => {
          setTodayEvents([]);
          setEntryId('');
          setMileage('');
          setExpense('');
          setNote('');
          setFileUlids([]);
        }}
      />
    </>
  )
};

export default React.memo(TimeTab);
