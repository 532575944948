import React from "react";

import { Tooltip, TextField } from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    top: '-12.5px',
    "&$labelFocused": {
      top: 0
    }
  },
  labelZero: {
    top: 0,
    "&$labelFocused": {
      top: 0
    }
  },
  labelFocused: {
    top: 0
  },
}));

const NumberFormattedInput = ({
  onChange,
  value,
  onFocus,
  onBlur,
  label,
  error,
  helperText,
  disabled,
  tooltip,
  title,
  styleProps,
  height = null,
  placeholder = null,
  otroProps,
  normalProps = false,
  modalField = false,
  type,
  receiptField = false,
  labelProps,
  numericKeyboard = false,
  editInput = false
}) => {
  const classes = useStyles();


  return (
    tooltip && tooltip.active ? (
      <Tooltip title={title} placement="top-start" arrow>
        <TextField
          variant="outlined"
          value={value}
          disabled={disabled}
          fullWidth
          error={error}
          label={label}
          helperText={helperText}
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          inputProps={{ inputMode: 'tel' }}
          InputProps={{
            style: {
              fontSize: "16px",
              fontStyle: "normal",
              fontFamily: "Arial,Helvetica,sans-serif",
              height: 33,
              padding: "5px",
              ...styleProps
            },
            ...otroProps
          }}
        />
      </Tooltip>
    ) : (modalField ?
      <TextField
        variant="outlined"
        value={value}
        disabled={disabled}
        fullWidth
        error={error}
        label={label}
        helperText={helperText}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        inputProps={{ inputMode: 'tel' }}
        InputProps={{
          placeholder: placeholder,
          style: {
            fontSize: "16px",
            fontStyle: "normal",
            fontFamily: "Arial,Helvetica,sans-serif",
            height: height || 30,
            padding: "5px",
            backgroundColor: 'white',
            ...styleProps
          },
          ...otroProps
        }}
        InputLabelProps={{
          shrink: true,
          // classes: {
          //   root: (value ? classes.labelZero : classes.labelRoot),
          //   focused: classes.labelFocused
          // }
          
        }}
      /> : ( receiptField ?  
      <TextField
        variant="outlined"
        value={value}
        disabled={disabled}
        error={error}
        onFocus={onFocus}
        label={label}
        helperText={helperText}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        inputProps={{ inputMode: 'tel' }}
        InputProps={{
          style: {
            fontSize: "16px",
            fontStyle: "normal",
            fontFamily: "Arial,Helvetica,sans-serif",
            height: 30,
            padding: "5px",
            ...styleProps
          },
          ...otroProps
        }}
        InputLabelProps={{
          classes: {
            root: (value ? classes.labelZero : classes.labelRoot),
            focused: classes.labelFocused
          }
        }}
      /> : ( numericKeyboard ?  
        <TextField
          variant="outlined"
          value={value}
          disabled={disabled}
          error={error}
          onFocus={onFocus}
          label={label}
          helperText={helperText}
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          inputProps={{ inputMode: 'decimal' }}
          InputProps={{
            style: {
              fontSize: "16px",
              fontStyle: "normal",
              fontFamily: "Arial,Helvetica,sans-serif",
              height: 30,
              padding: "5px",
              ...styleProps
            },
            ...otroProps
          }}
          InputLabelProps={{
            classes: {
              root: (value ? classes.labelZero : classes.labelRoot),
              focused: classes.labelFocused
            }
          }}
        /> : ( editInput ?  
          <TextField
            variant="outlined"
            value={value}
            disabled={disabled}
            error={error}
            onFocus={onFocus}
            label={label}
            helperText={helperText}
            onChange={onChange}
            onBlur={onBlur}
            type={type}
            style={{paddingRight: 5}}
            InputProps={{
              style: {
                ...styleProps
              },
              ...otroProps
            }}
            inputProps={{
              style:{
                textAlign: 'center',
                padding: 1
              },
              inputMode: 'decimal' 
            }}
            InputLabelProps={{
              classes: {
                root: (value ? classes.labelZero : classes.labelRoot),
                focused: classes.labelFocused
              }
            }}
          /> : 
      (normalProps ? 
      <TextField
        variant="outlined"
        value={value}
        disabled={disabled}
        fullWidth
        error={error}
        label={label}
        helperText={helperText}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        inputProps={{ inputMode: 'tel' }}
        InputProps={{
          style: {
            fontSize: "16px",
            fontStyle: "normal",
            fontFamily: "Arial,Helvetica,sans-serif",
          },
        }}
      /> : <TextField
      variant="outlined"
      value={value}
      disabled={disabled}
      fullWidth
      error={error}
      label={label}
      helperText={helperText}
      onChange={onChange}
      onBlur={onBlur}
      type={type}
      inputProps={{ inputMode: 'tel' }}
      InputProps={{
        style: {
          fontSize: "16px",
          fontStyle: "normal",
          fontFamily: "Arial,Helvetica,sans-serif",
          height: 30,
          padding: "5px",
          ...styleProps
        },
        ...otroProps
      }}
      InputLabelProps={{
        // shrink: true,
        style :{
          ...labelProps
        },
       
        classes: {
          root: (value ? classes.labelZero : classes.labelRoot),
          focused: classes.labelFocused
        }
      }}
    />
      ))))
    )
  )
};

export default NumberFormattedInput;
