import React, { useReducer, createContext } from 'react';

import contextReducer from './contextReducer';

const initialState = null;

export const ContactContext = createContext(initialState);

export const ContactProvider = ({ children }) => {
    const [appContact, dispatch] = useReducer(contextReducer, initialState);

    // Action Creators
    const deleteContact = () => dispatch({ type: 'DELETE_CONTACT' });
    const addContact = (appContact) => dispatch({ type: 'ADD_CONTACT', payload: appContact });

    return (
        <ContactContext.Provider value={{
            appContact,
            deleteContact,
            addContact
        }}>
            {children}
        </ContactContext.Provider>
    );
};
