import React, { useCallback, useState, useEffect } from 'react';
import { listS3documents } from '../../graphql/queries';
import { useQuery, gql } from '@apollo/client';
import { isImage, isPdf } from './util';
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { onCreateS3document, onUpdateS3document } from '../../graphql/subscriptions';
import Session from '../../session';
import Storage from './fileStore';
import { useAPI } from '../../utils/api';
import { makeStyles } from "@mui/styles";
import {CircularProgress, Backdrop} from '@mui/material';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(theme => ({
  attachment: {
    maxHeight:'280px',
    maxWidth: "100%",
    padding: "5px 0px",
    objectFit: 'contain',
    "&:hover": {
      cursor: "pointer"
    }
  },
  imageLoad: {
    padding: "28% 40%",
    display: "inline-block",
    margin: "auto",
    '& svg': {
      color: '#47AFEB',
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
  },
}));

export const S3Document = ({ fileUlid, id, style }) => {
  const { subscribeToMore, ...listS3Doc} = useQuery(gql(listS3documents), { variables: { filter: { fileUlid: { eq: fileUlid } }, limit: 10000000 } })

  useEffect(() => {
    subscribeToMore({
      document: gql(onCreateS3document),
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        else if (!subscriptionData.data.onCreateS3document) return prev;
        else if (prev.listS3documents.items.some(x => x.id === subscriptionData.data.onCreateS3document.id)) return prev;
        else {
          const newCreateS3Doc = subscriptionData.data.onCreateS3document;
          if (newCreateS3Doc.uploader === Session.UserId && newCreateS3Doc.accessibleOrgs.includes(Session.OrganizationId)) {
            prev.listS3documents.items.push(newCreateS3Doc);
          }
          return prev;
        }
      }
    })
  }, [subscribeToMore])
  
  useEffect(() => {
    subscribeToMore({
      document: gql(onUpdateS3document),
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !subscriptionData.data.onUpdateS3document) {
          return prev
        }

        const updatedS3Doc = subscriptionData.data.onUpdateS3document;
        prev.listS3documents.items = prev.listS3documents.items.map(i => i.id === updatedS3Doc.id ? updatedS3Doc : i);
        return prev;
      }
    })
  }, [subscribeToMore])

  if (listS3Doc.loading) {
    return 'Loading...'
  } else if (listS3Doc.error) {
    return `Error! ${listS3Doc.error.message}`;
  } else if (!listS3Doc.data.listS3documents || !listS3Doc.data.listS3documents.items.length > 0) {
    return ''
  }
  
  return <span style={style} id={id}>{listS3Doc.data.listS3documents.items[0].fileName}</span>;
}

export const DisplayFile = ({ docUlid, docName, style }) => {
  const api = useAPI();
  const classes = useStyles();
  const [dataUri, setDataUri] = useState('');

  const getUrl = useCallback(async (filename, isOpen) => {
    setDataUri('')
    const res = await api.getFetchObjectURL(filename);
    if (res && res.data && res.status === 200) {
      setDataUri(res.data.url);
      if (isOpen) {
        window.open(res.data.url);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function settingData() {
      await getUrl(docUlid, false);
    }
    settingData()
  }, [docUlid, getUrl]);

  const onImageLoad = async (e) => {
    e.persist();
    if (await Storage.existUlid(docUlid)) {
      return;
    }
    const image = e.target;
    const canvas = document.createElement("canvas");    
    canvas.height = image.height;
    canvas.width = image.width;
    const ctx = canvas.getContext("2d");
    
    ctx.drawImage(image,
      0,
      0,
      image.width,
      image.height
    );

    const reader = new FileReader();
    canvas.toBlob(blob => {
      reader.readAsDataURL(blob)
      reader.onloadend = async () => {
        await Storage.setItem({
          fileUlid: docUlid,
          dataUri: reader.result
        });
      }
    });
  }

  return (dataUri && dataUri.length > 0) ?
    (
      isImage(docUlid) ? (
        <div style={style}>
          <img className={classes.attachment} onClick={() => getUrl(docUlid,true)} src={dataUri} alt="" onLoad={onImageLoad} crossOrigin="anonymous" />
        </div>
        ) : (
        isPdf(docUlid) ? (
          <div style={{
            height: '100px'
          }}>
          <PdfPreview onClick={() => getUrl(docUlid,true)} file={dataUri} docUlid={docUlid} style={style} />
          </div>
        ) : (
          <div className="file-div">
            <img className={classes.attachment} onClick={() => getUrl(docUlid,true)} src="/file-download-solid.svg" alt="" style={{ borderRadius: '0px' }} />
            &nbsp;&nbsp; <p style={{ margin: '0px' }} >{docName}</p>
          </div>
        )
      )
    ) : (
      <Backdrop
        className={classes.backdrop}
        open={true}
      >
        <CircularProgress />
      </Backdrop>
    )
}


export const PdfPreview = ({ file, docUlid, style, onClick }) => {
  const [numPages, setNumPages] = useState(null);
  const [stringBase, setStringBase] = useState('');
  const classes = useStyles();

  useEffect(() => {
    const reader = new FileReader();

    const addFileInStorage = async () => {
      setStringBase(reader.result);
      await Storage.setItem({
        fileUlid: docUlid,
        dataUri: reader.result
      });
    }

    async function main() {
      if (file instanceof Blob) {
        setStringBase(file);
      } else {
        reader.addEventListener("load", addFileInStorage, false);  
        if (file) {
          fetch(file).then(response => {
            response.blob().then(data => {
              let blob = new Blob([data], { type: data.type });
              reader.readAsDataURL(blob);
            });
          });
        }
      }
    }
    main()

    return () => {
      setStringBase('');
      reader.removeEventListener("load", addFileInStorage, false);
    }
  }, [file, docUlid])

  return (<>
    {!stringBase && (
      <Backdrop
        className={classes.backdrop}
        open={true}
      >
        <CircularProgress />
      </Backdrop>
    )}
    {stringBase && (
      <div style={{height:'100px'}}>
      <Document
        file={stringBase}
        style={{
          height: '400px'
        }}
        onClick={onClick}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        loading={
          <Backdrop
            className={classes.backdrop}
            open={true}
          >
            <CircularProgress />
          </Backdrop>
        }
        error={
          <Backdrop
          className={classes.backdrop}
          open={true}
        >
          <CircularProgress />
        </Backdrop>
        }
      >
        {Array.from(
          new Array(numPages),
          (_, index) => (
            <Page
              style={{
                height: '100px'
              }}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ),
        )}
      </Document>
      </div>
    )}
  </>);
}
