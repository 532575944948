// import { datadogRum } from '@datadog/browser-rum';

const logger = () => {
    // const prevent_debug = (process.env.REACT_APP_STAGE === 'prod')
    const prevent_debug = false;
    if (prevent_debug) {
        console.debug = 
        console.info = 
        console.warn = 
        console.trace =
        console.assert = 
        () => {};
        console.log = (a,b) => {
            // datadogRum.addError(a)
            return null;
        }
        console.error = (a,b) => {
            // datadogRum.addError(a)
            return null;
        }
        if (window.console) {
            window.console.info =
            window.console.log = 
            window.console.info = 
            window.console.warn = () => {}
        }
    }
};

export default logger;