import {useEffect} from 'react';
import {useQuery, gql, useSubscription} from '@apollo/client';
import Session from '../session';
import { faxByOrg } from '../graphql/queries';
import { subscribeToFaxesByOrg } from '../graphql/subscriptions';

export const useFaxes = () => {
  const faxNum = JSON.parse(Session.userFaxNum) || [];
  const orgId = Session.OrganizationId
  
  const orgFaxes = useQuery(gql(faxByOrg), {
    variables: { limit: 10000, ulid: orgId, sortDirection: "DESC" }
  })

  let da = orgFaxes?.data?.faxByOrg?.items || []

  let receivedFaxQuery = da.filter(n => {
    return faxNum.includes(n.to) 
  })
  let sentFaxQuery = da.filter(n => {
    return faxNum.includes(n.from)
  })

  const { data: customSubEvent } = useSubscription(gql(subscribeToFaxesByOrg), {
    variables: {
      ulid: orgId
    }
  })
    
  useEffect(() => {
    if (customSubEvent) {
      // map if exists;
      const customSubEventIso = customSubEvent.subscribeToFaxesByOrg
      const customSubEventIsoFaxSid = customSubEventIso.faxSid
      const filterIds = da.map(o => o.faxSid);
      if (!filterIds.includes(customSubEventIsoFaxSid)) {
        orgFaxes.refetch()
      }
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customSubEvent]);
  return [receivedFaxQuery, sentFaxQuery]; 
};
