import React, { useState, useEffect }  from "react";
import {
    Dialog,
    Typography,
    DialogContent as MuiDialogContent,
    Snackbar,
    Alert as MuiAlert,
    Button,
    Checkbox,
    Backdrop,
    CircularProgress,
    Grid
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFax } from "@fortawesome/free-solid-svg-icons";
import { createTheme, ThemeProvider} from '@mui/material/styles';
import { withStyles, makeStyles } from '@mui/styles';
import {
    EventAvailable,
    VpnKeyOutlined,
    CurrencyExchangeOutlined,
    MedicalServicesOutlined,
    Business as BusinessIcon,
    VideocamOutlined as VideocamOutlinedIcon,
    SmsOutlined as SmsOutlinedIcon,
    Payment as PaymentIcon,
    AccessTime as AccessTimeIcon,
    ContactsOutlined as ContactsOutlinedIcon,
    CalendarToday as CalendarTodayIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    CheckCircle as CheckCircleIcon,
    ForumOutlined as ForumOutlinedIcon
} from '@mui/icons-material';

import moment from 'moment';
import {useAPI} from '../../utils/api';
import Session from "../../session";

const redTheme = createTheme({ 
    palette: { 
      primary: {
        main: '#F81010' 
      }
    }
  });
  
  const greenTheme = createTheme({ 
    palette: { primary: {
      main: "#10D236",
      contrastText: "#fff" //button text white instead of black
    }, 
  },
    overrides: {
      MuiButton: {
        containedPrimary: {
          color: 'white',
        },
      },
    }
  })

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: '50px',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '65%',
    },
    serviceContainer:{
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row',
      width: '100%',
    },
    servicesAvaliable: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '65%',
      border: 5,
    },
    includedServices: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '35%',
    },
    manageServiceButtons: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row',
      width: '30%',
      marginTop: 'auto'
    },
    checkMarkNoPadding: {
        margin: '0 5px 0 0',
        padding: 0,
    },
    logoWidth: {
      width: '23vw',
      alignSelf: 'center',
      marginTop: 20
    },
    reminderHeader: {
        fontSize: 8, 
        lineHeight: 1, 
        color: '#4D4F5C'
    },
    reminderText: {
        fontSize: 8, 
        marginLeft: 10, 
        lineHeight: 1, 
        marginRight: 10,
        color: '#4D4F5C'
    },
    backdrop: {
        zIndex: 9999,
    },
  }))

const AccountServicesModals = ({
    modalOpen,
    handlerModal,
    activeServices,
    includedServices,
    additionalServices,
    servicesComingSoon,
    usage
  }) => {
    const classes = useStyles();
    const api = useAPI();

    const [newActiveServices, setNewActiveServices] = useState([]);
    const [newAdditionalServices, setNewAdditionalServices] = useState([]);
    const [servicesAdded, setServicesAdded] = useState([]);
    const [servicesRemoved, setServicesRemoved] = useState([]);
    const [removedSubscriptionCost, setRemovedSubscriptionCost] = useState(0);
    const [addedSubscriptionCost, setAddedSubscriptionCost] = useState(0);
    const [totalSubscriptionCost, setTotalSubscriptionCost] = useState(0);
    const [proratedPrice, setProratedPrice] = useState(0);
    const [billingDate, setBillingDate] = useState();
    const [subscriptionData, setSubscriptionData] = useState();
    const [confirmPayment, setConfirmPayment] = useState(false);
    const [servicesUpdated, setServicesUpdated] = useState(false);
    const [additionalEfax, setAdditionalEfax] = useState(false);
    const [additionalSms, setAdditionalSms] = useState(false);

    const [severity, setSeverity] = useState('success');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);

    const [modalState, setModalState] = useState(1);
    // const [updatedServices, setUpdatedServices] = useState(false);

    const handleClose = () => { 
        setModalState(1);
        handlerModal(false);
        setConfirmPayment(false);
        let updatedActiveServices = newActiveServices.map(obj => {
            let temp = Object.assign({}, obj);
            temp.value = true
            return temp
        })
        let updatedAdditionalServices = newAdditionalServices.map(obj => {
            let temp = Object.assign({}, obj);
            temp.value = false
            return temp
        })
        setServicesUpdated(false);
        setNewActiveServices(updatedActiveServices);
        setNewAdditionalServices(updatedAdditionalServices);
    }

    const handleBack = () => {
        setModalState(1);
        let updatedActiveServices = newActiveServices.map(obj => {
            let temp = Object.assign({}, obj);
            temp.value = true
            return temp
        })
        let updatedAdditionalServices = newAdditionalServices.map(obj => {
            let temp = Object.assign({}, obj);
            temp.value = false
            return temp
        })
        setConfirmPayment(false);
        setServicesUpdated(false);
        setNewActiveServices(updatedActiveServices);
        setNewAdditionalServices(updatedAdditionalServices);
    }

    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    }

    useEffect(() => {
        let updatedActiveServices = activeServices.filter(obj => obj.service !== 'TeleHealth')
        let updatedAdditionalServices = additionalServices.filter(obj => obj.service !== 'TeleHealth')
        
        // console.log(newAdditionalServices)
        setNewActiveServices(updatedActiveServices);
        setNewAdditionalServices(updatedAdditionalServices);
                
    // eslint-disable-next-line
    },[activeServices, additionalServices])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        if(width <= 768){
          setIsMobile(true)
          // window.FreshworksWidget('hide');
        }
        return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);


    useEffect(() => {
        if(newAdditionalServices){
            let addedServices = newAdditionalServices.filter(object => object.value === true)
            let addedCost = 0
            addedServices.map(object => {
                return addedCost += object.price
            })
            
            calculateProation(addedCost)
            setTotalSubscriptionCost(addedCost - removedSubscriptionCost)
            setAddedSubscriptionCost(addedCost)
            setServicesAdded(addedServices)
        }
        // eslint-disable-next-line
    }, [newAdditionalServices]);
    

    useEffect(() => {
        if(newActiveServices){
            let removedServices = newActiveServices.filter(object => object.value === false)
            console.log(removedServices)
            let removedCost = 0
            let additonalEfaxCost = 0
            let additonalSmsCost = 0
            removedServices.map(object => {
                return removedCost += object.price
            })
            if(removedServices.length>0 && removedServices[0].service.toLowerCase() === 'efax'){
                const isAddtionlaEfax = usage.filter(x=>x.key === 'additionalefax');
                if(isAddtionlaEfax != null && isAddtionlaEfax.length>0){
                    setAdditionalEfax(true);
                    additonalEfaxCost = 2 * isAddtionlaEfax[0].quantity
                    removedCost +=additonalEfaxCost
                }
            }
            if(removedServices.length>0 && removedServices[0].service.toLowerCase() === 'sms'){
                const isAddtionlaSMS = usage.filter(x=>x.key === 'additionalsms');
                if(isAddtionlaSMS != null && isAddtionlaSMS.length>0){
                    setAdditionalSms(true);
                    additonalSmsCost = 2 * isAddtionlaSMS[0].quantity
                    removedCost +=additonalSmsCost
                }
            }
            setTotalSubscriptionCost(addedSubscriptionCost - removedCost)
            setRemovedSubscriptionCost(removedCost)
            setServicesRemoved(removedServices)
        }
        // eslint-disable-next-line
    }, [newActiveServices]);

    useEffect(() => {
        let addedServices = []
        if(newAdditionalServices) {
            addedServices = newAdditionalServices.filter(object => object.value === true)
        }
        let removedServices = []
        if(newActiveServices) {
            removedServices = newActiveServices.filter(object => object.value === false)
        }
        console.log(addedServices)
        console.log(addedServices.length)
        console.log(removedServices)
        console.log(removedServices.length)

        if(addedServices.length > 0 || removedServices.length > 0){
            console.log('true')
            setServicesUpdated(true)
        } else {
            console.log('false')
            setServicesUpdated(false)
        }
    }, [newAdditionalServices, newActiveServices])

    useEffect(() => {
        // Create an scoped async function in the hook
        async function grabBillingDate() {
            const result = await api.getSubscriptionDetail(Session.OrganizationId);
            const date = new Date(result.data.current_period_end * 1000)
            // const day = date.getDate()
            setBillingDate(date);
            setSubscriptionData(result.data);
        }
        grabBillingDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const calculateProation = (addedPayment) => {

        let periodEnd = moment(billingDate)
        let periodStart = moment(billingDate).subtract(1, 'months')
        
        let remaningTime = periodEnd.diff(moment(), 'seconds') / 86400
        let fullPeriod = periodEnd.diff(periodStart, 'seconds') / 86400

        let peroratedPrice = remaningTime / fullPeriod * addedPayment
        
        setProratedPrice(peroratedPrice.toFixed(2))
      }

    useEffect(() => {
        setIsOpen(modalOpen);
    }, [modalOpen]);
    
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const DialogContent = withStyles(() => ({
        root: {
            width: "100%",
            display: 'flex',
            flexDirection: 'column'
        }
    }))(MuiDialogContent);


    const handleAdditionalServices = (value, service) => {
        let result = [...newAdditionalServices];
        result = result.map((x) => {
            if(x.service === service) x.value = !value;
            return x
        });
        setNewAdditionalServices(result)
    }

    const handleActiveServices = (value, service) => {
        let result = [...newActiveServices];
        console.log(result)
        result = result.map((x) => {
            if(x.service === service) x.value = !value;
            return x
        });
        console.log(result)
        setNewActiveServices(result)
    }

    const getIcon = (service, fontColor) => {
        switch (service) {
          case 'Efax': return  <FontAwesomeIcon icon={faFax} style={{width: 20, color: fontColor}} />;
          case 'TeleHealth': return <VideocamOutlinedIcon style={{ color: fontColor , width: 20}}/>;
          case 'SMS': return <SmsOutlinedIcon style={{ color: fontColor , width: 20}}/>;
          case 'Payment': return <PaymentIcon style={{ color: fontColor , width: 20}}/>;
          case 'Appointment': return <EventAvailable style={{width: 20, color: fontColor}} />;
          case 'Time Clock': return <AccessTimeIcon style={{ color: fontColor, width: 20 }}/>;
          case 'Contacts': return <ContactsOutlinedIcon style={{ color: fontColor , width: 20}}/>;
          case 'Secure Messaging': return <VpnKeyOutlined style={{ color: fontColor , width: 20}}/>;
          case 'Appointment Scheduler': return <CalendarTodayIcon style={{ color: fontColor , width: 20}}/>;
          case 'Patients': return <img style={{ paddingLeft: 3 , width: 20}} src="/hospital-user-light.svg" alt='avtar' />;
          case 'Patient Portal': return <MedicalServicesOutlined style={{ color: fontColor , width: 20}}/>;
          case 'Chat': return <ForumOutlinedIcon style={{ color: fontColor , width: 20}}/>
          case 'Invoicing': return <CurrencyExchangeOutlined style={{ color: fontColor , width: 20}}/>
          default:
            return <BusinessIcon style={{width: 20, color: fontColor}} />;
        }
      }

    const handleUpdate = async () => {
        setLoading(true);
        console.log("Services being added:")
        console.log(servicesAdded)
        
        console.log("Services being removed:")
        console.log(servicesRemoved)
        let deleted = [];
        let tempPlan = Session.OrgPlan;
        let added = [];
        if(servicesRemoved.length>0){
            // eslint-disable-next-line array-callback-return
            servicesRemoved.map(el=>{
                deleted.push(el.service.toLowerCase());
                tempPlan = tempPlan.filter(x=>x !== el.service.toLowerCase());
            });
        }
        if(servicesAdded.length >0)
        {
            // eslint-disable-next-line array-callback-return
            servicesAdded.map(el=>{
                tempPlan.push(el.service.toLowerCase());
                added.push(el.service.toLowerCase());
            });
        }
        const obj = {
            plan:tempPlan,
            deletedPlan:deleted,
            addedPlan:added
        }
        const subscription = await api.UpdateOrgPlan(obj);
        console.log(subscription)
        if (subscription.status === 200) {
            setModalState(3);
        } else {
            setSeverity('error');
            setSnackBarMessage("Subscription Update Failed!");
            setOpenSnackBar(true);
        }        
        setLoading(false);
        console.log(obj);
    }

    return (
        <>
        {loading  && <>
                <Backdrop
                    className={classes.backdrop}
                    open={true}
                >
                    <CircularProgress />
                </Backdrop>
            </>}
            <Snackbar
                open={openSnackBar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={6000}
            >
                <Alert 
                severity={severity}
                sx={{ width: '100%' }}
                >
                {snackBarMessage}
                </Alert>
            </Snackbar>
            {modalState === 1 && <Dialog 
                open={modalIsOpen} 
                disableBackdropClick={true} 
                disableEscapeKeyDown
                fullScreen={isMobile}
                onClose={handleClose} 
                fullWidth
                maxWidth="sm"
            >
                <DialogContent>
                    {isMobile ? <>
                <Typography style={{margin: 10}} align='center' variant='h6'>Manage Your Services </Typography>
                <Typography style={{marginBottom: 10}} align='center' variant="subtitle2" >Add or modify your services below.</Typography>
                <Grid container style={{fontSize:13, marginBottom: '15px'}} justifyContent="space-between" spacing={0.5}>
                <Grid style={{marginBottom: '5px', marginTop:'5px'}} item xs={12} sm={6}>
                <Typography variant="subtitle2" style={{ color: "#707070" }} >Select the services you want to add (Per Month):</Typography>
                        {newAdditionalServices?.length > 0 && <>
                        {newAdditionalServices.map((s, id) => {
                            return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                            <div style={{width: '60%', display: 'flex'}}> 
                                {getIcon(s.service, "#707070" )} 
                                <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#4D4F5C"}}>{s.service}</Typography>
                            </div>
                            <div style={{width: '40%', display: 'flex'}}> 
                                <Checkbox 
                                    className={classes.checkMarkNoPadding} 
                                    checked={s.value} 
                                    onClick={() => {
                                        handleAdditionalServices(s.value, s.service)
                                    }}
                                />
                                <Typography variant="subtitle2" style={{color: "#707070"}}>{`$${s.price.toFixed(2)}`}</Typography>
                            </div>
                            </div>)
                        })}
                        </>}
                        {servicesComingSoon.map((s, id) => {
                            return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                            <div style={{width: '60%', display: 'flex'}}> 
                                {getIcon(s.service, "#d3d3d3" )} 
                                <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#d3d3d3"}}>{s.service}</Typography>
                            </div>
                            <div style={{width: '40%', display: 'flex'}}> 
                                <CheckBoxOutlineBlankIcon style={{ color: "#d3d3d3",  margin: '0 5px 0 0', padding: 0 }}/>
                                <Typography variant="subtitle2"style={{ color: "#d3d3d3" }}>{s.text}</Typography>
                            </div>
                            </div>)
                        })}
                </Grid>
                <Grid style={{marginBottom: '5px', marginTop:'5px'}} item xs={12} sm={6}>
                {newActiveServices?.length > 0 && <>
                            <Typography variant="subtitle2" style={{marginTop: 20, color: "#707070"}}>Active Services (Per Month):</Typography>
                            {newActiveServices.map((s, id) => {
                                return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                                <div style={{width: '60%', display: 'flex'}}> 
                                    {getIcon(s.service, '#47AFEB')} 
                                    <Typography variant="subtitle2" style={{marginLeft: 7.5, color: '#4D4F5C'}}>{s.service}</Typography>
                                </div>
                                <div style={{width: '40%', display: 'flex'}}> 
                                    <Checkbox 
                                        className={classes.checkMarkNoPadding} 
                                        checked={s.value} 
                                        onClick={() => {
                                            handleActiveServices(s.value, s.service)
                                        }}
                                    />
                                    <Typography variant="subtitle2" style={{ color: "#707070" }}>{`$${s.price.toFixed(2)}`}</Typography>
                                </div>
                                </div>)
                            })}
                        </>
                        }
                </Grid>
                <Grid style={{marginBottom: '5px', marginTop:'5px'}} item xs={12} sm={6}>
                <Typography variant="subtitle2" style={{ color: "#707070" }} >Included with all Services</Typography>
                    {includedServices.map((s, id) => {
                            return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                            <div style={{width: '70%', display: 'flex'}}> 
                                {getIcon(s.service, '#47AFEB')} 
                                <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#707070"}}>{s.service}</Typography>
                            </div>
                            <div style={{width: '30%', display: 'flex'}}> 
                                <CheckCircleIcon style={{ color: "#10D236", width: 20 }}/>
                            </div>
                            </div>)
                        })}
                </Grid>
                <Grid style={{marginBottom: '5px', marginTop:'5px', textAlign:'center'}} item xs={12} sm={6}>
                <Button color="primary" variant="contained" style={{marginRight: 5}} disabled={!servicesUpdated} onClick={() => {setModalState(2)}}>          
                            <Typography variant="subtitle2">Next</Typography>
                        </Button>
                        <ThemeProvider theme={redTheme}>
                            <Button variant="outlined" color="primary" onClick={handleClose}>          
                                <Typography variant="subtitle2" >Cancel</Typography>
                            </Button>
                        </ThemeProvider>
                </Grid>
                <Grid style={{marginBottom: '5px', marginTop:'5px'}} item xs={12} sm={6}>
                <Typography variant="subtitle2" className={classes.reminderHeader}>Reminder:</Typography>
                        <Typography variant="subtitle2" className={classes.reminderText}>Efax plan includes 500 total (sent/received) pages then $0.08 per page and 1 efax number.</Typography>                           
                        <Typography variant="subtitle2" className={classes.reminderText}>Additional Efax numbers can be purchased for $3.00 monthly.</Typography>                        
                        <Typography variant="subtitle2" className={classes.reminderText}>SMS plan includes 1000 Total (sent/received) messages then $0.04 per message and 1 SMS number. Additional SMS numbers can be purchased for $3.00 monthly.</Typography>
                        <Typography variant="subtitle2" className={classes.reminderText}>Payment Center processing fees are 2.9% + $0.30 per a transaction.</Typography>                        
                </Grid>
                </Grid>
                    </> :
                    <>
                <Typography style={{margin: 10}} align='center' variant='h4'>Manage Your Services </Typography>
                <Typography style={{marginBottom: 10}} align='center' variant="subtitle2" >Add or modify your services below.</Typography>
                <div className={classes.serviceContainer}>
                    <div className={classes.servicesAvaliable}>
                    <Typography variant="subtitle2" style={{ color: "#707070" }} >Select the services you want to add (Per Month):</Typography>
                        {newAdditionalServices?.length > 0 && <>
                        {newAdditionalServices.map((s, id) => {
                            return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                            <div style={{width: '60%', display: 'flex'}}> 
                                {getIcon(s.service, "#707070" )} 
                                <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#4D4F5C"}}>{s.service}</Typography>
                            </div>
                            <div style={{width: '40%', display: 'flex'}}> 
                                <Checkbox 
                                    className={classes.checkMarkNoPadding} 
                                    checked={s.value} 
                                    onClick={() => {
                                        handleAdditionalServices(s.value, s.service)
                                    }}
                                />
                                <Typography variant="subtitle2" style={{color: "#707070"}}>{`$${s.price.toFixed(2)}`}</Typography>
                            </div>
                            </div>)
                        })}
                        </>}
                        {servicesComingSoon.map((s, id) => {
                            return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                            <div style={{width: '60%', display: 'flex'}}> 
                                {getIcon(s.service, "#d3d3d3" )} 
                                <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#d3d3d3"}}>{s.service}</Typography>
                            </div>
                            <div style={{width: '40%', display: 'flex'}}> 
                                <CheckBoxOutlineBlankIcon style={{ color: "#d3d3d3",  margin: '0 5px 0 0', padding: 0 }}/>
                                <Typography variant="subtitle2"style={{ color: "#d3d3d3" }}>{s.text}</Typography>
                            </div>
                            </div>)
                        })}
                        {newActiveServices?.length > 0 && <>
                            <Typography variant="subtitle2" style={{marginTop: 20, color: "#707070"}}>Active Services (Per Month):</Typography>
                            {newActiveServices.map((s, id) => {
                                return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                                <div style={{width: '60%', display: 'flex'}}> 
                                    {getIcon(s.service, '#47AFEB')} 
                                    <Typography variant="subtitle2" style={{marginLeft: 7.5, color: '#4D4F5C'}}>{s.service}</Typography>
                                </div>
                                <div style={{width: '40%', display: 'flex'}}> 
                                    <Checkbox 
                                        className={classes.checkMarkNoPadding} 
                                        checked={s.value} 
                                        onClick={() => {
                                            handleActiveServices(s.value, s.service)
                                        }}
                                    />
                                    <Typography variant="subtitle2" style={{ color: "#707070" }}>{`$${s.price.toFixed(2)}`}</Typography>
                                </div>
                                </div>)
                            })}
                        </>
                        }
                    </div>
                    <div className={classes.includedServices}>
                    <Typography variant="subtitle2" style={{ color: "#707070" }} >Included with all Services</Typography>
                    {includedServices.map((s, id) => {
                            return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                            <div style={{width: '85%', display: 'flex'}}> 
                                {getIcon(s.service, '#47AFEB')} 
                                <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#707070"}}>{s.service}</Typography>
                            </div>
                            <div style={{width: '15%', display: 'flex'}}> 
                                <CheckCircleIcon style={{ color: "#10D236", width: 20 }}/>
                            </div>
                            </div>)
                        })}
                    </div>
                </div>
                <div style={{display: 'flex', marginTop: 20}}>
                    <div style={{width: '70%'}}>
                        <Typography variant="subtitle2" className={classes.reminderHeader}>Reminder:</Typography>
                        <Typography variant="subtitle2" className={classes.reminderText}>Efax plan includes 500 total (sent/received) pages then $0.08 per page and 1 efax number.</Typography>                           
                        <Typography variant="subtitle2" className={classes.reminderText}>Additional Efax numbers can be purchased for $3.00 monthly.</Typography>                        
                        <Typography variant="subtitle2" className={classes.reminderText}>SMS plan includes 1000 Total (sent/received) messages then $0.04 per message and 1 SMS number. Additional SMS numbers can be purchased for $3.00 monthly.</Typography>
                        <Typography variant="subtitle2" className={classes.reminderText}>Payment Center processing fees are 2.9% + $0.30 per a transaction.</Typography>                        
                    </div>
                    <div style={{width: '30%', display: 'flex', justifyContent: 'center', alignSelf: 'flex-end'}}>
                        <Button color="primary" variant="contained" style={{marginRight: 5}} disabled={!servicesUpdated} onClick={() => {setModalState(2)}}>          
                            <Typography variant="subtitle2">Next</Typography>
                        </Button>
                        <ThemeProvider theme={redTheme}>
                            <Button variant="outlined" color="primary" onClick={handleClose}>          
                                <Typography variant="subtitle2" >Cancel</Typography>
                            </Button>
                        </ThemeProvider>
                    </div>
                </div>
                </>}
                </DialogContent>
            
            </Dialog>}

            {modalState === 2 && <Dialog 
                open={modalIsOpen} 
                disableBackdropClick={true} 
                disableEscapeKeyDown
                fullScreen={isMobile}
                onClose={handleClose} 
                fullWidth
                maxWidth="sm"
            >
                <DialogContent>
                    {isMobile ? <> 
                        <Typography style={{margin: 10}} align='center' variant='h6'>Manage Your Services </Typography>
                        <Typography variant="subtitle1" style={{ color: "#707070", fontSize:'13px' }}>Confirm the following updates to your account</Typography>
                        <Grid container style={{fontSize:13, marginBottom: '15px'}} justifyContent="space-between" spacing={0.5}>
                        <Grid style={{marginBottom: '5px', marginTop:'5px'}} item xs={12} sm={6}>
                        {servicesAdded.length > 0 && <>
                            <Typography variant="subtitle2" style={{marginBottom: 10, marginTop: 10 , color: "#707070" , fontSize:'13px' }}>Charges to be added to your monthly subscription</Typography>
                            {servicesAdded.map((s, id) => {
                                return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                                <div style={{width: '50%', display: 'flex'}}> 
                                    {getIcon(s.service, "#47AFEB")} 
                                    <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#707070" }}>{s.service}</Typography>
                                </div>
                                    <div style={{width: '50%', display: 'flex'}}>                                 
                                        <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#707070" }}>{`+ $${s.price.toFixed(2)}`}</Typography>
                                    </div>
                                </div>)
                            })}
                            {subscriptionData && subscriptionData.status === 'active' ?
                            <Typography variant="subtitle2" style={{marginBottom: 10, marginTop: 10, color: "#707070" }}>{`A prorated charge of $${proratedPrice} will be billed for current month immediately.`}</Typography>:
                             null}
                        </>
                        }
                        </Grid>
                        <Grid style={{marginBottom: '5px', marginTop:'5px'}} item xs={12} sm={6}>
                        {servicesRemoved.length > 0 && <>
                            <Typography variant="subtitle2" style={{marginBottom: 10, marginTop: 10, color: "#707070" }}><span style={{color: '#F81010'}}>Remove Access</span> to the following Services and update the next monthly subscription. All usage history will be removed with this service.</Typography>
                            {servicesRemoved.map((s, id) => {
                                return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                                <div style={{width: '50%', display: 'flex'}}> 
                                    {getIcon(s.service, '#F81010')} 
                                    <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#707070" }}>{s.service}</Typography>
                                </div>
                                {s.service.toLowerCase() === 'efax' && additionalEfax ? <div style={{width: '50%', display: 'flex'}}>                                 
                                        <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#707070" }}>{`- $${(s.price + (2* usage.filter(x=>x.key === 'additionalefax')[0].quantity)).toFixed(2)}`}</Typography>
                                    </div> :
                                    s.service.toLowerCase() === 'sms' && additionalSms ? <div style={{width: '50%', display: 'flex'}}>                                 
                                    <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#707070" }}>{`- $${(s.price + (2* usage.filter(x=>x.key === 'additionalsms')[0].quantity)).toFixed(2)}`}</Typography>
                                </div> :
                                    <>
                                    <div style={{width: '50%', display: 'flex'}}>                                 
                                        <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#707070" }}>{`- $${s.price.toFixed(2)}`}</Typography>
                                    </div>
                                    </>}
                                </div>)
                            })}
                        </>
                        }
                        </Grid>
                        <Grid style={{marginBottom: '5px', marginTop:'5px'}} item xs={12} sm={6}>
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 15, alignItems: 'center', width: '90%'}}>
                            <Checkbox 
                                checked={confirmPayment}
                                disabled={loading}
                                style={{margin: '0 5px 0 0', padding: 0}}
                                onChange={() => setConfirmPayment(!confirmPayment)} 
                                name="Timeclock" 
                            />
                            <Typography align='left' variant='subtitle2' style={{ color: "#707070",fontSize: 13}}>{`I agree to have $${Math.abs(totalSubscriptionCost).toFixed(2)} ${totalSubscriptionCost > 0 ? 'added to' : 'removed from'} the monthly subscription.`}</Typography>
                        </div>
                        <Typography variant="subtitle2" style={{color: "#d3d3d3", fontSize: 10, marginTop: 10}}>*** Billing for your account will be updated to reflect these changes</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{textAlign:"center"}}>
                        <Button variant="outlined" color="primary" style={{marginRight: 5}} onClick={() => {
                        setModalState(1)}
                        }>          
                            <Typography variant="subtitle2" onClick={handleBack}>Back</Typography>
                        </Button>

                        <ThemeProvider theme={greenTheme}>
                        <Button variant="contained" color="primary" disabled={!confirmPayment} onClick={handleUpdate}>          
                            <Typography variant="subtitle2" >{servicesAdded.length > 0 ? 'Purchase' : 'Confirm'}</Typography>
                        </Button>
                        </ThemeProvider>
                        </Grid>
                        </Grid>
                    </> :
                    <>
                <Typography style={{margin: 10}} align='center' variant='h4'>Manage Your Services </Typography>
                <Typography variant="subtitle1" style={{ color: "#707070" }}>Confirm the following updates to your account</Typography>
                <div className={classes.serviceContainer}>
                    <div className={classes.servicesAvaliable}>
                        {servicesAdded.length > 0 && <>
                            <Typography variant="subtitle2" style={{marginBottom: 10, marginTop: 10 , color: "#707070"  }}>Charges to be added to your monthly subscription</Typography>
                            {servicesAdded.map((s, id) => {
                                return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                                <div style={{width: '50%', display: 'flex'}}> 
                                    {getIcon(s.service, "#47AFEB")} 
                                    <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#707070" }}>{s.service}</Typography>
                                </div>
                                    <div style={{width: '50%', display: 'flex'}}>                                 
                                        <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#707070" }}>{`+ $${s.price.toFixed(2)}`}</Typography>
                                    </div>
                                </div>)
                            })}
                            {subscriptionData && subscriptionData.status === 'active' ?
                            <Typography variant="subtitle2" style={{marginBottom: 10, marginTop: 10, color: "#707070" }}>{`A prorated charge of $${proratedPrice} will be billed for current month immediately.`}</Typography>:
                             null}
                        </>
                        }
                        {servicesRemoved.length > 0 && <>
                            <Typography variant="subtitle2" style={{marginBottom: 10, marginTop: 10, color: "#707070" }}><span style={{color: '#F81010'}}>Remove Access</span> to the following Services and update the next monthly subscription. All usage history will be removed with this service.</Typography>
                            {servicesRemoved.map((s, id) => {
                                return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                                <div style={{width: '50%', display: 'flex'}}> 
                                    {getIcon(s.service, '#F81010')} 
                                    <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#707070" }}>{s.service}</Typography>
                                </div>
                                {s.service.toLowerCase() === 'efax' && additionalEfax ? <div style={{width: '50%', display: 'flex'}}>                                 
                                        <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#707070" }}>{`- $${(s.price + (2* usage.filter(x=>x.key === 'additionalefax')[0].quantity)).toFixed(2)}`}</Typography>
                                    </div> :
                                    s.service.toLowerCase() === 'sms' && additionalSms ? <div style={{width: '50%', display: 'flex'}}>                                 
                                    <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#707070" }}>{`- $${(s.price + (2* usage.filter(x=>x.key === 'additionalsms')[0].quantity)).toFixed(2)}`}</Typography>
                                </div> :
                                    <>
                                    <div style={{width: '50%', display: 'flex'}}>                                 
                                        <Typography variant="subtitle2" style={{marginLeft: 7.5, color: "#707070" }}>{`- $${s.price.toFixed(2)}`}</Typography>
                                    </div>
                                    </>}
                                </div>)
                            })}
                        </>
                        }
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 15, alignItems: 'center', width: '90%'}}>
                            <Checkbox 
                                checked={confirmPayment}
                                disabled={loading}
                                style={{margin: '0 5px 0 0', padding: 0}}
                                onChange={() => setConfirmPayment(!confirmPayment)} 
                                name="Timeclock" 
                            />
                            <Typography align='left' variant='subtitle2' style={{ color: "#707070",fontSize: 13}}>{`I agree to have $${Math.abs(totalSubscriptionCost).toFixed(2)} ${totalSubscriptionCost > 0 ? 'added to' : 'removed from'} the monthly subscription.`}</Typography>
                        </div>
                        <Typography variant="subtitle2" style={{color: "#d3d3d3", fontSize: 10, marginTop: 10}}>*** Billing for your account will be updated to reflect these changes</Typography>

                    </div>
                    <div className={classes.manageServiceButtons}>

                    <Button variant="outlined" color="primary" style={{marginRight: 5}} onClick={() => {
                        setModalState(1)}
                        }>          
                            <Typography variant="subtitle2" onClick={handleBack}>Back</Typography>
                        </Button>

                        <ThemeProvider theme={greenTheme}>
                        <Button variant="contained" color="primary" disabled={!confirmPayment} onClick={handleUpdate}>          
                            <Typography variant="subtitle2" >{servicesAdded.length > 0 ? 'Purchase' : 'Confirm'}</Typography>
                        </Button>
                        </ThemeProvider>
                    </div>
                </div>
                </>}
                </DialogContent>
            </Dialog>}
            {modalState === 3 && <Dialog 
                open={modalIsOpen} 
                disableBackdropClick={true} 
                disableEscapeKeyDown
                fullScreen={isMobile}
                onClose={() => {window.location.reload()}} 
                fullWidth
            >
                <DialogContent>
                    <Typography style={{margin: 10}} align='center' variant='h4'>Confirmation</Typography>
                    <Typography variant="subtitle1" align='center' style={{color: "#707070" }}>Your account has been updated!</Typography>
                    <img alt="mylogo" src='/ExamRoomLive_Logo_headerLogo.svg' className={classes.logoWidth}/>
                    <div style={{alignSelf: 'flex-end'}}>
                        <ThemeProvider theme={redTheme}>
                            <Button variant="contained" color="primary" onClick={() => {window.location.reload()}}>          
                                    <Typography variant="subtitle2">Close</Typography>
                            </Button>
                        </ThemeProvider>
                    </div>
                </DialogContent>
            </Dialog>}
            {modalState === 4 && <Dialog 
                open={modalIsOpen} 
                onClose={handleClose}
                disableBackdropClick={true} 
                disableEscapeKeyDown
                fullScreen={isMobile}
                >
                <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
                <Typography align='center' variant='h4' style={{ marginBottom: 15}}>Payment Issue</Typography>
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: 15}}>
                        <Typography align='left' variant='subtitle1' style={{marginRight: 10}}>Payment Failed: </Typography>
                        <Typography align='left' variant='subtitle1'>failed reason</Typography>
                    </div>
                    <Typography align='left' variant='subtitle1' style={{marginBottom: 25}}>Please update your card information or contact your bank and try again to process. </Typography>
                        <div style={{justifyContent: 'center', display: 'flex', marginTop: 40}}>
                        <ThemeProvider theme={greenTheme}>
                            <Button variant='contained'> Update Card </Button>
                        </ThemeProvider>
                    </div>
                    
                </DialogContent>
                </Dialog> }
        </>)
  };

export default AccountServicesModals;