import React, { useState, useEffect }  from "react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import {useAPI} from '../../../utils/api';
import { getFullDuration } from "../../../utils/dateTimeFormat";
import NumberFormat from "react-number-format";
import NumberFormattedInput from "../../constant/numberFormattedInput";
import moment from 'moment';
import Session from '../../../session';
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  TextField,
  Backdrop,
  CircularProgress,
  Typography,
  IconButton,
  ThemeProvider,
  createTheme,
  Autocomplete,
  Button,
  Checkbox,
} from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';
import { withStyles, makeStyles } from '@mui/styles';

const styles = (theme) => ({
    root: {
        margin: 0,
        paddingLeft: 15,
        paddingTop: 8,
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const redTheme = createTheme({ 
  palette: { 
    primary: {
      main: '#F81010' 
    }
  }
})
const greenTheme = createTheme({ 
  palette: { primary: {
    main: "#10D236",
    contrastText: "#fff"
  }, 
},
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: 'white',
      },
    },
  }
}) 

const useStyles = makeStyles((theme) => ({
  activityRow: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 15,
    margin: 5,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  saveButtonContainer: {
    marginTop: '15px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  activityText: {
     width: '25%',
     color: '#707070',
     [theme.breakpoints.down('sm')]: {
      width: '33%',
    },
   },
   breakText:{
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
   },
   backdrop: {
     zIndex: 9999,
     position: "absolute",
   },
   editButtons: {
     textTransform: 'none',
     marginLeft: '10px',
     marginRight: '10px',
     fontSize: '13px',
     height: '26px',
     width: '95px',
     [theme.breakpoints.down('md')]: {
       width:'85px',
       height: '25px',
       fontSize: '11px',
     },
     [theme.breakpoints.down('sm')]: {
       height: '25px',
       marginLeft: '3px',
       marginRight: '3px',
       fontSize: '10px',
     },
   },
   cancelButtons: {
     textTransform: 'none',
     marginLeft: '10px',
     fontSize: '13px',
     height: '26px',
     width: '75px',
     [theme.breakpoints.down('md')]: {
       width:'65px',
       height: '25px',
       fontSize: '11px',
     },
     [theme.breakpoints.down('sm')]: {
       height: '25px',
       marginLeft: '3px',
       fontSize: '10px',
     },
   },
   expenseRow: {
    marginTop: '10px',
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '90%',
    justifyContent: 'space-between'
   },
   notesRow: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
   },
   labelRoot: {
     top: '-12.5px',
     "&$labelFocused": {
       top: 0
     }
   },
   labelZero: {
     top: 0,
     "&$labelFocused": {
       top: 0
     }
   },
   labelFocused: {
     top: 0
   },
   inputRow: {
     marginTop: 10,
     marginBottom: 15,
   },
   dateRow: {
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center'
   },
   breakContainer: {
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center'
   }
}));

const DialogContent = withStyles(() => ({
    root: {
      width: "100%",
      padding: 30
    }
  }))(MuiDialogContent);

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6">{children}</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
      </MuiDialogTitle>
    );
  });

const AddEntryModal = ({open, handleEntryModal, employees, handlerReload, handleSnackbar}) => {
    const classes = useStyles();
    const api = useAPI();

    const [list, setList] = useState(null);
    const [employee, setEmployee] = useState();
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(new Date());
    const [clockIn, setClockIn] = useState();
    const [clockOut, setClockOut] = useState();
    const [startBreak, setStartBreak] = useState();
    const [endBreak, setEndBreak] = useState();
    const [timeWorked, setTimeWorked] = useState();
    const [timeBreak, setTimeBreak] = useState();
    const [addBreak, setAddBreak] = useState(false);
    const [mileage, setMileage] = useState()
    const [expenses, setExpenses] = useState()
    const [notes, setNotes] = useState()

    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);

    const [, setRand] = useState(null);
    const random = () => (Math.floor(Math.random() * (99999 - 99)) + 99);

    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };

    const handleClose = () => {
      setLoading(false);
      setTimeWorked('');
      setTimeBreak('');
      setEmployee(null);
      setDate(new Date());
      setClockIn('');
      setClockOut('');
      setStartBreak('');
      setEndBreak('');
      setAddBreak(false);
      setMileage(null);
      setExpenses(null);
      setNotes(null);
      handleEntryModal(false);
    }

    useEffect(() => {
      if(!addBreak && clockIn && clockOut){
        let startHours = parseInt(clockIn.substring(0,2), 10)
        let startMinutes = parseInt(clockIn.substring(3,5), 10)

        let endHours = parseInt(clockOut.substring(0,2), 10)
        let endMinutes = parseInt(clockOut.substring(3,5), 10)
        let duration = ((endHours - startHours) * 3600) + ((endMinutes - startMinutes) * 60)
        setTimeWorked(getFullDuration(duration))
      }
      else if (clockIn && clockOut && endBreak && startBreak && addBreak) {
        let startHours = parseInt(clockIn.substring(0,2), 10)
        let startMinutes = parseInt(clockIn.substring(3,5), 10)

        let startBreakHours = parseInt(startBreak.substring(0,2), 10)
        let startBreakMinutes = parseInt(startBreak.substring(3,5), 10)

        let endBreakHours = parseInt(endBreak.substring(0,2), 10)
        let endBreakMinutes = parseInt(endBreak.substring(3,5), 10)

        let endHours = parseInt(clockOut.substring(0,2), 10)
        let endMinutes = parseInt(clockOut.substring(3,5), 10)

        let firstDuration = ((startBreakHours - startHours) * 3600) + ((startBreakMinutes - startMinutes) * 60)
        let breakDuration = ((endBreakHours - startBreakHours) * 3600) + ((endBreakMinutes - startBreakMinutes) * 60)
        let secondDuration = ((endHours - endBreakHours) * 3600) + ((endMinutes - endBreakMinutes) * 60)

        setTimeBreak(getFullDuration(breakDuration))
        setTimeWorked(getFullDuration(firstDuration + secondDuration))
      }
      else if(endBreak && startBreak && addBreak){
        let startBreakHours = parseInt(startBreak.substring(0,2), 10)
        let startBreakMinutes = parseInt(startBreak.substring(3,5), 10)

        let endBreakHours = parseInt(endBreak.substring(0,2), 10)
        let endBreakMinutes = parseInt(endBreak.substring(3,5), 10)
        let breakDuration = ((endBreakHours - startBreakHours) * 3600) + ((endBreakMinutes - startBreakMinutes) * 60)

        setTimeBreak(getFullDuration(breakDuration))
      }else {
        setTimeWorked('')
        setTimeBreak('')
      }
    }, [clockIn, clockOut, startBreak, endBreak, addBreak]);

    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      if(width <= 768){
        setIsMobile(true)
        // window.FreshworksWidget('hide');
      }
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (employees.length > 0) {
        const formattedList = employees.map(v => ({...v, label: `${v.firstName} ${v.lastName}`}))
        setList(formattedList);      
      }
    }, [employees]);

    const checkTimeActivities = () => {
      if(addBreak){
        if(clockIn < startBreak && startBreak < endBreak && endBreak < clockOut) return true
      } else{
        if(clockIn < clockOut) return true
      }
      return false
    }

    const handleEntry = () => {
      let checkActivites = checkTimeActivities();
      if(checkActivites){
        setLoading(true)
        submitEntry()
      } else{
        handleSnackbar('Invalid Timeclock Activities', 'error')
      }
    }

    const submitEntry = async () => {
      let d = new Date();
      let submitTime = Math.round(d.getTime() / 1000);
      let orgId = Session.OrganizationId;
      if(!addBreak){
        let dateString = moment(date).format("MM-DD-YYYY")
        let clockInTime  = moment(`${dateString} ${clockIn}`, "MM-DD-YYYY HH:mm").unix();
        let clockOutTime  = moment(`${dateString} ${clockOut}`, "MM-DD-YYYY HH:mm").unix();
        let payload = {
          orgId: orgId,
          employee: employee.ulid,
          clockIn: clockInTime,
          clockOut: clockOutTime,
          submitTime: submitTime,
        ...(expenses !== undefined) && {expenses: expenses},
        ...(mileage !== undefined) && {mileage: mileage},
        ...(notes !== undefined) && {notes: notes},
        }
        let response = await api.createTimeSheetEntry(payload)
        console.log(response)
        if(response.data.statusCode === 200) {
            handleSnackbar('Timesheet Entry Created', 'success');
          
          handlerReload();
          handleClose();
        }else{
          setLoading(false);
          handleSnackbar('Error creating Timeclock Entry', 'error')
        }
      }else {
        let dateString = moment(date).format("MM-DD-YYYY")
        let clockInTime  = moment(`${dateString} ${clockIn}`, "MM-DD-YYYY HH:mm").unix();
        let clockOutTime  = moment(`${dateString} ${clockOut}`, "MM-DD-YYYY HH:mm").unix();
        let startBreakTime  = moment(`${dateString} ${startBreak}`, "MM-DD-YYYY HH:mm").unix();
        let endBreakTime  = moment(`${dateString} ${endBreak}`, "MM-DD-YYYY HH:mm").unix();
  
        let payload = {
          orgId: orgId,
          employee: employee.ulid,
          clockIn: clockInTime,
          clockOut: clockOutTime,
          breakStart: startBreakTime,
          breakEnd: endBreakTime,
          submitTime: submitTime,
          ...(expenses !== undefined) && {expenses: expenses},
          ...(mileage !== undefined) && {mileage: mileage},
          ...(notes !== undefined) && {notes: notes},
        }
        let response = await api.createTimeSheetEntry(payload);
        if(response.data.statusCode === 200) {
          handleSnackbar('Timesheet Entry Created', 'success');
          handlerReload();
          handleClose();
        }else{
          setLoading(false);
          handleSnackbar('Error creating Timeclock Entry', 'error')
          
        }
      }
    }

    return (
<>
      <Backdrop
          className={classes.backdrop}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
    <Dialog 
        open={open} 
        onClose={handleClose}
        disableBackdropClick={true} 
        disableEscapeKeyDown
        fullWidth={true}
        fullScreen={isMobile}
    >
        <DialogTitle onClose={handleClose}>Add Entry</DialogTitle>
        <DialogContent>
          <div className={classes.inputRow}>
          <Autocomplete
            clearOnBlur
            selectOnFocus
            onChange={(event, newValue) => {
              setEmployee(newValue);
            }}
            value={employee}
            options={list}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                label="Select Employee"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontFamily: "Arial,Helvetica,sans-serif",
                    height: 30,
                    padding: "0px 5px 5px 11px",
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: (employee ? classes.labelZero : classes.labelRoot),
                    focused: classes.labelFocused
                  }
                }}
              />
            )}
          />
          </div>
          <div className={classes.dateRow}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                maxDate={new Date()}
                value={date}
                format
                onChange={(value)=> {
                  setDate(value);
                }}
                views={['year', 'month', 'day']}
                InputProps={{
                    style: {
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontFamily: "Arial,Helvetica,sans-serif",
                        height: 32,
                        width: 150
                    },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                renderInput={(params) => (
                    <TextField 
                        {...params} 
                        margin="normal"
                        style={{marginTop: 0, marginBottom: 5, marginRight: 30}} 
                    />
                )}
              />
            </LocalizationProvider>
            <div className={classes.breakContainer} >
              <Checkbox 
                checked={addBreak}
                size="small" 
                color='primary'
                onChange={() => {
                  setAddBreak(!addBreak)
                  setTimeBreak('')
                }}
              />
              <Typography variant="subtitle1" color='#707070'>Add Break</Typography>
            </div>
            </div>
            <div className={classes.activityRow}>
            <Typography variant="subtitle1" className={classes.activityText} color='#707070'>Clock In:</Typography>
              <TextField 
                id="time"
                type="time"
                onChange={(e) => {
                  setClockIn(e.target.value);
                }}
                value={clockIn}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  endAdornment: null,
                  style: {
                    fontStyle: "normal",
                    fontFamily: "Arial,Helvetica,sans-serif",
                    height: '32px',
                    width: '90px'
                  }
                }}
                inputProps={{
                  style:{
                    textAlign: 'center',
                    fontSize: '16px',
                    padding: 2
                  },
                }}
                /> 
            </div>
            <div className={classes.activityRow}>
            <Typography variant="subtitle1" className={classes.activityText} color='#707070'>Break Start:</Typography>
              <TextField 
                id="time"
                type="time"
                disabled={!addBreak}
                onChange={(e) => {
                  setStartBreak(e.target.value);
                  console.log(e.target.value)
                }}
                value={startBreak}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  endAdornment: null,
                  style: {
                    fontStyle: "normal",
                    fontFamily: "Arial,Helvetica,sans-serif",
                    height: '32px',
                    width: '90px'
                  }
                }}
                inputProps={{
                  style:{
                    textAlign: 'center',
                    fontSize: '16px',
                    padding: 2
                  },
                }}
                /> 
            </div>
            <div className={classes.activityRow}>
            <Typography variant="subtitle1" className={classes.activityText} color='#707070'>End Break:</Typography>
              <TextField 
                disabled={!addBreak}
                id="time"
                type="time"
                onChange={(e) => {
                  setEndBreak(e.target.value);
                }}
                value={endBreak}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  endAdornment: null,
                  style: {
                    fontStyle: "normal",
                    fontFamily: "Arial,Helvetica,sans-serif",
                    height: '32px',
                    width: '90px',
                    marginRight: '15px'
                  }
                }}
                inputProps={{
                  style:{
                    textAlign: 'center',
                    fontSize: '16px',
                    padding: 2
                  },
                }}
                /> 
                <Typography variant="subtitle1" className={classes.breakText} color='#707070'>{timeBreak?.charAt(0) !== '-'&& timeBreak}</Typography>
            </div>
            <div className={classes.activityRow}>
            <Typography variant="subtitle1" className={classes.activityText} color='#707070'>Clock Out:</Typography>
              <TextField 
                id="time"
                type="time"
                onChange={(e) => {
                  setClockOut(e.target.value);
                }}
                value={clockOut}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  endAdornment: null,
                  style: {
                    fontStyle: "normal",
                    fontFamily: "Arial,Helvetica,sans-serif",
                    height: '32px',
                    width: '90px'
                  }
                }}
                inputProps={{
                  style:{
                    textAlign: 'center',
                    fontSize: '16px',
                    padding: 2
                  },
                }}
                /> 
            </div>

            <div className={classes.activityRow}>
            <Typography variant="subtitle1" className={classes.activityText} color='#707070'>Time Worked:</Typography>
            <Typography variant="subtitle1" className={classes.activityText} color='#707070'>{timeWorked?.charAt(0) !== '-'&& timeWorked}</Typography>
            </div>
            <div className={classes.expenseRow}>
              <NumberFormat
                customInput={NumberFormattedInput}
                type='tel'
                allowNegative={false}
                label='Mileage'
                decimalScale={2}
                allowLeadingZeros={false}
                value={mileage}
                onValueChange={(value) => {
                  const val = parseFloat(value.value)
                  if ((val >= 0 && val < 10000) || value.value === '') {
                    setMileage(value.value);
                  }else{
                      setRand(random());
                    }
                }}
                thousandSeparator={true}
                styleProps={{
                  fontSize: '16px',                
                  fontStyle: "normal",
                  fontFamily: "Arial,Helvetica,sans-serif",
                  height: 32,
                  width: isMobile ? '90%' :225
                }}
              />
              <NumberFormat
                customInput={NumberFormattedInput}
                type='tel'
                label='Expenses'
                allowNegative={false}
                decimalScale={2}
                allowLeadingZeros={false}
                prefix={'$'}
                value={expenses}
                onValueChange={(value) => {
                  const val = parseFloat(value.value)
                  if ((val >= 0 && val < 10000) || value.value === '') {
                    setExpenses(value.value);
                  }else{
                    setRand(random());
                  }
                }}
                thousandSeparator={true}
                styleProps={{
                  fontSize: '16px',                
                  fontStyle: "normal",
                  fontFamily: "Arial,Helvetica,sans-serif",
                  height: 32,
                  width: isMobile ? '90%' :225
                }}
              />
            </div>

            <div className={classes.notesRow}>
                  <TextField
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    variant="outlined"
                    multiline
                    fullWidth
                    label="Notes"
                    rows={4}
                    InputProps={{
                      style: {
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontFamily: "Arial,Helvetica,sans-serif",
                      },
                    }}
                  />
                </div>
                <div className={classes.saveButtonContainer}>
                  <ThemeProvider theme={greenTheme}>
                    <Button variant="contained" className={classes.editButtons} disabled={addBreak ? (!clockIn || !clockOut || !employee || !startBreak || !endBreak) : (!clockIn || !clockOut || !employee)} onClick={handleEntry}>Add Entry</Button>
                  </ThemeProvider>
                  <ThemeProvider theme={redTheme}>
                    <Button variant="contained" className={classes.cancelButtons} onClick={handleClose}>Cancel</Button>
                  </ThemeProvider>
              </div>
        </DialogContent>
    </Dialog>
    </>
    )
}

export default AddEntryModal;
