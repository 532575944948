import React, { useState, useEffect } from 'react';
import Session from "../../../session";
import ErrorToast from '../../constant/errorToast';
import { isImage, isPdf, trimReceipt } from '../util';
import { ulid } from 'ulid';
import { uploadInS3 } from '../../../utils/s3upload';
import { S3Document, DisplayFile, PdfPreview } from '../s3Document';

import { withStyles, makeStyles } from '@mui/styles';
import {
  CircularProgress,
  Backdrop,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  Typography,
  Tabs,
  Tab,
  Button,
  TextField,
  IconButton,
  Divider,
  Grid
} from '@mui/material';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon
} from '@mui/icons-material';

import Confirmation from './ConfirmDeleteModule'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  nonSelectedTab: {
    padding: '0 2px',
    margin: 0,
    height: '100%',
    borderBottom: '1px solid lightgray',
    maxWidth: '100%',
  },
  selectedTab: {
    padding: '0 2px',
    margin: 0,
    height: '100%',
    borderBottom: '1px solid lightgray',
    maxWidth: '100%',
    backgroundColor: 'rgb(223, 242, 250)'
  },
  dialog:{
    height: '80%',
    top: 50,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      top: 0
    },
  },
  modalContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      alignContent: 'center'
    }
  },
  paper: {
    maxWidth: '650px',
    minWidth: '650px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      minWidth: '100%',
    },  
  },
  inputButtons:{
    top: '10px',
    height: "30px", 
    display: 'inline', 
    marginRight: 5, 
    color: 'white' 
  },
  disabledButton: {
    background: '#e0e0e0',
    color: 'white',
    '&:hover': {
      // background: '#e0e0e0',
      cursor: 'pointer',
    }
  },
  saveButton: {
    background: '#10D236',
    '&:hover': {
      background: '#0a8938',
      cursor: 'pointer'
    }
  },
  cancelButton:{
    background: '#E22C56',
    '&:hover': {
      background: '#971533',
      cursor: 'pointer'
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  editRecieptContainer:{
    display: 'flex', 
    height: "50px", 
    width: '100%',
    paddingTop: 5,
    [theme.breakpoints.down('sm')]: {
      height: '100px',
      justifyContent: 'center',
      flexWrap: 'wrap'
    },
  }
}));

const styles = {
  smallIcon: {
    width: 18,
    height: 18,
  }
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography style={{paddingBottom: 0}} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          onClick={onClose}
          className="icon cur-pointer"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Receipts = ({ entryId, isOpen, fileUlids, setFileUlids, setParent, api }) => {
    const [openModel, setOpenModel] = useState(false);
    const [receiptName, setReceiptName] = useState('');
    const [attachFile, setAttachFile] = useState(null);
    const [selectedFileUlid, setSelectedFileUlid] = useState('');
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const [loadModal, setLoadModal] = useState(true);
    const [deleteFile, setDeleteFile] = useState('');
    const [disabled, setDisable] = useState(false);
    const [editInputs, setEditInputs] = useState(false);
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);

    const classes = useStyles();

    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      if(width <= 768){
        setIsMobile(true)
        // window.FreshworksWidget('hide');
      }
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };
    useEffect(() => {
      setOpenModel(isOpen);
    }, [isOpen]);

    useEffect(() => {
      if(fileUlids.length > 0 && loadModal) {
        setSelectedFileUlid(fileUlids[0]);
        setValue(0);
        setLoadModal(false);
      }
      // eslint-disable-next-line
    }, [loadModal, fileUlids]);
  
    const onPickedFile = (files) => {
      if (files.length > 0) {
        setReceiptName(trimReceipt(files[0].name));
        setAttachFile(files[0]);
        setEditInputs(true);
        setDisable(true);
        setSelectedFileUlid('');
      }
    }

    const uploadFile = async () => {
      try {
        setLoading(true);
        const fileFormat = attachFile.name.substring(attachFile.name.lastIndexOf('.'));
        const formData = new FormData();
        formData.append('file', attachFile);
    
        const fileUlid = `${Session.OrganizationId}/files/${ulid()}${fileFormat}`;
        const puturl = await api.getPutObjectURL(fileUlid);
        if (puturl.status === 200) {
          const s3Upload = await uploadInS3(puturl.data.url, attachFile);
          console.log('s3UPload', s3Upload);
        }
    
        const resp2 = await api.linkS3Object({
          name: receiptName,
          ulid: fileUlid,
          accessibleOrgs: [Session.OrganizationId],
          meta: {
            hidden: true,
            type: 'adhoc'
          },
          uploader: Session.UserId,
        });
        console.log('resp2', resp2);
        if (resp2.status === 200 && entryId) {
          await api.putTimesheetExpense({ entryId,
            fileUlids: [...fileUlids, resp2.data.fileUlid]
          });
          setFileUlids([...fileUlids, resp2.data.fileUlid]);
          setSelectedFileUlid(resp2.data.fileUlid)
          setValue(fileUlids.length);
          setLoading(false);
        }
      } catch (error) {
        console.log('error in upload File', error);
        setLoading(false);
      }
    }
    
    const saveReceipt = async () => {
      setLoading(true);
      try {
        if(selectedFileUlid){
          await api.updateS3Object({
            fileName: receiptName,
            fileUlid: selectedFileUlid,
            accessibleOrgs: [Session.OrganizationId],
            meta:{
              hidden: true,
              type: 'adhoc'
            },
            newFileUlid: selectedFileUlid,
            orgId: Session.OrganizationId,
          });
          const input = document.querySelector('#second[type=file]');
          input.value = null;
          setAttachFile(null);
          setReceiptName(''); 
        } else {
          await uploadFile();
          setReceiptName('');
          const input = document.querySelector('#second[type=file]');
          input.value = null;
          setAttachFile(null);
        }
        setEditInputs(false)
        setDisable(false);
      } catch (error) {
        ErrorToast('Something went wrong to upload receipt');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  
    const closeModel = () => {
      setParent(false);
      setOpenModel(false);
      setDisable(false);
      setEditInputs(false);
      setSelectedFileUlid('');
      setValue(0);
      setAttachFile(null);
      setLoadModal(true);
    }
  
    const removeFile = async (fileUlid) => {
      setDeleteFile(fileUlid);
      setOpenDeleteModel(true);
    }
  
    const confirmDelete = async () => {
      setLoading(true);
      const newUlids = fileUlids.filter(x => x !== deleteFile);

      setFileUlids(newUlids);
      try {
        await api.putTimesheetExpense({ entryId,
          fileUlids: newUlids
        });
        if (deleteFile === selectedFileUlid) {
          setSelectedFileUlid('');
          setReceiptName('');
          if (newUlids.length > 0) {
            console.log('newUlids.length > 0', newUlids);

            setSelectedFileUlid(newUlids[0]);
            setValue(0)
          } else {
            closeModel()
          }
        }
        setDeleteFile('');
        setOpenDeleteModel(false);
      } catch (error) {
        console.log('error', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    const handleEditFile = async (id, fileUlid) => {
      const span = document.getElementById(id);
      const oldFileName = span.innerHTML;
      setReceiptName(oldFileName);
      setSelectedFileUlid(fileUlid);
      setDisable(true);
    }
  
    const cancelUpload = () => {
      setDisable(false);
      setEditInputs(false);
      if(attachFile){
        setAttachFile(null);
        setLoadModal(true);
      }
    }
  
    return (
      <Dialog 
        open={openModel} 
        onClose={closeModel}
        classes={{paper: classes.paper}}
        disableEscapeKeyDown
        className={classes.dialog}
        maxWidth
        fullScreen={isMobile}
      >
      <DialogTitle onClose={closeModel}>Receipts</DialogTitle>
      <MuiDialogContent style={{padding: '0 5px 0 15px', height: '525px'}} className={classes.root}>
      <div className={classes.root}>
        <Backdrop
          className={classes.backdrop}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
        <Grid container spacing={1} className={classes.modalContainer}>
          <Grid item xs={12} sm={5} style={{ margin: '25px 0', width: '100%'}}>
            <div style={{paddingBottom: "15px", display: 'flex', justifyContent: 'center'}}>
              <Button
                style={{width: 149}}
                color='primary'
                className="icon cur-pointer"
                variant='contained'
                disabled={editInputs}
                onClick={() => {
                  const input = document.querySelector('#second[type=file]');
                  input.value = null;
                  input.click();
                }}
              > New Receipt</Button>
              <input 
                id="second" 
                type="file" 
                accept='image/png, image/jpeg, .pdf, image/heic' 
                multiple={false} 
                style={{ width: '0' }} 
                onChange={(e) => {
                  if (e.target.files) {
                    onPickedFile(e.target.files)
                  } else {
                    setEditInputs(false);
                  }
                }} 
              />
            </div>  
              <Divider />
            <Tabs
              orientation="vertical"
              aria-label="Vertical tabs"
              indicatorColor="primary"
              style={{padding: 0, width: '100%'}}
              value={value}
            >
              {fileUlids.map((file, index) => {
                return (
                  <Tab
                    key={index}
                    className={(file === selectedFileUlid) ? classes.selectedTab : classes.nonSelectedTab}
                    disabled={editInputs}
                    label={<>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between', 
                        width: '100%',
                        height: '100%',
                      }}>
                        <S3Document style={{fontSize: '12px', paddingTop: '3px', paddingLeft: '10px', fontFamily: 'arial', textTransform: 'none'}} id={"s3file" + index} fileUlid={file} />
                        <div>
                          <IconButton disabled={editInputs} 
                            // style={styles.smallIcon} 
                            className={`icon cur-pointer`} size='small' aria-label='edit'
                            onClick={() => {
                              setEditInputs(true);
                              handleEditFile("s3file" + index, file)
                              }}
                          >
                            <EditIcon style={styles.smallIcon}/>
                          </IconButton>
                          <IconButton disabled={editInputs} 
                            // style={styles.smallIcon} 
                            className="icon cur-pointer" size='small' aria-label="delete"
                            onClick={() => removeFile(file)}
                          >
                            <DeleteIcon style={styles.smallIcon}/>
                          </IconButton>
                        </div>
                      </div>
                    </>}
                    onClick={() => {
                      if (selectedFileUlid){
                        handleEditFile("s3file" + index, file);
                      }
                        setSelectedFileUlid(file)
                        setValue(index)
                    }}
                  />
                );
              })}
            </Tabs>
          </Grid>

          <Grid item xs={12} sm={7} style={{ margin: '15px 0', display: 'flex', justifyContent: 'center', width: '100%', flexDirection: 'column', alignItems: 'center'}} justify="center">
            {disabled && editInputs &&  <div className={classes.editRecieptContainer}>
                  <TextField 
                    style={{ width: '100%', marginRight: 5, height: '20px' }}
                    variant='outlined'
                    value={receiptName}
                    error={!receiptName.length > 0} 
                    onChange={(e) => {
                      setReceiptName(e.target.value);
                    }} 
                    onBlur={(e) => {
                      trimReceipt(e.target.value);
                    }}
                    label='Name Receipt'
                    type="text"
                    inputProps={{
                      style: {
                        width: '100%',
                        background: '#dff2fa',
                        fontSize: '10px',
                      },
                      maxLength: 25
                    }}
                  />
                <>
                  <Button 
                    size='small'
                    disabled={!receiptName.length > 0} 
                    className={!receiptName.length > 0 ? `${classes.inputButtons} ${classes.disabledButton}` : `${classes.inputButtons} ${classes.saveButton}`} 
                    onClick={saveReceipt}>
                    Save
                  </Button>
                  <Button 
                    size='small'
                    disabled={!receiptName.length > 0} 
                    className={!receiptName.length > 0 ? `${classes.inputButtons} ${classes.disabledButton}` : `${classes.inputButtons} ${classes.cancelButton}`} 
                    onClick={cancelUpload}>
                    Cancel
                  </Button>
                </>

              <div style={{paddingBottom: "15px"}} />
              <Divider />
            </div>}

            <div style={{display: 'flex', maxHeight: '400px', minHeight: '420px', paddingTop: '10px',}}>
              {selectedFileUlid && !attachFile && 
                <DisplayFile docUlid={selectedFileUlid} docName={receiptName} />
              }
    
              {attachFile && (
                isImage(attachFile.name) ? (                  
                  <img style={{margin:'5px', maxWidth:'100%', maxHeight:'280px', objectFit: 'contain'}}  src={URL.createObjectURL(attachFile)} alt="attachment" />
                  ) : (
                  isPdf(attachFile.name) ? (
                    <div style={{display: 'flex', maxWidth: '100%', maxHeight: '1584', paddingTop: '20px'}}>
                      <PdfPreview file={attachFile} />
                    </div>
                  ) : (
                    // Show files
                    <div className="file-div" style={{maxWidth: '100%', maxHeight: '100%', paddingTop: '20px'}}>
                      <img className='att-img' src="/file-download-solid.svg" alt="attachment" style={{ borderRadius: '0px', width: '100px' }} />
                      &nbsp;&nbsp; <p style={{ margin: '0px' }} >{attachFile.name}</p>
                    </div>
                  )
                )
              )}
            </div>
            <Confirmation
              isOpen={openDeleteModel}
              setCancel={() => {
                setOpenDeleteModel(false);
              }}
              setDelete={confirmDelete}
            />
            
          </Grid>
        </Grid>
      </div>
      </MuiDialogContent>
      </Dialog>
    )
  }

export default Receipts