import React, { useEffect, useState } from "react";

export const toHHMMSS = (totalSeconds) => {
  let sec_num = parseInt(totalSeconds, 10); // don't forget the second param
  let hours   = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) { hours   = "0"+hours; }
  if (minutes < 10) { minutes = "0"+minutes; }
  if (seconds < 10) { seconds = "0"+seconds; }

  let displayString = '';
  // if (Number(hours) === 0) {
  //   displayString += minutes + ':' + seconds;
  // } else {
  // }
  displayString += hours + ':' + minutes + ':' + seconds;

  return displayString;
}

const Timer = ({ timestamp, fixed = false, appendTime = 0 }) => {
  const [time, setTime] = useState('00:00:00');
  const adjustedTime = timestamp - appendTime;

  const fixedTime = () => {
    const durations = new Date().getTime() - new Date((adjustedTime * 1000)).getTime();
    let count = durations / 1000;
    setTime(toHHMMSS(count));
  }

  const withTimeout = () => {
    const durations = new Date().getTime() - new Date((adjustedTime * 1000)).getTime();
    let count = durations / 1000;

    setTimeout(() => {
      setTime(toHHMMSS(count));
      count = count + 1;
    }, 1000);
  }

  useEffect(() => {
    if (!Number(adjustedTime)) {
      setTime('00:00:00');
    }
  }, [adjustedTime])

  useEffect(() => {
    if (Number(adjustedTime)) {
      if (fixed) {
        fixedTime();
      } else {
        withTimeout();
      }
    }
  })

  return <span>{time}</span>;
}

export default Timer;
