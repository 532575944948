function permissionsStructure() {
  const UI_Permission_Name = String('UI_Permission_Name');
  this.appointment = { viewable: Boolean(false) };
  this.chat = { 
    viewable: Boolean(false),
    edit_delete_options: {
      [UI_Permission_Name]: String("Edit/Delete Chat"),
      viewable: Boolean(false)
    }
  };
  this.contacts = { viewable: Boolean(false) };
  this.efax = {
    viewable: Boolean(false),
    settings_options: {
      [UI_Permission_Name]: String("Efax Admin"),
      viewable: Boolean(false)
    }
  };
  this.patients = { viewable: Boolean(false) };
  this.payment = { 
    viewable: Boolean(false),
    refund_options: {
      [UI_Permission_Name]: String("Refund Payments"),
      viewable: Boolean(false)
    },
    reports_options: {
      [UI_Permission_Name]: String("Financial Reporting"),
      viewable: Boolean(false)
    }
  };
  this.profile = {
    viewable: Boolean(true),
    manage_users: {
      [UI_Permission_Name]: String("Add/Edit Users"),
      viewable: Boolean(false)
    },
    manage_services: {
      [UI_Permission_Name]: String("Manage Services"),
      viewable: Boolean(false)
    }
  };
  this.secure_messaging = { 
    viewable: Boolean(false),
    edit_delete_options: {
      [UI_Permission_Name]: String("Edit/Delete Secure Chat"),
      viewable: Boolean(false)
    }
  };
  this.sms = { 
    viewable: Boolean(false),
    settings_options: {
      [UI_Permission_Name]: String("SMS Admin"),
      viewable: Boolean(false)
    }
  };
  this.telehealth = { viewable: Boolean(false) };
  this.time_clock = { 
    viewable: Boolean(false),
    admin_reports_options: {
      [UI_Permission_Name]: String("Timeclock Admin"),
      viewable: Boolean(false)
    }
  };
  this.printAllowList = () => {
    let nestLevel = 0;
    function nest(arr) {
      for (const [key, value] of Object.entries(arr)) {
        if (typeof value === "object" && 
        typeof value.viewable === 'boolean') {
          let indent = '';
          for (let i = 0; i < nestLevel; i++) {
            indent += " - ";
          }
          console.log(`${indent}${key}: ${value.viewable}`);
          const valueArr = Object.entries(value);
          if (valueArr.length > 1){
            nestLevel++;
            const filteredArr = valueArr.filter(n => n[0] !== 'viewable');
            filteredArr.forEach(k => {
              const filVal = Object.values(k);
              const reconstructObj = {};
              reconstructObj[filVal[0]] = filVal[1];
              nest(reconstructObj);
            })
            nestLevel--;
          }
        }
      }
    }
    nest(this);
    return
  }
}

function notificationStructure() {
  function initModules() {
    this.appointment = {
      enabled: Boolean(false),
      appointmentConfirmation: {
        key: 'appointmentConfirmation',
        enabled: Boolean(false),
      },
      appointmentReminder: {
        key: 'appointmentReminder',
        enabled: Boolean(false),
      },
      patientWaitingInRoom: {
        key: 'patientWaitingInRoom',
        enabled: Boolean(false),
      },
    };
    this.chat = {
      enabled: Boolean(false),
      newChatMessage: {
        key: 'newChatMessage',
        enabled: Boolean(false),
      }
    };
    this.contacts = {
      enabled: Boolean(false),
    };
    this.efax = {
      enabled: Boolean(false),
      newEfax: {
        key: 'newEfax',
        enabled: Boolean(false),
      }
    };
    this.patients = {
      enabled: Boolean(false),
    };
    this.payment = {
      enabled: Boolean(false),
      newPayment: {
        key: 'newPayment',
        enabled: Boolean(false),
      },
      newCopay: {
        key: 'newCopay',
        enabled: Boolean(false),
      },
      paymentConfirmation: {
        key: 'paymentConfirmation',
        enabled: Boolean(false),
      },
      paymentReminder: {
        key: 'paymentReminder',
        enabled: Boolean(false),
      },
      changeInSubscription: {
        key: 'changeInSubscription',
        enabled: Boolean(false),
      }
    };
    this.secure_messaging = {
      enabled: Boolean(false),
      newSecureMessageOffice: {
        key: 'newSecureMessageOffice',
        enabled: Boolean(false),
      },
      newSecureMessagePatient: {
        key: 'newSecureMessagePatient',
        enabled: Boolean(false),
      }
    };
    this.sms = {
      enabled: Boolean(false),
      newSms: {
        key: 'newSms',
        enabled: Boolean(false),
      }
    };
    this.time_clock = {
      enabled: Boolean(false),
    }
  }
  this.sms = new initModules();
  this.email = new initModules();
  this.webpush = new initModules();
  this.mobilepush = new initModules();
}

export { permissionsStructure, notificationStructure }
  