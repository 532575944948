import React, { useContext } from 'react';
import {
  TextField,
  Autocomplete,
  Grid,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LocationOn as LocationOnIcon } from '@mui/icons-material';

import { GOOGLE_MAP_KEY } from '../config';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { LocalLanguageContext } from '../components/LocalLanguageProvider';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  inputField: {
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  labelRoot: {
    top: '-10.5px',
    "&$labelFocused": {
      top: 1
    }
  },
  labelZero: {
    top: 0,
    "&$labelFocused": {
      top: 0
    }
  },
  labelFocused: {
    top: 0
  },
  outLineLabel: {
    padding: '4.5px 4px 7.5px 6px',
    top: '15px'
  }
}));

export default function GoogleMaps({value, setValue, disabledValue, height, fullSize = false}) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  const { workerLang } = useContext(LocalLanguageContext);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  };

  const LokalisedContent = (key, fallback) => {
    const translated_string = workerLang[key];
    return translated_string ? translated_string : fallback;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <>
      {fullSize ? 
        <Autocomplete
          id="google-map-demo"
          className={classes.inputField}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          disabled={disabledValue}
          includeInputInList
          filterSelectedOptions
          value={value}
          onChange={(event, newValue) => {
            if (newValue) {
              setOptions(newValue ? [newValue, ...options] : options);
              setValue(newValue.description);
            } else {
              setValue(null)
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField 
              {...params}
              label={"Address"}
              variant="outlined"
              disabled={disabledValue}
              fullWidth
              InputProps={{
                ...params.InputProps,
                classes:{input: classes.outLineLabel},
                style: {
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontFamily: "Arial,Helvetica,sans-serif",
                  // height: height || 32,
                  display: 'flex',
                  alignContent: 'center'
                },
              }}
              // InputLabelProps={{
              //   classes: {
              //     root: (value ? classes.labelZero : classes.labelRoot),
              //     focused: classes.labelFocused,
              //   }
              // }}
            />
          )}
          renderOption={(props, option, { selected }) => {
            const matches = option.structured_formatting ? option.structured_formatting.main_text_matched_substrings : null;
            const parts = option.structured_formatting ? parse(
              option.structured_formatting.main_text,
              matches.map((match) => [match.offset, match.offset + match.length]),
            ) : null;
            const keystrippedprops = props;
            delete keystrippedprops.key;

            return (
              <li {...keystrippedprops}>
                {matches && parts && <Grid container alignItems="center">
                  <Grid item>
                    <LocationOnIcon className={classes.icon} />
                  </Grid>
                  <Grid item xs>
                    {parts.map((part, index) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                      </span>
                    ))}

                    <Typography variant="body2" color="textSecondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>}
              </li>
            );
          }}
        /> : <Autocomplete
        id="google-map-demo"
        className={classes.inputField}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        disabled={disabledValue}
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          if (newValue) {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue.description);
          } else {
            setValue(null)
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField 
            {...params}
            label={LokalisedContent('patient_profile_address', 'Address')}
            variant="outlined"
            disabled={disabledValue}
            fullWidth
            InputProps={{
              ...params.InputProps,
              classes:{input: classes.outLineLabel},
              style: {
                fontSize: "16px",
                fontStyle: "normal",
                fontFamily: "Arial,Helvetica,sans-serif",
                height: height || 32,
                display: 'flex',
                alignContent: 'center'
              },
            }}
            InputLabelProps={{
              // classes: {
              //   root: (value ? classes.labelZero : classes.labelRoot),
              //   focused: classes.labelFocused,
              // }
              shrink: true
            }}
          />
        )}
        renderOption={(props, option, { selected }) => {
          const matches = option.structured_formatting ? option.structured_formatting.main_text_matched_substrings : null;
          const parts = option.structured_formatting ? parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          ) : null;
          const keystrippedprops = props;
          delete keystrippedprops.key;

          return (
            <li {...keystrippedprops}>
              {matches && parts && <Grid container alignItems="center">
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>}
            </li>
          );
        }}
      />}
    </>
  );
}
