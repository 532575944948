import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  Tooltip,
  Typography,
  CircularProgress,
  IconButton,
  Button,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  Snackbar,
  Backdrop,
  Avatar,
  Alert as MuiAlert
} from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useSWR, { trigger } from 'swr';
import { API_URL } from '../../config';
import { 
  CheckCircle as CheckCircleIcon,
  Edit as EditIcon
} from '@mui/icons-material';
import UserAvatar from '../constant/userAvatar';
import Session from '../../session';
import NumberFormattedInput from "../constant/numberFormattedInput";
import NumberFormat from "react-number-format";
import axios from "axios";
import {useAPI} from '../../utils/api';
import { capitalize } from "../../utils/capitalizeNames";
import { isValidPhoneNumber, isValidEmail } from "../../utils/formats";
import './profileStyle.css';
import LokalisedContent from '../LokalisedContent';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const redTheme = createTheme({ 
  palette: { 
    primary: {
      main: '#F81010' 
    }
  }
})
const greenTheme = createTheme({ 
  palette: { primary: {
    main: "#10D236",
    contrastText: "#fff"
  }, 
},
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: 'white',
      },
    },
  }
}) 

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    border: '1.5px solid #BCBCCB',
    borderRadius: "5px",
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
    padding: '10px',
    paddingBottom: '30px'
  },
  profileFields: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  inputFields: {
    marginTop: '25px',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  roleFieldContainer: {
    borderRadius: "5px",
    width: '50%',
    marginTop: '12.5px',
  },
  inputFieldContainer: {
    borderRadius: "5px",
    marginLeft: '30px',
    marginTop: '12.5px',
    width: '70%'
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  avatar: {
    cursor: 'pointer',
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px'
    },
  },
  editIcon:{
    position: 'absolute',
    top: 30,
    right: 30
  },
  accessText: {
    cursor: 'default',
    marginLeft: '-10px',
    marginTop: '10px'
  },
  accessContainer: {
    width: '95%'
  },
  accessTable: {
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
    border: '1.5px solid #BCBCCB',
    borderRadius: "5px",
  },
  accessTextElement: {
    marginBottom: '5px',
    textDecoration: 'underline',
    cursor: 'default'
  },
  noBorder: {
    border: "none",
  },
  accessElement: {
    margin: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
  },
  notificationContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: "5px",
    alignSelf: 'flex-start',
    marginTop: '20px',
    marginLeft: '30px',
    width: '50%',
    border: '1.5px solid #BCBCCB',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
      marginBottom: '50px'
      },
  },
  notificationText:{
    color: '#4D4F5C',
    margin: '9px',
    width: '70%',
  },
  notificationHeader: {
    color: '#4D4F5C',
    marginLeft: '10px',
    textAlign: 'left'
  },
  notificationOption: {
    textAlign: 'center',
  },
  notificationTextContainer: {
    margin: '10px',
    marginLeft: '50px',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  checkboxContainer: {
    margin: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center'
  },
  editButtons: {
    textTransform: 'none',
    marginLeft: '10px',
    marginRight: '10px',
    fontSize: '13px',
    height: '26px',
    width: '75px',
    [theme.breakpoints.down('md')]: {
      width:'65px',
      height: '25px',
      fontSize: '11px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '25px',
      marginLeft: '3px',
      marginRight: '3px',
      fontSize: '10px',
    },
  },
  disabledInput: {
    color: '#000000'
  },
  saveButtonContainer: {
    marginTop: '30px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  saveButtonModalContainer: {
    marginBottom: '30px',
    marginTop: '15px',
    paddingRight: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  inputProps: {
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Arial,Helvetica,sans-serif",
    height: 30,
  },
  checkboxInput: {
    padding: 0,
    marginTop: '5px',
    marginBottom: '5px',
  },
  labelRoot: {
    top: '-12.5px',
    "&$labelFocused": {
      top: 0
    }
  },
  labelZero: {
    top: 0,
    "&$labelFocused": {
      top: 0
    }
  },
  labelFocused: {
    top: 0
  },
}))


const ProfileTab = () => {
  const api = useAPI();
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [mobilePhone, setMobilePhone] = useState('')
  const [officePhone, setOfficePhone] = useState('')
  const [role, setRole] = useState('')
  const [modelOpenLogo, setModelOpenLogo] = useState(false);

  const [reqFirstName, setReqFirstName] = useState(false)
  const [reqLastName, setReqLastName] = useState(false)
  const [reqEmail, setReqEmail] = useState(false)
  const [reqMobilePhone, setReqMobilePhone] = useState(false)
  const [reqOfficePhone, setReqOfficePhone] = useState(false)
  
  const [firstNameUpdate, setFirstNameUpdate] = useState(false)
  const [lastNameUpdate, setLastNameUpdate] = useState(false)
  const [emailUpdate, setEmailUpdate] = useState(false)
  const [mobileNumUpdate, setMobileNumUpdate] = useState(false)
  const [officeNumUpdate, setOfficeNumUpdate] = useState(false)
  const [userUpdate, setUserUpdate] = useState(false)

  const [sendFile, setSentFile] = useState(null);
  const [edit, setEdit] = useState(false)
  const [, setIsLoading] = useState(false);
  const [isSaveing, setIsSaving] = useState(false)
  
  const [warningModal, setWarningModal] = useState(false)

  const [isAppointment, setIsAppointment] = useState(false);
  const [isSMS, setIsSMS] = useState(false);
  const [isEfax, setIsEfax] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const [isPay, setIsPay] = useState(false);
  const [isTimeClock, setIsTimeClock] = useState(false);
  const [isPatient, setIsPatient] = useState(false);
  const [isContacts, setIsContacts] = useState(false);
  const [isTeleHealth, setIsTeleHealth]=  useState(false);
  const [isSecureMessage, setIsSecureMessage] = useState(false);
  // Snackbar stuff
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [errorUpdating, setErrorUpdating] = useState(false);
  const [userData, setUserData] = useState(null);
  const [reload, setReload] = useState(true);
  const fileInput = useRef(null);
  const refField1 = useRef();
  const refField2 = useRef();
  
  const classes = useStyles();

  const selfUrl = `${API_URL}/v3/users/self`;

  const fetcher = url => axios.get(url, {
    headers: {
        'Authorization': `Bearer ${Session.Token}`,
        'Content-Type': 'application/json',
    }
  }).then(res => res.data);

  const { data: selfData, errorSelf } = useSWR(() => selfUrl, fetcher);

  useEffect(() => {
    if (selfData && !errorSelf) {
      const self = selfData.user;
      if(self){
        setUserData(self);
      }
      setReload(false);
    }
  }, [selfData, errorSelf]);

  useEffect(() => {
    if(reload) {
      trigger(selfUrl);
      setReload(false);
    } 
  }, [reload, selfUrl]);

  useEffect(() => {
    let updateArrayCheck = [ firstNameUpdate, lastNameUpdate,emailUpdate, mobileNumUpdate, officeNumUpdate]
    let checker = (arr) => arr.every(v => v === false);
    if(checker(updateArrayCheck)){
      setUserUpdate(false);
    }
  }, [firstNameUpdate, lastNameUpdate,emailUpdate, mobileNumUpdate, officeNumUpdate, userUpdate,])

  useEffect(() => {
    setFirstName(userData?.firstName);
    setLastName(userData?.lastName);
    setEmail(userData?.email || '');
    if (userData?.mobileNum) {
      let stripped = userData.mobileNum.split("+1");
      setMobilePhone(stripped[1]);
    } else {
      setMobilePhone('');
    }
    if (userData?.officeNum) {
      let stripped = userData.officeNum.split("+1");
      setOfficePhone(stripped[1]);
    } else {
      setOfficePhone('');
    }
    setRole(userData?.role || '');
    if(userData?.permissions){
      if(userData.permissions.appointment.viewable){
        setIsAppointment(true);
      }
      if(userData.permissions.efax.viewable){
        setIsEfax(true);
      }
      if(userData.permissions.sms.viewable){
        setIsSMS(true);
      }
      if(userData.permissions.chat.viewable){
        setIsChat(true);
      }
      if(userData.permissions.payment.viewable){
        setIsPay(true);
      }
      if(userData.permissions.time_clock.viewable){
        setIsTimeClock(true);
      }
      if(userData.permissions.secure_messaging.viewable){
        setIsSecureMessage(true);
      }
      if(userData.permissions.patients.viewable){
        setIsPatient(true);
      }
      if(userData.permissions.contacts.viewable){
        setIsContacts(true);
      }
      if(userData.permissions.telehealth.viewable){
        console.log('teledis')
        setIsTeleHealth(true);
      }
    }
  // eslint-disable-next-line 
  }, [userData])


  const editData = () => { 
    let tempEdit = edit
    if(tempEdit){
      setFirstName(userData.firstName);
      setLastName(userData.lastName);
      setEmail(userData.email || '');
      if (userData.mobileNum) {
        let stripped = userData.mobileNum.split("+1");
        setMobilePhone(stripped[1]);
      } else {
        setMobilePhone('');
      }
      if (userData.officeNum) {
        let stripped = userData.officeNum.split("+1");
        setOfficePhone(stripped[1]);
      } else {
        setOfficePhone('');
      }
    };
    setEdit(!tempEdit); 
  };

  const handleWarningModal = (status) => {
    setWarningModal(status)
  };

  const handleSave = async() => {
    if(emailUpdate){
      handleWarningModal(true)
    } else {
      await saveProfile()
    }
  };

  const handlerReload = (status) =>{
    setReload(status);
  };

  const saveProfile = async() => {
    try {
      setIsSaving(true)
      const payload = {
        ...firstNameUpdate && {firstName: capitalize(firstName)},
        ...lastNameUpdate && {lastName: capitalize(lastName)},
        ...mobileNumUpdate && {mobileNum:'+1' + mobilePhone.replace(/[- .()]/g, "")},
        ...officeNumUpdate && {officeNum:'+1' + officePhone.replace(/[- .()]/g, "")},
        ...emailUpdate && {email: email},
      }
      const res = await api.editStaff(payload);
      if(res.status === 200){
        setSeverity('success');
        setSnackBarMessage('Successfully updated profile!');
        setOpenSnackBar(true);  
        handlerReload(true);
        setEdit(false);
        setFirstNameUpdate(false);
        setLastNameUpdate(false);
        setMobileNumUpdate(false);
        setOfficeNumUpdate(false);
        setEmailUpdate(false);
      }
      setIsSaving(false);
    } catch(error) {
      setErrorUpdating(true)
      setSeverity('error');
      setSnackBarMessage(error.response.data.message);
      setOpenSnackBar(true);
      handleWarningModal(false);
      setIsSaving(false);
    }
    handleWarningModal(false);
  }
  
  const handleOpenFile = (e) => {
    setSentFile(e.target.files[0]);
    if (e.target.files.length > 0)
      handlerLogoModel(true);
  };

  const openLogoModel = () => { fileInput.current.click(); };

  const avatarHandler = (item) => {
    if (item) { upload_Avatar(item) }
  };

  const handlerLogoModel = (st) => { setModelOpenLogo(st); };

  const uploadInS3 = (signedUrl1, file) => new Promise(resolve => {
      const instance = axios.create();
      instance.defaults.headers.common['Content-Type'] = file.type;
      const myHeaders = {
          headers: {
              'Content-Type': file.type
          }
      };
      instance.put(signedUrl1, file, myHeaders)
          .then((uploadRes) => {
              const avatartPath = signedUrl1.substring(0, signedUrl1.indexOf("?"));
              Session.SetAvatarUrl(avatartPath);
              resolve(uploadRes);
              handlerReload(true);
              setIsLoading(false);
          })
          .catch((err) => {
              resolve(err)
              setIsLoading(false);
          });
  });

  const upload_Avatar = async (isUploadAvatar) => {
    setIsLoading(true);
    const puturl = await api.getSignedPublicS3Key(isUploadAvatar.name);
    if (puturl.status === 200) {
      setSeverity('success');
      setSnackBarMessage('Successfully updated profile picture!');
      setOpenSnackBar(true);   
      const userUrl = puturl.data.url;
      uploadInS3(userUrl, isUploadAvatar);
      const [rawurl] = userUrl.split('?')
      await api.editOtherStaffUser({"avatarUrl": rawurl}, userData.ulid);
    };
  };
  
  const checkFName = () => {
    if (firstName.length === 0) {
      setReqFirstName(true);
    } else {
      setReqFirstName(false);
    }
  };

  const checkLName = () => {
    if (lastName.length === 0) {
      setReqLastName(true);
    } else {
      setReqLastName(false);
    }
  };

  const handleFirstNameChange = (string) => {
    setErrorUpdating(false)
    let currentFirstName = userData.firstName ? userData.firstName : ''
    if (currentFirstName !== string){             
      setFirstNameUpdate(true)
      setUserUpdate(true)
    } else {
      setFirstNameUpdate(false)
    }
    setFirstName(string)
  };

  const handleLastNameChange = (string) => {
    setErrorUpdating(false)
    let currentLastName = userData.lastName ? userData.lastName : ''
    if ( currentLastName !== string){           
      setLastNameUpdate(true)
      setUserUpdate(true)
    } else {
      setLastNameUpdate(false)
    }
    setLastName(string)
  };

  const handleEmailChange = (string) => {
    setErrorUpdating(false)
    let currentEmail = userData.email ? userData.email : ''
    if (currentEmail !== string){         
      setEmailUpdate(true)
      setUserUpdate(true)
    } else {
      setEmailUpdate(false)
    }
    setEmail(string)
  };

  const handleMobileNumChange = (number) => {
    setErrorUpdating(false)
    let stripped = userData.mobileNum ? userData.mobileNum.split("+1") : '';
    let currentPhoneNum = stripped ? stripped[1] : ''
    let inputNumber = number.value ? number.value : ''
    if (currentPhoneNum !== inputNumber){        
      setMobileNumUpdate(true)
      setUserUpdate(true)
    } else {
      setMobileNumUpdate(false)
    }
  };

  const handleOfficeNumChange = (number) => {
    setErrorUpdating(false)
    let stripped = userData.officeNum ? userData.officeNum.split("+1") : '';
    let currentOfficeNum = stripped ? stripped[1] : ''
    let inputNumber = number.value ? number.value : ''
    if (currentOfficeNum !== inputNumber){         
      setOfficeNumUpdate(true)
      setUserUpdate(true)
    } else {
      setOfficeNumUpdate(false)
    }
  };

  const handleCancel = () => {
    setEdit(false)
    setErrorUpdating(false)
    setFirstNameUpdate(false)
    setLastNameUpdate(false)
    setMobileNumUpdate(false)
    setOfficeNumUpdate(false)
    setEmailUpdate(false)
    setFirstName(userData.firstName);
    setLastName(userData.lastName);
    setEmail(userData.email || '');
    if (userData.mobileNum) {
      let stripped = userData.mobileNum.split("+1");
      setMobilePhone(stripped[1]);
    } else {
      setMobilePhone('')
    }
    if (userData.officeNum) {
      let stripped = userData.officeNum.split("+1");
      setOfficePhone(stripped[1]);
    } else {
      setOfficePhone('')
    }
    setReqFirstName(false)
    setReqLastName(false)
    setReqEmail(false)
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  return (
    <> 
          <Backdrop
            className={classes.backdrop}
            open={reload}
          >
            <CircularProgress />
          </Backdrop>
          <Snackbar
            open={openSnackBar}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert 
              onClose={handleClose} 
              severity={severity}
              sx={{ width: '100%' }}
            >
              {snackBarMessage}
            </Alert>
          </Snackbar>
          <div className={classes.root}>
            <div className={classes.profileFields}>
              {!edit && 
              <IconButton aria-label="edit organization info" className={classes.editIcon} onClick={editData}>
                <EditIcon  style={{ color: "#47AFEB" }}/>
              </IconButton>}
              <div className={classes.inputFields}>
                <div className={classes.inputFieldContainer}>
                  <TextField
                    onKeyPress={(e) => { if (e.key === 'Enter') {refField1.current.focus() } }}
                    onChange={(e) => handleFirstNameChange(e.target.value)}
                    value={firstName}
                    onBlur={checkFName}
                    error={reqFirstName}
                    variant="outlined"
                    fullWidth
                    disabled={!edit || isSaveing}
                    name="firstname"
                    label={reqFirstName ? "Name is Required" : 'First Name'}
                    classes={{notchedOutline:classes.input}}
                    InputProps={{
                      style: {
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontFamily: "Arial,Helvetica,sans-serif",
                        height: 30
                      }}
                    }
                    inputProps={{
                      maxLength: 20
                    }}
                    InputLabelProps={{
                      classes: {
                        root: (firstName ? classes.labelZero : classes.labelRoot),
                        focused: classes.labelFocused
                      }
                    }}
                  />
                </div>
                <div className={classes.inputFieldContainer}>
                  <TextField
                    inputRef={refField1}
                    onKeyPress={(e) => { if (e.key === 'Enter') {refField2.current.focus() } }}
                    onChange={(e) => handleLastNameChange(e.target.value)}
                    value={lastName}
                    onBlur={checkLName}
                    error={reqLastName}
                    variant="outlined"
                    fullWidth
                    disabled={!edit || isSaveing}
                    name="lastName"
                    label={reqLastName ? "Last Name is Required" : 'Last Name'}
                    classes={{notchedOutline:classes.input}}
                    InputProps={{
                      style: {
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontFamily: "Arial,Helvetica,sans-serif",
                        height: 30
                      }}
                    }
                    inputProps={{
                      maxLength: 20
                    }}
                    InputLabelProps={{
                      classes: {
                        root: (lastName ? classes.labelZero : classes.labelRoot),
                        focused: classes.labelFocused
                      }
                    }}
                  />
                </div>
                <div className={classes.inputFieldContainer}>
                    <TextField
                      inputRef={refField2}
                      value={email}
                      onChange={(e) => {
                        handleEmailChange(e.target.value)
                        setReqEmail(!isValidEmail(e.target.value))
                      }}
                      disabled={!edit || isSaveing}
                      inputProps={{ maxLength: 50 }}
                      variant="outlined"
                      error={reqEmail}
                      fullWidth
                      label={reqEmail ? "Email is Required" : 'Email'}
                      InputProps={{
                        style: {
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontFamily: "Arial,Helvetica,sans-serif",
                          height: 30,
                          padding: "5px",
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: (email ? classes.labelZero : classes.labelRoot),
                          focused: classes.labelFocused
                        }
                      }}
                    />
                </div>
                <div className={classes.inputFieldContainer}>
                <NumberFormat
                    customInput={NumberFormattedInput}
                    className={classes.sendInputSms}
                    value={mobilePhone}
                    onChange={(e) => {
                      setMobilePhone(e.target.value)
                    }}
                    onValueChange={(value) => {
                      handleMobileNumChange(value);
                      if (isValidPhoneNumber(value.value) || !value.value) {
                          setReqMobilePhone(false);
                      } else {
                          setReqMobilePhone(true)
                      }
                    }}
                    error={reqMobilePhone}
                    format="(###) ###-####"
                    mask=" "
                    variant="outlined"
                    disabled={!edit || isSaveing}
                    label={reqMobilePhone ? "Mobile Phone is Required" : 'Mobile Number'}
                    classes={{notchedOutline:classes.input}}
                    InputProps={{
                      style: {
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontFamily: "Arial,Helvetica,sans-serif",
                        height: 30
                      }}
                    }
                    InputLabelProps={{
                      classes: {
                        root: (mobilePhone ? classes.labelZero : classes.labelRoot),
                        focused: classes.labelFocused
                      }
                    }}
                  />
                </div>
                <div className={classes.inputFieldContainer}>
                <NumberFormat
                    customInput={NumberFormattedInput}
                    className={classes.sendInputSms}
                    error={reqOfficePhone}
                    value={officePhone}
                    label={reqOfficePhone ? "Invalid Office Number" : "Office Number"}
                    onChange={(e) => setOfficePhone(e.target.value)}
                    onValueChange={(value) => { 
                      handleOfficeNumChange(value);
                        if (isValidPhoneNumber(value.value) || !value.value) {
                          setReqOfficePhone(false);
                        } else {
                          setReqOfficePhone(true)
                        } 
                    }}
                    format="(###) ###-####"
                    variant="outlined"
                    mask=" "
                    disabled={!edit || isSaveing}
                    name="firstname"
                    InputProps={{
                      style: {
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontFamily: "Arial,Helvetica,sans-serif",
                        height: 30
                      }}
                    }
                    InputLabelProps={{
                      classes: {
                        root: (officePhone ? classes.labelZero : classes.labelRoot),
                        focused: classes.labelFocused
                      }
                    }}
                  />
                </div>
              </div>
              <div className={classes.avatarContainer}>
                <input type='file' style={{ display: 'none'}} id='logo_pic' ref={fileInput} accept="image/x-png,image/gif,image/jpeg" onChange={handleOpenFile} />
                  <Tooltip title="Update Avatar" arrow className={classes.avatar}>
                    <Avatar sx={{ height: '125px', width: '125px', }} src={Session.AvatarUrl} onClick={openLogoModel}/>
                  </Tooltip>
                  <div className={classes.roleFieldContainer}>
                  <TextField
                    value={role}
                    disabled
                    variant="outlined"
                    fullWidth
                    name="role"
                    label="Role"
                    InputProps={{
                      style: {
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontFamily: "Arial,Helvetica,sans-serif",
                        height: 30
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: (role ? classes.labelZero : classes.labelRoot),
                        focused: classes.labelFocused
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={classes.accessContainer}>
              <Typography variant="body2" color="textSecondary" className={classes.accessText}>
                You have access to:
              </Typography>
              <div className={classes.accessTable}>

              <div className={classes.accessElement}>
                    <div className={classes.accessTextElement}>Appointment</div>
                    <div>
                      {isAppointment ? <CheckCircleIcon style={{ color: "#47AFEB" }}/> :  <CheckCircleIcon style={{ color: "#d3d3d3" }}/>}
                    </div>
                </div>

                <div className={classes.accessElement}>
                    <div className={classes.accessTextElement}>{LokalisedContent('ModuleName-eFax', 'eFax')}</div>
                    <div>
                      {isEfax ? <CheckCircleIcon style={{ color: "#47AFEB" }}/> :  <CheckCircleIcon style={{ color: "#d3d3d3" }}/>}
                    </div>
                </div>
                
                <div className={classes.accessElement}>
                    <div className={classes.accessTextElement}>SMS</div>
                    <div>
                      {isSMS ? <CheckCircleIcon style={{ color: "#47AFEB" }}/> :  <CheckCircleIcon style={{ color: "#d3d3d3" }}/>}
                    </div>
                </div>
                
                <div className={classes.accessElement}>
                    <div className={classes.accessTextElement}>Chat</div>
                    <div>
                      {isChat ? <CheckCircleIcon style={{ color: "#47AFEB" }}/> :  <CheckCircleIcon style={{ color: "#d3d3d3" }}/>}
                    </div>
                </div>
                
                <div className={classes.accessElement}>
                    <div className={classes.accessTextElement}>Payment</div>
                    <div>
                      {isPay ? <CheckCircleIcon style={{ color: "#47AFEB" }}/> : <CheckCircleIcon style={{ color: "#d3d3d3" }}/>}
                    </div>
                    
                </div>

                <div className={classes.accessElement}>
                    <div className={classes.accessTextElement}>Time Clock</div>
                    <div>
                      {isTimeClock ? <CheckCircleIcon style={{ color: "#47AFEB" }}/> :  <CheckCircleIcon style={{ color: "#d3d3d3" }}/>}
                    </div>
                </div>
        
                <div className={classes.accessElement}>
                    <div className={classes.accessTextElement}>Patient</div>
                    <div>
                      {isPatient ? <CheckCircleIcon style={{ color: "#47AFEB" }}/> :  <CheckCircleIcon style={{ color: "#d3d3d3" }}/>}
                    </div>
                </div>
                <div className={classes.accessElement}>
                    <div className={classes.accessTextElement}>Contacts</div>
                    <div>
                      {isContacts ? <CheckCircleIcon style={{ color: "#47AFEB" }}/> :  <CheckCircleIcon style={{ color: "#d3d3d3" }}/>}
                    </div>
                </div>

                <div className={classes.accessElement}>
                    <div className={classes.accessTextElement}>TeleHealth</div>
                    <div>
                      {isTeleHealth ? <CheckCircleIcon style={{ color: "#47AFEB" }}/> :  <CheckCircleIcon style={{ color: "#d3d3d3" }}/>}
                    </div>
                </div>


                <div className={classes.accessElement}>
                    <div className={classes.accessTextElement}>Secure Message</div>
                    <div>
                      {isSecureMessage ? <CheckCircleIcon style={{ color: "#47AFEB" }}/> :  <CheckCircleIcon style={{ color: "#d3d3d3" }}/>}
                    </div>
                </div>
              </div>
            </div>

            
            {/* Notifications currently hidden ATM
                <div className={classes.notificationContainer}>
                <div className={classes.notificationText}>
                  <Typography className={classes.notificationHeader} variant="subtitle2">Notification</Typography>
                  <div className={classes.notificationTextContainer}>
                    <PaymentIcon style={{fill: "#47AFEB"}}/>
                    <Typography className={classes.notificationOption} variant="subtitle2">Payment</Typography>
                  </div>
                  <div className={classes.notificationTextContainer}>
                    <PrintIcon style={{fill: "#47AFEB"}}/>
                    <Typography className={classes.notificationOption} variant="subtitle2">eFax</Typography>
                  </div>
                  <div className={classes.notificationTextContainer}>
                    <SmsOutlinedIcon style={{fill: "#47AFEB"}}/>
                    <Typography className={classes.notificationOption} variant="subtitle2">SMS</Typography>
                  </div>
                  <div className={classes.notificationTextContainer}>
                    <QuestionAnswerOutlinedIcon style={{fill: "#47AFEB"}}/>
                    <Typography className={classes.notificationOption} variant="subtitle2">Chat</Typography>
                  </div>
                </div>
                
                <div className={classes.checkboxContainer}>
                  <Typography className={classes.checkboxHeader} variant="subtitle2">Email</Typography>
                  <Checkbox
                    disabled={!edit}
                    defaultChecked
                    color="primary"
                    className={classes.checkboxInput}
                  />
                  <Checkbox
                    disabled={!edit}
                    defaultChecked
                    color="primary"
                    className={classes.checkboxInput}
                  />
                  <Checkbox
                    disabled={!edit}
                    defaultChecked
                    color="primary"
                    className={classes.checkboxInput}
                  />
                  
                  <Checkbox
                    disabled={!edit}
                    defaultChecked
                    color="primary"
                    className={classes.checkboxInput}
                  />
                </div>
                <div className={classes.checkboxContainer}>
                  <Typography className={classes.checkboxHeader} variant="subtitle2">Text</Typography>
                  <Checkbox
                    disabled={!edit}
                    defaultChecked
                    color="primary"
                    className={classes.checkboxInput}
                  />
                  <Checkbox
                    disabled={!edit}
                    defaultChecked
                    color="primary"
                    className={classes.checkboxInput}
                  />
                  <Checkbox
                    disabled={!edit}
                    defaultChecked
                    color="primary"
                    className={classes.checkboxInput}
                  />
                  <Checkbox
                    disabled={!edit}
                    defaultChecked
                    color="primary"
                    className={classes.checkboxInput}
                  />
                </div>
            </div> */}
            {edit &&
            <div className={classes.saveButtonContainer}>
              
              <ThemeProvider theme={greenTheme}>
                <Button variant="contained" className={classes.editButtons} onClick={handleSave} disabled={reqFirstName || reqLastName || reqEmail || reqMobilePhone || reqOfficePhone || !userUpdate || isSaveing || errorUpdating}>{isSaveing ? (<CircularProgress color="inherit" size="20px"/>) : 'Save'}</Button>
              </ThemeProvider>
              <ThemeProvider theme={redTheme}>
                <Button variant="contained" className={classes.editButtons} onClick={handleCancel} disabled={isSaveing}>Cancel</Button>
              </ThemeProvider>
            </div>}
          </div>
          <Dialog open={warningModal} >
            <div>
              <MuiDialogTitle style={{ textAlign: 'center' }}>Are You Sure?</MuiDialogTitle>
              <MuiDialogContent style={{ textAlign: 'center' }}>Changing your Email will affect how you log in. Your updated Email will be your new login email.</MuiDialogContent> 
              <div className={classes.saveButtonModalContainer}>
                <ThemeProvider theme={greenTheme}>
                  <Button variant="contained" className={classes.editButtons} onClick={saveProfile} disabled={reqFirstName || reqLastName || reqEmail || reqMobilePhone || reqOfficePhone || !userUpdate || isSaveing}>{isSaveing ? (<CircularProgress color="inherit" size="20px"/>) : 'Save'}</Button>
                </ThemeProvider>
                <ThemeProvider theme={redTheme}>
                  <Button variant="contained" className={classes.editButtons} onClick={() => handleWarningModal(false)} disabled={isSaveing}>Cancel</Button>
                </ThemeProvider>
                </div>
              </div>
          </Dialog>
        {modelOpenLogo ?
          <UserAvatar modelOpen={modelOpenLogo} logo_file={sendFile} handlerModel={handlerLogoModel} avatarHandler={avatarHandler} />
          : ""}
    </>
  );
}

export default ProfileTab;
