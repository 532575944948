import React, { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles, withStyles } from '@mui/styles';
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle as MuiDialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert as MuiAlert,
  DialogContent as MuiDialogContent
} from '@mui/material';
import {
  Close as CloseIcon,
  HighlightOff as HighlightOffIcon,
  Schedule as ScheduleIcon,
  CheckCircleOutline as CheckCircleOutlineIcon
} from "@mui/icons-material";
import {
  Add as AddIcon
} from '@mui/icons-material';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StatusRefund, StatusFailed, StatusSuccess } from '../styled/paymentsCommon';
import StripeTextField from "../payment/MaterialCardElement";
import './profileStyles.css'
import { useAPI } from '../../utils/api';
import Session from "../../session";
import { API_URL } from '../../config';
import useSWR, { trigger } from 'swr';
import axios from "axios";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const mobileTheme = createTheme({
  typography: {
    fontSize: 10
  },
  palette: {
    primary: {
      main: '#47afeb',
      dark: '#005184',
      contrastText: '#fff',
    },
  },

});

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingLeft: 15,
    paddingTop: 8,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  paymentButtonContainer: {
    marginTop: '25px',
    marginBottom: '25px'
    // width: '90%',
    // display: 'flex',
    // justifyContent: 'flex-end',
    // [theme.breakpoints.down('sm')]: {
    //   justifyContent: 'center'
    // }
  },
  tabBox: {
    width: '100%',
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: '1px',
    margin: 0,
    borderRadius: '10px',
    fontSize: 'small',
    height: 650,
    marginInline: 45,
    '& .viewedEfax': {
      '&:hover': {
        backgroundColor: '#BBE1F7'
      },
    },
    '& .cellAlign': {
      paddingLeft: '18px'
    },
    [theme.breakpoints.down('sm')]: {
      marginInline: '2%',
      '& .hoverOver': {
        cursor: 'pointer',
      },
    }
  },
  datagridContainer: {
    width: '100%',
    marginTop: '20px'
  },
  cancelButton: {
    marginLeft: '10px',
    color: 'white',
    background: '#E22C56',
    '&:hover': {
      background: '#971533',
      cursor: 'pointer',
    },
  },
  submitButton: {
    marginRight: '10px',
    color: 'white',
    background: '#10D236',
    '&:hover': {
      background: '#0a8938',
      cursor: 'pointer',
    },
  },
  buttonContainer: {
    margin: '10px 20px 15px 20px',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },  
  backdrop: {
    zIndex: 9999,
  }
}))

const DialogContent = withStyles(() => ({
  root: {
    width: "100%",
  }
}))(MuiDialogContent);


const CustomNoRowsOverlay = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '325px', alignItems: 'center', justifyContent: 'center' }}>
      <Typography color="inherit">No Invoices</Typography>
    </div>
  );
};

const columns = [
  {
    field: 'invoice',
    headerName: 'Invoice',
    flex: 1,
    disableColumnMenu: true,
    valueFormatter: (params) => {
      const date = new Date(params.value * 1000);
      return `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`;
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    disableColumnMenu: true,
    valueFormatter: (params) => {
      return `$ ${(params.value / 100).toFixed(2)}`
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      console.log('params', params);
      return params.value === 'draft' ? <StatusRefund>Scheduled</StatusRefund> :
        (params.value === 'failed') ? 
          <Tooltip 
            title={<div>{`Payment declined on card ending in: ${params.row.card}`}<br />{`Reason: ${params.row.payment_error}`}</div>}
            placement='left'
            arrow
          >
            <span><StatusFailed>Failed</StatusFailed></span>
          </Tooltip> 
          :
          <StatusSuccess>Paid</StatusSuccess>
    }
  },
  {
    field: 'card',
    headerName: 'Card',
    flex: 1,
    disableColumnMenu: true,
    valueFormatter: (params) => {
      return `x${params.value}`
    },
  },
  {
    field: '',
    headerName: '',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    cellClassName: 'buttonAlign',
    renderCell: (parmas) => {
      const date = new Date(parmas.row.date * 1000)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      const formatted = `${month}/${day}/${year}`;

      return parmas.row.status === 'draft' ? <><ScheduleIcon style={{ color: "#47AFEB", marginRight: '10px' }} /> <div>{`Auto Pay on ${formatted}`}</div></> :
        (parmas.row.status === 'failed') ? <><HighlightOffIcon style={{ color: "#F20634 ", marginRight: '10px' }} /> <div>{`Failed ${formatted}`}</div></> :
          <><CheckCircleOutlineIcon style={{ color: "#10D236", marginRight: '10px' }} /> <div>{`Paid ${formatted}`}</div></>
    }
  },
]
const columnsMobile = [
  {
    field: 'invoice',
    headerName: 'Invoice',
    flex: 0.8,
    disableColumnMenu: true,
    valueFormatter: (params) => {
      const date = new Date(params.value * 1000);
      return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 0.5,
    disableColumnMenu: true,
    valueFormatter: (params) => {
      return `$ ${(params.value / 100).toFixed(2)}`
    },
  },
  {
    field: '',
    headerName: '',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (parmas) => {
      const date = new Date(parmas.row.date * 1000)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      const formatted = `${month}/${day}/${year}`;

      return parmas.row.status === 'draft' ? <><ScheduleIcon style={{ color: "#47AFEB", marginRight: '1px' }} /> <div>{`Auto Pay on ${formatted}`}</div></> :
        (parmas.row.status === 'failed') ? <><HighlightOffIcon style={{ color: "#F20634 ", marginRight: '1px' }} /> <div>{`Failed ${formatted}`}</div></> :
          <><CheckCircleOutlineIcon style={{ color: "#10D236", marginRight: '1px' }} /> <div>{`Paid ${formatted}`}</div></>
    }
  },
  // {
  //   field: '',
  //   headerName: '',
  //   flex: 1.3,
  //   disableColumnMenu: true,
  //   sortable: false,
  //   cellClassName: 'buttonAlign',
  //   renderCell: (parmas) => {
  //     return parmas.row.status.name === 'scheduled' ? <><ScheduleIcon style={{ color: "#47AFEB", marginRight: '10px' }} /> <div>{`${parmas.row.date}`}</div></> :
  //       (parmas.row.status.name === 'failed') ? <><HighlightOffIcon style={{ color: "#F20634 ", marginRight: '10px' }} /> <div>{`${parmas.row.date}`}</div></> :
  //         <><CheckCircleOutlineIcon style={{ color: "#10D236", marginRight: '10px' }} /> <div>{`${parmas.row.date}`}</div></>
  //   }
  // },
]

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});
const AccountBilling = () => {
  const api = useAPI();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [invoiceData, setInvoiceData] = useState();
  const [invoiceList, setInvoiceList] = useState();
  const [isInvoceSelected, setinvoiceSelected] = useState(false);
  const [status, setStatus] = useState("");
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const elements = useElements();
  const stripe = useStripe();
  const [cardName, setCardName] = useState("");
  // const [nameError, setNameError] = useState(false);
  const [valid, setValid] = useState(false);
  const [orgData, setOrgData] = useState(null);
  const [reload, setReload] = useState(false);
  // Snackbar stuff
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [subscriptionData, setSubscriptionData] = useState();

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };
  
  const onCardName = (e) => {
    setCardName(e.target.value)
    // if (e.target.value != null && e.target.value.length === 0) {
    //   setNameError(true);
    // } else {
    //   setNameError(false);
    // }
  };
  const selfUrl = `${API_URL}/v3/users/self`;

  const fetcher = url => axios.get(url, {
    headers: {
      'Authorization': `Bearer ${Session.Token}`,
      'Content-Type': 'application/json',
    }
  }).then(res => res.data);

  const { data: selfData, errorSelf } = useSWR(() => selfUrl, fetcher);

  useEffect(() => {
    if (selfData && !errorSelf) {
      const org = selfData.org;
      if (org) {
        setOrgData(org);
      }
      setReload(false);
    }
  }, [selfData, errorSelf]);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  // eslint-disable-next-line
  useEffect(async () => {
    if (reload) {
      await trigger(selfUrl)
      setReload(false);
    }
  }, [reload, selfUrl]);

  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Create an scoped async function in the hook
    async function anyNameFunction() {
      const result = await api.getBilling(Session.OrganizationId);
      if(result.data){
        setRows(result.data);
      }
      setIsLoader(false);
    }
    // Execute the created function directly
    anyNameFunction();
    setIsLoader(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    if (width <= 768) {
      setIsMobile(true);
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cardComplete) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [cardComplete]);
  const closeModal = () => {
    setModalIsOpen(false)
    setCardName("");
    setIsInvoice(false);
    // setNameError(false);
  }


  const downloadPdfDocument = () => {
        setinvoiceSelected(true);
        setTimeout(() => {
          const input = document.getElementById('invoiceId');
          html2canvas(input)
              .then((canvas) => {
                  const imgData = canvas.toDataURL('image/png');
                  const pdf = new jsPDF("l", "mm", "a4");
                  pdf.addImage(imgData, 'JPEG', 10, 10, 280, 140);
                  pdf.save(`${new Date().getTime()}.pdf`);
              });
          setinvoiceSelected(false);
        }, 0);
  }

  const onClickRow = async (rowInfo) => {
    setIsLoader(true);
    setInvoiceList(undefined);
    const result = await api.getInvoiceDetails(rowInfo.id, Session.OrganizationId);
    if (result.status === 200) {
      setInvoiceData(result.data);
      setTimeout(() => {
        let status = ''
        if(result.data.paid === true){
          status = `Paid: $ ${(result.data.total / 100).toFixed(2)} on ${getFormattedDate(result.data.status_transitions.paid_at)}`;
        }
        if(result.data.billing_reason === 'upcoming'){
          status = `Scheduled Payment: $ ${(result.data.total / 100).toFixed(2)} on ${getFormattedDate(result.data.next_payment_attempt)}`;
        }
        if(result.data.paid === false && result.data.billing_reason !== 'upcoming'){
          status = `Payment Overdue: $ ${(result.data.total / 100).toFixed(2)} on ${getFormattedDate(result.data.created)}`;
        }
        setStatus(status);
      }, 1000);
      setIsLoader(false);
      setIsInvoice(true);
      setModalIsOpen(true);
     

      let lines = result.data.lines.data;
      let invoiceList = [];
      if(result.data.billing_reason === 'manual'){
        lines.forEach(el=>{
          invoiceList.push({text: `eFax & SMS Usage`, subText: 'Overage Amount', quantity:0, rate: 0, amount: (el.amount)/100 });
        });
        setInvoiceList(invoiceList);
      }
      else if(result.data.billing_reason === 'subscription_update' && lines.some(x=>x.proration === true)){
        lines.forEach(el => {
          if(el.amount>0){
            let tempRate = 0;
            if(el.plan.billing_scheme !== "tiered"){
              tempRate = el.plan.amount / 100;
            }else{
              for (let index = 0; index < el.plan.tiers.length; index++) {
                if(el.plan.tiers[index].up_to !== null && el.quantity <= el.plan.tiers[index].up_to){
                  tempRate = el.plan.tiers[index].flat_amount / 100;
                }else if(el.plan.tiers[index].up_to == null){
                  tempRate = el.plan.tiers[index].unit_amount_decimal / 100;
                }
              }
            }
            let tempText = '';
            let proratedDis = 0;
            let duplicate;
            if(el.plan.metadata !== null){
              duplicate = lines.find(x=>x.plan.metadata.plan === el.plan.metadata.plan && x.amount <0);
              if(duplicate !== null && duplicate !== undefined){
                proratedDis = duplicate.amount;
              }
              const pn = el.plan.metadata.plan;
              tempText = pn === 'base' ? 'Additional Users' : pn === 'telehealth' ? 'Telehealth Users': pn === 'payment' ? 'Payment Module' : pn ==='additionalefax' 
              ? 'Efax Additional Numbers': pn === 'additionalsms' ? 'SMS Additional Numbers' : '';
            }else{
              if(el.plan.nickname === 'Efax Access'){
                tempText = 'Efax Module';
              }
              if(el.plan.nickname === 'SMS Access'){
                tempText = 'SMS Module';
              }
            }
            if(proratedDis !== 0){
              // const date = new Date(el.period.start * 1000);
              // const disDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`; 
              // const des = `Remaining time on 1 x ERL Med Subscription after ${disDate}`;
              invoiceList.push({text: `${tempText} (Prorated)`, subText: '', quantity:1, rate: tempRate, amount: (el.amount + proratedDis)/100 });
            }else{
              invoiceList.push({text: `${tempText} (Prorated)`, subText: '', quantity:el.quantity, rate: tempRate, amount: (el.amount + proratedDis)/100 });
            }
            proratedDis = 0;
            duplicate = {};
          }
        });
        setInvoiceList(invoiceList);
      }else{
        let base = lines.filter(x=>x.plan.metadata.plan === 'base' && x.amount!== 0);
        if(base !== undefined && base.length>0){
          let tiers = base[0].plan.tiers;
          for (let index = 0; index < base.length; index++) {
            for (let j = 0; j < tiers.length; j++) {
              let finalRate = 0; 
              let finalAmount = 0;
              if(tiers[j].up_to !== null && base[index].quantity <= tiers[j].up_to && base[index].quantity === 0){
                finalRate = tiers[j].flat_amount / 100;
                finalAmount = 1 * finalRate;
                invoiceList.push({text: 'Base', subText: `(includes 1 User)`, quantity: 1, rate: finalRate, amount: finalAmount });
                break;
              }else if(tiers[j].up_to == null && base[index].amount >=0){
                finalRate = tiers[j].unit_amount / 100;
                finalAmount = base[index].quantity * finalRate;
                invoiceList.push({text: 'Additional Users', subText: '', quantity: base[index].quantity, rate: finalRate, amount: finalAmount });
                break;
              }
            }
          }
          const eFax = lines.filter(x=>x.plan.nickname === 'Efax Access');
          if(eFax !== undefined && eFax.length>0){
            invoiceList.push({text: 'Efax Module', subText: '', quantity: eFax[0].quantity, rate: eFax[0].price.unit_amount / 100, amount: eFax[0].amount/100 });
            const efaxAdon = lines.filter(x=>x.plan.metadata.plan === 'additionalefax');
            if(efaxAdon !== undefined && efaxAdon.length>0){           
              invoiceList.push({text: 'Efax Additional Numbers', subText: '', quantity: efaxAdon[0].quantity, rate: efaxAdon[0].price.unit_amount / 100, amount: efaxAdon[0].amount/100 });
            }
          }
          const sms = lines.filter(x=>x.plan.nickname === 'SMS Access');
          if(sms !== undefined && sms.length>0){
            invoiceList.push({text: 'SMS Module', subText: '', quantity: sms[0].quantity, rate: sms[0].price.unit_amount / 100, amount: sms[0].amount/100 });
            const smsAdon = lines.filter(x=>x.plan.metadata.plan === 'additionalsms');
            if(smsAdon !== undefined && smsAdon.length>0){           
              invoiceList.push({text: 'SMS Additional Numbers', subText: '', quantity: smsAdon[0].quantity, rate: smsAdon[0].price.unit_amount / 100, amount: smsAdon[0].amount/100 });
            }
          }
          const tH = lines.filter(x=>x.plan.metadata.plan === 'telehealth' && x.amount > 0);
          if(tH !== undefined && tH.length>0){
            if(tH[0].proration){
              invoiceList.push({text: 'Telehealth Users', subText: '(Prorated)', quantity: tH[0].quantity, rate: tH[0].price.unit_amount / 100, amount: tH[0].amount/100 });
            }else{
              invoiceList.push({text: 'Telehealth Users', subText: '', quantity: tH[0].quantity, rate: tH[0].price.unit_amount / 100, amount: tH[0].amount/100 });
            }
          }
          const pt = lines.filter(x=>x.plan.metadata.plan === 'payment');
          if(pt !== undefined && pt.length>0){
            invoiceList.push({text: 'Payment Module', subText: '', quantity: pt[0].quantity, rate: pt[0].price.unit_amount / 100, amount: pt[0].amount/100 });
          }

          const eFaxUsage = lines.filter(x=>x.plan.metadata.plan === 'efaxUsage' && x.amount > 0);
          if(eFaxUsage !== undefined && eFaxUsage.length > 0){
            invoiceList.push({text: 'Efax Overage', subText: '(500 pages included)', quantity: eFaxUsage[0].quantity, rate: eFaxUsage[0].plan.tiers[1].unit_amount / 100, amount: eFaxUsage[0].amount/100 });
          }

          const smsUsage = lines.filter(x=>x.plan.metadata.plan === 'smsUsage' && x.amount > 0);
          if(smsUsage !== undefined && smsUsage.length > 0){
            invoiceList.push({text: 'SMS Overage', subText: '(1000 messages included)', quantity: smsUsage[0].quantity, rate: smsUsage[0].plan.tiers[1].unit_amount / 100, amount: smsUsage[0].amount/100 });
          }
          setInvoiceList(invoiceList);
        }
      }
     
    }else{
      setIsLoader(false);
    }
  };
  useEffect(() => {
    async function anyNameFunction() {
      const result = await api.getSubscriptionDetail(Session.OrganizationId);
      console.log(result.data)
      if(result.data){
        setSubscriptionData(result.data);
      }
    }
    anyNameFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFormattedDate = (obj) =>{
    const date = new Date(obj * 1000)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const formatted = `${month}/${day}/${year}`;
    return formatted;
  }

  const handleSubmitNew = async (e) => {
    try {
      if (!stripe || !elements || !cardComplete) {
        return;
      }
      const cardElement = elements.getElement(CardElement);
      var ownerInfo;
      if(cardName != null && cardName !== ""){
        ownerInfo = {
          owner: {
            name: `${cardName}`,
            email: `${orgData.orgEmail}`
          }
        };
      }else{
        ownerInfo = {
          owner: {
            email: `${orgData.orgEmail}`
          }
        };
      }
      const source = await stripe.createSource(cardElement, ownerInfo);
      setIsLoader(true);
      if (source.source.id !== undefined) {
        let payload = {
          orgEmail: orgData.orgEmail,
          sourceid: source.source.id
        };
        const stripeCustomer = await api.updateOrgSource(payload);
        if (stripeCustomer.status === 200) {
          setModalIsOpen(false);
          setSeverity('success');
          setSnackBarMessage("Payment Method Added Successfully");
          setOpenSnackBar(true);
          setValid(false);
          // Refetch the records
          setIsLoader(true);
          const result = await api.getBilling(Session.OrganizationId);
          if(result.data){
            setRows(result.data);
          }
          setIsLoader(false);
        }
      }
      setIsLoader(false);
    } catch (error) {
      setError(true);
      setIsLoader(false);
      setSeverity('error');
      setSnackBarMessage(error.response.data.message);
      setOpenSnackBar(true);
    }
  }
  return (
    <>
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert 
          onClose={handleClose}
          severity={severity}
          sx={{ width: '100%' }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Backdrop
        className={classes.backdrop}
        open={isLoader}
      >
        <CircularProgress />
      </Backdrop>
      <Grid container style={{fontSize:13}} className={classes.paymentButtonContainer} justifyContent="space-between" spacing={0.5}>
        {isMobile ? 
        <>
          <Grid item style={{paddingLeft:'18%',color:'rgb(112 112 110)'}} xs={12} sm={6} >
          {subscriptionData && subscriptionData.status === 'trialing' ? `Free Trial Ending On ${getFormattedDate(subscriptionData.current_period_end)}` : ''}
        </Grid>
         <Grid style={{textAlign:'right', paddingRight:'9%'}} item xs={12} sm={6} >
         <Button variant="outlined" startIcon={<AddIcon />} color="primary" onClick={() => setModalIsOpen(true)}> Manage Payment Method</Button>
         </Grid>
         </>
        :
        <>
        <Grid item style={{paddingLeft:'4%',color:'rgb(112 112 110)',paddingTop:'15px'}} xs={12} sm={6} >
          {subscriptionData && subscriptionData.status === 'trialing' ? `Free Trial Ending On ${getFormattedDate(subscriptionData.current_period_end)}` : ''}
        </Grid>
         <Grid style={{textAlign:'right', paddingRight:'3%'}} item xs={12} sm={6} >
         <Button variant="outlined" startIcon={<AddIcon />} color="primary" onClick={() => setModalIsOpen(true)}> Manage Payment Method</Button>
         </Grid>
         </>
         }
      </Grid>
      {/* <div className={classes.paymentButtonContainer}>
        <Button variant="outlined" startIcon={<AddIcon />} color="primary" onClick={() => setModalIsOpen(true)}> Manage Payment Method</Button>
      </div> */}
      <div style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center'
      }}>
        <div className={classes.tabBox}>
          {isMobile ?

            <ThemeProvider theme={mobileTheme}>
              <DataGridPro
                rows={rows}
                columns={columnsMobile}
                pageSize={10}
                disableSelectionOnClick
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                // getCellClassName={() => 'cellAlign'}
                getRowClassName={() => 'viewedEfax'}
                pagination
              />
            </ThemeProvider>
            :
            <DataGridPro
              rows={rows}
              columns={columns}
              pageSize={10}
              onRowClick={onClickRow}
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              getCellClassName={() => 'cellAlign'}
              getRowClassName={() => 'viewedEfax'}
              pagination
            />}
        </div>
      </div>
      {isInvoice ? <Dialog
        open={modalIsOpen}
        disableEscapeKeyDown
        fullWidth
        maxWidth='md'
        fullScreen={isMobile}
        onClose={closeModal}
      >
         <DialogContent id="invoiceId">
         <Typography className='invoice-title' align='center'>
          <div><img alt="logo-header" src="/ExamRoomLiveLogo.png" style={{height:88, maxWidth:174, minWidth:150}}></img></div>
          <div style={{paddingLeft:'20%'}}>Invoice</div>
        </Typography>
         <Typography>
         <Grid container style={{fontSize:13}} justifyContent="space-between" spacing={0.5}>
         <Grid item xs={12} sm={1} className='bill-to'>
           Billing To:
         </Grid>
         <Grid className='bill-address' item xs={12} sm={4}>
           {Session.OrgName}
           <Grid>
            {invoiceData.customer_email}
           </Grid>
         </Grid>
         <Grid item xs={12} sm={1}>
         </Grid>
         <Grid item xs={12} sm={4}>
           {`Invoice : ${invoiceData.number !== null ? invoiceData.number : 'Yet to be Generated'} `}
           <Grid>{status}</Grid>
         </Grid>
         <Grid item xs={12} sm={2}>
         {`${new Date(invoiceData.created * 1000).toLocaleString('default', { month: 'long' })} ${new Date(invoiceData.created * 1000).getFullYear()}`}
         </Grid>
         </Grid>
         <Grid container>
         <Grid item xs={12} sm={6}>
         </Grid>
         </Grid>
         <Grid className='bill-address' container style={{fontSize:13, paddingTop:6}} justifyContent="space-between" spacing={0.5}>
         <Grid item xs={12} sm={1}>
         </Grid>
         <Grid xs={12} sm={11} style={{paddingLeft:3}}>
           {orgData.mailingAddress}
         </Grid>
         </Grid>
         <Grid container style={{fontSize:13, paddingTop:10}} justifyContent="space-between" spacing={0.5}>
         <Grid item xs={12} sm={6}>
         </Grid>
         <Grid item xs={12} sm={6} style={{display:"flex", paddingLeft:'24%'}}>
           {
             !isInvoceSelected ?
           <>
          <div style={{marginTop:5, paddingRight:15}}>Export</div>  
          {/* <Button style={{marginLeft:10}} size="small" variant="outlined">CSV</Button> <div class="vl"></div> */}
           <Button size="small" onClick={downloadPdfDocument} variant="outlined">PDF</Button></> : null }
         </Grid>
         </Grid>
         <Grid container style={{fontSize:14, paddingTop:10}} justifyContent="space-between" spacing={0.5}>
         <Grid style={{backgroundColor:'#ebebeb'}} item xs={12} sm={8}>
          Description
         </Grid>
         <Grid style={{backgroundColor:'#ebebeb'}} item xs={12} sm={1}>
          Qty
         </Grid>
         <Grid style={{backgroundColor:'#ebebeb'}} item xs={12} sm={1}>
          Rate
         </Grid>
         <Grid style={{backgroundColor:'#ebebeb'}} item xs={12} sm={2}>
          Amount
         </Grid>
         </Grid>
         {
          invoiceList && invoiceList.map((el)=>{
             return(
            <Grid container style={{fontSize:12, borderBottom:'1px solid #ebebeb', color:'gray'}} justifyContent="space-between" spacing={0.5}>
            <Grid item xs={12} sm={8}>
             <span>{`${el.text}` }</span> <span className='sub-text'>{`${el.subText}`}</span>
            </Grid>
            <Grid item xs={12} sm={1}>
             {el.quantity}
            </Grid>
            <Grid item xs={12} sm={1}>
             {`$ ${el.rate}`}
            </Grid>
            <Grid item xs={12} sm={2}>
             {`$ ${el.amount}`}
            </Grid>
            </Grid>
            )
           })
         }
        

         <Grid container style={{fontSize:13, paddingTop:20}} justifyContent="space-between" spacing={0.5}>
         <Grid item xs={12} sm={8}>
          
         </Grid>
         <Grid style={{ borderBottom:'1px solid #ebebeb'}} item xs={12} sm={2}>
           Subtotal
         <Grid>
           Discounts
         </Grid>
         <Grid>
           Tax
         </Grid>
         </Grid>
         <Grid style={{ borderBottom:'1px solid #ebebeb'}} item xs={12} sm={2}>
         {` $ ${(invoiceData.subtotal / 100).toFixed(2)}`}
         <Grid>
         { invoiceData.total_discount_amounts.length>0 ? `-$ ${(invoiceData.total_discount_amounts[0].amount / 100).toFixed(2)}`: ''}
         </Grid>
         </Grid>
         </Grid>

         <Grid container style={{fontSize:14, paddingTop:10}} justifyContent="space-between" spacing={0.5}>
         <Grid item xs={12} sm={8}>
         Thank You for using ExamRoom Live!
         </Grid>
         <Grid item xs={12} sm={2}>
           Total
         </Grid>
         <Grid item xs={12} sm={2}>
          {`$ ${(invoiceData.total / 100).toFixed(2)}`}
         </Grid>
         </Grid>
         </Typography>
         </DialogContent>
      </Dialog> :
      <Dialog
        open={modalIsOpen}
        disableEscapeKeyDown
        fullWidth
        fullScreen={isMobile}
        onClose={closeModal}
      >
        <DialogTitle onClose={closeModal}> Payment Information </DialogTitle>
        <div style={{ margin: '10px 20px 15px 20px' }}>
          <StripeTextField
            label="Credit Card"
            stripeElement={CardElement}
            error={error}
            onChange={(e) => {
              if(e.error){
                setError(true)
              }else{
                setError(false);
              }
              setCardComplete(e.complete);
            }}
          />
        </div>
        <div style={{ margin: '10px 20px 75px 20px' }}>
          <TextField
            value={cardName}
            onChange={onCardName}
            variant="outlined"
            fullWidth
            label={"Name on Card"}
            InputProps={{
              style: {
                fontSize: "16px",
                fontStyle: "normal",
                fontFamily: "Arial,Helvetica,sans-serif",
                height: 50,
                padding: "5px 5px 5px 0",
              },
            }}
          />
        </div>
        <div style={{ margin: '10px 20px 15px 20px', display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant='contained' className={classes.submitButton} onClick={handleSubmitNew} disabled={!valid}>Update Payment Method</Button>
          <Button variant='contained' className={classes.cancelButton} onClick={closeModal}>Cancel</Button>
        </div>
      </Dialog>}
    </>
  );
}

export default AccountBilling;
