/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeToAppointmentUpdate = /* GraphQL */ `
  subscription SubscribeToAppointmentUpdate($patientId: String!) {
    subscribeToAppointmentUpdate(patientId: $patientId) {
      id
      text
      description
      appointmentType
      startDate
      endDate
      allDay
      organizationId
      assigneeId
      patientId
      status
      color
      copay
      willAttend
      appointmentNotes {
        items {
          id
          userName
          userId
          appointmentId
          appointmentNote
          createdTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToUserByUlid = /* GraphQL */ `
  subscription SubscribeToUserByUlid($ulid: String!) {
    subscribeToUserByUlid(ulid: $ulid) {
      id
      firstName
      lastName
      ulid
      newnotif
      inapp
      vidnotif
      notifications {
        items {
          id
          userId
          user {
            id
            firstName
            lastName
            ulid
            newnotif
            inapp
            vidnotif
            currentJTI
            auth0Sub
            createdAt
            updatedAt
          }
          type
          createdTimestamp
          data
          form
          orgId
          isActive
          threadId
          createdAt
          updatedAt
        }
        nextToken
      }
      currentJTI
      auth0Sub
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToOrgAppointment = /* GraphQL */ `
  subscription SubscribeToOrgAppointment($organizationId: String!) {
    subscribeToOrgAppointment(organizationId: $organizationId) {
      id
      text
      description
      appointmentType
      startDate
      endDate
      allDay
      organizationId
      assigneeId
      patientId
      status
      color
      copay
      willAttend
      appointmentNotes {
        items {
          id
          userName
          userId
          appointmentId
          appointmentNote
          createdTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToCallHistoryById = /* GraphQL */ `
  subscription SubscribeToCallHistoryById($id: String!) {
    subscribeToCallHistoryById(id: $id) {
      id
      participants {
        userVideoId
        name
        userId
        role
        time_joined
        time_left
        totalDuration
      }
      patientId
      host
      roomName
      meetingId
      historyId
      meetingRoom {
        id
        isJoinable
        isCompleted
        historyId
        callHistory {
          id
          participants {
            userVideoId
            name
            userId
            role
            time_joined
            time_left
            totalDuration
          }
          patientId
          host
          roomName
          meetingId
          historyId
          meetingRoom {
            id
            isJoinable
            isCompleted
            historyId
            currentParticipants
            createdAt
            updatedAt
          }
          duration
          createdTimestamp
          createdAt
          updatedAt
        }
        currentParticipants
        createdAt
        updatedAt
      }
      duration
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToCreateMsgsByThreadId = /* GraphQL */ `
  subscription SubscribeToCreateMsgsByThreadId($threadId: String!) {
    subscribeToCreateMsgsByThreadId(threadId: $threadId) {
      id
      threadId
      message
      from
      attachment
      mediaUrl
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToSmsByThreadId = /* GraphQL */ `
  subscription SubscribeToSmsByThreadId($threadId: String!) {
    subscribeToSmsByThreadId(threadId: $threadId) {
      id
      ulid
      threadId
      thread {
        id
        threadName
        ulid
        users
        smsmessages {
          items {
            id
            ulid
            threadId
            message
            smsSid
            to
            from
            contact
            status
            createdTimestamp
            isDismissed
            senderUlid
            attachment
            unread
            createdAt
            updatedAt
          }
          nextToken
        }
        messageType
        createdTimestamp
        isDismissed
        newMessageCount
        updatedTimestamp
        orgId
        staffId
        recipientId
        organization {
          id
          ulid
          name
          businessHours
          businessNumber
          timezone
          orgType
          orgLogo
          orgPlan
          loggedInUsers {
            ulid
            session
          }
          stripeId
          stripeOnboarded
          smsThreads {
            nextToken
          }
          phoneNumbers {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      message
      smsSid
      to
      from
      contact
      status
      createdTimestamp
      isDismissed
      senderUlid
      attachment
      unread
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToCreateChatByThreadId = /* GraphQL */ `
  subscription SubscribeToCreateChatByThreadId($threadId: String!) {
    subscribeToCreateChatByThreadId(threadId: $threadId) {
      id
      ulid
      text
      createdTimestamp
      to
      from
      attachment
      threadId
      isDismissed
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToCreateSecureMessagesByThreadId = /* GraphQL */ `
  subscription SubscribeToCreateSecureMessagesByThreadId($threadId: String!) {
    subscribeToCreateSecureMessagesByThreadId(threadId: $threadId) {
      id
      ulid
      text
      createdTimestamp
      to
      from
      attachment
      threadId
      isDismissed
      senderUlid
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToStripeTransactionByOrgId = /* GraphQL */ `
  subscription SubscribeToStripeTransactionByOrgId($orgTo: String!) {
    subscribeToStripeTransactionByOrgId(orgTo: $orgTo) {
      id
      userFrom
      orgTo
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      tags
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToBulkPatientByDocumentId = /* GraphQL */ `
  subscription SubscribeToBulkPatientByDocumentId($documentId: String!) {
    subscribeToBulkPatientByDocumentId(documentId: $documentId) {
      id
      success
      failure
      documentId
      completed
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToSmsThreadByOrg = /* GraphQL */ `
  subscription SubscribeToSmsThreadByOrg($ulid: String) {
    subscribeToSmsThreadByOrg(ulid: $ulid) {
      id
      threadName
      ulid
      users
      smsmessages {
        items {
          id
          ulid
          threadId
          thread {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          message
          smsSid
          to
          from
          contact
          status
          createdTimestamp
          isDismissed
          senderUlid
          attachment
          unread
          createdAt
          updatedAt
        }
        nextToken
      }
      messageType
      createdTimestamp
      isDismissed
      newMessageCount
      updatedTimestamp
      orgId
      staffId
      recipientId
      organization {
        id
        ulid
        name
        businessHours
        businessNumber
        timezone
        orgType
        orgLogo
        orgPlan
        loggedInUsers {
          ulid
          session
        }
        stripeId
        stripeOnboarded
        smsThreads {
          items {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          nextToken
        }
        phoneNumbers {
          items {
            id
            number
            type
            inUse
            nickname
            orgDisplayName
            displayNumber
            default
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToFaxesByOrg = /* GraphQL */ `
  subscription SubscribeToFaxesByOrg($ulid: String!) {
    subscribeToFaxesByOrg(ulid: $ulid) {
      id
      ulid
      filename
      fileUlid
      faxSid
      orgNumber
      isViewed
      isDismissed
      to
      from
      contact
      status
      faxStatus
      mediaURL
      createdTimestamp
      pageCount
      fetchState
      tags
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToTransactions = /* GraphQL */ `
  subscription SubscribeToTransactions($orgTo: String) {
    subscribeToTransactions(orgTo: $orgTo) {
      id
      userFrom
      orgTo
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      tags
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToPayouts = /* GraphQL */ `
  subscription SubscribeToPayouts($stripeId: String) {
    subscribeToPayouts(stripeId: $stripeId) {
      id
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToAppointmentByOrgId = /* GraphQL */ `
  subscription SubscribeToAppointmentByOrgId($organizationId: String!) {
    subscribeToAppointmentByOrgId(organizationId: $organizationId) {
      id
      text
      description
      appointmentType
      startDate
      endDate
      allDay
      organizationId
      assigneeId
      patientId
      status
      color
      copay
      willAttend
      appointmentNotes {
        items {
          id
          userName
          userId
          appointmentId
          appointmentNote
          createdTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBanner = /* GraphQL */ `
  subscription OnCreateBanner {
    onCreateBanner {
      id
      type
      message
      buttonLabel
      buttonUrl
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBanner = /* GraphQL */ `
  subscription OnUpdateBanner {
    onUpdateBanner {
      id
      type
      message
      buttonLabel
      buttonUrl
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBanner = /* GraphQL */ `
  subscription OnDeleteBanner {
    onDeleteBanner {
      id
      type
      message
      buttonLabel
      buttonUrl
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFax = /* GraphQL */ `
  subscription OnCreateFax {
    onCreateFax {
      id
      ulid
      filename
      fileUlid
      faxSid
      orgNumber
      isViewed
      isDismissed
      to
      from
      contact
      status
      faxStatus
      mediaURL
      createdTimestamp
      pageCount
      fetchState
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFax = /* GraphQL */ `
  subscription OnUpdateFax {
    onUpdateFax {
      id
      ulid
      filename
      fileUlid
      faxSid
      orgNumber
      isViewed
      isDismissed
      to
      from
      contact
      status
      faxStatus
      mediaURL
      createdTimestamp
      pageCount
      fetchState
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFax = /* GraphQL */ `
  subscription OnDeleteFax {
    onDeleteFax {
      id
      ulid
      filename
      fileUlid
      faxSid
      orgNumber
      isViewed
      isDismissed
      to
      from
      contact
      status
      faxStatus
      mediaURL
      createdTimestamp
      pageCount
      fetchState
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChat = /* GraphQL */ `
  subscription OnCreateChat {
    onCreateChat {
      id
      ulid
      text
      createdTimestamp
      to
      from
      attachment
      threadId
      isDismissed
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChat = /* GraphQL */ `
  subscription OnUpdateChat {
    onUpdateChat {
      id
      ulid
      text
      createdTimestamp
      to
      from
      attachment
      threadId
      isDismissed
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChat = /* GraphQL */ `
  subscription OnDeleteChat {
    onDeleteChat {
      id
      ulid
      text
      createdTimestamp
      to
      from
      attachment
      threadId
      isDismissed
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSecureMessage = /* GraphQL */ `
  subscription OnCreateSecureMessage {
    onCreateSecureMessage {
      id
      ulid
      text
      createdTimestamp
      to
      from
      attachment
      threadId
      isDismissed
      senderUlid
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSecureMessage = /* GraphQL */ `
  subscription OnUpdateSecureMessage {
    onUpdateSecureMessage {
      id
      ulid
      text
      createdTimestamp
      to
      from
      attachment
      threadId
      isDismissed
      senderUlid
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSecureMessage = /* GraphQL */ `
  subscription OnDeleteSecureMessage {
    onDeleteSecureMessage {
      id
      ulid
      text
      createdTimestamp
      to
      from
      attachment
      threadId
      isDismissed
      senderUlid
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSms = /* GraphQL */ `
  subscription OnCreateSms {
    onCreateSms {
      id
      ulid
      threadId
      thread {
        id
        threadName
        ulid
        users
        smsmessages {
          items {
            id
            ulid
            threadId
            message
            smsSid
            to
            from
            contact
            status
            createdTimestamp
            isDismissed
            senderUlid
            attachment
            unread
            createdAt
            updatedAt
          }
          nextToken
        }
        messageType
        createdTimestamp
        isDismissed
        newMessageCount
        updatedTimestamp
        orgId
        staffId
        recipientId
        organization {
          id
          ulid
          name
          businessHours
          businessNumber
          timezone
          orgType
          orgLogo
          orgPlan
          loggedInUsers {
            ulid
            session
          }
          stripeId
          stripeOnboarded
          smsThreads {
            nextToken
          }
          phoneNumbers {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      message
      smsSid
      to
      from
      contact
      status
      createdTimestamp
      isDismissed
      senderUlid
      attachment
      unread
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSms = /* GraphQL */ `
  subscription OnUpdateSms {
    onUpdateSms {
      id
      ulid
      threadId
      thread {
        id
        threadName
        ulid
        users
        smsmessages {
          items {
            id
            ulid
            threadId
            message
            smsSid
            to
            from
            contact
            status
            createdTimestamp
            isDismissed
            senderUlid
            attachment
            unread
            createdAt
            updatedAt
          }
          nextToken
        }
        messageType
        createdTimestamp
        isDismissed
        newMessageCount
        updatedTimestamp
        orgId
        staffId
        recipientId
        organization {
          id
          ulid
          name
          businessHours
          businessNumber
          timezone
          orgType
          orgLogo
          orgPlan
          loggedInUsers {
            ulid
            session
          }
          stripeId
          stripeOnboarded
          smsThreads {
            nextToken
          }
          phoneNumbers {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      message
      smsSid
      to
      from
      contact
      status
      createdTimestamp
      isDismissed
      senderUlid
      attachment
      unread
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSms = /* GraphQL */ `
  subscription OnDeleteSms {
    onDeleteSms {
      id
      ulid
      threadId
      thread {
        id
        threadName
        ulid
        users
        smsmessages {
          items {
            id
            ulid
            threadId
            message
            smsSid
            to
            from
            contact
            status
            createdTimestamp
            isDismissed
            senderUlid
            attachment
            unread
            createdAt
            updatedAt
          }
          nextToken
        }
        messageType
        createdTimestamp
        isDismissed
        newMessageCount
        updatedTimestamp
        orgId
        staffId
        recipientId
        organization {
          id
          ulid
          name
          businessHours
          businessNumber
          timezone
          orgType
          orgLogo
          orgPlan
          loggedInUsers {
            ulid
            session
          }
          stripeId
          stripeOnboarded
          smsThreads {
            nextToken
          }
          phoneNumbers {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      message
      smsSid
      to
      from
      contact
      status
      createdTimestamp
      isDismissed
      senderUlid
      attachment
      unread
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMsgs = /* GraphQL */ `
  subscription OnCreateMsgs {
    onCreateMsgs {
      id
      threadId
      message
      from
      attachment
      mediaUrl
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMsgs = /* GraphQL */ `
  subscription OnUpdateMsgs {
    onUpdateMsgs {
      id
      threadId
      message
      from
      attachment
      mediaUrl
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMsgs = /* GraphQL */ `
  subscription OnDeleteMsgs {
    onDeleteMsgs {
      id
      threadId
      message
      from
      attachment
      mediaUrl
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRoomPermissions = /* GraphQL */ `
  subscription OnCreateRoomPermissions {
    onCreateRoomPermissions {
      id
      threadId
      roles
      roleactions
      users
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRoomPermissions = /* GraphQL */ `
  subscription OnUpdateRoomPermissions {
    onUpdateRoomPermissions {
      id
      threadId
      roles
      roleactions
      users
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRoomPermissions = /* GraphQL */ `
  subscription OnDeleteRoomPermissions {
    onDeleteRoomPermissions {
      id
      threadId
      roles
      roleactions
      users
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSmsThread = /* GraphQL */ `
  subscription OnCreateSmsThread {
    onCreateSmsThread {
      id
      threadName
      ulid
      users
      smsmessages {
        items {
          id
          ulid
          threadId
          thread {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          message
          smsSid
          to
          from
          contact
          status
          createdTimestamp
          isDismissed
          senderUlid
          attachment
          unread
          createdAt
          updatedAt
        }
        nextToken
      }
      messageType
      createdTimestamp
      isDismissed
      newMessageCount
      updatedTimestamp
      orgId
      staffId
      recipientId
      organization {
        id
        ulid
        name
        businessHours
        businessNumber
        timezone
        orgType
        orgLogo
        orgPlan
        loggedInUsers {
          ulid
          session
        }
        stripeId
        stripeOnboarded
        smsThreads {
          items {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          nextToken
        }
        phoneNumbers {
          items {
            id
            number
            type
            inUse
            nickname
            orgDisplayName
            displayNumber
            default
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSmsThread = /* GraphQL */ `
  subscription OnUpdateSmsThread {
    onUpdateSmsThread {
      id
      threadName
      ulid
      users
      smsmessages {
        items {
          id
          ulid
          threadId
          thread {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          message
          smsSid
          to
          from
          contact
          status
          createdTimestamp
          isDismissed
          senderUlid
          attachment
          unread
          createdAt
          updatedAt
        }
        nextToken
      }
      messageType
      createdTimestamp
      isDismissed
      newMessageCount
      updatedTimestamp
      orgId
      staffId
      recipientId
      organization {
        id
        ulid
        name
        businessHours
        businessNumber
        timezone
        orgType
        orgLogo
        orgPlan
        loggedInUsers {
          ulid
          session
        }
        stripeId
        stripeOnboarded
        smsThreads {
          items {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          nextToken
        }
        phoneNumbers {
          items {
            id
            number
            type
            inUse
            nickname
            orgDisplayName
            displayNumber
            default
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSmsThread = /* GraphQL */ `
  subscription OnDeleteSmsThread {
    onDeleteSmsThread {
      id
      threadName
      ulid
      users
      smsmessages {
        items {
          id
          ulid
          threadId
          thread {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          message
          smsSid
          to
          from
          contact
          status
          createdTimestamp
          isDismissed
          senderUlid
          attachment
          unread
          createdAt
          updatedAt
        }
        nextToken
      }
      messageType
      createdTimestamp
      isDismissed
      newMessageCount
      updatedTimestamp
      orgId
      staffId
      recipientId
      organization {
        id
        ulid
        name
        businessHours
        businessNumber
        timezone
        orgType
        orgLogo
        orgPlan
        loggedInUsers {
          ulid
          session
        }
        stripeId
        stripeOnboarded
        smsThreads {
          items {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          nextToken
        }
        phoneNumbers {
          items {
            id
            number
            type
            inUse
            nickname
            orgDisplayName
            displayNumber
            default
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      firstName
      lastName
      ulid
      newnotif
      inapp
      vidnotif
      notifications {
        items {
          id
          userId
          user {
            id
            firstName
            lastName
            ulid
            newnotif
            inapp
            vidnotif
            currentJTI
            auth0Sub
            createdAt
            updatedAt
          }
          type
          createdTimestamp
          data
          form
          orgId
          isActive
          threadId
          createdAt
          updatedAt
        }
        nextToken
      }
      currentJTI
      auth0Sub
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      firstName
      lastName
      ulid
      newnotif
      inapp
      vidnotif
      notifications {
        items {
          id
          userId
          user {
            id
            firstName
            lastName
            ulid
            newnotif
            inapp
            vidnotif
            currentJTI
            auth0Sub
            createdAt
            updatedAt
          }
          type
          createdTimestamp
          data
          form
          orgId
          isActive
          threadId
          createdAt
          updatedAt
        }
        nextToken
      }
      currentJTI
      auth0Sub
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      firstName
      lastName
      ulid
      newnotif
      inapp
      vidnotif
      notifications {
        items {
          id
          userId
          user {
            id
            firstName
            lastName
            ulid
            newnotif
            inapp
            vidnotif
            currentJTI
            auth0Sub
            createdAt
            updatedAt
          }
          type
          createdTimestamp
          data
          form
          orgId
          isActive
          threadId
          createdAt
          updatedAt
        }
        nextToken
      }
      currentJTI
      auth0Sub
      createdAt
      updatedAt
    }
  }
`;
export const onCreateStripeTransaction = /* GraphQL */ `
  subscription OnCreateStripeTransaction {
    onCreateStripeTransaction {
      id
      userFrom
      orgTo
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStripeTransaction = /* GraphQL */ `
  subscription OnUpdateStripeTransaction {
    onUpdateStripeTransaction {
      id
      userFrom
      orgTo
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteStripeTransaction = /* GraphQL */ `
  subscription OnDeleteStripeTransaction {
    onDeleteStripeTransaction {
      id
      userFrom
      orgTo
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onCreateStripePayouts = /* GraphQL */ `
  subscription OnCreateStripePayouts {
    onCreateStripePayouts {
      id
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStripePayouts = /* GraphQL */ `
  subscription OnUpdateStripePayouts {
    onUpdateStripePayouts {
      id
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteStripePayouts = /* GraphQL */ `
  subscription OnDeleteStripePayouts {
    onDeleteStripePayouts {
      id
      stripeId
      object
      objectId
      amount
      fee
      net
      amountRefunded
      refundedTimestamp
      metadata
      livemode
      lastEventId
      raw
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInvoice = /* GraphQL */ `
  subscription OnCreateInvoice {
    onCreateInvoice {
      id
      ulid
      amount
      organizationId
      patientId
      type
      description
      currentStatus
      activity
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInvoice = /* GraphQL */ `
  subscription OnUpdateInvoice {
    onUpdateInvoice {
      id
      ulid
      amount
      organizationId
      patientId
      type
      description
      currentStatus
      activity
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInvoice = /* GraphQL */ `
  subscription OnDeleteInvoice {
    onDeleteInvoice {
      id
      ulid
      amount
      organizationId
      patientId
      type
      description
      currentStatus
      activity
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onCreateS3document = /* GraphQL */ `
  subscription OnCreateS3document {
    onCreateS3document {
      id
      ulid
      fileName
      fileUlid
      uploader
      accessibleOrgs
      createdTimestamp
      meta
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateS3document = /* GraphQL */ `
  subscription OnUpdateS3document {
    onUpdateS3document {
      id
      ulid
      fileName
      fileUlid
      uploader
      accessibleOrgs
      createdTimestamp
      meta
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteS3document = /* GraphQL */ `
  subscription OnDeleteS3document {
    onDeleteS3document {
      id
      ulid
      fileName
      fileUlid
      uploader
      accessibleOrgs
      createdTimestamp
      meta
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      userId
      user {
        id
        firstName
        lastName
        ulid
        newnotif
        inapp
        vidnotif
        notifications {
          items {
            id
            userId
            type
            createdTimestamp
            data
            form
            orgId
            isActive
            threadId
            createdAt
            updatedAt
          }
          nextToken
        }
        currentJTI
        auth0Sub
        createdAt
        updatedAt
      }
      type
      createdTimestamp
      data
      form
      orgId
      isActive
      threadId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      userId
      user {
        id
        firstName
        lastName
        ulid
        newnotif
        inapp
        vidnotif
        notifications {
          items {
            id
            userId
            type
            createdTimestamp
            data
            form
            orgId
            isActive
            threadId
            createdAt
            updatedAt
          }
          nextToken
        }
        currentJTI
        auth0Sub
        createdAt
        updatedAt
      }
      type
      createdTimestamp
      data
      form
      orgId
      isActive
      threadId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      userId
      user {
        id
        firstName
        lastName
        ulid
        newnotif
        inapp
        vidnotif
        notifications {
          items {
            id
            userId
            type
            createdTimestamp
            data
            form
            orgId
            isActive
            threadId
            createdAt
            updatedAt
          }
          nextToken
        }
        currentJTI
        auth0Sub
        createdAt
        updatedAt
      }
      type
      createdTimestamp
      data
      form
      orgId
      isActive
      threadId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCallHistory = /* GraphQL */ `
  subscription OnCreateCallHistory {
    onCreateCallHistory {
      id
      participants {
        userVideoId
        name
        userId
        role
        time_joined
        time_left
        totalDuration
      }
      patientId
      host
      roomName
      meetingId
      historyId
      meetingRoom {
        id
        isJoinable
        isCompleted
        historyId
        callHistory {
          id
          participants {
            userVideoId
            name
            userId
            role
            time_joined
            time_left
            totalDuration
          }
          patientId
          host
          roomName
          meetingId
          historyId
          meetingRoom {
            id
            isJoinable
            isCompleted
            historyId
            currentParticipants
            createdAt
            updatedAt
          }
          duration
          createdTimestamp
          createdAt
          updatedAt
        }
        currentParticipants
        createdAt
        updatedAt
      }
      duration
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCallHistory = /* GraphQL */ `
  subscription OnUpdateCallHistory {
    onUpdateCallHistory {
      id
      participants {
        userVideoId
        name
        userId
        role
        time_joined
        time_left
        totalDuration
      }
      patientId
      host
      roomName
      meetingId
      historyId
      meetingRoom {
        id
        isJoinable
        isCompleted
        historyId
        callHistory {
          id
          participants {
            userVideoId
            name
            userId
            role
            time_joined
            time_left
            totalDuration
          }
          patientId
          host
          roomName
          meetingId
          historyId
          meetingRoom {
            id
            isJoinable
            isCompleted
            historyId
            currentParticipants
            createdAt
            updatedAt
          }
          duration
          createdTimestamp
          createdAt
          updatedAt
        }
        currentParticipants
        createdAt
        updatedAt
      }
      duration
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCallHistory = /* GraphQL */ `
  subscription OnDeleteCallHistory {
    onDeleteCallHistory {
      id
      participants {
        userVideoId
        name
        userId
        role
        time_joined
        time_left
        totalDuration
      }
      patientId
      host
      roomName
      meetingId
      historyId
      meetingRoom {
        id
        isJoinable
        isCompleted
        historyId
        callHistory {
          id
          participants {
            userVideoId
            name
            userId
            role
            time_joined
            time_left
            totalDuration
          }
          patientId
          host
          roomName
          meetingId
          historyId
          meetingRoom {
            id
            isJoinable
            isCompleted
            historyId
            currentParticipants
            createdAt
            updatedAt
          }
          duration
          createdTimestamp
          createdAt
          updatedAt
        }
        currentParticipants
        createdAt
        updatedAt
      }
      duration
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePaymentTags = /* GraphQL */ `
  subscription OnCreatePaymentTags {
    onCreatePaymentTags {
      id
      organizationId
      title
      color
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePaymentTags = /* GraphQL */ `
  subscription OnUpdatePaymentTags {
    onUpdatePaymentTags {
      id
      organizationId
      title
      color
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePaymentTags = /* GraphQL */ `
  subscription OnDeletePaymentTags {
    onDeletePaymentTags {
      id
      organizationId
      title
      color
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEfaxTags = /* GraphQL */ `
  subscription OnCreateEfaxTags {
    onCreateEfaxTags {
      id
      organizationId
      title
      color
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEfaxTags = /* GraphQL */ `
  subscription OnUpdateEfaxTags {
    onUpdateEfaxTags {
      id
      organizationId
      title
      color
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEfaxTags = /* GraphQL */ `
  subscription OnDeleteEfaxTags {
    onDeleteEfaxTags {
      id
      organizationId
      title
      color
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAppointment = /* GraphQL */ `
  subscription OnCreateAppointment {
    onCreateAppointment {
      id
      text
      description
      appointmentType
      startDate
      endDate
      allDay
      organizationId
      assigneeId
      patientId
      status
      color
      copay
      willAttend
      appointmentNotes {
        items {
          id
          userName
          userId
          appointmentId
          appointmentNote
          createdTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAppointment = /* GraphQL */ `
  subscription OnUpdateAppointment {
    onUpdateAppointment {
      id
      text
      description
      appointmentType
      startDate
      endDate
      allDay
      organizationId
      assigneeId
      patientId
      status
      color
      copay
      willAttend
      appointmentNotes {
        items {
          id
          userName
          userId
          appointmentId
          appointmentNote
          createdTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAppointment = /* GraphQL */ `
  subscription OnDeleteAppointment {
    onDeleteAppointment {
      id
      text
      description
      appointmentType
      startDate
      endDate
      allDay
      organizationId
      assigneeId
      patientId
      status
      color
      copay
      willAttend
      appointmentNotes {
        items {
          id
          userName
          userId
          appointmentId
          appointmentNote
          createdTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAppointmentNotes = /* GraphQL */ `
  subscription OnCreateAppointmentNotes {
    onCreateAppointmentNotes {
      id
      userName
      userId
      appointmentId
      appointmentNote
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAppointmentNotes = /* GraphQL */ `
  subscription OnUpdateAppointmentNotes {
    onUpdateAppointmentNotes {
      id
      userName
      userId
      appointmentId
      appointmentNote
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAppointmentNotes = /* GraphQL */ `
  subscription OnDeleteAppointmentNotes {
    onDeleteAppointmentNotes {
      id
      userName
      userId
      appointmentId
      appointmentNote
      createdTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMeetingRoom = /* GraphQL */ `
  subscription OnCreateMeetingRoom {
    onCreateMeetingRoom {
      id
      isJoinable
      isCompleted
      historyId
      callHistory {
        id
        participants {
          userVideoId
          name
          userId
          role
          time_joined
          time_left
          totalDuration
        }
        patientId
        host
        roomName
        meetingId
        historyId
        meetingRoom {
          id
          isJoinable
          isCompleted
          historyId
          callHistory {
            id
            patientId
            host
            roomName
            meetingId
            historyId
            duration
            createdTimestamp
            createdAt
            updatedAt
          }
          currentParticipants
          createdAt
          updatedAt
        }
        duration
        createdTimestamp
        createdAt
        updatedAt
      }
      currentParticipants
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMeetingRoom = /* GraphQL */ `
  subscription OnUpdateMeetingRoom {
    onUpdateMeetingRoom {
      id
      isJoinable
      isCompleted
      historyId
      callHistory {
        id
        participants {
          userVideoId
          name
          userId
          role
          time_joined
          time_left
          totalDuration
        }
        patientId
        host
        roomName
        meetingId
        historyId
        meetingRoom {
          id
          isJoinable
          isCompleted
          historyId
          callHistory {
            id
            patientId
            host
            roomName
            meetingId
            historyId
            duration
            createdTimestamp
            createdAt
            updatedAt
          }
          currentParticipants
          createdAt
          updatedAt
        }
        duration
        createdTimestamp
        createdAt
        updatedAt
      }
      currentParticipants
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMeetingRoom = /* GraphQL */ `
  subscription OnDeleteMeetingRoom {
    onDeleteMeetingRoom {
      id
      isJoinable
      isCompleted
      historyId
      callHistory {
        id
        participants {
          userVideoId
          name
          userId
          role
          time_joined
          time_left
          totalDuration
        }
        patientId
        host
        roomName
        meetingId
        historyId
        meetingRoom {
          id
          isJoinable
          isCompleted
          historyId
          callHistory {
            id
            patientId
            host
            roomName
            meetingId
            historyId
            duration
            createdTimestamp
            createdAt
            updatedAt
          }
          currentParticipants
          createdAt
          updatedAt
        }
        duration
        createdTimestamp
        createdAt
        updatedAt
      }
      currentParticipants
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBulkPatient = /* GraphQL */ `
  subscription OnCreateBulkPatient {
    onCreateBulkPatient {
      id
      success
      failure
      documentId
      completed
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBulkPatient = /* GraphQL */ `
  subscription OnUpdateBulkPatient {
    onUpdateBulkPatient {
      id
      success
      failure
      documentId
      completed
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBulkPatient = /* GraphQL */ `
  subscription OnDeleteBulkPatient {
    onDeleteBulkPatient {
      id
      success
      failure
      documentId
      completed
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrganization = /* GraphQL */ `
  subscription OnCreateOrganization {
    onCreateOrganization {
      id
      ulid
      name
      businessHours
      businessNumber
      timezone
      orgType
      orgLogo
      orgPlan
      loggedInUsers {
        ulid
        session
      }
      stripeId
      stripeOnboarded
      smsThreads {
        items {
          id
          threadName
          ulid
          users
          smsmessages {
            nextToken
          }
          messageType
          createdTimestamp
          isDismissed
          newMessageCount
          updatedTimestamp
          orgId
          staffId
          recipientId
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      phoneNumbers {
        items {
          id
          number
          type
          inUse
          nickname
          orgDisplayName
          displayNumber
          default
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrganization = /* GraphQL */ `
  subscription OnUpdateOrganization {
    onUpdateOrganization {
      id
      ulid
      name
      businessHours
      businessNumber
      timezone
      orgType
      orgLogo
      orgPlan
      loggedInUsers {
        ulid
        session
      }
      stripeId
      stripeOnboarded
      smsThreads {
        items {
          id
          threadName
          ulid
          users
          smsmessages {
            nextToken
          }
          messageType
          createdTimestamp
          isDismissed
          newMessageCount
          updatedTimestamp
          orgId
          staffId
          recipientId
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      phoneNumbers {
        items {
          id
          number
          type
          inUse
          nickname
          orgDisplayName
          displayNumber
          default
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrganization = /* GraphQL */ `
  subscription OnDeleteOrganization {
    onDeleteOrganization {
      id
      ulid
      name
      businessHours
      businessNumber
      timezone
      orgType
      orgLogo
      orgPlan
      loggedInUsers {
        ulid
        session
      }
      stripeId
      stripeOnboarded
      smsThreads {
        items {
          id
          threadName
          ulid
          users
          smsmessages {
            nextToken
          }
          messageType
          createdTimestamp
          isDismissed
          newMessageCount
          updatedTimestamp
          orgId
          staffId
          recipientId
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      phoneNumbers {
        items {
          id
          number
          type
          inUse
          nickname
          orgDisplayName
          displayNumber
          default
          organization {
            id
            ulid
            name
            businessHours
            businessNumber
            timezone
            orgType
            orgLogo
            orgPlan
            stripeId
            stripeOnboarded
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePhoneNumbers = /* GraphQL */ `
  subscription OnCreatePhoneNumbers {
    onCreatePhoneNumbers {
      id
      number
      type
      inUse
      nickname
      orgDisplayName
      displayNumber
      default
      organization {
        id
        ulid
        name
        businessHours
        businessNumber
        timezone
        orgType
        orgLogo
        orgPlan
        loggedInUsers {
          ulid
          session
        }
        stripeId
        stripeOnboarded
        smsThreads {
          items {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          nextToken
        }
        phoneNumbers {
          items {
            id
            number
            type
            inUse
            nickname
            orgDisplayName
            displayNumber
            default
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePhoneNumbers = /* GraphQL */ `
  subscription OnUpdatePhoneNumbers {
    onUpdatePhoneNumbers {
      id
      number
      type
      inUse
      nickname
      orgDisplayName
      displayNumber
      default
      organization {
        id
        ulid
        name
        businessHours
        businessNumber
        timezone
        orgType
        orgLogo
        orgPlan
        loggedInUsers {
          ulid
          session
        }
        stripeId
        stripeOnboarded
        smsThreads {
          items {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          nextToken
        }
        phoneNumbers {
          items {
            id
            number
            type
            inUse
            nickname
            orgDisplayName
            displayNumber
            default
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePhoneNumbers = /* GraphQL */ `
  subscription OnDeletePhoneNumbers {
    onDeletePhoneNumbers {
      id
      number
      type
      inUse
      nickname
      orgDisplayName
      displayNumber
      default
      organization {
        id
        ulid
        name
        businessHours
        businessNumber
        timezone
        orgType
        orgLogo
        orgPlan
        loggedInUsers {
          ulid
          session
        }
        stripeId
        stripeOnboarded
        smsThreads {
          items {
            id
            threadName
            ulid
            users
            messageType
            createdTimestamp
            isDismissed
            newMessageCount
            updatedTimestamp
            orgId
            staffId
            recipientId
            createdAt
            updatedAt
          }
          nextToken
        }
        phoneNumbers {
          items {
            id
            number
            type
            inUse
            nickname
            orgDisplayName
            displayNumber
            default
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
