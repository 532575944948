import React, { useState } from 'react';
import {Tab, Tabs} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AccountServices from './AccountServices.js'
import AccountBilling from './AccountBilling.js'
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
    padding: '10px',
    paddingBottom: '30px',
  },
  tabs: {
    display: 'flex',
    color: '#707070',
    background: '#dff2fa',
    borderRadius: '5px',
    height: '25px',
    width: 'fit-content',
    margin: 'auto',
    border: '1px solid #e8e8e8'
  },
  tab: {
    background: 'transparent',
    border: 'none',
    display: 'flex',
    width: '150px',
    '& > div': {
      margin: 'auto',
      color: '#707070'
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.8'
    }
  },
  activetab: {
    background: '#9ed8f2',
    borderRadius: 5,
    border: 'none',
    display: 'flex',
    width: '150px',
    '& > div': {
      margin: 'auto',
      color: '#707070'
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.8'
    }
  },
}))


const AccountTab = () => {
  
  const classes = useStyles();
  const [tab, setTab] = useState('billing');
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabs}
        indicatorColor="primary"
        textColor="white"
        onChange={handleChange}
        value={tab}
        centered
      >
        <Tab className={tab === 'billing' ? classes.activetab : classes.tab} label="Billing" value='billing'/>
        <Tab className={tab === 'services' ? classes.activetab : classes.tab} label="Services" value='services'/>
      </Tabs>
      {tab === 'billing' &&         
        <Elements stripe={stripePromise}>
          <AccountBilling /> 
        </Elements>}
      {tab === 'services' && <AccountServices />}
    </div>
  );
}

export default AccountTab;
