import OrgType from "../organization/orgNav";
import React, { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@mui/styles';

import {
  Drawer,
  List,
  CssBaseline,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';

import {
  Menu as MenuIcon, 
  ChevronLeft as ChevronLeftIcon
} from '@mui/icons-material';


import { useAuth0 } from "../../react-auth0-spa";
import { LOGOUT_URL } from "../../config";
import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalLanguageContext } from '../LocalLanguageProvider'

const drawerWidth = 174;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  menuButton: {
    color: 'whitesmoke',
    padding: '4px'
  },
  menu: {
    border: 'none',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',    
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    backgroundColor: '#1b1919',
    zIndex:0
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5),
    backgroundColor: '#1b1919',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  icon: {
    color: 'whitesmoke',
    minWidth: '30px',
  },
  text: {
    paddingLeft: '0px',
    color: 'whitesmoke',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 'normal',
    fontSize: '14px'
  },
  listButton: {
    padding: '10px',
    '&:hover': {
      backgroundColor: '#47afeb'
    }
  }
}));

export default function MiniDrawer({twilioChat, twilioChatUnread, twilioSecureMessageUnread}) {
  const { isAuthenticated, loginWithRedirect, logout, loading } = useAuth0();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const { workerLang } = useContext(LocalLanguageContext);

  const LokalisedContent = (key, fallback) => {
    const translated_string = workerLang[key];
    return translated_string ? translated_string : fallback;
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    if(width <= 768){
      setOpen(false)
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


    const toggleBar = () => {    
      const body = document.querySelector('body');
      var spans = document.getElementById('SBTCenterContent').getElementsByTagName('span');
      if (body.classList.contains('small')) {
        body.classList.remove('small');
        if(width <= 768 && window.location.pathname === '/timeclock'){
        spans[2].style['left'] = '121px';
        }
      } else {
        body.classList.add('small');
        if(width <= 768 && window.location.pathname === '/timeclock'){
        spans[2].style['left'] = '60px';
        }
      }
    };

  const handleDrawerOpen = () => {
    toggleBar()
    setOpen(true);
  };

  const handleDrawerClose = () => {
    toggleBar()
    setOpen(false);
  };
  

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
        </div>
        <Divider />
        <ListItem button className={classes.menu} onClick={open ? handleDrawerClose : handleDrawerOpen}>
          <ListItemIcon style={{paddingLeft: '0px'}}>
            {!open ?
            <IconButton onClick={() => {handleDrawerOpen();toggleBar()}} edge="start" className={classes.menuButton} >
              <MenuIcon />
            </IconButton>
            : 
            <IconButton onClick={() => {handleDrawerClose();toggleBar()}} edge="start" className={classes.menuButton}>
              <ChevronLeftIcon />
            </IconButton>
            }
          </ListItemIcon>
        </ListItem>

        <Divider className={classes.menu} />

        {!isAuthenticated && !loading && (
          <ListItem button className={classes.listButton} onClick={() => loginWithRedirect({})}>
            <ListItemIcon className={classes.icon}>{<FontAwesomeIcon icon={faSignInAlt} fixedWidth/>}</ListItemIcon>
            <ListItemText 
              disableTypography
              primary={<Typography type="body2" className={classes.text}>Log in</Typography>} 
            />
          </ListItem>
        )}

        {isAuthenticated && (
          <List className={classes.menu}>
            <OrgType twilioChatUnread={twilioChatUnread} twilioSecureMessageUnread={twilioSecureMessageUnread}/>
            <ListItem button className={classes.listButton} onClick={() => logout({ returnTo: LOGOUT_URL + '?logout=true' })}>
              <ListItemIcon className={classes.icon}>{<FontAwesomeIcon icon={faSignOutAlt} fixedWidth/>}</ListItemIcon>
              <ListItemText 
                disableTypography
                primary={<Typography type="body2" className={classes.text}>
                  {LokalisedContent('nav_bar_logout', 'Log Out')}
                </Typography>} 
              />
            </ListItem>
          </List>
        )}
      </Drawer>
    </div>
  );
}

