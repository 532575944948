import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Session from '../../session';
import { useLocation } from "react-router";

const Img = styled.img`
height: 60px;
max-width: 174px;
min-width: 174px;
z-index:999;
background:white;
`;

const LogoHeader = () => {
    let location = useLocation();
     const path = location.pathname;
    const [logo, setLogo] = useState('/ExamRoomLive_Logo_headerLogo.svg');
    const [isMeeting, setIsMeeting] = useState(false);

    useEffect(() => {
        const logoPath = Session.OrgLogoUrl;
        if (logoPath !== null && logoPath !== undefined) { setLogo(logoPath); }
        else { setLogo('/ExamRoomLive_Logo_headerLogo.svg'); }
    }, [])
    
    useEffect(()=>{
        if(path === '/meeting' || path === '/guest'){ setIsMeeting(true); }
        else { setIsMeeting(false) }
    },[path])

    return ( <> {isMeeting ? <Img alt="logo-header" src={logo} /> :
        <Link to="/" style={{ display: 'flex' }}>
            <Img alt="logo-header" src={logo} />
        </Link>
     }</>);
};

export default LogoHeader;