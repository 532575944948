import { useEffect, useState } from 'react';
import { useLazyQuery, gql, useSubscription } from '@apollo/client';
import Session from '../session';
import { smsThreadsByUlid } from '../graphql/queries';
import { subscribeToSmsThreadByOrg } from '../graphql/subscriptions';

export const useSmsThreads = () => {
    const orgId = Session.OrganizationId;
    const [smsThreads, setSmsThreads] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [threadsLoaded, setThreadsLoaded] = useState(false);

    const [getListThreads, {data: listSmsThreadsResult}] = useLazyQuery(gql(smsThreadsByUlid));

    useEffect(() => {
        if (!orgId) {
            return;
        }
        getListThreads({
            variables: {
                ulid: orgId,
            }
        });
    }, [getListThreads, orgId]);


    useEffect(() => {
        if (listSmsThreadsResult && initialLoad) {
            if (listSmsThreadsResult.smsThreadsByUlid.items) {
                setThreadsLoaded(true);
                setSmsThreads(listSmsThreadsResult.smsThreadsByUlid.items);
                setInitialLoad(false);
            }
        }
        // eslint-disable-next-line
    }, [listSmsThreadsResult, initialLoad]);

    const {data: subscriptionUpdate} = useSubscription(gql(subscribeToSmsThreadByOrg), {
        variables: {
          ulid: orgId,
        }
    });
  
    useEffect(() => {

        if (subscriptionUpdate) {
            const delta = subscriptionUpdate.subscribeToSmsThreadByOrg;
            if(smsThreads.length === 0){
                setSmsThreads([delta]);
            } else {
                const threadBeingUpdated = smsThreads.find(x => x.id === delta.id);
        
                if(threadBeingUpdated){
                    threadBeingUpdated.newMessageCount = delta.newMessageCount;
                    const tempArray = [...smsThreads];
                    const uniqueArray = Array.from(new Set(tempArray));
                    setSmsThreads(uniqueArray);
        
                } else {
                    const tempArray = [...smsThreads, delta];
                    const uniqueArray = Array.from(new Set(tempArray));
                    setSmsThreads(uniqueArray);
                }
            }
        }
    
        // eslint-disable-next-line
    }, [subscriptionUpdate]);

    return [smsThreads, threadsLoaded];
};