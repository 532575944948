import React, { useState, useEffect } from "react";
import { getFullDate, getFullDuration } from '../../../utils/dateTimeFormat';
import { S3Document, DisplayFile } from '../s3Document';
import Currency from "../../../utils/formatCurrency";
import { ConvertMiles, WorkedTime, trimReceipt } from "../util";
import {
  Button,
  CircularProgress,
  Backdrop,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  TextField,
  Typography,
  IconButton,
  Grid,
  Tabs,
  Tab
} from '@mui/material';
import {
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import { withStyles, makeStyles } from '@mui/styles';
import Session from "../../../session";
import Confirmation from './ConfirmDeleteModule';
import '../clock.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  titleDate: {
    color: '#707070',
    fontSize: '18px',
  },
  tab: {
    color: '#707070',
    width: '100px',
  },
  labelContainer: {
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  tableCell: {
    color: '#707070',
    fontSize: '12px',
    padding: '15px',
    '&:hover': {
      background: '#dff2fa'
    },
    '&:active': {
      background: '#dff2fa'
    }
  },
  nonSelectedTab: {
    padding: '0 2px',
    margin: 0,
    height: '100%',
    borderBottom: '1px solid lightgray',
    maxWidth: '100%',
  },
  selectedTab: {
    padding: '0 2px',
    margin: 0,
    height: '100%',
    borderBottom: '1px solid lightgray',
    maxWidth: '100%',
    backgroundColor: 'rgb(223, 242, 250)'
  },
  activityTable: {
    borderCollapse: 'collapse',
    margin: '5px 0 3px',
    fontSize: '11px',
    '& tbody tr td': {
      paddingBottom: '3px',
    },
  },
  dataWidth: {
    paddingLeft: '15px',
    width: '50%',
  },
  dateText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    },
  },
  inputButtons:{
    top: '10px',
    height: "30px", 
    display: 'inline', 
    marginRight: 5, 
    color: 'white' 
  },
  submitButtons:{
    display: 'inline', margin: '5px',
    paddingLeft: '25px',
    paddingRight: '25px',
    color: 'white'
  },
  disabledButton: {
    background: '#e0e0e0',
    color: 'white',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  saveButton: {
    background: '#10D236',
    '&:hover': {
      background: '#0a8938',
      cursor: 'pointer'
    }
  },
  cancelButton:{
    background: '#E22C56',
    '&:hover': {
      background: '#971533',
      cursor: 'pointer'
    }
  },
  dialog: {
    height: '80%',
    top: 50,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      top: 0
    },
  },
  editRecieptContainer:{
    display: 'flex', 
    height: "50px", 
    paddingTop: 5,
    [theme.breakpoints.down('sm')]: {
      height: '100px',
      justifyContent: 'center',
      width: '100%',
      flexWrap: 'wrap'
    },
  },
}));

const styles = {
  smallIcon: {
    width: 18,
    height: 18,
  }
};

const DialogTitle = withStyles(styles)((props) => {
  const useStyles = makeStyles((theme) => ({
    dialogHeaderReceipts: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 80,
      },
    },
    dialogButton: {
      position: "absolute",
      top: "8px",
      right: "8px",
    },
    dialogButtonReceipts: {
      position: "absolute",
      top: "8px",
      right: "8px",
    }
  }));
  const classes = useStyles();
  const { children, onClose, receipts, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other} className={receipts ? null : classes.dialogHeaderReceipts}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={receipts ? classes.dialogButtonReceipts : classes.dialogButton}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});



const SubmitTimeSheet = ({
  isOpen,
  handlePreviewFile, 
  selectedFileUlid, 
  setSelectedFileUlid, 
  closeModal, 
  details,
  location,
  entryId,
  mileage,
  expense,
  note,
  api,
  events,
  nextExpectedActions,
  setFileUlids,
  setNextExpectedActions,
  setParent,
  setSubmitEvent,
}) => {
  const [openModel, setOpenModel] = useState(false);
  const [editInputs, setEditInputs] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [receiptName, setReceiptName] = useState('');
  const [deleteFile, setDeleteFile] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [totalHours, setTotalHours] = useState(0);
  const [loading, setLoading] = useState(false);
  const [vertTab, setVertTab] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    setOpenModel(isOpen)
  }, [isOpen]);

  useEffect(() => {
    if (details && details.length > 0) {
      setSelectedFileUlid(details[0]);
      setVertTab(0);
    } else if (details.length === 0) {
      setSelectedFileUlid('');
    }
  }, [details, setSelectedFileUlid]);

  useEffect(() => {
    const [worked] = WorkedTime(events)
    setTotalHours(worked);
  }, [events, nextExpectedActions])

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    if(width <= 768){
      setIsMobile(true)
      // window.FreshworksWidget('hide');
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveReceipt = async () => {
    try {
      setLoading(true);
      setEditInputs(false);
      await api.updateS3Object({
        fileName: receiptName,
        fileUlid: selectedFileUlid,
        accessibleOrgs: [Session.OrganizationId],
        meta:{
          hidden: true,
          type: 'adhoc'
        },
        newFileUlid: selectedFileUlid,
        uploader: Session.UserId,
        orgId: Session.OrganizationId,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditFile = (id, fileUlid) => {
    const span = document.getElementById(id);
    const oldFileName = span.innerHTML;
    setReceiptName(oldFileName);
    setSelectedFileUlid(fileUlid);
  };

  const cancelUpload = () => {
    setEditInputs(false);
  };

  const removeFile = async (fileUlid) => {
    setDeleteFile(fileUlid);
    setOpenDeleteModel(true);
  }

  const handleConfirm = async () => {
    try {
      setLoading(true);
      setEditInputs(false);
      const res = await api.submitTimesheet({ entryId, location });
      if (res.status === 200) {
        setNextExpectedActions(res.data.expectedNextEvent);
        setSubmitEvent(res.data.event);
      }

      setSubmitted(true);
      setSelectedFileUlid('');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = async () => {
    setLoading(true);
    const newUlids = details.filter(x => x !== deleteFile);
    setFileUlids(newUlids);
    try {
      await api.putTimesheetExpense({ entryId,
        fileUlids: newUlids
      });
      setFileUlids(newUlids);

      if (deleteFile === selectedFileUlid) {
        setSelectedFileUlid('');
        setReceiptName('');
        if (newUlids.length > 0) {
          setSelectedFileUlid(newUlids[0]);
          setVertTab(0);
        }
      };

      setDeleteFile('');
      setOpenDeleteModel(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const closeModel = () => {
    setParent(false);
    setOpenModel(false);
    setEditInputs(false);
    setSubmitted(false);
    closeModal();
  }

  return (
    <Dialog
      open={openModel} 
      onClose={closeModel}
      fullWidth={selectedFileUlid ? true : false}
      disableEscapeKeyDown
      className={classes.dialog}
      fullScreen={isMobile}
    >
      <DialogTitle onClose={closeModel} receipts={details && details.length > 0} >Submit Time</DialogTitle>
      <MuiDialogContent style={{padding: '0 5px 0 15px', height: 'auto'}}>
      <div className={classes.root}>
        <Backdrop
          className={classes.backdrop}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
      {!submitted ? 
        <Grid container spacing={1}>
          <Grid item xs={12} sm={selectedFileUlid ? 6 : 12}>
            <div className={classes.dateText}>{getFullDate(~~(Date.now()/1000))}</div>
            <strong className="sub-header">Today's Totals: </strong>
            <hr />
            <table className={classes.activityTable}>
              <tbody>
                <tr>
                  <td>Hours Worked:</td>
                  <td className={classes.dataWidth}>{getFullDuration(totalHours >= 86400 ? 86400 : totalHours)}</td>
                </tr>
                <tr>
                  <td>Mileage:</td>
                  <td className={classes.dataWidth}>{ConvertMiles(mileage)} Miles</td>
                </tr>
                <tr>
                  <td>Expenses:</td>
                  <td className={classes.dataWidth}>{Currency(expense)}</td>
                </tr>
                <tr>
                  <div>Notes:</div>
                  <td className={classes.dataWidth}>{note}</td>
                </tr>
              </tbody>
            </table>

            {details && details.length > 0 &&

              <div style={{paddingTop: '20px'}}>
                <Confirmation
                  isOpen={openDeleteModel}
                  setCancel={() => {
                    setOpenDeleteModel(false);
                  }}
                  setDelete={confirmDelete}
                />
                <strong className="sub-header">Receipts: </strong>
                <Tabs
                  orientation="vertical"
                  aria-label="Vertical tabs"
                  indicatorColor="primary"
                  style={{padding: 0, width: '100%'}}
                  value={vertTab}
                >
                  {details.map((file, index) => {
                    return (
                      <Tab
                        key={index}
                        className={(file === selectedFileUlid && vertTab === index) ? classes.selectedTab : classes.nonSelectedTab}
                        label={
                          <div style={{
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            width: '100%', 
                            height: '100%',
                          }}>
                            <S3Document style={{fontSize: '11px', paddingTop: '3px', paddingLeft: '10px', fontFamily: 'arial', textTransform: 'none'}} id={"s3file" + index} fileUlid={file} />
                            <span>
                              <IconButton disabled={editInputs} className={`icon cur-pointer`} size='small' aria-label='edit'
                                onClick={() => {
                                  handleEditFile("s3file" + index, file);
                                  setEditInputs(true);
                                }}
                              >
                                <EditIcon size='small' />
                              </IconButton>
                              <IconButton disabled={editInputs} className="icon cur-pointer" size='small' aria-label="delete"
                                onClick={() => removeFile(file)}
                              >
                                <DeleteIcon size='small'/>
                              </IconButton>
                            </span>
                          </div>
                        }
                        onClick={() => {
                          setSelectedFileUlid(file);
                          setVertTab(index);
                          handlePreviewFile("s3file" + index, file);
                          if(editInputs){
                            setEditInputs(false);
                            setReceiptName('');
                          }
                        }}
                      />
                    );
                  })}
                </Tabs>
              </div>
            }


          </Grid>
          {selectedFileUlid && 
            <Grid item xs={12} sm={6} style={{ margin: '15px 0', display: 'flex', justifyContent: 'center', width: '100%', flexDirection: 'column', alignItems: 'center'}} justify="center">
            {editInputs && (
                <div className={classes.editRecieptContainer}>
                <TextField 
                    style={{ width: '100%', marginRight: 5, height: '20px' }}
                    variant='outlined'
                    error={!receiptName.length > 0}
                    value={receiptName} 
                    onChange={(e) => {
                      setReceiptName(e.target.value);
                    }} 
                    onBlur={(e) => {
                      trimReceipt(e.target.value)
                    }}
                    label='Name Receipt'
                    type="text"
                    inputProps={{
                      style: {
                        width: '100%',
                        background: '#dff2fa',
                        fontSize: '10px',
                      },
                      maxLength: 25
                    }}
                  />
                  <>
                    <Button 
                      size='small'
                      disabled={!receiptName.length > 0} 
                      className={!receiptName.length > 0 ? `${classes.inputButtons} ${classes.disabledButton}` : `${classes.inputButtons} ${classes.saveButton}`} 
                      onClick={saveReceipt}
                    >
                      Save
                    </Button>
                    <Button 
                      size='small'
                      disabled={!receiptName.length > 0}
                      className={!receiptName.length > 0 ? `${classes.inputButtons} ${classes.disabledButton}` : `${classes.inputButtons} ${classes.cancelButton}`} 
                      onClick={cancelUpload}
                    >
                      Cancel
                    </Button>
                  </>
                </div>
              )}
              <div style={{display: 'flex', maxHeight: '400px', minHeight: '420px', paddingTop: '10px'}}>
                <DisplayFile docUlid={selectedFileUlid} docName={receiptName} style={{display: 'flex', maxHeight: '400px', minHeight: '420px', paddingTop: '10px'}}/>
              </div>
            </Grid>
          }
          <Grid item xs={12} style={{paddingTop: '15px', paddingBottom: '15px'}}>
            <center style={{width: '100%'}}>
              <p style={{color: '#707070', fontSize: 14 }}>Confirm End of Day Report</p>
              <Button
                variant='contained'
                className={`${classes.submitButtons} ${classes.cancelButton}`}
                onClick={closeModel}
              >
                Cancel
              </Button>
              <Button 
                variant='contained'
                className={`${classes.submitButtons} ${classes.saveButton}`}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </center>
          </Grid>
        </Grid> 
        : 
          <center className={classes.dialogContent}>
            <div style={{ color: '#707070', fontSize: 23, margin: '23px 0' }}>{getFullDate(~~(Date.now()/1000))}</div>
            <p style={{color: '#707070', margin: '28px 0'}}>Your time has successfully been submitted. You may review the Reports section for your time card history.</p>
            <Button
            variant='contained'
              color='primary'
              style={{
              float: 'initial', 
                marginBottom: 35
              }} 
              onClick={closeModel}
              className="icon cur-pointer"
            >
              Ok
            </Button>
          </center>
      }
      </div>
      </MuiDialogContent>
    </Dialog>
  )
}



const SubmitTimeSheetModal = ({ 
  modalIsOpen, 
  setModelOpen, 
  fileUlids, 
  location, 
  entryId, 
  mileage, 
  expense, 
  note, 
  api,
  events,
  nextExpectedActions,
  setFileUlids,
  setNextExpectedActions,
  setParent,
  setSubmitEvent,
}) => {
  const [selectedFileUlid, setSelectedFileUlid] = useState('');
  const [receiptName, setReceiptName] = useState('');
  const closeModal = () => setModelOpen(false);

  const handlePreviewFile = (id, fileUlid) => {
    setSelectedFileUlid('');
    const span = document.getElementById(id);
    const oldFileName = span.innerHTML;
    setReceiptName(oldFileName);
    setSelectedFileUlid(fileUlid);
  }

  return (
    <SubmitTimeSheet
      isOpen={modalIsOpen}
      selectedFileUlid={selectedFileUlid}
      handlePreviewFile={handlePreviewFile}
      closeModal={closeModal}
      details={fileUlids}
      receiptName={receiptName}
      setSelectedFileUlid={setSelectedFileUlid}
      location={location}
      entryId={entryId}
      mileage={mileage}
      expense={expense}
      note={note}
      api={api}
      events={events}
      nextExpectedActions={nextExpectedActions}
      setFileUlids={setFileUlids}
      setNextExpectedActions={setNextExpectedActions}
      setParent={setParent}
      setSubmitEvent={setSubmitEvent}
    />
  );
};

export default SubmitTimeSheetModal;