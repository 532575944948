import styled from "styled-components";

export const AddButton = styled.button`
background: #47afeb;
padding: 14px 21px;
color: white;
border: none;
min-width: max-content;
font-family: 'Roboto', sans-serif;
letter-spacing: 1px;
border-radius: 12px;
font-size: 16px;
margin-left: 20px;
&:focus {
  outline: none;
}
&:hover {
  cursor: pointer;
  opacity: 0.8;
}
&:active {
  outline: none;
}
`;

export const DetailButton = styled.button`
  background: #47afeb;
  padding: 5px 15px;
  color: white;
  border: none;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
  border-radius: 7px;
  font-size: small;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:active {
    outline: none;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`; 

export const Td = styled.td`
  font-family: 'Roboto', sans-serif;
  padding: 15px;
  font-size: small;
  color: darkslategray;
`;
export const Th = styled.th`
  font-family: 'Roboto', sans-serif;
  border-bottom: 1px solid #ccc;
  padding: 15px;
  text-align: left;
  font-size: small;
`;

export const ModalButton = styled.button`
  padding: 5px 12px;
  border-radius: 6px;
  background-color: #47afeb;
  color: whitesmoke;
  font-size: small;
  border: none;
  &:focus {
    outline: none;
  }
  &:active {
    outline: none; 
  }
&:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;
export const ProcessButton = styled.button`
  padding: 5px 12px;
  border-radius: 6px;
  background-color: grey;
  color: whitesmoke;
  font-size: small;
  border: none;
  &:focus {
    outline: none;
  }
  &:active {
    outline: none; 
  }
&:hover {
    cursor: pointer;
  }
`;
export const DisableButton = styled.button`
  padding: 5px 12px;
  border-radius: 6px;
  color: whitesmoke;
  font-size: small;
  border: none;
  &:focus {
    outline: none;
  }
  &:active {
    outline: none; 
  }
&:hover {
  cursor: not-allowed;
  opacity: 0.8;
  }
`;
export const P = styled.p`
color: red;
font-size: 12px;
margin: 0px;
`;
export const Message = styled.p`
text-align: left;
letter-spacing: 0px;
color: #707070;
opacity: 1;
margin: 0;
font-size: 10px;
`;