import React, { useState, useRef, useEffect } from "react";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { BlockPicker } from 'react-color';
import {isMobile} from 'react-device-detect';
import classNames from "classnames";
import {
  TextField,
  Autocomplete,
  Button,
  Snackbar,
  Alert as MuiAlert,
  createFilterOptions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
  ClickAwayListener,
  Tooltip
} from '@mui/material';
import {
  LocalOffer,
  Add
} from "@mui/icons-material";
import { withStyles, makeStyles } from '@mui/styles';

import StripeTextField from "./MaterialCardElement";
import genId from '../../utils/genConfirmId';
import CurrencyInput from "./newCurrencyInput";
import './paymentStyle.css';
import PaymentModal from './payModal';
import Session from "../../session";
import { capitalize } from '../../utils/capitalizeNames'
import {useAPI} from '../../utils/api';
import useSWR from 'swr';
import axios from 'axios';
import { API_URL } from '../../config';
import {usePaymentTags} from '../../appsync-hooks/paymentTagsHook';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  formB: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    maxWidth: '432px',
    width: '100%'
  },
  formC: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-between',
    padding: '0',
    margin: '5px',
    maxWidth: '350px',
    width: '100%',
  },
  wrapBtn: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0 0 0'
  },
  tag: {
    height: 24,
    position: "relative",
    zIndex: 0,
    "& .MuiChip-label": {
      color: "#fff",
    },
    
    "&:after": {
      content: '""',
      right: 10,
      top: 6,
      height: 12,
      width: 12,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
    },
  },
  inputAuto: {
   "& .MuiAutocomplete-input": {
    height: '1.995em !important'
   }
  },
  grayTag: {
    backgroundColor: '#666666',
    "& .MuiChip-deleteIcon": {
      color: "#666666",
    },
  },
  redTag: {
    backgroundColor: '#85200E',
    "& .MuiChip-deleteIcon": {
      color: "#85200E",
    },
  },
  yellowTag: {
    backgroundColor: '#BF9021',
    "& .MuiChip-deleteIcon": {
      color: '#BF9021',
    },
  },
  purpleTag: {
    backgroundColor: '#674EA7',
    "& .MuiChip-deleteIcon": {
      color: "#674EA7",
    },
  },
  greenTag: {
    backgroundColor: '#34A853',
    "& .MuiChip-deleteIcon": {
      color: '#34A853',
    },
  }
}));

const RedTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor : '#85200e'
  },
  arrow: {
    color: '#85200e'
  },
}))(Tooltip);

const PurpleTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor : '#674ea7'
  },
  arrow: {
    color: '#674ea7'
  },
}))(Tooltip);

const GTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor : '#34a853'
  },
  arrow: {
    color: '#34a853'
  },
}))(Tooltip);

const GyTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor : '#666666'
  },
  arrow: {
    color: '#666666'
  },
}))(Tooltip);

const YwTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor : '#bf9021'
  },
  arrow: {
    color: '#bf9021'
  },
}))(Tooltip);


const CheckoutForm = (props) => {
  const classes = useStyles();
  const api = useAPI();
  const [isConnectedToPatient, setIsConnectedToPatient] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, isLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [amount, setAmount] = useState(0);
  const [comments, setComments] = useState("");
  const [modelOpen, setModelOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [patient, setPatient] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState('');
  // Snackbar stuff
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarUrl, setSnackBarUrl] = useState('');
  const [openTag, setOpenTag] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [paymentTags,createPyamentTagFn] = usePaymentTags();
  const [tempSelected, setTempSelected] = React.useState('');
  const [tagList, setTagList] = useState([]);

  const [severity, setSeverity] = useState('error');
  //Support Role disable form
  const [isDisabledForSupportRole, setIsDisabledForSupportRole] = useState(false);
  const currentOrgId = Session.OrganizationId;
  const userHomeOrgId = Session.HomeOrg;
  const hasSupportRole = Session.CustomOrgSwitcher;

  const url = `${API_URL}/v3/orgs/${Session.OrganizationId}/patients`;

  const refField1 = useRef();

  useEffect(()=>{
    if(paymentTags && paymentTags.length >0){
      setTagList(paymentTags);
      if(tempSelected !== ''){
        const findNew = paymentTags.find(x=>x.title === tempSelected.title);
        if(findNew!== null){
          setSelected(oldArray => [...oldArray, findNew]);
          setTempSelected('');
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[paymentTags]);

  useEffect(() => {
    const disableForm = hasSupportRole && (userHomeOrgId !== currentOrgId);
    disableForm ? setIsDisabledForSupportRole(true) : setIsDisabledForSupportRole(false);
  }, [userHomeOrgId, currentOrgId, hasSupportRole]);

  const fetcher = url => axios.get(url, {
    headers: {
        'Authorization': `Bearer ${Session.Token}`,
        'Content-Type': 'application/json',
    }
  }).then(res => res.data);

  const { data: patientOptions } = useSWR(() => url, fetcher);

  props.handlerReload(reload);
  
  const reset = () => {
    setPatient(null);
    setCustomer('');
    setAmount(0);
    setComments("");
  };

  const onAmountChange = (value) => {
    setAmount(value);
  };

  const onCommentChange = (e) => {
    setComments(e.target.value)
  };

  const handleSubmitNew = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || loading) {
      return;
    }
    isLoading(true);
    const cardElement = elements.getElement(CardElement);


    const paymentToken = await stripe.createToken(cardElement);

    if (paymentToken.token !== undefined) {
      const idToken = paymentToken.token.id
      const confirm_id = genId();
      const tagIds = [];
      selected.forEach(el=>{
        tagIds.push(el.id);
      });
      

      const payload = {
        "stripeToken": idToken,
        "amount": amount,
        "comment": comments,
        "confirmation": confirm_id,
        "OrganizationId": `${Session.OrganizationId}`,
        "patient": (isConnectedToPatient && patient !== null) ? `${capitalize(patient.firstName)} ${capitalize(patient.lastName)}` : customer,
        "patientUlid": (isConnectedToPatient && patient !== null) ? patient.ulid : null,
        "tag":tagIds
      };
      //console.log(payload)
      

      try {
        const sendpayment = await api.paymentCenterPay(payload);
        if (sendpayment &&
            sendpayment.data !== undefined && 
            sendpayment.status === 200) {
          setResponse(sendpayment.data);
          setSelected([]);
          handlerModel(true);
          reset();
          cardElement.clear();
        }
      } catch (error) {
        setError(true);
        setSeverity('error');
        setSnackBarMessage(error.response.data.message);
        setSnackBarUrl(error.response.data.redirectLink)
        setOpenSnackBar(true);
      };
      isLoading(false);
    };
  };

  const handlerModel = (status) => {
    if (status) { setReload(false) }
    setModelOpen(status);
  };

  const handlerReload = (status) => {
    setReload(status);
  };

  const handleCustomerChange = (event) => {
    setCustomer(event.target.value);
  };

  const handleCheckBoxClick = () => {
    setCustomer(null);
    setPatient(null);
    setIsConnectedToPatient(!isConnectedToPatient)
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleTagDisplay = () => {
    setOpenTag(!openTag)
  }

  const handleClickAway = () => {
    setOpenTag(!openTag)
  };

  const LimitTags = React.memo(() => {
    const filter = createFilterOptions();
    const [selectedColor, setSelectedColor] = useState('#666666');
    const [open, toggleOpen] = React.useState(false);
    const [dialogValue, setDialogValue] = React.useState({
      title: '',
      id: ''
    });
    const DialogClose = () => {
      setDialogValue({
        title: '',
        id: '',
      });
      toggleOpen(false);
    };

    const HandleAddNewTag = async () => {
      if(dialogValue.title === '' || dialogValue.title === null || dialogValue.title === undefined){
        return false;
      }
      const payload = {
        organizationId: Session.OrganizationId,
        title: dialogValue.title,
        color: selectedColor
      };
      await createPyamentTagFn(payload);
      setTempSelected(payload);
      toggleOpen(false);
    }

    const handlColorChange = (color) => {
      if(color.hex){
      setSelectedColor(color.hex);
       }
    }

    let blockStyles={
      default: { 
        input:{
          display: 'none'
        },
        label:{
          display: 'none'
        }
      },
    };

    return (
      <>
        <Autocomplete
          clearOnBlur={false}
          filterSelectedOptions={true}
          multiple
          id="multiple-limit-tags"
          options={tagList}
          value={selected}
          onChange={(event, newValue) => {
            if(newValue && event.code === 'Enter' && newValue.filter(x=>!x.hasOwnProperty('id')).length>0){
              const nv = newValue.filter(x=>!x.hasOwnProperty('id'));
              setDialogValue({
                title: nv[0],
                id: '',
              });
              toggleOpen(true);
              return
            }
            if (newValue.length <= 5) {
            if(newValue && newValue.filter(x=>x.isNew).length>0){
                // timeout to avoid instant validation of the dialog's form.
                setTimeout(() => {
                  toggleOpen(true);
                  setDialogValue({
                    title: event.target.innerText.substring('4'),
                    id: '',
                  });
                });
            }
            else {
            setSelected(newValue.filter(x=>x.isNew !== true))
            }
            } else {
              return;
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add ${params.inputValue}`,
                isNew:true
              });
            }
            return filtered;
          }}
          getOptionLabel={(options) => options.title}
          freeSolo
          renderInput={(params) => (
            <TextField  {...params} placeholder={selected && selected.length>0 ? null : 'Select Tags'} 
            inputProps={{ ...params.inputProps, maxLength: 10}}/> 
          )}
          sx={{ width: '284px' }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                classes={{
                  root:classNames(classes.tag,{
                    [classes.redTag]: option.color === '#85200e',
                    [classes.grayTag]: option.color === '#666666',
                    [classes.yellowTag]: option.color === '#bf9021',
                    [classes.purpleTag]: option.color === '#674ea7',
                    [classes.greenTag]: option.color === '#34a853',
                  })
                }}
                label={`${option.title}`}
                {...getTagProps({ index })}
              />
            ))
          }
        />
        <Dialog 
          open={open} 
          onClose={DialogClose}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "278px",  // Set your width here
              },
            },
          }}
        >
            <DialogTitle>Add a new tag</DialogTitle>
            <DialogContent
              sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
            >
              <TextField
                autoFocus
                margin="dense"
                id="name"
                value={dialogValue.title}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    title: event.target.value,
                  })
                }
                inputProps={{
                  maxLength: 10,
                }}
                label="Tag Name"
                type="text"
                variant='outlined'
              />
              <DialogContentText sx={{paddingBottom:'10px', alignSelf: 'baseline'}}>
              Select tag color
              </DialogContentText>
              <BlockPicker 
              triangle="hide"
              styles={blockStyles}
              color={selectedColor ? selectedColor : '#666666'}
              onChangeComplete={handlColorChange}
              colors={['#666666','#85200E','#BF9021','#674EA7','#34A853']}
              />
            </DialogContent>
            <DialogActions>
              <Button style={{backgroundColor:'#E22C56', color:'white',maxHeight:'32px'}} onClick={DialogClose}>Cancel</Button>
              <Button style={{backgroundColor:'#10D236', color:'white',maxHeight:'32px'}} disabled={dialogValue.title !== '' ? false: true} onClick={HandleAddNewTag}>Save</Button>
            </DialogActions>
        </Dialog>
        </>
    );
  });

  return (
    <>
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert 
          onClose={handleClose} 
          severity={severity}
          sx={{ width: '100%' }}
        >
          {snackBarMessage} {snackBarUrl ? <><br/><a href={snackBarUrl}> {snackBarUrl} </a></>: ''}
        </Alert>
      </Snackbar>
      <div style={{ display: 'flex', }}>
        <div className={classes.formB}>
          <div>
            <div className={classes.formC}>
                <div style={{width: 'calc(100% - 120px)'}}>
                </div>
                <input
                  disabled={loading || isDisabledForSupportRole}
                  style={{margin: '18px 10px 0px 0px'}}
                  type="checkbox"
                  checked={isConnectedToPatient}
                  onChange={handleCheckBoxClick}
                />
                <p style={{fontStyle: 'normal', fontSize: 12, fontWeight: 'normal', margin: '18px 3px 0px 0px', width: '120px'}}>
                  Connect to patient 
                </p>    
            </div>
            {isConnectedToPatient ?
            <div style={{margin: '10px 0 15px 0'}}>
              <Autocomplete
                disabled={loading || isDisabledForSupportRole}
                value={patient}
                clearOnBlur
                selectOnFocus
                onChange={(event, newValue) => {
                  setPatient(newValue);
                }}
                inputValue={searchInputValue}
                onInputChange={(event, newInputValue) => {
                  setSearchInputValue(newInputValue);
                }}
                renderOption={(props, option, { selected }) => {
                  const keystrippedprops = props;
                  delete keystrippedprops.key;
                  return (
                  <li {...keystrippedprops}>
                  <div style={{fontSize: '16px', fontStyle: 'normal', fontFamily: 'Arial,Helvetica,sans-serif',}}>
                      {`${option.firstName} ${option.lastName}`}
                      <br />
                      <div style={{fontSize: '12px', fontStyle: 'normal', fontFamily: 'Arial,Helvetica,sans-serif', fontWeight: 'lighter'}}>
                        {`${option.dob}`}
                      </div>
                    </div>
                  </li>
                )}}
                options={patientOptions || []}
                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                renderInput={(params) => (
                  <TextField
                    disabled={loading} 
                    {...params}
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    label='Patient Name'
                    InputProps={{
                      ...params.InputProps, 
                      placeholder: 'Please select a patient', 
                      style: {
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontFamily: 'Arial,Helvetica,sans-serif',
                        height: 50,
                        padding: '5px 5px 5px 13px',
                      }
                    }}
                  />
                )}
              />
            </div>
            : <div style={{margin: '10px 0 15px 0'}}>
                <TextField 
                  onKeyPress={(e) => { if (e.key === 'Enter') {refField1.current.focus()} }}
                  disabled={loading || isDisabledForSupportRole}
                  value={customer} 
                  variant='outlined'
                  fullWidth 
                  onChange={handleCustomerChange}
                  InputLabelProps={{
                    shrink: true
                  }}
                  label='Customer Name'
                  placeholder='Please enter customer name'
                  InputProps={{
                    style: {
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontFamily: 'Arial,Helvetica,sans-serif',
                      height: 50,
                      padding: '5px',
                    }
                  }}
                />
              </div>}
          </div>
          <div style={{margin: '10px 0 15px 0'}}>
            <StripeTextField 
              inputRef={refField1}
              disabledValue={loading || isDisabledForSupportRole}
              label="Credit Card" 
              stripeElement={CardElement}
              error={error}
              onChange={(e) => {
                  if(e.error){
                    setError(true)
                  }else{
                    setError(false);
                  }
                  setCardComplete(e.complete);
              }}/>
          </div>

          <div style={{margin: '10px 0 15px 0', display:"-webkit-box", position: isMobile ? 'static' : 'relative'}}>
            <CurrencyInput
              disabled={loading || isDisabledForSupportRole}
              onValueChange={onAmountChange} 
              value={amount}
              height={56}
              currencyLabel={'Payment Amount'}
            />
            {
              !openTag 
                && 
              !selected.length > 0 
                &&
              !isMobile
                &&
              <Tooltip title="Add Tag">
                <span onClick={handleTagDisplay} style={{cursor:'pointer'}}>
              <LocalOffer style={{margin: '15px 0px 0px 5px', color:'darkgray'}}/>
              <Add style={{color:'#47AFEB'}}/>
              </span>
              </Tooltip>
            }
            {openTag ? <div style={{position: 'absolute', zIndex: 800, right: -140, top: 0, backgroundColor:'white'}}><ClickAwayListener onClickAway={handleClickAway}><div><LimitTags/></div></ClickAwayListener></div>: selected && selected.length > 0 && selected.map((el,index) => {
              return (
                <>{el.color === '#85200e' ? <RedTooltip title={el.title} arrow><LocalOffer onClick={handleTagDisplay} style={{margin: '15px 0px 0px 5px',cursor:'pointer', color:`${el.color}`}}/></RedTooltip> 
            : el.color === '#674ea7'? <PurpleTooltip title={el.title} arrow><LocalOffer onClick={handleTagDisplay} style={{margin: '15px 0px 0px 5px',cursor:'pointer', color:`${el.color}`}}/></PurpleTooltip>
            : el.color === '#34a853'? <GTooltip title={el.title} arrow><LocalOffer onClick={handleTagDisplay} style={{margin: '15px 0px 0px 5px',cursor:'pointer', color:`${el.color}`}}/></GTooltip>
            : el.color === '#666666'? <GyTooltip title={el.title} arrow><LocalOffer onClick={handleTagDisplay} style={{margin: '15px 0px 0px 5px',cursor:'pointer', color:`${el.color}`}}/></GyTooltip>
            : <YwTooltip title={el.title} arrow><LocalOffer onClick={handleTagDisplay} style={{margin: '15px 0px 0px 5px',cursor:'pointer', color:`${el.color}`}}/></YwTooltip> }</>
              // <LocalOffer onClick={handleTagDisplay} style={{margin: '15px 0px 0px 5px',cursor:'pointer', color:`${el.color}`}}/>
              )
            })}
          </div>

          <div style={{margin: '10px 0 15px 0'}}>
            <TextField 
              disabled={loading || isDisabledForSupportRole}
              value={comments} 
              variant='outlined'
              fullWidth
              multiline
              rows={7}
              onChange={onCommentChange}
              InputLabelProps={{
                shrink: true
              }}
              label='Comments'
              placeholder='Comments here..'
              InputProps={{
                style: {
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontFamily: 'Arial,Helvetica,sans-serif',
                  padding: '12px',
                }
              }}
            />
          </div>
          <div className={classes.wrapBtn}> 
            {amount === 0 || !cardComplete ? 
              <Button variant="contained" disabled style={{opacity: 0.5 }}>
                Process Payment
              </Button>
              :
              <Button variant="contained" color='primary' onClick={handleSubmitNew} className="icon cur-pointer" disabled={!stripe || loading || isDisabledForSupportRole}>
                {loading ? 'Processing...' : 'Process Payment'}
              </Button>
            }
          </div>
        </div>
      </div>
      {modelOpen ?
        <PaymentModal modalOpen={modelOpen} response={response} handlerModal={handlerModel} handlerReload={handlerReload} isReload={true} />
        : ''}
    </>
  );
};

export default CheckoutForm;
