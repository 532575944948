import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {Button} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  downCircle: {
    height: "100px",
    width: "200px",
    borderRadius: "0 0 150px 150px",
    border: "7px solid #8080801f",
    borderTop: 'none',
    textAlign: 'center',
    fontSize: '24px',
    [theme.breakpoints.down('sm')]: {
      height: '75px',
      width: '150px',
      fontSize: '10px'
    },
  },
}))

const basicTheme = createTheme({
  palette: {
    primary: {
      main: '#0ebd4b',
      dark: '#0d8537',
      contrastText: '#fff',
    },
    secondary: {
      main: '#cf2f38',
      dark: '#9c2229',
      contrastText: '#fff',
    },
  },
});

const breakAndLocationDisabledTheme = createTheme({
  palette: {
    primary: {
      main: '#29e339',
      dark: '#229c2d',
      contrastText: '#fff',
    },
    secondary: {
      main: '#c0c0c0',
      dark: '#a9a9a9',
      contrastText: '#fff',
    },
  },
});

export default function BottomCircleButton({children, event, onClick, delayState}) {
  const classes = useStyles();

  return (
    <>
      {event === 'Clock In' 
              ? 
              <ThemeProvider theme={basicTheme}>
                <Button color='primary' variant="contained" onClick={onClick} className={classes.downCircle} label={classes.buttonLabel} disabled={delayState}>
                  {children}
                </Button>
              </ThemeProvider> 
                : 
                  event === 'Clock Out' 
                    ?
                    <ThemeProvider theme={basicTheme}>
                      <Button color='secondary' variant="contained" onClick={onClick} className={classes.downCircle} disabled={delayState}>
                        {children}
                      </Button>
                    </ThemeProvider> 
                      : 
                        event === 'Break Over' 
                          ? 
                          <ThemeProvider theme={breakAndLocationDisabledTheme}>
                            <Button color='primary' variant="contained" onClick={onClick} className={classes.downCircle} disabled={delayState}>
                              {children}
                            </Button>
                          </ThemeProvider> 
                            : 
                            '' 
      }
    </>
  );
}