import React, { useState, useEffect } from 'react';
import { WorkedTime } from '../util';
import { toHHMMSS } from '../../constant/timer';
import { makeStyles } from "@mui/styles";
import {Paper} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  styledUpCircle: {
    height: '100px',
    width: '200px',
    borderRadius: '150px 150px 0 0',
    backgroundColor: '#e2f0f7',
    border: '7px solid #80808012',
    borderBottom: 'none',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '75px',
      width: '150px',
    },
  },
  title: {
    margin: '25px 0 0 0',
    fontSize: '19px',
    fontWeight: 'lighter',
    color: '#707070',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    },
  },
  timeWorked: {
    color: '#707070',
    fontSize: '28px',
    margin: 0,
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px'
    },
  }
}));

const ClockTime = ({ nextExpectedActions, events }) => {
    const classes = useStyles();
    const [workedTime, setWorkedTime] = useState(0);
    
    useEffect(() => {
      const getTime = () => {
        if (events.length > 0) {
          const [worked] = WorkedTime(events, nextExpectedActions);
          setWorkedTime(worked);
        } else {
          setWorkedTime(0);
        }
      }

      const timer = setInterval(() => {
        getTime();
      }, 1000);
      
      return () => clearInterval(timer);
    }, [events, nextExpectedActions]);
  
    return (
      <Paper className={classes.styledUpCircle}>
        <p className={classes.title}>Time Worked:</p>
        <p className={classes.timeWorked}><span >{toHHMMSS(workedTime)}</span></p>
      </Paper>
    );
};

export default ClockTime;
