import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from '@mui/styles';
import {
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  Typography,
  IconButton,
  CircularProgress,
  Backdrop,
  Dialog,
  Grid
} from "@mui/material";
import {Close as CloseIcon} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: '8px'
  },
  titleDate: {
    color: '#707070',
    fontSize: '18px',
  },
  tab: {
    color: '#707070',
    width: '100px',
  },
  labelContainer: {
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  tableCell: {
    color: '#707070',
    fontSize: '12px',
    padding: '15px',
    '&:hover': {
      background: '#dff2fa'
    },
    '&:active': {
      background: '#dff2fa'
    }
  },
  nonSelectedTab: {
    padding: '0 2px',
    margin: 0,
    height: '100%',
    borderBottom: '1px solid lightgray',
    maxWidth: '100%',
  },
  selectedTab: {
    padding: '0 2px',
    margin: 0,
    height: '100%',
    borderBottom: '1px solid lightgray',
    maxWidth: '100%',
    backgroundColor: 'rgb(223, 242, 250)'
  },
  activityTable: {
    borderCollapse: 'collapse',
    margin: '5px 0 3px',
    fontSize: '11px',
    '& tbody tr td': {
      paddingBottom: '3px',
    },
  },
  dataWidth: {
    paddingLeft: '15px',
    width: '50%',
  },
  activityDataWidth: {
    width: '25%',
  },
}));

const styles = {
  smallIcon: {
    width: 18,
    height: 18,
  }
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography style={{paddingBottom: 0}} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          onClick={onClose}
          className="icon cur-pointer"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const TimeSheetDetails = ({ details, modalIsOpen, setModelOpen }) => {
  const classes = useStyles();
  const [loading,] = useState(false);
  const [, setCurrent] = useState({});
  const [, setSelectedFileUlid] = useState('');
  const [, setReceiptName] = useState('');

  useEffect(() => {
    setCurrent(details);

    return () => {
      setCurrent({});
      setSelectedFileUlid('');
      setReceiptName('');
    }
  }, [details])

  const closeModal = () => {
    setModelOpen(false);
  }

  return (
    <>
    <Dialog
      open={modalIsOpen} 
      onClose={closeModal}
      fullWidth={true}
      disableBackdropClick
      disableEscapeKeyDown
      className={classes.dialog}
    >
      <DialogTitle onClose={closeModal}>Details</DialogTitle>
      <MuiDialogContent style={{padding: '0 5px 15px 15px'}}>
      <div className={classes.root}>
        <Backdrop
          className={classes.backdrop}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
        <Grid container spacing={1}>
          <Grid item xs={6} >
                Details stuff
          </Grid>
          
          <Grid item xs={6} style={{ textAlign: 'center', margin: '0 0 25px 0'}}>
                2nd grid details stuff
          </Grid>
        </Grid>
      </div>
      </MuiDialogContent>
    </Dialog>
    </>
  );
}




export default TimeSheetDetails;
