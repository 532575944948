const builder = (url) => {
    if (url.includes('localhost')) { return 'http://' + url }
    else { return 'https://' + url }
}

export const FE_URL = builder(process.env.REACT_APP_URL);
export const API_URL = builder(process.env.REACT_APP_API_URL)
export const AGORA_APP_ID = process.env.REACT_APP_AGORA_APP_ID;
export const CLIENTID = process.env.REACT_APP_STRIPE_CLIENT_ID;

export const GOOGLE_MAP_KEY = 'AIzaSyC-C5qNjyOTGsjSG8ILpThyVJSTFqSyN98';

const preprodconfig = {
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MSG_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
}
const postprodconfig = {
    apiKey: process.env.REACT_APP_PROD_GOOGLE_API_KEY,
    authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PROD_FIREBASE_URL,
    projectId: process.env.REACT_APP_PROD_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PROD_MSG_SENDER_ID,
    appId: process.env.REACT_APP_PROD_APP_ID,
    measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID
};

const preprodselector = () => {
    if (process.env.REACT_APP_STAGE === 'prod') {
        return postprodconfig
    } else {
        return preprodconfig
    }
}

const logoutfn = () => {
    if (process.env.REACT_APP_URL === 'localhost:3000') {
        return `http://${process.env.REACT_APP_URL}`
    } else if(process.env.REACT_APP_STAGE === 'prod'){
        return `https://${process.env.REACT_APP_LOGOUT_URL}`
    } else {
        return `https://${process.env.REACT_APP_URL}`
    }
}

export const LOGOUT_URL = logoutfn()

export const FIREBASECONFIG = preprodselector()
