const localForage = require('localforage')
const KEY = 'loadedReceipt';

const Storage = {
  getReceipt: async () => JSON.parse(await localForage.getItem(KEY)) || [],
  getURI: async (fileUlid) => {
    const receipts = await Storage.getReceipt();
    const found = receipts.find(x => x.fileUlid === fileUlid)
    if (found) {
      return found.dataUri
    }
    return ''
  },
  existUlid: async (fileUlid) => {
    const receipts = await Storage.getReceipt();
    return receipts.some(x => x.fileUlid === fileUlid);
  },
  setItem: async (item) => {
    const receipts = await Storage.getReceipt();
    if (!receipts.includes(item)) {
      receipts.push(item);
      await localForage.setItem(KEY, JSON.stringify(receipts));
    }
  },
  clear: () => localForage.removeItem(KEY)
}

export default Storage;