import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Backdrop
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFax } from "@fortawesome/free-solid-svg-icons";
import {
  EventAvailable,
  VpnKeyOutlined,
  CurrencyExchangeOutlined,
  MedicalServicesOutlined,
  Business as BusinessIcon,
  VideocamOutlined as VideocamOutlinedIcon,
  SmsOutlined as SmsOutlinedIcon,
  ForumOutlined as ForumOutlinedIcon,
  Payment as PaymentIcon,
  AccessTime as AccessTimeIcon,
  ContactsOutlined as ContactsOutlinedIcon,
  CheckBox as CheckBoxIcon,
  CalendarToday as CalendarTodayIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckCircle as CheckCircleIcon,
  RemoveCircleOutlineOutlined as RemoveCircleOutlineOutlinedIcon,
  Add as AddIcon
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import Session from "../../session";
import { useAPI } from '../../utils/api';
import AccountServicesModals from './AccountServicesModals';
import DeleteOrganizationModal from './DeleteOrganizationModal';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '50px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '65%',
  },
  serviceContainer:{
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    width: '100%'
  },
  servicesAvaliable: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '70%',
  },
  includedServices: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '30%',
  }
}))


const AccountServices = () => {
  const api = useAPI();

  const [activeServices, setActiveServices] = useState([]);
  const [additionalServices, setAdditionalServices] = useState([]);
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [rows, setRows] = useState([]);
  const [usersCount, setUsersCount] = useState();
  const [isLoader, setIsLoader] = useState(true);
  const [plans, setPlans] = useState([])
  const [plansPricing, setPlansPricing] = useState([])
  const [subscriptionData, setSubscriptionData] = useState();
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const includedServices = [
    {service: 'Time Clock'},
    {service: 'Patients'},
    {service: 'Contacts'},
    {service: 'Chat'},
    {service: 'Secure Messaging'},
    {service: 'Patient Portal'},
    {service: 'Appointment'}
  ]

  const serviceListPrice = [
    { service: 'efax', value: '00.00'},
    { service: 'payment', value: '00.00'},
    { service: 'sms', value: '00.00'},
    { service: 'telehealth', value: '00.00'}
  ]

  useEffect(() => {
    // Create an scoped async function in the hook
    async function anyNameFunction() {
      const result = await api.getServices(Session.OrganizationId);
      console.log(result.data);
      setRows(result.data);
      setIsLoader(false);
    }
    // Execute the created function directly
    anyNameFunction();
    setIsLoader(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const servicesComingSoon = [ {service: 'Invoicing', text: 'Coming Soon'}]

  useEffect(() => {
    api.getPlans().then(res => {
      setPlans(res.data);
      console.log(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (plans.length > 0) {
      let servicePrice = serviceListPrice.map(el => {
        const check = plans.find(x => x.key === el.service);
        if (check) {
          el.value = Number(check.price.replace(/[^0-9.-]+/g,""));
        }
        return el;
      })
      console.log(servicePrice)
      setPlansPricing(servicePrice);
    }
      // eslint-disable-next-line
  }, [plans]);

  useEffect(() => {
    if(rows.length>0 && plansPricing.length>0){
      let newActiveServices = []
      let newAdditiopnalServices = []
      const tempCount = rows.find(x=>x.key === 'base');
      setUsersCount(tempCount.quantity);
      if (Session.OrgPlan.includes('efax')){
        let tempEfax = rows.find(x=>x.key === 'efaxUsage');
        newActiveServices.push({service: 'Efax', text: `Usage (${tempEfax ? tempEfax.quantity - 1 : 0} of 500)`, value: true, price: plansPricing[0].value})
      } else {
        newAdditiopnalServices.push({service: 'Efax', text: '', value: false, price: plansPricing[0].value})
      }
      if (Session.OrgPlan.includes('payment')){
        newActiveServices.push({service: 'Payment', text: '', value: true, price: plansPricing[1].value})
      } else {
        newAdditiopnalServices.push({service: 'Payment', text: '', value: false, price: plansPricing[1].value})
      }
      if (Session.OrgPlan.includes('sms')){
        let tempSMS = rows.find(x=>x.key === 'smsUsage');
        newActiveServices.push({service: 'SMS', text: `Usage (${tempSMS ? tempSMS.quantity - 1 : 0} of 1000)`, value: true, price: plansPricing[2].value})
      } else {
        newAdditiopnalServices.push({service: 'SMS', text: '', value: false, price: plansPricing[2].value})
      }
      let tempTeleHealth = rows.find(x=>x.key === 'telehealth');
      newActiveServices.push({service: 'TeleHealth', text: `Users (${tempTeleHealth ? tempTeleHealth.quantity : 0})`, value: true, price: plansPricing[3].value})

      setActiveServices(newActiveServices)
      setAdditionalServices(newAdditiopnalServices)
    }
  }, [rows, plansPricing]);

  const classes = useStyles();

  const handleAccountServiceModal = (status) => {
    setModalOpen(status)
  }

  const handleDeleteOrgModal = (status) => {
    setDeleteModalOpen(status)
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    if (width <= 768) {
      setIsMobile(true);
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function anyNameFunction() {
      const result = await api.getSubscriptionDetail(Session.OrganizationId);
      console.log(result.data)
      if(result.data){
        setSubscriptionData(result.data);
      }
    }
    anyNameFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFormattedDate = (obj) =>{
    const date = new Date(obj * 1000)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const formatted = `${month}/${day}/${year}`;
    return formatted;
  }

  const getIcon = (service, fontColor) => {
    switch (service) {
      case 'Efax': return  <FontAwesomeIcon icon={faFax} style={{width: 20, color: fontColor}} />;
      case 'TeleHealth': return <VideocamOutlinedIcon style={{ color: fontColor , width: 20}}/>;
      case 'SMS': return <SmsOutlinedIcon style={{ color: fontColor , width: 20}}/>;
      case 'Payment': return <PaymentIcon style={{ color: fontColor , width: 20}}/>;
      case 'Appointment': return <EventAvailable style={{width: 20, color: fontColor}} />;
      case 'Time Clock': return <AccessTimeIcon style={{ color: fontColor, width: 20 }}/>;
      case 'Contacts': return <ContactsOutlinedIcon style={{ color: fontColor , width: 20}}/>;
      case 'Secure Messaging': return <VpnKeyOutlined style={{ color: fontColor , width: 20}}/>;
      case 'Appointment Scheduler': return <CalendarTodayIcon style={{ color: fontColor , width: 20}}/>;
      case 'Patients': return <img style={{ paddingLeft: 3 , width: 20}} src="/hospital-user-light.svg" alt='avtar' />;
      case 'Patient Portal': return <MedicalServicesOutlined style={{ color: fontColor , width: 20}}/>;
      case 'Chat': return <ForumOutlinedIcon style={{ color: fontColor , width: 20}}/>
      case 'Invoicing': return <CurrencyExchangeOutlined style={{ color: fontColor , width: 20}}/>
      default:
        return <BusinessIcon style={{width: 20, color: fontColor}} />;
    }
  }

  return (
    <> 
    {isLoader ? <><Backdrop
      className={classes.backdrop}
      open={true}
      >
        <CircularProgress />
      </Backdrop></> :<>{isMobile ? 
      <>
        <Grid container style={{fontSize:13, marginBottom: '15px'}} justifyContent="space-between" spacing={0.5}>
         <Grid style={{color:'rgb(112 112 110)', textAlign:"center", marginBottom: '5px', marginTop:'5px'}} item xs={12} sm={6} >
         {subscriptionData && subscriptionData.status === 'trialing' ? `Free Trial Ending On ${getFormattedDate(subscriptionData.current_period_end)}` : ''}
         </Grid>
         <Grid style={{ textAlign:"center", marginBottom: '5px', marginTop:'5px'}} item xs={12} sm={6}>
         <Button variant="outlined" startIcon={<AddIcon />} color="primary" onClick={() => {setModalOpen(true)}}>          
            <Typography variant="subtitle2">Manage Services</Typography>
          </Button>
         </Grid>
         <Grid item style={{color:'rgb(112 112 110)', textAlign:"center" ,marginBottom: '5px',marginTop:'5px'}} xs={12} sm={6} >
         Number of Users: {usersCount}
        </Grid>
        <Grid item style={{marginBottom: '5px',marginTop:'5px'}} xs={12} sm={6}>
        <Typography variant="subtitle2" style={{ color: '#707070' }}>Active Services:</Typography>
              {activeServices.map((s, id) => {
                return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                  <div style={{width: '50%', display: 'flex'}}> 
                    {getIcon(s.service, "#47AFEB")} 
                    <Typography variant="subtitle2" style={{marginLeft: 7.5, color: '#4D4F5C'}}>{s.service}</Typography>
                  </div>
                  <div style={{width: '50%', display: 'flex', alignItems: 'center'}}> 
                    <CheckBoxIcon style={{ color: "#47AFEB",  margin: '0 5px 0 0', padding: 0 }}/>
                    <Typography variant="subtitle2" style={{ color: '#707070', fontSize: 11 }}>{s.text}</Typography>
                  </div>
                </div>)
              })}
        </Grid>
        <Grid item style={{marginBottom: '5px',marginTop:'5px'}} xs={12} sm={6}>
        <Typography variant="subtitle2" style={{marginTop: 20 ,color: '#707070'}}>Additional Services Offered (Per Month):</Typography>
              {additionalServices.map((s, id) => {
                return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                  <div style={{width: '50%', display: 'flex'}}> 
                    {getIcon(s.service, '#707070')} 
                    <Typography variant="subtitle2" style={{marginLeft: 7.5, color: '#707070'}}>{s.service}</Typography>
                  </div>
                  {s.service === 'TeleHealth' ? 
                  <div style={{width: '50%', display: 'flex', alignItems: 'center'}}> 
                    <CheckBoxOutlineBlankIcon style={{ color: "#707070",  margin: '0 5px 0 0', padding: 0 }}/>
                    <Typography variant="subtitle2"style={{ color: "#707070" }}>{`$${s.price.toFixed(2)} per user`}</Typography>
                  </div> :
                  <div style={{width: '50%', display: 'flex', alignItems: 'center'}}> 
                    <CheckBoxOutlineBlankIcon style={{ color: "#707070",  margin: '0 5px 0 0', padding: 0 }}/>
                    <Typography variant="subtitle2"style={{ color: "#707070" }}>{`$${s.price.toFixed(2)}`}</Typography>
                  </div>}
                </div>)
              })}
                            {servicesComingSoon.map((s, id) => {
                return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                  <div style={{width: '50%', display: 'flex'}}> 
                      {getIcon(s.service, '#707070')} 
                      <Typography variant="subtitle2" style={{marginLeft: 7.5, color: '#707070' }}>{s.service}</Typography>
                    </div>
                  <div style={{width: '50%', display: 'flex', alignItems: 'center'}}> 
                      <CheckBoxOutlineBlankIcon style={{ color: "#707070",  margin: '0 5px 0 0', padding: 0 }}/>
                      <Typography variant="subtitle2" style={{ color: "#707070" }}>{s.text}</Typography>
                  </div>
                </div>)
              })}
        </Grid>
        <Grid item style={{marginBottom: '5px',marginTop:'5px'}} xs={12} sm={6}>
        <Typography variant="subtitle2" style={{ color: "#707070" }}>Included with all Services</Typography>
        {includedServices.map((s, id) => {
                return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                  <div style={{width: '50%', display: 'flex'}}> 
                    {getIcon(s.service, "#47AFEB")} 
                    <Typography variant="subtitle2" style={{marginLeft: 7.5, color: '#4D4F5C'}}>{s.service}</Typography>
                  </div>
                  <div style={{width: '50%', display: 'flex'}}> 
                    <CheckCircleIcon style={{ color: "#10D236", width: 20 }}/>
                  </div>
                </div>)
              })}
        </Grid>
      </Grid>
      <Grid item style={{marginBottom: '5px',marginTop:'5px'}} xs={12} sm={6}>
      <Button variant="outlined" startIcon={<RemoveCircleOutlineOutlinedIcon />} color="error" style={{marginTop: 15, alignSelf: 'center'}} onClick={() => {setDeleteModalOpen(true)}}>          
          <Typography variant="subtitle2" style={{fontSize: 10}}>Cancel My Account</Typography>
      </Button>
      <AccountServicesModals 
        modalOpen={modalOpen}
        handlerModal={handleAccountServiceModal}
        activeServices={activeServices}
        includedServices={includedServices}
        additionalServices={additionalServices}
        servicesComingSoon={servicesComingSoon}
        usage={rows}
      />
      <DeleteOrganizationModal 
        modalOpen={deleteModalOpen}
        handlerModal={handleDeleteOrgModal}
      />
      </Grid>
      </>
      : <>
      <div className={classes.root}>
      <Grid container style={{fontSize:13, marginBottom: '15px'}} justifyContent="space-between" spacing={0.5}>
            <Grid item style={{paddingLeft:'1px',color:'rgb(112 112 110)',paddingTop:'15px'}} xs={12} sm={6} >
            Number of Users: {usersCount}
            </Grid>
            <Grid style={{textAlign:'right',color:'rgb(112 112 110)', paddingRight:'12%'}} item xs={12} sm={6} >
            {subscriptionData && subscriptionData.status === 'trialing' ? `Free Trial Ending On ${getFormattedDate(subscriptionData.current_period_end)}` : ''}
            </Grid>
            </Grid>
            <div className={classes.serviceContainer}>
        <div className={classes.servicesAvaliable}>
          <Typography variant="subtitle2" style={{ color: '#707070' }}>Active Services:</Typography>
              {activeServices.map((s, id) => {
                return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                  <div style={{width: '50%', display: 'flex'}}> 
                    {getIcon(s.service, "#47AFEB")} 
                    <Typography variant="subtitle2" style={{marginLeft: 7.5, color: '#4D4F5C'}}>{s.service}</Typography>
                  </div>
                  <div style={{width: '50%', display: 'flex', alignItems: 'center'}}> 
                    <CheckBoxIcon style={{ color: "#47AFEB",  margin: '0 5px 0 0', padding: 0 }}/>
                    <Typography variant="subtitle2" style={{ color: '#707070', fontSize: 11 }}>{s.text}</Typography>
                  </div>
                </div>)
              })}
            <Typography variant="subtitle2" style={{marginTop: 20 ,color: '#707070'}}>Additional Services Offered (Per Month):</Typography>
              {additionalServices.map((s, id) => {
                return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                  <div style={{width: '50%', display: 'flex'}}> 
                    {getIcon(s.service, '#707070')} 
                    <Typography variant="subtitle2" style={{marginLeft: 7.5, color: '#707070'}}>{s.service}</Typography>
                  </div>
                  {s.service === 'TeleHealth' ? 
                  <div style={{width: '50%', display: 'flex', alignItems: 'center'}}> 
                    <CheckBoxOutlineBlankIcon style={{ color: "#707070",  margin: '0 5px 0 0', padding: 0 }}/>
                    <Typography variant="subtitle2"style={{ color: "#707070" }}>{`$${s.price.toFixed(2)} per user`}</Typography>
                  </div> :
                  <div style={{width: '50%', display: 'flex', alignItems: 'center'}}> 
                    <CheckBoxOutlineBlankIcon style={{ color: "#707070",  margin: '0 5px 0 0', padding: 0 }}/>
                    <Typography variant="subtitle2"style={{ color: "#707070" }}>{`$${s.price.toFixed(2)}`}</Typography>
                  </div>}
                </div>)
              })}
              {servicesComingSoon.map((s, id) => {
                return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                  <div style={{width: '50%', display: 'flex'}}> 
                      {getIcon(s.service, '#707070')} 
                      <Typography variant="subtitle2" style={{marginLeft: 7.5, color: '#707070' }}>{s.service}</Typography>
                    </div>
                  <div style={{width: '50%', display: 'flex', alignItems: 'center'}}> 
                      <CheckBoxOutlineBlankIcon style={{ color: "#707070",  margin: '0 5px 0 0', padding: 0 }}/>
                      <Typography variant="subtitle2" style={{ color: "#707070" }}>{s.text}</Typography>
                  </div>
                </div>)
              })}
        </div>
        <div className={classes.includedServices}>
          <Typography variant="subtitle2" style={{ color: "#707070" }}>Included with all Services</Typography>
          {includedServices.map((s, id) => {
                return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                  <div style={{width: '75%', display: 'flex'}}> 
                    {getIcon(s.service, "#47AFEB")} 
                    <Typography variant="subtitle2" style={{marginLeft: 7.5, color: '#4D4F5C'}}>{s.service}</Typography>
                  </div>
                  <div style={{width: '25%', display: 'flex'}}> 
                    <CheckCircleIcon style={{ color: "#10D236", width: 20 }}/>
                  </div>
                </div>)
              })}
          <Button variant="outlined" startIcon={<AddIcon />} color="primary" style={{marginTop: 50}} onClick={() => {setModalOpen(true)}}>          
            <Typography variant="subtitle2">Manage Services</Typography>
          </Button>
        </div>
        
      </div>
      <Button variant="outlined" startIcon={<RemoveCircleOutlineOutlinedIcon />} color="error" style={{marginTop: 75, alignSelf: 'center'}} onClick={() => {setDeleteModalOpen(true)}}>          
          <Typography variant="subtitle2" style={{fontSize: 10}}>Cancel My Subscription</Typography>
      </Button>
      <AccountServicesModals 
        modalOpen={modalOpen}
        handlerModal={handleAccountServiceModal}
        activeServices={activeServices}
        includedServices={includedServices}
        additionalServices={additionalServices}
        servicesComingSoon={servicesComingSoon}
        usage={rows}
      />
      <DeleteOrganizationModal 
        modalOpen={deleteModalOpen}
        handlerModal={handleDeleteOrgModal}
      />
      </div>
            </>}</>
    // <div className={classes.root}>
    //   {isMobile ? 
    //   <>

    //   </> : 
    //   <>

    // </>
    //   }
    //   {isMobile ? <>
    //     <Typography variant="subtitle2" style={{ color: '#707070' }}>Active Services:</Typography>
    //           {activeServices.map((s, id) => {
    //             return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
    //               <div style={{width: '50%', display: 'flex'}}> 
    //                 {getIcon(s.service, "#47AFEB")} 
    //                 <Typography variant="subtitle2" style={{marginLeft: 7.5, color: '#4D4F5C'}}>{s.service}</Typography>
    //               </div>
    //               <div style={{width: '50%', display: 'flex', alignItems: 'center'}}> 
    //                 <CheckBoxIcon style={{ color: "#47AFEB",  margin: '0 5px 0 0', padding: 0 }}/>
    //                 <Typography variant="subtitle2" style={{ color: '#707070', fontSize: 11 }}>{s.text}</Typography>
    //               </div>
    //             </div>)
    //           })}
    //   </>:<>
    //   <div className={classes.serviceContainer}>
    //     <div className={classes.servicesAvaliable}>
    //       <Typography variant="subtitle2" style={{ color: '#707070' }}>Active Services:</Typography>
    //           {activeServices.map((s, id) => {
    //             return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
    //               <div style={{width: '50%', display: 'flex'}}> 
    //                 {getIcon(s.service, "#47AFEB")} 
    //                 <Typography variant="subtitle2" style={{marginLeft: 7.5, color: '#4D4F5C'}}>{s.service}</Typography>
    //               </div>
    //               <div style={{width: '50%', display: 'flex', alignItems: 'center'}}> 
    //                 <CheckBoxIcon style={{ color: "#47AFEB",  margin: '0 5px 0 0', padding: 0 }}/>
    //                 <Typography variant="subtitle2" style={{ color: '#707070', fontSize: 11 }}>{s.text}</Typography>
    //               </div>
    //             </div>)
    //           })}
    //         <Typography variant="subtitle2" style={{marginTop: 20 ,color: '#707070'}}>Additional Services Offered (Per Month):</Typography>
    //           {additionalServices.map((s, id) => {
    //             return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
    //               <div style={{width: '50%', display: 'flex'}}> 
    //                 {getIcon(s.service, '#707070')} 
    //                 <Typography variant="subtitle2" style={{marginLeft: 7.5, color: '#707070'}}>{s.service}</Typography>
    //               </div>
    //               {s.service === 'TeleHealth' ? 
    //               <div style={{width: '50%', display: 'flex', alignItems: 'center'}}> 
    //                 <CheckBoxOutlineBlankIcon style={{ color: "#707070",  margin: '0 5px 0 0', padding: 0 }}/>
    //                 <Typography variant="subtitle2"style={{ color: "#707070" }}>{`$${s.price.toFixed(2)} per user`}</Typography>
    //               </div> :
    //               <div style={{width: '50%', display: 'flex', alignItems: 'center'}}> 
    //                 <CheckBoxOutlineBlankIcon style={{ color: "#707070",  margin: '0 5px 0 0', padding: 0 }}/>
    //                 <Typography variant="subtitle2"style={{ color: "#707070" }}>{`$${s.price.toFixed(2)}`}</Typography>
    //               </div>}
    //             </div>)
    //           })}
    //           {servicesComingSoon.map((s, id) => {
    //             return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
    //               <div style={{width: '50%', display: 'flex'}}> 
    //                   {getIcon(s.service, '#707070')} 
    //                   <Typography variant="subtitle2" style={{marginLeft: 7.5, color: '#707070' }}>{s.service}</Typography>
    //                 </div>
    //               <div style={{width: '50%', display: 'flex', alignItems: 'center'}}> 
    //                   <CheckBoxOutlineBlankIcon style={{ color: "#707070",  margin: '0 5px 0 0', padding: 0 }}/>
    //                   <Typography variant="subtitle2" style={{ color: "#707070" }}>{s.text}</Typography>
    //               </div>
    //             </div>)
    //           })}
    //     </div>
    //     <div className={classes.includedServices}>
    //       <Typography variant="subtitle2" style={{ color: "#707070" }}>Included with all Services</Typography>
    //       {includedServices.map((s, id) => {
    //             return (<div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
    //               <div style={{width: '50%', display: 'flex'}}> 
    //                 {getIcon(s.service, "#47AFEB")} 
    //                 <Typography variant="subtitle2" style={{marginLeft: 7.5, color: '#4D4F5C'}}>{s.service}</Typography>
    //               </div>
    //               <div style={{width: '50%', display: 'flex'}}> 
    //                 <CheckCircleIcon style={{ color: "#10D236", width: 20 }}/>
    //               </div>
    //             </div>)
    //           })}
    //       <Button variant="outlined" startIcon={<AddIcon />} color="primary" style={{marginTop: 50}} onClick={() => {setModalOpen(true)}}>          
    //         <Typography variant="subtitle2">Manage Services</Typography>
    //       </Button>
    //     </div>
        
    //   </div>
    //   </>
    //   }

    //   <Button variant="outlined" startIcon={<RemoveCircleOutlineOutlinedIcon />} color="error" style={{marginTop: 75, alignSelf: 'center'}} onClick={() => {setDeleteModalOpen(true)}}>          
    //       <Typography variant="subtitle2" style={{fontSize: 10}}>Cancel My Account</Typography>
    //   </Button>
    //   <AccountServicesModals 
    //     modalOpen={modalOpen}
    //     handlerModal={handleAccountServiceModal}
    //     activeServices={activeServices}
    //     includedServices={includedServices}
    //     additionalServices={additionalServices}
    //     servicesComingSoon={servicesComingSoon}
    //     usage={rows}
    //   />
    //   <DeleteOrganizationModal 
    //     modalOpen={deleteModalOpen}
    //     handlerModal={handleDeleteOrgModal}
    //   />
    // </div>
  }
    </>
  );
}

export default AccountServices;
