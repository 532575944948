import React, { createContext, useEffect, useState } from 'react';
import { Locales } from './LangMap'

export const LocalLanguageContext = createContext();

export const LocalLanguageProvider = ({ children }) => {
  const options = Object.keys(Locales);

  const [betaLangVersion, setbetaLangVersion] = useState(false);
  const [langCode, setLangCode] = useState(options[0]);
  const [workerLang, setWorkerLang] = useState({});
  const prefixUrl = 'https://jopetranslations.s3.us-east-2.amazonaws.com/ERL'

  useEffect(() => {
    const fetchWorkingLang = () => {
      fetch(`${prefixUrl}/locales/${langCode}.json`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(myJson) {
          setWorkerLang(myJson);
        });
    };
    const fetchBetaLang = () => {
      fetch(`${prefixUrl}/betaLocales/${langCode}.json`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(myJson) {
          setWorkerLang(myJson);
        });
    };
    if (Locales[langCode]) {
      console.log('Using Beta Language: ', betaLangVersion);
      betaLangVersion ? fetchBetaLang() : fetchWorkingLang();
    } else {
      console.log('Locale Does not exist');
    }
  }, [langCode, betaLangVersion]);

  useEffect(() => {
    if (process.env.REACT_APP_STAGE !== 'prod' || process.env.REACT_APP_STAGE !== 'demo') { setbetaLangVersion(true) }
  }, [workerLang]);

  return (
    <LocalLanguageContext.Provider value={{ betaLangVersion, langCode, workerLang, setLangCode }}>
      {children}
    </LocalLanguageContext.Provider>
  );
};
