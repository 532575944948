import styled from "styled-components";

export const StatusRefund = styled.button`
  background: #B4BBBF 0% 0% no-repeat padding-box;
  color: white;
  border-radius: 10px;
  opacity: 1;
  font-weight: bold;
  height: 25px;
  width: 90px;
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
  &:hover {
    cursor: default;
  }
`;
export const StatusFailed = styled.button`
  background: #F20634 0% 0% no-repeat padding-box;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  opacity: 1;
  height: 25px;
  width: 90px;
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
  &:hover {
    cursor: default;
  }
`;
export const StatusSuccess = styled.button`
  background: #00cc00 0% 0% no-repeat padding-box;
  color: white;
  border-radius: 10px;
  opacity: 1;
  font-weight: bold;
  height: 25px;
  width: 90px;
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
  &:hover {
    cursor: default;
  }
`;