import React, { useState, useEffect } from 'react';
import {
  CircularProgress,
  Backdrop,
  Tabs,
  Tab,
  TextField,
  Box,
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  Checkbox,
  Table,
  TableCell,
  TableRow
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {DateRangePicker,LocalizationProvider} from '@mui/x-date-pickers-pro';

import { startAndEndOfWeek } from '../../utils/dateTimeFormat';
import NestedTable from '../nestedTable/nestedTable'
import NonNestedTable from '../nestedTable/nonNestedTable'
import Currency from '../../utils/formatCurrency';


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tabs: {
    display: 'flex',
    color: '#707070',
    background: '#dff2fa',
    borderRadius: '5px',
    height: '25px',
    width: 'fit-content',
    margin: 'auto',
    border: '1px solid #e8e8e8'
  },
  tab: {
    background: 'transparent',
    border: 'none',
    display: 'flex',
    width: '150px',
    '& > div': {
      margin: 'auto',
      color: '#707070'
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.8'
    }
  },
  activetab: {
    background: '#9ed8f2',
    borderRadius: 5,
    border: 'none',
    display: 'flex',
    width: '150px',
    '& > div': {
      margin: 'auto',
      color: '#707070'
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.8'
    }
  },
  detailtable: {
    borderRadius: '5px',
    overflow: 'hidden',
    marginBottom: '25px',
  },
  topDetailtable: {
    borderRadius: '5px',
    overflow: 'hidden',
    marginTop: '55px',
    marginBottom: '35px',
    width: '43vh',
  },
  topTableContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
  },
  topTableCellsLeft: {
    padding: '7px',
    paddingLeft: '15px',
    fontFamily: 'Helvetica',
    color: 'grey'
  },
  topTableCellsRight: {
    padding: '7px',
    paddingRight: 0,
    fontFamily: 'Helvetica',
    color: 'grey'
  },
  bottomTableHeaderCells: {
    fontFamily: 'Helvetica',
  },
  noHoverLightRow: {
    background: 'white',
  },
  tableRowLight: {
    color: '#707070',
    background: 'white',
    '&:hover': {
      background: '#dff2fa',
      cursor: 'pointer',
    }
  },
  tableRowDark: {
    color: '#707070',
    background: '#EBEBEB',
    '&:hover': {
      background: '#dff2fa',
      cursor: 'pointer',
    }
  },
  tableRowLightBlue: {
    background: '#dff2fa',
  },
  tableRowLightGreen: {
    background: '#caf7d3',
  },
  tableRowLightGrey: {
    background: '#EBEBEB',
  },
  viewButton: {
    color: '#47AFEB',
    fontWeight: 'Bold',
  },
}));

/// !!! If today, no nesting table"
const TabType = {
  Today: 'today',
  ThisWeek: 'thisweek',
  LastWeek: 'lastweek',
  Custom: 'custom',
  Payouts: 'payouts'
};

const PaymentReportsTab = ({ orgPayments, orgPayouts }) => {
  const [checkBoxSelectedList, setCheckBoxSelectedList] = useState(['paid', 'failed', 'refunded']);
  const [tab, setTab] = useState(TabType.Today);
  const [transactions, setTransactions] = useState([])
  const [filteredTransactions, setFilteredTransactions] = useState([])
  const [filteredPayouts, setFilteredPayouts] = useState([])
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const [loading, setLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalFees, setTotalFees] = useState(0);
  const [totalRefunds, setTotalRefunds] = useState(0);
  const [totalNet, setTotalNet] = useState(0);


  const currentDate = new Date()
  const startf = new Date();
  startf.setDate(startf.getDate() - 1);
  const endf = new Date();
  endf.setDate(endf.getDate() + 1);

  const [startDate, setStartDate] = useState(startf);
  const [endDate, setEndDate] = useState(endf);
  const [outerValues, setOuterValues] = useState([])

  let weekBefore = new Date();
  weekBefore.setDate(weekBefore.getDate() - 7);

  const [value, setValue] = useState([weekBefore, Date.now()]);
  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
    setTab(TabType[Object.keys(TabType)[newValue]]);
  };

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  // Filter by tab Date
  useEffect(() => {
    setTotalAmount(0)
    let filtered = orgPayments;
    let payoutFiltered = orgPayouts;
    if (tab === TabType.Custom) {
      if (startDate) {
        filtered = filtered.filter(d => new Date(d.createdAt) >= new Date(startDate));
      }
      if (endDate) {
        const end = new Date(endDate);
        end.setDate(end.getDate() + 1);
        filtered = filtered.filter(d => new Date(d.createdAt) <= end);
      }
      filtered = filtered.filter(d => !d.object.includes('payout'));
      filtered =  filtered.sort((a, b) => b.createdTimestamp - a.createdTimestamp);
    } else if (tab === TabType.LastWeek) {
      const lastweek = new Date();
      lastweek.setDate(lastweek.getDate() - 7);
      const [monday, sunday] = startAndEndOfWeek(lastweek);
      filtered = filtered.filter(d => !d.object.includes('payout'));
      filtered = filtered.filter(d => new Date(d.createdAt) >= monday && new Date(d.createdAt) <= sunday);
    } else if (tab === TabType.ThisWeek) {
      const thisweek = new Date();
      thisweek.setDate(thisweek.getDate());
      const [monday, sunday] = startAndEndOfWeek(thisweek);
      filtered = filtered.filter(d => !d.object.includes('payout'));
      filtered = filtered.filter(d => new Date(d.createdAt) >= monday && new Date(d.createdAt) <= sunday);
      filtered =  filtered.sort((a, b) => b.createdTimestamp - a.createdTimestamp);
    } else if (tab === TabType.Today) {
      const start = new Date();
      start.setHours(0,0,0,0);
      //start.setDate(start.getDate() - 1);
      const end = new Date();
      end.setHours(24,0,0,0);
      //end.setDate(end.getDate() + 1);
      filtered = filtered.filter(d => !d.object.includes('payout'));
      filtered = filtered.filter(d => (new Date(d.createdAt) >= start) && (new Date(d.createdAt) <= end));
      filtered =  filtered.sort((a, b) => b.createdTimestamp - a.createdTimestamp);
    } else if (tab === TabType.Payouts) {
      filtered = payoutFiltered; 
      if (new Date(startDate) !== new Date(value[0])) {
        filtered = filtered.filter(d => new Date(d.createdAt) >= new Date(value[0]));
        const end = new Date(endDate);
        end.setDate(end.getDate() + 1);
        filtered = filtered.filter(d => new Date(d.createdAt) <= end);
      }
      else {
        if (startDate) {
          filtered = filtered.filter(d => new Date(d.createdAt) >= new Date(startDate));
        }
        if (endDate) {
          const end = new Date(endDate);
          end.setDate(end.getDate() + 1);
          filtered = filtered.filter(d => new Date(d.createdAt) <= end);
        }
      }
      filtered = filtered.filter(d => d.object.includes('payout'));
      console.log('date filtered')
      console.log(filtered)
    }

    const formattedarr = []
    let totAmount = 0;
    let totFees = 0;
    let totRefunds = 0;
    let totNet = 0;

    filtered.map(n => {
      const date = new Date(n.createdTimestamp * 1000)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      let formatted;
      let tempFormatted;
      function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds()
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        var tempTime = hours + ':' + minutes + ':'+ seconds + ' ' + ampm;
        return [strTime,tempTime];
      }
      const createdDate = new Date(n.createdTimestamp * 1000)
      if (tab === TabType.Today) {
        let res = formatAMPM(createdDate);
        formatted = res[0];
        tempFormatted = res[1]; 
      } else {
        formatted = `${month}/${day}/${year}`
        tempFormatted = `${month}/${day}/${year}` 
      }
      console.log(formatted)
      if (!formattedarr.includes(tempFormatted)) {
        formattedarr.push(tempFormatted);
      }
      const badMetaJson = n.metadata;
      const correctMetaJson = badMetaJson.replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": ');
      const meta = JSON.parse(correctMetaJson);
      const raw = JSON.parse(n.raw)
      n.formatted = formatted
      n.tempFormatted = tempFormatted;
      n.inner = formatAMPM(createdDate)[0]
      if (raw.data.object.status === 'succeeded' || raw.data.object.status === 'paid') {
        if (raw.data.object.refunded) {
          if(tab === TabType.Payouts){
            n.status = 'Withdrawn'
            n.statustype = 'refunded'
          }else{
            n.status = 'Refunded'
            n.statustype = 'refunded'
          }
        } else {
          if(tab === TabType.Payouts){
            if(n.amount>0){
              n.status = 'Deposited'
              n.statustype = 'success'
            }else{
              n.status = 'Withdrawn'
              n.statustype = 'withdrawn'
            }
          }else{
            n.status = 'Paid'
            n.statustype = 'success'
          }
        }
      } else if (raw.data.object.status === 'failed') {
        n.status = 'Declined'
        n.statustype = 'failed'
      }
      else if (raw.data.object.status === 'in_transit') {
          n.status = 'In Transit'
          n.statustype = 'In Transit'
      } else {
        n.status = raw.data.object.status
        n.statustype = raw.data.object.status
      }
      n.customername = meta.Name
      n.confirmationnumber = meta.Confirmation
      console.log('balance transactions')
      console.log(raw)
      n.fee = n.fee || 0
      n.net = n.net || 0

      totAmount += n.amount || 0;
      totFees += n.fee || 0
      totRefunds += n.amountRefunded || 0
      totNet += n.net || 0
      setTotalAmount(totAmount)
      return n
    })


    setTotalFees(totFees)
    setTotalRefunds(totRefunds)
    setTotalNet(totNet)

    setOuterValues(formattedarr)
    setTransactions(filtered);
    setFilteredPayouts(payoutFiltered)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, orgPayments, startDate, endDate]);


  useEffect(() => {
    let filteredTrans = transactions;
    if (!checkBoxSelectedList.includes('paid')) {
      filteredTrans = filteredTrans.filter(t => t.statustype !== 'success')
    }
    if (!checkBoxSelectedList.includes('failed')) {
      filteredTrans = filteredTrans.filter(t => t.statustype !== 'failed')
    }
    if (!checkBoxSelectedList.includes('refunded')) {
      filteredTrans = filteredTrans.filter(t => t.statustype !== 'refunded')
    }

    let totAmount = 0;
    let totFees = 0;
    let totRefunds = 0;
    let totNet = 0;

    const formattedarr = [];
    filteredTrans.forEach(n => {
      if (!formattedarr.includes(n.tempFormatted)) {
        formattedarr.push(n.tempFormatted);
      }
      if (n.statustype === 'failed') {
        console.log('no total calcs for failed')
      }
      if (n.statustype === 'success') {
        totAmount += n.amount || 0;
        totNet += n.net || 0;
        totFees += n.fee || 0;
      }
      if (n.statustype === 'refunded') {
        totRefunds += n.amountRefunded || 0;
       // totNet += n.net || 0;
      }
    })
    if (tab !== TabType.Payouts) {
      setTotalAmount(totAmount)
    }

    setTotalFees(totFees)
    setTotalRefunds(totRefunds)
    setTotalNet(totNet)
    setOuterValues(formattedarr)
    setFilteredTransactions(filteredTrans)

  }, [checkBoxSelectedList, transactions, tab])

  const headers = [{
    header: 'TimeStamp',
    value: 'formatted',
    summable: false,
    type: 'time'
  }, {
    header: 'Status',
    value: 'status',
    summable: false,
    type: 'status'
  }, {
    header: 'Customer Name',
    value: 'customername',
    summable: false,
    type: 'string'
  }, {
    header: 'Amount',
    value: 'amount',
    summable: true,
    type: 'currency'
  }, {
    header: 'Fees',
    value: 'fee',
    summable: true,
    type: 'currency'
  }, {
    header: 'Net',
    value: 'net',
    summable: true,
    type: 'currency'
  }, {
    header: 'Confirmation #',
    value: 'confirmationnumber',
    summable: false,
    type: 'string'
  }]


  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      <Tabs
        className={classes.tabs}
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="white"
        centered
      >
        <Tab className={tab === TabType.Today ? classes.activetab : classes.tab} label="Today" onClick={() => {
          setTab(TabType.Today); setTabValue(0);
        }} />
        <Tab className={tab === TabType.ThisWeek ? classes.activetab : classes.tab} label="This Week" onClick={() => {
          setTab(TabType.ThisWeek); setTabValue(1);
        }} />
        <Tab className={tab === TabType.LastWeek ? classes.activetab : classes.tab} label="Last Week" onClick={() => {
          setTab(TabType.LastWeek);
          setTabValue(2);
        }} />
        <Tab className={tab === TabType.Custom ? classes.activetab : classes.tab} label="Custom" onClick={() => {
          setTab(TabType.Custom);
          setTabValue(3);
          setStartDate(value[0]);
          setEndDate(value[1]);
        }} />
        <Tab className={tab === TabType.Payouts ? classes.activetab : classes.tab} label="Payouts" onClick={() => {
          setTab(TabType.Payouts);
          setTabValue(4);
        }} />
      </Tabs>

      {tab === TabType.Custom || tab === TabType.Payouts ? (
        <div style={{
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          border: "1px solid #e8e8e",
          paddingTop: "35px",
          paddingBottom: '-20px',
          borderRadius: "5px"
        }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              maxDate={currentDate}
              value={value}
              onChange={date => {
                setStartDate(date[0])
                setEndDate(date[1])
                setValue(date)
              }
              }
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} helperText="" onChange={(e, v) => console.log(e, v)} />
                  <Box sx={{ mx: 1 }}> to </Box>
                  <TextField {...endProps} helperText="" onChange={(e, v) => console.log(e, v)} />
                </>
              )}
            />
          </LocalizationProvider>
        </div>
      ) : null}
      {tab !== TabType.Payouts ?
        <div style={{
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: "35px",
          paddingBottom: '-20px',
        }}>
          <div style={{
            display: "flex",
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '43vh'
          }}>
            <span style={{ fontFamily: 'Helvetica', paddingRight: '10px' }}>
              Filter:
            </span>

            <CheckBoxList
              checkBoxSelectedList={checkBoxSelectedList}
              setCheckBoxSelectedList={setCheckBoxSelectedList}
            />

          </div>
        </div>
        : null}

      <div className={classes.topTableContainer}>
        <Table className={classes.topDetailtable} aria-label="simple table">
          <TableRow className={classes.tableRowLightBlue}>
            <TableCell className={classes.topTableCellsLeft}>{tab !== TabType.Payouts ? 'Amount Processed:' : 'Total Amount:'}</TableCell>
            <TableCell className={classes.topTableCellsRight} align="center">{Currency((totalAmount / 100) || 0)}</TableCell>
          </TableRow>
          {tab !== TabType.Payouts ? <>
            <TableRow className={classes.noHoverLightRow}>
              <TableCell className={classes.topTableCellsLeft}>Fees:</TableCell>
              <TableCell className={classes.topTableCellsRight} align="center">{Currency((totalFees / 100) || 0)}</TableCell>
            </TableRow>

            <TableRow className={classes.tableRowLightGreen}>
              <TableCell className={classes.topTableCellsLeft}>Refunds:</TableCell>
              <TableCell className={classes.topTableCellsRight} align="center">{Currency((totalRefunds / 100) || 0)}</TableCell>
            </TableRow>

            <TableRow className={classes.tableRowLightGrey}>
              <TableCell className={classes.topTableCellsLeft}>Net:</TableCell>
              <TableCell className={classes.topTableCellsRight} align="center">{Currency((totalNet / 100) || 0)}</TableCell>
            </TableRow></> : null}
        </Table>
      </div>

      {tab === TabType.Payouts || tab === TabType.Today ?
          <> {tab === TabType.Payouts ? <NonNestedTable data={filteredPayouts} filterValue={'tempFormatted'} outerValues={outerValues} headers={headers} activeTab={tab} /> 
          : <NonNestedTable data={filteredTransactions} filterValue={'tempFormatted'} outerValues={outerValues} headers={headers} activeTab={tab} />} </>
        :
        <NestedTable data={filteredTransactions} filterValue={'tempFormatted'} outerValues={outerValues} headers={headers} />
      }
    </>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CheckBoxList = ({ checkBoxSelectedList, setCheckBoxSelectedList }) => {
  const [setShow] = useState(false);
  const checkBoxRef = React.createRef();
  const classes = useStyles();

  useEffect(() => {
    const handleClickOutside = event => {
      if (checkBoxRef.current && !checkBoxRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [checkBoxRef, setShow]);

  const handleChange = (event) => {
    if (event.target.value.includes(null)) {
      setCheckBoxSelectedList([]);
    } else {
      setCheckBoxSelectedList(event.target.value);
    }
  };

  const calcRender = (selected) => {
    if (selected.length === 0) {
      return 'Filter by Status'
    } else if (selected.length === 3) {
      return 'All Selected'
    } else {
      return 'Filtering'
    }
  };

  return (
    <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
      <Select
        labelId="demo-mutiple-name-label"
        id="demo-mutiple-name"
        multiple
        MenuProps={MenuProps}
        value={checkBoxSelectedList}
        onChange={handleChange}
        displayEmpty={'First Load'}
        style={{ fontSize: '12px' }}
        renderValue={(selected) => calcRender(selected)}
      >
        <MenuItem value={'paid'}>
          <Checkbox color='primary' checked={checkBoxSelectedList.includes('paid')} />
          <ListItemText primary='Paid' />
        </MenuItem>
        <MenuItem value={'failed'}>
          <Checkbox color='primary' checked={checkBoxSelectedList.includes('failed')} />
          <ListItemText primary='Failed' />
        </MenuItem>
        <MenuItem value={'refunded'}>
          <Checkbox color='primary' checked={checkBoxSelectedList.includes('refunded')} />
          <ListItemText primary='Refunded' />
        </MenuItem>
      </Select>
    </FormControl>
  );
};


export default React.memo(PaymentReportsTab);
