import {useEffect, useState} from 'react';
import { useQuery, gql, useSubscription} from '@apollo/client';

import Session from '../session';
import { transactionsByOrg } from '../graphql/queries';
import { 
  onCreateStripeTransaction, 
  onUpdateStripeTransaction 
} from '../graphql/subscriptions';
  
export const useOrgPayments = () => {
  
    const [orgPayments, setOrgPayments] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);

    // const [getStripeTransactions, {
    //   data: listStripeTransactionsQuery, loading: transactionLoading
    // }] = useLazyQuery(gql(transactionsByOrg), {
    //   onCompleted: (r) => setOrgPayments(r.transactionsByOrg.items)
    // });

    const {subscribeToMore, ...listStripeTransactionsQuery} = useQuery(gql(transactionsByOrg), {
      variables: {
        orgTo: Session.OrganizationId,
        sortDirection: "DESC",
        limit:150,
      }
    });
    

  const checkForDuplicates = (array) => {
    return array.length !== orgPayments.length;
  }

  useEffect(() => {
      const idArray = orgPayments.map(x => x.id);
      
      const uniqueMessages = [...new Set(idArray)];
      const hasDuplicates = checkForDuplicates(uniqueMessages);
      if(hasDuplicates){
        let uniqueMessageObjects = uniqueMessages.map(x => {
          var [msgdata] = orgPayments.filter(n => n.id === x)
          return msgdata
        });
        setOrgPayments(uniqueMessageObjects);
      }
    // eslint-disable-next-line
  }, [orgPayments]);


  // useEffect(() => {
  //   getStripeTransactions({
  //     variables: {
  //       orgTo: Session.OrganizationId,
  //       sortDirection: "DESC"
  //     },
  //   });
  // }, [getStripeTransactions]);

  // useEffect(() => {
  //   if (listStripeTransactionsQuery) {
  //     setOrgPayments(listStripeTransactionsQuery.data.transactionsByOrg.items);
  //     setInitialLoad(false);
  //   }
  //   // eslint-disable-next-line
  // }, [listStripeTransactionsQuery, initialLoad]);
    
  const {data: onNewTransaction} = useSubscription(gql(onCreateStripeTransaction), {
    variables: {
      filter:{
          orgTo: { eq:  Session.OrganizationId }
        }
      }
  });

  const {data: onUpdatedTransaction} = useSubscription(gql(onUpdateStripeTransaction), {
    variables: {
      filter:{
          orgTo: { eq:  Session.OrganizationId }
        }
      }
  });

  useEffect(() => {
    if (onNewTransaction) {
      const delta = onNewTransaction.onCreateStripeTransaction
      if (delta.orgTo !== Session.OrganizationId) {
        return
      }
      // Check if Dupe
      let dupe = false;
      orgPayments.forEach(x => {
        if (x.id === delta.id) {
          dupe = true;
        }
      });
      if (!dupe) {
        setOrgPayments([...orgPayments, delta]);
      }
    }
    // eslint-disable-next-line
  }, [onNewTransaction]);


  useEffect(() => {
    if (onUpdatedTransaction) {
      const delta = onUpdatedTransaction.onUpdateStripeTransaction
        let temp = orgPayments.map(x => {
          if (x.id === delta.id) {
            x = delta
          }
          return x
        });
        setOrgPayments(temp);
    }
    // eslint-disable-next-line
  }, [onUpdatedTransaction]);

  if(initialLoad && !listStripeTransactionsQuery.loading && !listStripeTransactionsQuery.error){
    setOrgPayments(listStripeTransactionsQuery.data.transactionsByOrg.items);
    setInitialLoad(false);
  }
  
  return orgPayments;
};