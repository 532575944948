import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import Session from "./session";
import ErrorBoundary from './utils/error-boundary';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ConsoleLog from './utils/console';
import Amplify from "aws-amplify";
import { LOGOUT_URL } from "./config";
import {useAPI} from './utils/api';


Amplify.configure({
  "aws_project_region": process.env.REACT_APP_AMPLIFY_REGION,
  "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AMPLIFY_ENDPOINT,
  "aws_appsync_region": process.env.REACT_APP_AMPLIFY_REGION,
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": process.env.REACT_APP_AMPLIFY_API_KEY
});

const theme = createTheme({
  palette: {
    primary: {
      // Main blue color for the app. This is set as the main primary color.
      main: '#47afeb',
      // ignore all color variations except main
      dark: '#005184',
      // ignore all color variations except main
      contrastText: '#fff',
    },
  },
});

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

  export const Auth0Context = React.createContext();
  export const useAuth0 = () => useContext(Auth0Context);
  export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const api = useAPI();

  const [isAuthenticated, setIsAuthenticated] = useState();
  const [isLoadedUser, setIsLoadedUser] = useState(false);
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [initTime, setInitTime] = useState(0);
  const [loginError, setLoginError] = useState();
  const [auth0Id, setAuth0Id] = useState();

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=")) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        } catch (err) {
          ConsoleLog(err);
        }
      } else if (window.location.search.includes("error=")) {
        try {
          var errorMessage = getQueryVariable(window.location.href, 'error_description');
          setLoginError(errorMessage);
        } catch (err) {
          ConsoleLog(err);
        }
      }
      const auth = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(auth);
      if (auth) {
        const user = await auth0FromHook.getUser();
        const token = await auth0FromHook.getIdTokenClaims();
        const authToken = await token.__raw;
        console.log(user.sub)
        setAuth0Id(user.sub)
        const userOrgId = await token['https://sbt-dashboard.com/OrgUlid'];
        const userId = await token['https://sbt-dashboard.com/UserUlid'];
        const userName = await token.name;
        const userAvatar = await token.picture;
        const accessibleOrgs = await token['https://sbt-dashboard.com/AccessibleOrgs']
        const userEmail = await token.email;
        Session.SetUserEmail(userEmail);
        Session.SetAvatarUrl(userAvatar || '/user2.png');
        Session.SetUserName(userName);
        const userRole = await token['https://sbt-dashboard.com/roles'];
        Session.SetRoles(userRole)
        if (userRole?.includes('support')) {
          Session.SetAccessibleOrgs(JSON.stringify(accessibleOrgs))
          if(Session.CustomOrgSwitcher) {
            console.log('Support User')
          } else {
            Session.SetOrganizationId(userOrgId);
            Session.SetHomeOrg(userOrgId)
          }
          Session.SetCustomOrgSwitcher(true);
        } else {
          Session.SetOrganizationId(userOrgId);
        }
        Session.SetUserId(userId);
        Session.SetToken(authToken);
        Session.SetTokenExpireTime(token.exp);
        setUser(user);
        setIsLoadedUser(true);
        setLoading(false);
        return authToken;
      } else {
        setLoading(false);
      }
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  function getQueryVariable(href, variable) {
    const [ , query] = href.split('?');
    if (!query) {
      return false;
    }

    const vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var [key, value] = vars[i].split('=');
      if (decodeURIComponent(key) === variable) {
        return decodeURIComponent(value);
      }
    }
    return false;
  }

  const loginWithPopup = async (params = {}) => {
    if (window.location.search.includes("emailaddr=")) {
      console.log('EMAILADD')
      var emailaddr = getQueryVariable(window.location.href, 'emailaddr');
      params.emailaddr = emailaddr
    }

    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
    setLoading(false);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const loginWithRedirect = async (params = {}) => {
    // Start with session clear ?
    Session.clear(); 
    // State of Cookies
    const bailout = async () => {
      await auth0Client.logout({ returnTo: LOGOUT_URL + '?logout=true' }); 
      Session.clear(); 
    }
    if (window.location.search.includes("emailaddr=")) {
      var emailaddr = getQueryVariable(window.location.href, 'emailaddr');
      params.emailaddr = emailaddr
    };
    if (!initTime) {
      setInitTime(Date.now() / 1000);
      setLoading(true);
      await auth0Client.loginWithRedirect(params);
    } else if ((Date.now()/1000) < (initTime + 1)) {
      setLoading(true);
      await bailout();
    }
    setLoading(false);
  };


  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary isUserAuthenticated={isAuthenticated}>
        <Auth0Context.Provider
        value={{
          isAuthenticated: isAuthenticated && isLoadedUser,
          user,
          loading,
          popupOpen,
          loginError,
          loginWithPopup,
          handleRedirectCallback,
          getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
          loginWithRedirect,
          getTokenSilently: (...p) => { auth0Client.getTokenSilently(...p) },
          getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
          logout: (...p) => {
            api.removeWebToken(auth0Id);
            auth0Client.logout(...p); 
            Session.clear();
            setInitTime(0);
          }
        }}
      >
        {children}
      </Auth0Context.Provider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};
