import {useEffect, useState} from 'react';
import {useQuery, gql} from '@apollo/client';
import Session from '../session';
import SetHeaderLogo from '../utils/setHeaderLogo';
import { getOrganization } from '../graphql/queries';
import { useAuth0 } from '../react-auth0-spa';

export const useOrganization = () => {
  const orgId = Session.OrganizationId;
  const { user } = useAuth0();
  const organizationQuery = useQuery(gql(getOrganization), { fetchPolicy: 'cache-and-network', variables: {id: orgId}});
  const [sessionId, setSessionId] = useState();

  useEffect(() => {
    if (organizationQuery.data) {
      const organization = organizationQuery.data.getOrganization;
      if (organization) {
        Session.SetOrgLogoUrl(organization.orgLogo || "/ExamRoomLive_Logo_headerLogo.svg");
        SetHeaderLogo(organization.orgLogo || "/ExamRoomLive_Logo_headerLogo.svg");
        Session.SetOrgName(organization.name || '');
        Session.SetOrgType(organization.orgType || 'medical');
        Session.SetOrganizationStripeId(organization.stripeId);
        Session.SetOrgPlan(organization.orgPlan || []);
        Session.SetBusinessHours(organization.businessHours || []);
        if (organization.stripeOnboarded) {
          Session.SetIsStripeConnect(true);
        }
        Session.SetBusinessNumber(organization.businessNumber || '');
        const orgNumbers = organization.phoneNumbers.items || [];


        if (orgNumbers.length > 0) { orgNumbers.toSorted(function(a, b){return Date.parse(a.createdAt) - Date.parse(b.createdAt) })}
        
        const smsNums = orgNumbers.filter(pn => (pn).type === 'sms' && (pn).inUse);
        const faxNums = orgNumbers.filter(pn => (pn).type === 'fax' && (pn).inUse);
        const rawSmsArr = smsNums.map(pn => {return (pn).number});
        const rawFaxArr = faxNums.map(pn => {return (pn).number});
        const rawNums = orgNumbers.map(pn => {return (pn)});
        Session.SetOrgNumbers(rawNums);
        Session.SetUserSmsNum(rawSmsArr);
        Session.SetUserFaxNum(rawFaxArr);
        Session.SetBusinessNumber(organization.businessNumber || '');
      }
    }
  }, [organizationQuery, sessionId]);

  useEffect(() => {
    if (user) {
      const sessId = user['https://sbt-dashboard.com/sessionID']
      if (sessId) {
        setSessionId(sessId);
      }
      
    }
  }, [user]);

  return organizationQuery?.data;
};
