import { useContext } from 'react';
import { LocalLanguageContext } from '../components/LocalLanguageProvider';

export default function useLocalLanguageContext() {
  const context = useContext(LocalLanguageContext);
  if (!context) {
    throw new Error('useLocalLanguageContext must be used within a LocalLanguageProvider');
  }
  return context;
}
