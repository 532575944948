import React, { useState, useEffect, useRef }  from "react";
import { BlockPicker } from 'react-color'
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  CircularProgress,
  Backdrop,
  Typography,
  Select,
  OutlinedInput,
  TextField,
  IconButton,
  FormControl,
  Checkbox,
  Tooltip,
  InputLabel,
  MenuItem,
  Button,
  ClickAwayListener,
  Snackbar,
  Alert as MuiAlert
} from '@mui/material';
import {
  EventAvailable,
  VpnKeyOutlined,
  Close as CloseIcon,
  Person as PersonIcon,
  Print as PrintIcon,
  SmsOutlined,
  Help as HelpIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  ForumOutlined as ForumOutlinedIcon,
  PaymentTwoTone as PaymentTwoToneIcon,
  AccessTimeOutlined as AccessTimeOutlinedIcon,
  LocationCity as LocationCityIcon,
  CheckBox as CheckBoxIcon,
  LocalHospital as LocalHospitalIcon,
  PermContactCalendar as PermContactCalendarIcon
} from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {permissionsStructure, notificationStructure} from './permissionConst';
import {useAPI} from '../../utils/api';
import { capitalize } from '../../utils/capitalizeNames';
import StripeTextField from "../payment/MaterialCardElement";
import Session from "../../session";
import moment from 'moment';
import { isValidEmail } from "../../utils/formats";
import NumberFormat from "react-number-format";
import {useOrganization} from '../../appsync-hooks/organizationHook';
import { useAuth0 } from "../../react-auth0-spa";
import useStyles from './AddUserModalStyles';
import { CardElement } from '@stripe/react-stripe-js';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      width: 250,
    },
  },
};

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingTop: 10,
    paddingLeft: 15,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: '3px',
    color: theme.palette.grey[500],
  },
});

const DialogTitleExitable = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogTitleExitableCenter = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography align='center' variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    width: "100%",
  }
}))(MuiDialogContent);

const NumberFormatCustom = ({ onChange, value, label, error, disabled}) => {
  const classes = useStyles();
  return (
    <TextField
      disabled={disabled}
      variant="outlined"
      inputProps={{ inputMode: 'tel' }}
      value={value}
      label={label}
      error={error}
      onChange={onChange}
      InputProps={{
        className: classes.textFields
      }}
    />
  );
};

const AddUserModal = ({
  modalOpen,
  handlerModal,
  handlerReload,
  userDetails,
  AuthUser
}) => {
  const api = useAPI();
  const classes = useStyles();

  const [isAdmin, setIsAdmin] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [updateUser, setUpdateUser] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('staff');
  const [emailNotification, setEmailNotification] = useState(true);
  const [text, setText] = useState(false);
  const [reqEmail, setReqEmail] = useState(false);
  const [email, setEmail] = useState(null);
  const [number, setNumber] = useState(null);
  const [reqMob, setReqMob] = useState(false);
  const [officeNumber, setOfficeNumber] = useState(null);
  const [reqOfficeNum, setReqOfficeNum] = useState(false);
  const [faxPerm, setFaxPerm] = useState(true);
  const [smsPerm, setSmsPerm] = useState(true);
  const [chatPerm, setChatPerm] = useState(true);
  const [paymentPerm, setPaymentPerm] = useState(true);
  const [appointmentPerm, setAppointmentPerm] = useState(true);
  const [timeClockPerm, setTimeClockPerm] = useState(true);
  const [patientsPerm, setPatientsPerm] = useState(true);
  const [contactsPerm, setContactsPerm] = useState(true);
  const [secureMessagingPerm, setSecureMessagingPerm] = useState(true);
  const [telehealthPerm, setTelehealthPerm] = useState(true);

  const [newEfaxEmailNotif, setNewEfaxEmailNotif] = useState(true);
  const [newSmsEmailNotif, setNewSmsEmailNotif] = useState(true);
  const [timeClockReportsPerm, setTimeClockReportsPerm] = useState(true);
  const [chatEditDeletePerm, setchatEditDeletePerm] = useState(true);
  const [secureChatEditDeletePerm, setsecureChatEditDeletePerm] = useState(true);
  const [paymentReportsPerm, setPaymentReportsPerm] = useState(true);
  const [efaxSettingsPerm, setEfaxSettingsPerm] = useState(true);
  const [usersPerm, setUsersPerm] = useState(true);
  const [manageServicesPerm, setManageServices] = useState(true);
  
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [chatEditDeletePermUpdate,setchatEditDeletePermUpdate] = useState(false);
  const [secureChatEditDeletePermUpdate,setsecureChatEditDeletePermUpdate] = useState(false);
  const [timeClockReportsPermUpdate, setTimeClockReportsPermUpdate] = useState(false);
  const [paymentReportsPermUpdate, setPaymentReportsPermUpdate] = useState(false);
  const [newSmsEmailNotifUpdate, setNewSmsEmailNotifUpdate] = useState(false);
  const [newEfaxEmailNotifUpdate, setNewEfaxEmailNotifUpdate] = useState(false);
  const [efaxSettingsPermUpdate, setEfaxSettingsPermUpdate] = useState(false);
  const [usersPermUpdate, setUsersPermUpdate] = useState(false);
  const [manageServicesPermUpdate, setManageServicesUpdate] = useState(false);

  const [firstNameUpdate, setFirstNameUpdate] = useState(false);
  const [lastNameUpdate, setLastNameUpdate] = useState(false);
  const [numberUpdate, setNumberUpdate] = useState(false);
  const [officeNumberUpdate, setOfficeNumberUpdate] = useState(false);
  const [emailUpdate, setEmailUpdate] = useState(false);
  const [roleUpdate, setRoleUpdate] = useState(false);
  const [faxPermUpdate, setFaxPermUpdate] = useState(false);
  const [smsPermUpdate, setSmsPermUpdate] = useState(false);
  // const [chatPermUpdate, setChatPermUpdate] = useState(false);
  const [paymentPermUpdate, setPaymentPermUpdate] = useState(false);
  const [timeClockPermUpdate, setTimeClockPermUpdate] = useState(false);
  const [patientsPermUpdate, setPatientsPermUpdate] = useState(false);
  const [contactsPermUpdate, setContactsPermUpdate] = useState(false);
  const [requiredTooltip, setRequiredToolTip] = useState("")
  const [secureMessagingPermUpdate, setSecureMessagingPermUpdate] = useState(false);
  const [telehealthPermUpdate, setTelehealthPermUpdate] = useState(false);

  const [appointmentPermUpdate, setAppointmentPermUpdate] = useState(false);

  const [hasEfax, setHasEfax] = useState(false);
  const [hasSms, setHasSms] = useState(false);
  const [hasPayment, setHasPayment] = useState(false);
  const [hasTimeClock, setHasTimeClock] = useState(false);
  const [hasPatients, setHasPatients] = useState(false);
  const [hasContacts  , setHasContacts] = useState(false);
  const [hasTeleHealth, setHasTeleHealth] = useState(false);

  const [proratedPrice, setProratedPrice] = useState(0);
  const [monthlyPrice, setMonthlyPrice] = useState(0)

  const [seePatients, setSeePatients] = useState(false);
  const [seePatientsUpdate, setSeePatientsUpdate] = useState(false);

  const [appointmentColor, setAppointmentColor] = useState(null);
  const [appointmentColorUpdate, setAppointmentColorUpdate] = useState(false);

  const [isEditUserModal, setIsEditUserModal] = useState(false);
  const [isArchivingUser, setIsArchivingUser] = useState(false);
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [userSaveSuccess, setUserSaveSuccess] = useState(false);
  const [confirmPayment, setConfirmPayment] = useState(false);
  const [paymentError, setPaymentError] = useState(false);

  const [, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [canAddNewUser, setCanAddNewUser] = useState(false);
  // eslint-disable-next-line
  const [viewAppointment, setViewAppointment] = useState(false);
  const [viewEfax, setViewEfax] = useState(false);
  // eslint-disable-next-line
  const [viewSecureMessages, setViewSecureMessages] = useState(false);
  const [viewSMS, setViewSMS] = useState(false);
  // eslint-disable-next-line
  const [viewChat, setViewChat] = useState(false);
  const [viewPayment, setViewPayment] = useState(false);
  const [viewTimeClock, setViewTimeClock] = useState(false);
  // const [viewPatients, setViewPatients] = useState(false);
  // const [viewContacts, setViewContacts] = useState(false);
  const [viewTelehealth, setViewTelehealth] = useState(false)
  const [loading, setLoading] = useState(false);
  const [billingDate, setBillingDate] = useState();
  const [subscriptionData, setSubscriptionData] = useState();
  const organization = useOrganization();
  const { user } = useAuth0();
  const [modulePermissionsForm, setModulePermissionsForm] = useState('Account');
  // Snackbar stuff
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);

  const refField1 = useRef()

  const handleAppointmentColorChange = (color) => {
    let currentColor;
    if(userDetails){
      currentColor = userDetails.appointmentColor ? userDetails.appointmentColor: null;
    }
    if(color.hex){
      if(currentColor !== color.hex){
        setAppointmentColorUpdate(true);
        setUpdateUser(true);
      } else {
        setAppointmentColorUpdate(false);
      }
    }
    setAppointmentColor(color.hex);
  }

  const handleAvailableForAppointmentsChange = (isAvailable) => {
    let currentAvailability;
    if(userDetails){
      currentAvailability = userDetails.seePatients === 1 ? true : false;
    }
    if(currentAvailability !== isAvailable){
      setSeePatientsUpdate(true);
      setUpdateUser(true);
    } else {
      setSeePatientsUpdate(false);
    }
    setSeePatients(isAvailable);
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    async function anyNameFunction() {
      try {
        const result = await api.getSubscriptionDetail(Session.OrganizationId);
        console.log(result)
        let date = false
        if(result.data){
          date = new Date(result.data.current_period_end * 1000)
        }
        setBillingDate(date);
        setSubscriptionData(result.data);
      }
      catch (err) {
        console.log(err)
      }
    }
    anyNameFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(AuthUser) {
      const userRoles = AuthUser['https://sbt-dashboard.com/roles'];
      if(userRoles.includes('admin')){
        setIsAdmin(true);
      }else{
        setTelehealthPerm(false);
      }
    }
  }, [AuthUser]);

  //Checking for user details
  useEffect(() => {
    if(userDetails) {
      setUpdateUser(false);
      setFirstName(userDetails.firstName || '');
      setLastName(userDetails.lastName || '');
      if(userDetails.mobileNum){
        const strippedMobNum = userDetails.mobileNum.split("+1");
        setNumber(strippedMobNum[1]);
      } else {
        setNumber(null);
      }
      if(userDetails.officeNum){
        const strippedOfficeNum = userDetails.officeNum.split("+1");
        setOfficeNumber(strippedOfficeNum[1]);
      } else {
        setOfficeNumber(null);
      }
      setRole(userDetails.role);
      if(userDetails.email) {
        setEmail(userDetails.email);
        setReqEmail(false);
      }
      setSeePatients(userDetails.seePatients || false);
      setAppointmentColor(userDetails.appointmentColor || null)
      setDisplayColorPicker(userDetails.appointmentColor === null ? true : false);
      setEmailNotification(userDetails.notifyEmail);
      setText(userDetails.notifySms);
      setFaxPerm(userDetails.permissions.efax?.viewable || false);
      setSmsPerm(userDetails.permissions.sms?.viewable || false);
      setChatPerm(userDetails.permissions.chat?.viewable || false);
      setPaymentPerm(userDetails.permissions.payment?.viewable || false);
      setAppointmentPerm(userDetails.permissions.appointment?.viewable || false);
      setTimeClockPerm(userDetails.permissions.time_clock?.viewable || false);
      setPatientsPerm(userDetails.permissions.patients?.viewable || false);
      setContactsPerm(userDetails.permissions.contacts?.viewable || false);
      setSecureMessagingPerm(userDetails.permissions.secure_messaging?.viewable || false);
      setTelehealthPerm(userDetails.permissions.telehealth?.viewable || false);
      setEfaxSettingsPerm(userDetails.permissions.efax?.settings_options?.viewable || false);
      setUsersPerm(userDetails.permissions.profile?.manage_users?.viewable || false);
      setManageServices(userDetails.permissions.profile?.manage_services?.viewable || false);
      setPaymentReportsPerm(userDetails.permissions.payment?.reports_options?.viewable || false);
      setTimeClockReportsPerm(userDetails.permissions.time_clock?.admin_reports_options?.viewable || false);
      setchatEditDeletePerm(userDetails.permissions.chat?.edit_delete_options?.viewable || false);
      setsecureChatEditDeletePerm(userDetails.permissions.secure_messaging?.edit_delete_options?.viewable || false)
      setNewSmsEmailNotif(userDetails.notifications.email?.sms?.newSms?.enabled || false);
      setNewEfaxEmailNotif(userDetails.notifications.email?.efax?.newEfax?.enabled || false);

      
      if(userDetails){
        setIsEditUserModal(true);
      } else {
        setIsEditUserModal(false);
      }
      return;
    }
    const permissions = user["https://sbt-dashboard.com/Permissions"];
    if(permissions.efax.viewable){
      setViewEfax(true);
    }
    if(permissions.sms.viewable){
      setViewSMS(true);
    }
    if(permissions.payment.viewable){
      setViewPayment(true);
    }
    if (permissions.time_clock.viewable){
      setViewTimeClock(true);
    }
    if (permissions.telehealth.viewable ){
      setViewTelehealth(true);
    }
    if( permissions.patients.viewable && permissions.contacts.viewable){
      setHasPatients(true);
      setHasContacts(true);
    }
    // eslint-disable-next-line
  }, [userDetails]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    if(width <= 768){
      setIsMobile(true)
     // window.FreshworksWidget('hide');
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    let updateArrayCheck = [ firstNameUpdate, lastNameUpdate, numberUpdate, officeNumberUpdate, emailUpdate, roleUpdate, telehealthPermUpdate, contactsPermUpdate, patientsPermUpdate, timeClockPermUpdate, paymentPermUpdate, smsPermUpdate, faxPermUpdate, appointmentColorUpdate, appointmentPermUpdate, secureMessagingPermUpdate, seePatientsUpdate]
    let checker = (arr) => arr.every(v => v === false);
    if(checker(updateArrayCheck)){
      setUpdateUser(false)
    }
  }, [ firstNameUpdate, lastNameUpdate,numberUpdate, officeNumberUpdate, emailUpdate, roleUpdate, telehealthPermUpdate, contactsPermUpdate, patientsPermUpdate, timeClockPermUpdate, paymentPermUpdate, smsPermUpdate, faxPermUpdate, appointmentColorUpdate, appointmentPermUpdate, secureMessagingPermUpdate, seePatientsUpdate ])

  useEffect(() => {
    if (organization?.getOrganization.orgPlan.length > 0) {
      if (organization.getOrganization.orgPlan.includes('efax')){
        setHasEfax(true);
      }
      if (organization.getOrganization.orgPlan.includes('appointment')) {
        setViewAppointment(true);
      }
      if (organization.getOrganization.orgPlan.includes('secure messaging')){
        setViewSecureMessages(true);
      }
      if (organization.getOrganization.orgPlan.includes('sms')){
        setHasSms(true);
      }
      if (organization.getOrganization.orgPlan.includes('chat')){
        setViewChat(true);
      }
      if (organization.getOrganization.orgPlan.includes('payment')) {
        setHasPayment(true);
      }
      if (organization.getOrganization.orgPlan.includes('time clock')) {
        setHasTimeClock(true);
      }
      if (organization.getOrganization.orgPlan.includes("telehealth")){
        setHasTeleHealth(true);
      }
    }
    // eslint-disable-next-line
  }, [organization]);

  // function to close modal and reset state
  const closeModal = () => {
    handlerModal(false);
    setFirstNameUpdate(false);
    setLastNameUpdate(false);
    setEmailUpdate(false);
    setCanAddNewUser(false);
    setIsSavingUser(false);
    setUserSaveSuccess(false);
    setIsEditUserModal(false);
    setPaymentError(false);
    setUpdateUser(false);
    setReqEmail(false);
    setAppointmentColor(null);
    setSeePatients(false);
    setDisplayColorPicker(true);
    setReqMob(false);
    setReqOfficeNum(false);
    setFirstName('');
    setLastName('');
    setNumber(null);
    setOfficeNumber(null);
    setRole('staff');
    setEmail(null);
    setEmailNotification(true);
    setText(false);
    setFaxPerm(true);
    setSmsPerm(true);
    setChatPerm(true);
    setPaymentPerm(true);
    setAppointmentPerm(true);
    setTimeClockPerm(true);
    setPatientsPerm(true);
    setContactsPerm(true);
    setSecureMessagingPerm(true);
    setTelehealthPerm(true);
    setNewEfaxEmailNotif(true);
    setNewSmsEmailNotif(true);
    setTimeClockReportsPerm(true);
    setchatEditDeletePerm(true);
    setsecureChatEditDeletePerm(true);
    setPaymentReportsPerm(true);
    setEfaxSettingsPerm(true);
    setUsersPerm(true);
    setManageServices(true);
    setIsArchivingUser(false);
    setConfirmPayment(false);
    setModulePermissionsForm('Account');
  };

  // Checking Modal open state
  useEffect(() => {
    setIsOpen(modalOpen);
  }, [modalOpen]);

  useEffect(() => {
    let requiredFields = []
    let tooltipString = ""
    if(!firstName){ requiredFields.push('First Name') }   
    if(!lastName){ requiredFields.push('Last Name') }
    if(!email || reqEmail){ requiredFields.push('Email') }
    if(!appointmentColor){ requiredFields.push('Appointment Color') }
    if(requiredFields.length > 1) {
      requiredFields.map((string, index) => {
        if(index + 1 === requiredFields.length && requiredFields.length){
          tooltipString += ` and ${string}`
        } else {        
          tooltipString += ` ${string},`
        }
        return true
      });
      setRequiredToolTip(tooltipString);
    } else if (requiredFields.length === 1) {
      setRequiredToolTip(requiredFields[0])
    } else {
      setRequiredToolTip("")
    } 
  }, [email ,reqEmail, firstName, lastName, appointmentColor])

  // Checking if required fields are ready
  useEffect(() => {
    if (firstNameUpdate && lastNameUpdate && emailUpdate && !reqEmail && firstName && lastName && appointmentColor) {
      setCanAddNewUser(true);
      return;
    }
    setCanAddNewUser(false);
  }, [firstNameUpdate, lastNameUpdate, emailUpdate, reqEmail, firstName, lastName, appointmentColor]);

  // Checking if optional mobile number field has some input
  useEffect(() => {
    if (reqMob){
      setUpdateUser(false);
      setCanAddNewUser(false);
    } else if (!reqMob && firstNameUpdate && lastNameUpdate && emailUpdate && !reqEmail && firstName.length > 0 && lastName.length > 0 && !reqOfficeNum && appointmentColor) {
      setCanAddNewUser(true);
    }
    // eslint-disable-next-line 
  }, [reqMob, number, reqOfficeNum]);

  // Checking if optional office number field has some input
  useEffect(() => {
    if (reqOfficeNum){
      setUpdateUser(false);
      setCanAddNewUser(false);
    } else if (!reqOfficeNum && firstNameUpdate && lastNameUpdate && emailUpdate && !reqEmail && firstName.length > 0 && lastName.length > 0 && !reqMob && appointmentColor) {
      setCanAddNewUser(true);
    }
    // eslint-disable-next-line 
  }, [reqOfficeNum, officeNumber, reqMob]);

  // Handle First Name
  const handleFirstNameChange = (name) => {
    let currentFirstName = ''
    if(userDetails){
      currentFirstName = userDetails.firstName ? userDetails.firstName : '';
    }
    if(currentFirstName !== name){
      setFirstNameUpdate(true);
      if(!reqOfficeNum && !reqMob){
        setUpdateUser(true);
      }
    } else{
      setFirstNameUpdate(false);
    }
    setFirstName(name);
  };

  // Handle Last Name
  const handleLastNameChange = (name) => {
    let currentLastName = ''
    if(userDetails){
      currentLastName = userDetails.lastName ? userDetails.lastName : '';
    }
    if(currentLastName !== name){
      setLastNameUpdate(true);
      if(!reqOfficeNum && !reqMob){
        setUpdateUser(true);
      }
    } else{
      setLastNameUpdate(false);
    }
    setLastName(name);
  };

  // Handle Mobile Number
  const handleMobileNumberChange = (phoneNumber) => {
    let currentNumber = ''
    if(userDetails){
        let stripped = userDetails.mobileNum ? userDetails.mobileNum.split("+1") : '';
        currentNumber = stripped ? stripped[1] : '';
        if (currentNumber !== phoneNumber) {
          setNumberUpdate(true)
          if(phoneNumber === '') {
            setNumber(phoneNumber);
            setReqMob(false);
            setUpdateUser(true);
            return;
          }
        const checkParsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');
        if(checkParsedNumber) {
          if(checkParsedNumber.isValid()){
            setReqMob(false);
            setNumber(phoneNumber);
            if(!reqOfficeNum){
              setUpdateUser(true);
            }
            return;
          }
          setNumber(phoneNumber);
          setReqMob(true);
          setUpdateUser(false);
        }
      }
    } 
    else{
      if(phoneNumber === '') {
        setNumber(phoneNumber);
        setReqMob(false);
        setUpdateUser(true);
        return;
      }
      const checkParsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');
      if(checkParsedNumber){
        if(checkParsedNumber.isValid()){
          setReqMob(false);
          setNumber(phoneNumber);
          if(!reqMob){
            setUpdateUser(true);
          }
          return;
        }
        setNumber(phoneNumber);
        setReqMob(true);
      }
    }
  };

  // Handle Office Number
  const handleOfficeNumberChange = (phoneNumber) => {
    if(userDetails){
        let stripped = userDetails.officeNum ? userDetails.officeNum.split("+1") : '';
        let currentNumber = stripped ? stripped[1] : '';
      if (currentNumber !== phoneNumber){
        setOfficeNumberUpdate(true)
        if(phoneNumber === '') {
          setOfficeNumber(phoneNumber);
          setReqOfficeNum(false);
          setUpdateUser(true);
          return;
        }
        const checkParsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');
        if(checkParsedNumber){
          if(checkParsedNumber.isValid()){
            setReqOfficeNum(false);
            setOfficeNumber(phoneNumber);
            if(!reqMob){
              setUpdateUser(true);
            }
            return;
          }
          setOfficeNumber(phoneNumber);
          setReqOfficeNum(true);
          setUpdateUser(false);
        }
      }
      else{
        setOfficeNumberUpdate(false)
      }
    }
    else{
      if(phoneNumber === '') {
        setOfficeNumber(phoneNumber);
        setReqOfficeNum(false);
        setUpdateUser(true);
        return;
      }
      const checkParsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');
      if(checkParsedNumber){
        if(checkParsedNumber.isValid()){
          setReqOfficeNum(false);
          setOfficeNumber(phoneNumber);
          if(!reqMob){
            setUpdateUser(true);
          }
          return;
        }
        setOfficeNumber(phoneNumber);
        setReqOfficeNum(true);
      }
    }
  };

  // Handle Email
  const handleEmailChange = (string) => {
    let currentEmail = ''
    if(userDetails){
      currentEmail = userDetails.email ? userDetails.email : '';  
    }
    if(currentEmail !== string){
      setEmailUpdate(true);
      if(isValidEmail(string)){
        if(!reqOfficeNum || !reqMob){
          setUpdateUser(true);
        }
      }else{
        setUpdateUser(false);
        setReqEmail(true);
      }
    } else{
      setEmailUpdate(false);
    }
    setEmail(string);
  };


  // Handle Role
  const handleRoleChange = (event) => {
    let currentRole = ''
    if(userDetails){
      currentRole = userDetails.role ? userDetails.role : '';
    }
    if (currentRole !== event.target.value){
      setRoleUpdate(true);
      if(!reqOfficeNum && !reqMob){
        setUpdateUser(true);
      }
    }else{
      setRoleUpdate(false);
    }
    setRole(event.target.value);
  };

  // Handle email notifications
  // const handleEmailPrefChange = (bool) => {
  //   if(userDetails){
  //     let currentNotifyEmail = userDetails.notifyEmail ? userDetails.notifyEmail  : 0
  //     let boolInput = bool ? 1 : 0
  //     if (currentNotifyEmail !== boolInput){
  //       setEmailNotificationUpdate(true);
  //       if(!reqOfficeNum && !reqMob){
  //         setUpdateUser(true);
  //       }
  //     }else{
  //       setEmailNotificationUpdate(false);
  //     }
  //   }
  //   setEmailNotification(bool);
  // };
  const handlePaymentReportsChange = (bool) => {
    if(userDetails){
      let currentPaymentReportsPerm = userDetails.permissions.payment?.reports_options?.viewable ? userDetails.permissions.payment?.reports_options?.viewable : false;
      let boolInput = bool ? true : false;
      if (currentPaymentReportsPerm !== boolInput){
        setPaymentReportsPermUpdate(true);
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setPaymentReportsPermUpdate(false)
      }
    }
    setPaymentReportsPerm(bool);
  };

  const handleChatEditDeleteChange = (bool) => {
    if(userDetails){
      let currentChatEditDeletePerm = userDetails.permissions.chat?.edit_delete_options?.viewable ? userDetails.permissions.chat?.edit_delete_options?.viewable : false;
      let boolInput = bool ? true : false;
      if (currentChatEditDeletePerm !== boolInput){
        setchatEditDeletePermUpdate(true);
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setchatEditDeletePermUpdate(false)
      }
    }
    setchatEditDeletePerm(bool);
  };

  const handleSecureChatEditDeleteChange = (bool) => {
    if(userDetails){
      let currentSecureChatEditDeletePerm = userDetails.permissions.secure_messaging?.edit_delete_options?.viewable ? userDetails.permissions.secure_messaging?.edit_delete_options?.viewable : false;
      let boolInput = bool ? true : false;
      if (currentSecureChatEditDeletePerm !== boolInput){
        setsecureChatEditDeletePermUpdate(true);
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setsecureChatEditDeletePermUpdate(false)
      }
    }
    setsecureChatEditDeletePerm(bool);
  };


  const handleTimeclockReportsChange = (bool) => {
    if(userDetails){
      let currentTimeclockReportsPerm = userDetails.permissions.time_clock?.admin_reports_options?.viewable ? userDetails.permissions.time_clock?.admin_reports_options?.viewable : false;
      let boolInput = bool ? true : false;
      if (currentTimeclockReportsPerm !== boolInput){
        setTimeClockReportsPermUpdate(true);
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setTimeClockReportsPermUpdate(false)
      }
    }
    setTimeClockReportsPerm(bool);
  };

  const handleUsersPermChange = (bool) => {
    if(userDetails){
      let currentUsersPerm = userDetails.permissions.profile?.manage_users?.viewable ? userDetails.permissions.profile?.manage_users?.viewable : false;
      let boolInput = bool ? true : false;
      if (currentUsersPerm !== boolInput){
        setUsersPermUpdate(true);
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setUsersPermUpdate(false)
      }
    }
    setUsersPerm(bool);
  };

  const handleManageServicesChange = (bool) => {
    if(userDetails){
      let currentManageServicesPerm = userDetails.permissions.profile?.manage_services?.viewable ? userDetails.permissions.profile?.manage_services?.viewable : false;
      let boolInput = bool ? true : false;
      if (currentManageServicesPerm !== boolInput){
        setManageServicesUpdate(true);
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setManageServicesUpdate(false)
      }
    }
    setManageServices(bool);
  };

  const handleEfaxSettingsChange = (bool) => {
    if(userDetails){
      let currentEfaxSettingsPerm = userDetails.permissions.efax?.settings_options?.viewable ? userDetails.permissions.efax?.settings_options?.viewable : false;
      let boolInput = bool ? true : false;
      if (currentEfaxSettingsPerm !== boolInput){
        setEfaxSettingsPermUpdate(true);
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setEfaxSettingsPermUpdate(false)
      }
    }
    setEfaxSettingsPerm(bool);
  };

  const handleNewSmsNotifChange = (bool) => {
    if(userDetails){
      let currentNewSmsNotif = userDetails.notifications?.email?.sms?.newSms?.enabled ? userDetails.notifications?.email?.sms?.newSms?.enabled : false;
      let boolInput = bool ? true : false;
      if (currentNewSmsNotif !== boolInput){
        setNewSmsEmailNotifUpdate(true);
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setNewSmsEmailNotifUpdate(false)
      }
    }
    setNewSmsEmailNotif(bool);
  };

  const handleNewEfaxNotifChange = (bool) => {
    if(userDetails){
      let currentNewEfaxNotif = userDetails.notifications?.email?.sms?.newSms?.enabled ? userDetails.notifications?.email?.sms?.newSms?.enabled : false;
      let boolInput = bool ? true : false;
      if (currentNewEfaxNotif !== boolInput){
        setNewEfaxEmailNotifUpdate(true);
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setNewEfaxEmailNotifUpdate(false)
      }
    }
    setNewEfaxEmailNotif(bool);
  };

  // eslint-disable-next-line
  const handleTelehealthChange = (bool) => {
    if(userDetails){
      let currentTelehealthPerm = userDetails.permissions.telehealth?.viewable ? userDetails.permissions.telehealth?.viewable : false;
      let boolInput = bool ? true : false
      if (currentTelehealthPerm !== boolInput){  
        setTelehealthPermUpdate(true);      
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setTelehealthPermUpdate(false);
      }
      setTelehealthPerm(bool);
    }
    else{
      setTelehealthPerm(bool);
    }
  };

  // eslint-disable-next-line
  const handleSecureMessagingChange = (bool) => {
    // secureMessagingPerm
    let currentSecureMessagingPerm = userDetails?.permissions?.secure_messaging?.viewable ? userDetails.permissions?.secure_messaging?.viewable : false;
    let boolInput = bool ? true : false;
    if (currentSecureMessagingPerm !== boolInput){
      setSecureMessagingPermUpdate(true);     
      if(!reqOfficeNum && !reqMob){
        setUpdateUser(true);
      }
    } else {
      setSecureMessagingPermUpdate(true);     
    }
    console.log(bool)
    setSecureMessagingPerm(bool);
  };

  const handleContactsChange = (bool) => {
    if(userDetails){
      let currentContactsPerm = userDetails.permissions.contacts?.viewable ? userDetails.permissions.contacts?.viewable : false;
      let boolInput = bool ? true : false;
      if (currentContactsPerm !== boolInput){
        setContactsPermUpdate(true);
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setContactsPermUpdate(false)
      }
    }
    setContactsPerm(bool);
  };

  const handlePatientsChange = (bool) => {
    if(userDetails){
      let currentPatientsPerm = userDetails.permissions.patients?.viewable ? userDetails.permissions.patients?.viewable : false;
      let boolInput = bool ? true : false;
      if (currentPatientsPerm !== boolInput){
        setPatientsPermUpdate(true);
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setPatientsPermUpdate(false)
      }
    }
    setPatientsPerm(bool);
  };

  const handleTimeClockChange = (bool) => {
    if(userDetails){
      let currentTimeClockPerm = userDetails.permissions.time_clock?.viewable ? userDetails.permissions.time_clock?.viewable : false;
      let boolInput = bool ? true : false;
      if (currentTimeClockPerm !== boolInput){
        setTimeClockPermUpdate(true);
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setTimeClockPermUpdate(false);
      }
    }
    setTimeClockPerm(bool);
  };

  const handlePaymentChange = (bool) => {
    if(userDetails){
      let currentPaymentPerm = userDetails.permissions.payment?.viewable ? userDetails.permissions.payment?.viewable : false;
      let boolInput = bool ? true : false;
      if (currentPaymentPerm !== boolInput){
        setPaymentPermUpdate(true);
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setPaymentPermUpdate(false);
      }
    }
    setPaymentPerm(bool);
  };

  const handleAppointmentChange = (bool) => {
    if(userDetails){
      let currentAppointmentPerm = userDetails.permissions.appointment?.viewable ? userDetails.permissions.appointment?.viewable : false;
      let boolInput = bool ? true : false;
      if (currentAppointmentPerm !== boolInput){
        setAppointmentPermUpdate(true);
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setAppointmentPermUpdate(false);
      }
    }
    setAppointmentPerm(bool);
  };

  // eslint-disable-next-line
  const handleChatChange = (bool) => {
    if(userDetails){
    let currentChatPerm = userDetails.permissions.chat?.viewable ? userDetails.permissions.chat?.viewable : false;
    let boolInput = bool ? true : false;
    if (currentChatPerm !== boolInput){
      if(!reqOfficeNum && !reqMob){
        setUpdateUser(true);
      }
    }else{
      setUpdateUser(false)
    }
    }
    setChatPerm(bool);
  };

  // eslint-disable-next-line
  const handleSmsChange = (bool) => {
    if(userDetails){
      let currentSmsPerm = userDetails.permissions.sms?.viewable ? userDetails.permissions.sms?.viewable : false;
      let boolInput = bool ? true : false;
      if (currentSmsPerm !== boolInput){
        setSmsPermUpdate(true);
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setSmsPermUpdate(false)
      }
    }
    setSmsPerm(bool);
  };

  const handleEfaxChange = (bool) => {
    if(userDetails){
      let currentEfaxPerm = userDetails.permissions.efax?.viewable  ? userDetails.permissions.efax?.viewable  : false;
      let boolInput = bool ? true : false;
      if (currentEfaxPerm !== boolInput){
        setFaxPermUpdate(true);
        if(!reqOfficeNum && !reqMob){
          setUpdateUser(true);
        }
      }else{
        setFaxPermUpdate(false)
      }
    }
    setFaxPerm(bool);
  };

  const UpdateOrgSubs = async(teleParam) =>{
    try {
      let subscriptionPayload ={
        // This needs to be ALWAYS true 
        isTeleHealth : teleParam
      }
      const subscription = await api.UpdateOrgSubscription(subscriptionPayload);
      if (subscription.status === 200 && subscription.data !== null) {
        setSeverity('success');
        setSnackBarMessage("Subscription Update Successful!");
        setOpenSnackBar(true);
        setLoading(false);
      }
      else {
        setSeverity('error');
        setSnackBarMessage("Subscription Update Failed!");
        setOpenSnackBar(true);
        setLoading(false);
      }
    } catch (error) {
      setSeverity('error');
      setSnackBarMessage("Subscription Update Failed!");
      setOpenSnackBar(true);        
      setLoading(false);
    }
  }

  const handleSubmit = async () => {
    await ProcessOrgCreation();
  };

  const ProcessOrgCreation = async () => {
    setLoading(true);
    try {
      if(!isEditUserModal) {
        const permissions = new permissionsStructure();
        permissions.appointment.viewable = appointmentPerm;
        permissions.efax.viewable = faxPerm;
        permissions.efax.settings_options.viewable = efaxSettingsPerm;
        permissions.sms.viewable = smsPerm;
        permissions.chat.viewable = chatPerm;
        permissions.chat.edit_delete_options.viewable = chatEditDeletePerm;
        permissions.secure_messaging.edit_delete_options.viewable = secureChatEditDeletePerm;
        permissions.payment.viewable = paymentPerm;
        permissions.payment.reports_options.viewable = paymentReportsPerm;
        permissions.time_clock.viewable = timeClockPerm;
        permissions.time_clock.admin_reports_options.viewable = timeClockReportsPerm;
        permissions.patients.viewable = patientsPerm;
        permissions.secure_messaging.viewable = secureMessagingPerm;
        permissions.contacts.viewable = contactsPerm;
        permissions.telehealth.viewable = telehealthPerm;
        permissions.profile.manage_services.viewable = manageServicesPerm;
        permissions.profile.manage_users.viewable = usersPerm;

        const notifications = new notificationStructure();
        notifications.email.efax.newEfax.enabled = newEfaxEmailNotif;
        notifications.email.sms.newSms.enabled = newSmsEmailNotif;
        notifications.webpush.sms.enabled = true;
        notifications.webpush.sms.newSms.enabled = true;

        const payload = {
          firstName: capitalize(firstName),
          lastName: capitalize(lastName),
          mobileNum: (number !== null && number !== '' ) ? ("+1" + number) : null,
          email: email,
          notifyEmail: emailNotification,
          notifySms: text,
          isTeleHealth: telehealthPerm,
          officeNum: (officeNumber !== null && officeNumber !== '' ) ? ("+1" + officeNumber) : null,
          role: role.toLowerCase(),
          permissions: permissions,
          notifications: notifications,
          appointmentColor: appointmentColor,
          seePatients: seePatients
        };

        const addUser = await api.createStaffUser(payload);
        if(addUser.status === 200) {
          setSeverity('success');
          setSnackBarMessage('Successfully added user');
          setOpenSnackBar(true);
          handlerReload(true);
          setIsSavingUser(false);
          setUserSaveSuccess(true);
          return;
        } else {
          const err = addUser.data;
          setSeverity('error');
          setSnackBarMessage(err);
          setOpenSnackBar(true);
          return;
        }
      };
      
      const permissions = new permissionsStructure();
      // let checkTeleHealth = permissions.telehealth.viewable;
      permissions.appointment.viewable = appointmentPerm;
      permissions.efax.viewable = faxPerm;
      permissions.efax.settings_options.viewable = efaxSettingsPerm;
      permissions.sms.viewable = smsPerm;
      permissions.chat.viewable = chatPerm;
      permissions.chat.edit_delete_options.viewable = chatEditDeletePerm;
      permissions.secure_messaging.edit_delete_options.viewable = secureChatEditDeletePerm;
      permissions.payment.viewable = paymentPerm;
      permissions.payment.reports_options.viewable = paymentReportsPerm;
      permissions.time_clock.viewable = timeClockPerm;
      permissions.time_clock.admin_reports_options.viewable = timeClockReportsPerm;
      permissions.patients.viewable = patientsPerm;
      permissions.secure_messaging.viewable = secureMessagingPerm;
      permissions.contacts.viewable = contactsPerm;
      permissions.telehealth.viewable = telehealthPerm;
      permissions.profile.manage_services.viewable = manageServicesPerm;
      permissions.profile.manage_users.viewable = usersPerm;

      const notifications = new notificationStructure();
      notifications.email.efax.newEfax.enabled = newEfaxEmailNotif;
      notifications.email.sms.newSms.enabled = newSmsEmailNotif;
      notifications.webpush.sms.enabled = true;
      notifications.webpush.sms.newSms.enabled = true;

      const payload = {
        ...firstNameUpdate && {firstName: capitalize(firstName)},
        ...lastNameUpdate  && {lastName: capitalize(lastName)},
        ...numberUpdate  && {mobileNum: (number !== null && number !== '' ) ? ("+1" + number) : null},
        ...emailUpdate  && {email: email},
        ...officeNumberUpdate  && {officeNum: (officeNumber !== null && officeNumber !== '' ) ? ("+1" + officeNumber) : null},
        ...roleUpdate  && {role: role.toLowerCase()},
        ...appointmentColorUpdate && {appointmentColor: appointmentColor},
        ...seePatientsUpdate && {seePatients: seePatients},
        ...(usersPermUpdate || manageServicesPermUpdate || efaxSettingsPermUpdate || paymentReportsPermUpdate || timeClockReportsPermUpdate || faxPermUpdate || smsPermUpdate || paymentPermUpdate || timeClockPermUpdate || patientsPermUpdate || contactsPermUpdate || telehealthPermUpdate || appointmentPermUpdate || secureMessagingPermUpdate || chatEditDeletePermUpdate || secureChatEditDeletePermUpdate) && {permissions: permissions},
        ...(newSmsEmailNotifUpdate || newEfaxEmailNotifUpdate) && {notifications: notifications}
      };

      const user = userDetails.ulid;
      const editUser = await api.editOtherStaffUser(payload, user);
      if (editUser.status === 200) {
        setSeverity('success');
        setSnackBarMessage('Successfully edited user');
       if(telehealthPermUpdate){
          await UpdateOrgSubs(telehealthPerm);
       }
        setOpenSnackBar(true);
        setLoading(false);
        handlerReload(true);
        closeModal();
      } else {
        const err = editUser.data;
        setSeverity('error');
        setLoading(false);
        setSnackBarMessage(err);
        setOpenSnackBar(true);
      }
    } catch (error) {
      setSeverity('error');
      setLoading(false);
      setSnackBarMessage(error.response.data.message);
      setOpenSnackBar(true);
    } finally {
      setLoading(false);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const calculateProation = (billingDate) => {
    let monthlyPayment = 5
    if(telehealthPerm){
      monthlyPayment += 25
    }
    if(billingDate){
      setMonthlyPrice(monthlyPayment)
      let periodEnd = moment(billingDate)
      let periodStart = moment(billingDate).subtract(1, 'months')
      let remaningTime = periodEnd.diff(moment(), 'seconds') / 86400
      let fullPeriod = periodEnd.diff(periodStart, 'seconds') / 86400
      let peroratedPrice = remaningTime / fullPeriod * monthlyPayment
      setProratedPrice(peroratedPrice.toFixed(2))
    }else{
      setMonthlyPrice(monthlyPayment)
      setProratedPrice(0)
    }
  }

  const handleArchiveUser = async () => {
    try {
      const payload = userDetails.ulid;
      const archiveUser = await api.deleteStaffUser(payload);
      if (archiveUser.status === 200) {
        setSeverity('success');
        setSnackBarMessage('User has been successfully archived');
        setOpenSnackBar(true);
        handlerReload(true);
        closeModal();
      }
    } catch (error) {
      setSeverity('error');
      setSnackBarMessage(error.response.data.message);
      setOpenSnackBar(true);
    }
  };
  const handleNext = async () => {
    setLoading(true);
    let firstStep = {
      email: email,
      mobileNum: `+1${number}`
    }
    const isValidEmailNumber = await api.checkIfUserExists(firstStep);
    if (isValidEmailNumber != null && isValidEmailNumber.status === 200) {
      if (isValidEmailNumber.data.number && isValidEmailNumber.data.email) {
        setSeverity('error');
        setReqMob(true);
        setReqEmail(true);
        setSnackBarMessage("This Email & Number already exists");
        setOpenSnackBar(true);
      }
      if (isValidEmailNumber.data.email && !isValidEmailNumber.data.number) {
        setSeverity('error');
        setReqEmail(true);
        setSnackBarMessage("This Email already exists");
        setOpenSnackBar(true);
      }
      if (isValidEmailNumber.data.number && !isValidEmailNumber.data.email) {
        setSeverity('error');
        setReqMob(true);
        setSnackBarMessage("This Number already exists");
        setOpenSnackBar(true);
      }
      if (isValidEmailNumber.data.email || isValidEmailNumber.data.number) {
        setLoading(false);
        return false;
      } else {
        setLoading(false);
        setIsSavingUser(true);
      }
    }
    else {
      setLoading(false);
    }
  }
  let blockStyles={
    default: { 
      card:{
        boxShadow: null,
      },
      head: {
        borderLeft: `1px solid ${(appointmentColor?.toLowerCase() !== '#ffffff' && appointmentColor !== null )  ? appointmentColor : '#dcdcdc' }`,
        borderRight: `1px solid ${(appointmentColor?.toLowerCase() !== '#ffffff' && appointmentColor !== null )  ? appointmentColor : '#dcdcdc' }`,
        borderTop: `1px solid ${(appointmentColor?.toLowerCase() !== '#ffffff' && appointmentColor !== null )  ? appointmentColor : '#dcdcdc' }`,
      },
       body: {
        borderLeft: `1px solid ${(appointmentColor?.toLowerCase() !== '#ffffff' && appointmentColor !== null )  ? appointmentColor : '#dcdcdc' }`,
        borderRight: `1px solid ${(appointmentColor?.toLowerCase() !== '#ffffff' && appointmentColor !== null )  ? appointmentColor : '#dcdcdc' }`,
        borderBottom: `1px solid ${(appointmentColor?.toLowerCase() !== '#ffffff' && appointmentColor !== null )  ? appointmentColor : '#dcdcdc' }`,   
      },
      triangle: {
       borderColor: `transparent transparent ${(appointmentColor?.toLowerCase() !== '#ffffff' && appointmentColor !== null )  ? appointmentColor : '#dcdcdc' }`   
     },
     label: {
       color: '#FFFFFF',
     },
    },
  };

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert 
          onClose={handleClose} 
          severity={severity}
          sx={{ width: '100%' }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      {isArchivingUser ? 
        <Dialog 
          open={modalIsOpen} 
          onClose={closeModal}
          disableBackdropClick={true} 
          disableEscapeKeyDown
          style={{margin: 20}}
          fullScreen={isMobile}
          // fullWidth
        >
          <DialogContent>
            <Typography style={{margin: 20}} align='center' variant='h4'>Are You Sure?</Typography>
            <Typography align='center' variant='h7'>{`${firstName} ${lastName} will be archived. You may reactivate at a later time if you wish.`}</Typography>
            <Typography style={{margin: 20}} align='center' variant="subtitle2">***Billing for your account will be updated to reflect these changes.</Typography>
            <div style={{float: 'right', margin: 20}}>
              <Button
                className={classes.submitButton}
                variant='contained'
                onClick={handleArchiveUser}
              >
                Archive User
              </Button>
              <Button
                variant='contained'
                color='secondary'
                className={classes.cancelButton}
                onClick={() => setIsArchivingUser(false)}
              >
                Cancel
              </Button> 
            </div>
            
          </DialogContent>
        </Dialog> :
        (isSavingUser
          ? 
            <Dialog 
              open={modalIsOpen} 
              onClose={closeModal}
              disableBackdropClick={true} 
              disableEscapeKeyDown
              style={{paddingBottom: 0}}
              fullScreen={isMobile}
              // fullWidth
            >
              <DialogContent style={{display: 'flex', flexDirection: 'column', paddingBottom: 0, minWidth: !isMobile ? 600 : 0}}>
                <Typography style={{margin: 20}} align='center' variant='h4'>Are You Sure?</Typography>
                <Typography align='left' variant='subtitle1' style={{marginBottom: 25}}>{`${firstName} ${lastName} will be created. `}</Typography>
                <Typography align='left' variant='subtitle2'>Charges to be added to your monthly subscription: </Typography>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Typography align='left' variant='subtitle2' style={{marginLeft: 40, width: 50}}>$5</Typography>
                  <Typography align='left' variant='subtitle2' style={{marginRight: 3}}>-</Typography>                  
                  <Typography align='left' variant='subtitle2'>Additional User</Typography>

                </div>
                {telehealthPerm &&
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Typography align='left' variant='subtitle2' style={{marginLeft: 40, width: 50}}>$25</Typography>
                  <Typography align='left' variant='subtitle2' style={{marginRight: 3}}>-</Typography>
                  <Typography align='left' variant='subtitle2'>Additional Telehealth User</Typography>

                </div>}

                {subscriptionData && subscriptionData.status === 'active' && proratedPrice !== 0 ? <Typography align='left' variant='subtitle2' style={{marginTop: 25, marginBottom: 25}}>{`A prorated charge of $${proratedPrice} will be added to your billing invoice.`}</Typography> : <Typography align='left' variant='subtitle2' style={{marginTop: 25}}></Typography>}
                <div style={{display: 'flex', flexDirection: 'row', marginBottom: 25}}>
                  <Checkbox 
                    checked={confirmPayment}
                    disabled={loading}
                    style={{margin: '0 5px 0 0', padding: 0}}
                    onChange={() => setConfirmPayment(!confirmPayment)} 
                    name="Timeclock" 
                  />
                  {proratedPrice !== 0 ? 
                  <Typography align='left' variant='subtitle2'>{`I agree to ${subscriptionData && subscriptionData.status === 'active' ? `add a prorated payment of $${proratedPrice} to my next billing invoice, and`: ''} have $${monthlyPrice} added to the monthly subscription.`}</Typography> : 
                  <Typography align='left' variant='subtitle2' style={{marginRight:150}}>{`I agree to have $${monthlyPrice} added to the monthly subscription.`}</Typography>
                  }
                </div>
                <div style={{justifyContent: 'flex-end', display: 'flex', marginTop: 20}}>
                  <Button
                    className={classes.submitButton}
                    disabled={!confirmPayment || loading}
                    variant='contained'
                    onClick={handleSubmit}
                  >
                    Confirm
                  </Button>
                  <Button
                    variant='contained'
                    color='secondary'
                    className={classes.cancelButton}
                    disabled={loading}
                    onClick={() => {
                      setConfirmPayment(false);
                      setIsSavingUser(false);
                    }}
                  >
                    Cancel
                  </Button> 
                </div>
                <Typography align='left' variant='subtitle2' style={{color: '#909090', marginBottom: 0, paddingBottom: 0, fontSize: 11}}>***Billing for your account will be updated to reflect these changes.</Typography>

                
              </DialogContent>
            </Dialog>
      :
      (userSaveSuccess ? 
      <Dialog 
        open={modalIsOpen} 
        onClose={closeModal}
        disableBackdropClick={true} 
        disableEscapeKeyDown
        // style={{margin: 20}}
        fullScreen={isMobile}
        fullWidth
      >
        <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
          <Typography style={{margin: 20}} align='center' variant='h4'>User Created</Typography>
          <Typography align='left' variant='subtitle1' style={{marginBottom: 25}}>{`${firstName} ${lastName} has successsfully been created. They may log in with the email you supplied. `}</Typography>
          <div style={{justifyContent: 'center', display: 'flex', marginTop: 20}}>
            <Button
              className={classes.submitButton}
              variant='contained'
              onClick={closeModal}
            >
              Close
            </Button>
          </div>
          
        </DialogContent>
      </Dialog> : 
      (paymentError ? 
        <Dialog 
          open={modalIsOpen} 
          onClose={closeModal}
          disableBackdropClick={true} 
          disableEscapeKeyDown
          // style={{margin: 20}}
          fullScreen={isMobile}
          fullWidth
        >
          <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
          <DialogTitleExitableCenter onClose={closeModal}>Payment Issue</DialogTitleExitableCenter>
            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 25}}>
              <Typography align='left' variant='subtitle1' style={{marginRight: 10}}>Payment Failed: </Typography>
              <Typography align='left' variant='subtitle1'>failed reason</Typography>
            </div>
            <Typography align='left' variant='subtitle1' >Please update your card information or contact your bank and try again to process. </Typography>

            <StripeTextField 
              disabledValue={loading}
              label="Credit Card" 
              stripeElement={CardElement}
              onChange={(e) => {
                  if(e.error){
                    setError(true)
                  }else{
                    setError(false);
                  }
                  setCardComplete(e.complete);
              }}/>
            <div style={{justifyContent: 'center', display: 'flex', marginTop: 40}}>
              <Button
                className={classes.submitButton}
                variant='contained'
                disabled={!cardComplete}
              >
                Update Card
              </Button>
            </div>
            
          </DialogContent>
        </Dialog> : 
      <Dialog open={modalIsOpen} onClose={closeModal} fullWidth fullScreen={isMobile}>
      <DialogTitleExitable onClose={closeModal}>{isEditUserModal ? 'Edit User' : 'Add User'}</DialogTitleExitable>
        <div className={classes.contentContainer}>
          <Backdrop
            className={classes.backdrop}
            open={loading}
          >
            <CircularProgress />
          </Backdrop>
          <form autoComplete="false">

            <div className={classes.row}>
              <div className={classes.inputContainerLeft}>
                <TextField
                  onKeyPress={(e) => { if (e.key === 'Enter') {refField1.current.focus() } }}
                  disabled={loading}
                  label="First Name" 
                  onChange={(e) => handleFirstNameChange(e.target.value)}
                  InputProps={{
                    className: classes.textFields
                  }}
                  inputProps={{
                    maxLength: 20
                  }}
                  value={firstName}
                  autoComplete="false"
                />
                </div>
                <div className={classes.inputContainerRight}>
                  <TextField
                  inputRef={refField1}
                  disabled={loading}
                  label="Last Name"
                  onChange={(e) => handleLastNameChange(e.target.value)}
                  InputProps={{
                    className: classes.textFields
                  }}
                  inputProps={{
                    maxLength: 20
                  }}
                  value={lastName}
                />
                </div>
            </div>

            <div className={classes.row}>
                
            <div className={classes.inputContainerLeft}>
              <NumberFormat
                  disabled={loading}
                  customInput={NumberFormatCustom}
                  value={number}
                  error={reqMob}
                  label={"Mobile Number"}
                  onValueChange={(value) => {
                    handleMobileNumberChange(value.value);
                  }}
                  format="(###) ###-####"
                  mask=" "
                />
                </div>
                <div className={classes.inputContainerRight}>
                  <NumberFormat
                  disabled={loading}
                  customInput={NumberFormatCustom}
                  value={officeNumber}
                  error={reqOfficeNum}
                  label={"Office Number"}
                  onValueChange={(value) => {
                    handleOfficeNumberChange(value.value);
                  }}
                  format="(###) ###-####"
                  mask=" "
                />
                </div>
            </div>
            {isMobile ? 
            <div className={classes.row}>
              <div className={classes.emailInputContainer}>
                <TextField
                  disabled={loading}
                  fullWidth
                  label="Email"
                  InputProps={{
                    style: {
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontFamily: "Arial,Helvetica,sans-serif",
                      height: 50,
                      marginBottom: 15,
                    },
                  }}
                  autoComplete="false"
                  onChange={(e) => {
                    handleEmailChange(e.target.value);
                    setReqEmail(!isValidEmail(e.target.value))
                  }}
                  // onBlur={checkEmail}
                  value={email}
                  error={reqEmail}
                />
                </div>
            </div> : null
            }
            <div className={classes.row}>

            <div className={classes.inputContainerLeft} >
              <form >
              <FormControl >
                <InputLabel id="demo-dialog-select-label" shrink>Role</InputLabel>
                <Select
                  disabled={loading || !isAdmin}
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={role}
                  MenuProps={MenuProps}
                  onChange={handleRoleChange}
                  input={
                    <OutlinedInput
                      label="Role"
                      notched
                      style={{
                        fontSize: "16px",
                        fontStyle: "normal",
                        minWidth:  isMobile ? "157px" : "268px",
                        maxWidth: isMobile ? "157px" : "268px",
                        fontFamily: "Arial,Helvetica,sans-serif",
                        height: 50,
                        marginBottom: isMobile ? "0px" : "15px",
                      }}
                    />
                  }
                >
                  <MenuItem value={'staff'}>staff</MenuItem>
                  <MenuItem value={'admin'}>admin</MenuItem>
                </Select>
                </FormControl>
              </form>

              </div>
              {!isMobile ? 
              <div style={{width: '50%'}}>
              <Tooltip
                title='Select a unique color for this user to display on the appointment schedule.'
                placement="top"
                arrow
              >
                <div className={classes.inputContainerCalendar} onClick={ () => {setDisplayColorPicker(true)}}>
                    <span className={classes.appointmentColorCircle} 
                      style={{ backgroundColor: appointmentColor ? appointmentColor : null, border: (appointmentColor?.toLowerCase() === '#ffffff' || appointmentColor === null ) ? '1px solid #707070' : null}}/>
                    <Typography variant='subtitle1' color="#707070" >Calendar Color</Typography>
                </div>
                </Tooltip>
                {(displayColorPicker || appointmentColor === null ) ? 
                  <ClickAwayListener 
                    onClickAway={ () => { 
                      if(appointmentColor !== null) setDisplayColorPicker(false)
                    }}
                  >
                    <div className={classes.popover}>
                      <BlockPicker 
                        styles={blockStyles}
                        color={appointmentColor ? appointmentColor : '#FFFFFF'}
                        onChangeComplete={handleAppointmentColorChange}
                        colors={['#34a853', '#4285f4', '#7f6000', '#46bdc6', '#ff6d01', '#ff00ff', '#bf9000', '#674ea7', '#85200c', '#0c343d', '#666666', '#45818e']}
                      />
                    </div> 
                  </ClickAwayListener> : null}
                    

                </div> : null }
            </div>
            {!isMobile ? 
            <div className={classes.row}>
              <div className={classes.emailInputContainer}>
                <TextField
                  disabled={loading}
                  fullWidth
                  label="Email"
                  InputProps={{
                    style: {
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontFamily: "Arial,Helvetica,sans-serif",
                      height: 50,
                      marginBottom: 15,
                    },
                  }}
                  autoComplete="false"
                  onChange={(e) => {
                    handleEmailChange(e.target.value);
                    setReqEmail(!isValidEmail(e.target.value))
                  }}
                  // onBlur={checkEmail}
                  value={email}
                  error={reqEmail}
                />
                </div>
            </div> : null
            }
            {!isMobile ?
            <Tooltip
              title='User will be available to book appointments with.'
              placement="top"
              arrow
            >
              <div className={classes.appointmentRow}>
                <Checkbox 
                  size="small"
                  color='primary'
                  checked={seePatients}
                  className={classes.permissionCheckMarkNoPaddingTelehealth}
                  onChange={() => handleAvailableForAppointmentsChange(!seePatients)}
                  name="Available for appointments" 
                />
                <Typography style={{marginLeft: '12px'}} variant='subtitle1'>Available for appointments</Typography>
              </div>
            </Tooltip> : 
            <div className={classes.row}>
              <Tooltip
              title='User will be available to book appointments with.'
              placement="top"
              arrow
            >
              <div className={classes.appointmentRow}>
                <Checkbox 
                  size="small"
                  color='primary'
                  checked={seePatients}
                  className={classes.permissionCheckMarkNoPaddingTelehealth}
                  onChange={() => handleAvailableForAppointmentsChange(!seePatients)}
                  name="Available for appointments" 
                />
                <Typography style={{marginLeft: '12px'}} variant='subtitle1'>Available for appointments</Typography>
              </div>
            </Tooltip>
            <div style={{width: '46%'}}>
              <Tooltip
                title='Select a unique color for this user to display on the appointment schedule.'
                placement="top"
                arrow
              >
                <div className={classes.inputContainerCalendar} onClick={ () => {setDisplayColorPicker(true)}}>
                    <span className={classes.appointmentColorCircle} 
                      style={{ backgroundColor: appointmentColor ? appointmentColor : null, border: (appointmentColor?.toLowerCase() === '#ffffff' || appointmentColor === null ) ? '1px solid #707070' : null}}/>
                    <Typography variant='subtitle1' color="#707070" >Calendar Color</Typography>
                </div>
                </Tooltip>
                {(displayColorPicker || appointmentColor === null ) ? 
                  <ClickAwayListener 
                    onClickAway={ () => { 
                      if(appointmentColor !== null) setDisplayColorPicker(false)
                    }}
                  >
                    <div className={classes.popover}>
                      <BlockPicker 
                        styles={blockStyles}
                        color={appointmentColor ? appointmentColor : '#FFFFFF'}
                        onChangeComplete={handleAppointmentColorChange}
                        colors={['#34a853', '#4285f4', '#7f6000', '#46bdc6', '#ff6d01', '#ff00ff', '#bf9000', '#674ea7', '#85200c', '#0c343d', '#666666', '#45818e']}
                      />
                    </div> 
                  </ClickAwayListener> : null}
                    

                </div>
            </div>
            }
            {/* <div className={classes.formControl}>
              <Typography variant='body1' className={classes.thirdRow}>Notification Preferences:</Typography>
              <div className={classes.notficationContainer} >
                <FormControl className={classes.notficationContainer} >
                  <FormGroup className={`${classes.checkBoxPadding}  ${classes.notficationContainer}`}>
                    <FormControlLabel
                      control={<Checkbox 
                        disabled={loading} size="small" color='primary' checked={emailNotification} onChange={() => handleEmailPrefChange(!emailNotification)} name="Email" />}
                      label="Email"
                    />
                    <FormControlLabel
                      control={<Checkbox 
                        disabled={loading} size="small" color='primary' checked={text} onChange={() => handleTextPrefChange(!text)} name="Text" />}
                      label="Text"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div> */}

            <div className={classes.bottomAccessPermissions}>
              <div className={classes.accessContainer}>
                <div className={classes.accessLabel}>
                  <Typography>Access</Typography>
                  <Tooltip
                    title='Select which modules your user has access to below. Only modules a user has access to will be displayed to them.'
                    placement="right"
                    arrow
                  >
                    <HelpIcon color='primary'/>
                  </Tooltip>
                </div>
                <Tooltip
                  title={"View or update your own information."}
                  placement="right"
                  arrow
                >
                  <div 
                   className={modulePermissionsForm === 'Account' ? classes.moduleAccessSelected : classes.moduleAccess}
                   onClick={() => setModulePermissionsForm('Account')}
                  >
                    <div className={classes.iconAndLabel}>
                      <PersonIcon
                        className={classes.iconColor}
                      />
                      <Typography>Account</Typography>
                    </div>
                    
                    <CheckBoxIcon 
                      checked={true} disabled className={classes.disabledCheck}
                    />
                  </div>
                </Tooltip>

                <Tooltip
                  title="Schedule and manage patient appointments."
                  placement="right"
                  arrow
                >
                  <div
                    className={modulePermissionsForm === 'Appointment' ? classes.moduleAccessSelected : classes.moduleAccess} 
                    onClick={() => setModulePermissionsForm('Appointment')} 
                  >
                    <div className={classes.iconAndLabel}>

                    <EventAvailable 
                        className={!viewAppointment ? classes.disabledIconColor : classes.iconColor}
                        style={{width: 24}}
                      />
                      <Typography>Appointment</Typography>
                    </div>
                    {/* <CheckBoxIcon 
                      checked={true} disabled className={classes.disabledCheck}
                    /> */}
                    <Checkbox 
                      size="small" 
                      color='primary'
                      checked={appointmentPerm}
                      disabled={!viewAppointment}
                      className={classes.permissionCheckMarkNoPadding} 
                      onChange={() => handleAppointmentChange(!appointmentPerm)} 
                      name="Chat" 
                    />
                  </div>
                </Tooltip>
                <Tooltip
                    title={hasEfax ? (viewEfax ? 
                      'Send and Receive Faxes.' : 
                      'Send and receive faxes. Please contact Administrator for access to this module.') : 
                      'Send and receive faxes. Contact Support to add access to this module.'}
                  placement="right"
                  arrow
                >
                  <div 
                    className={modulePermissionsForm === 'Efax' ? classes.moduleAccessSelected : classes.moduleAccess}
                    onClick={() => setModulePermissionsForm('Efax')}
                  >
                    <div className={classes.iconAndLabel}>
                      <PrintIcon 
                        className={!hasEfax || !viewEfax ? classes.disabledIconColor : classes.iconColor}
                      />
                      <Typography className={(!hasEfax ||!viewEfax) && classes.disabledTextColor}>eFax</Typography>
                    </div>
                    <Checkbox 
                      size="small" 
                      color='primary'
                      checked={faxPerm}
                      disabled={!viewEfax}
                      className={classes.permissionCheckMarkNoPadding}
                      onChange={() => handleEfaxChange(!faxPerm)} 
                      name="eFax" 
                    />
                  </div>
                </Tooltip>
                <Tooltip
                  title={hasSms ? (viewSMS ? 
                    'Communicate with Patients via text message.' : 
                    'Communicate with Patients via text message. Please contact Administrator for access to this module.') : 
                    'Communicate with Patients via text message. Contact Support to add access to this module.'}
                  placement="right"
                  arrow
                >
                  <div 
                    className={modulePermissionsForm === 'Sms' ? classes.moduleAccessSelected : classes.moduleAccess}
                    onClick={() => setModulePermissionsForm('Sms')}
                  >
                    <div className={classes.iconAndLabel}>
                      <SmsOutlined
                        className={!hasSms || !viewSMS ? classes.disabledIconColor : classes.iconColor}
                      />
                      <Typography className={(!hasSms || !viewSMS) && classes.disabledTextColor}>SMS</Typography>
                    </div>
                    <Checkbox 
                      size="small" 
                      color='primary'
                      checked={smsPerm}
                      disabled={!viewSMS}
                      className={classes.permissionCheckMarkNoPadding}
                      onChange={() => handleSmsChange(!smsPerm)} 
                      name="SMS" 
                    />
                  </div>
                </Tooltip>
                <Tooltip
                  title="Internal Chat to communicate with other employees."
                  placement="right"
                  arrow
                >
                  <div
                    className={modulePermissionsForm === 'Chat' ? classes.moduleAccessSelected : classes.moduleAccess} 
                    onClick={() => setModulePermissionsForm('Chat')} 
                  >
                    <div className={classes.iconAndLabel}>
                      <ForumOutlinedIcon
                        className={classes.iconColor}
                      />
                      <Typography>Chat</Typography>
                    </div>
                    {/* <CheckBoxIcon 
                      checked={true} disabled className={classes.disabledCheck}
                    /> */}
                    <Checkbox 
                      size="small" 
                      color='primary'
                      checked={chatPerm}
                      disabled={!viewChat}
                      className={classes.permissionCheckMarkNoPadding} 
                      onChange={() => handleChatChange(!chatPerm)} 
                      name="Chat" 
                    />
                  </div>
                </Tooltip>
                <Tooltip
                  title={hasPayment ? (viewPayment ? 
                    'Accept one-time or recurring credit or debit card purchases.' : 
                    'Accept one-time or recurring credit or debit card purchases. Please contact Administrator for access to this module.') : 
                    'Accept one-time or recurring credit or debit card purchases. Contact Support to add access to this module.'}
                  placement="right"
                  arrow
                >
                  <div 
                    className={modulePermissionsForm === 'Payment' ? classes.moduleAccessSelected : classes.moduleAccess}
                    onClick={() => setModulePermissionsForm('Payment')}
                  >
                    <div className={classes.iconAndLabel}>
                      <PaymentTwoToneIcon 
                        className={!hasPayment || !viewPayment ? classes.disabledIconColor : classes.iconColor}
                        />
                      <Typography className={(!hasPayment || !viewPayment) && classes.disabledTextColor}>Payment</Typography>
                    </div>
                    
                    {hasPayment ? <Checkbox 
                      size="small" 
                      color='primary'
                      checked={paymentPerm}
                      disabled={!viewPayment}
                      className={classes.permissionCheckMarkNoPadding}
                      onChange={() => handlePaymentChange(!paymentPerm)} 
                      name="Payment" 
                    /> : <CheckBoxOutlineBlankIcon className={classes.disabledCheck}/>}
                  </div>
                </Tooltip>
                <Tooltip
                  title={hasTimeClock ? (viewTimeClock ? 
                    'Track time with location, mileage and expenses. Add notes and attached receipts.' : 
                    'Track time with location, mileage and expenses. Add notes and attached receipts. Please contact Administrator for access to this module.') : 
                    'Track time with location, mileage and expenses. Add notes and attached receipts. Contact Support to add access to this module.'}
                  placement="right"
                  arrow
                >
                  <div 
                    className={modulePermissionsForm === 'Timeclock' ? classes.moduleAccessSelected : classes.moduleAccess}
                    onClick={() => setModulePermissionsForm('Timeclock')}
                  >
                    <div className={classes.iconAndLabel}>
                      <AccessTimeOutlinedIcon
                        className={!hasTimeClock || !viewTimeClock ? classes.disabledIconColor : classes.iconColor}
                      />
                      <Typography className={(!hasTimeClock || !viewTimeClock )&& classes.disabledTextColor}>Time Clock</Typography>
                    </div><Checkbox 
                      size="small" 
                      color='primary'
                      checked={timeClockPerm}
                      disabled={!viewTimeClock}
                      className={classes.permissionCheckMarkNoPadding}
                      onChange={() => handleTimeClockChange(!timeClockPerm)} 
                      name="Timeclock" 
                    />
                  </div>
                </Tooltip>
                <Tooltip
                  title={"Save Patient contact information to easily communicate and consolidate transaction history."}
                  placement="right"
                  arrow
                >
                  <div 
                    className={modulePermissionsForm === 'Patients' ? classes.moduleAccessSelected : classes.moduleAccess}
                    onClick={() => setModulePermissionsForm('Patients')}
                  >
                    <div className={classes.iconAndLabel}>
                      <LocationCityIcon 
                        className={hasPatients ? classes.iconColor : classes.disabledIconColor}
                      />
                      <Typography className={!hasPatients && classes.disabledTextColor}>Patient</Typography>
                    </div>
                    
                    {(hasPatients && hasContacts) ? <CheckBoxIcon 
                      checked={true} disabled className={classes.disabledCheck}
                    /> : <Checkbox 
                    size="small" 
                    color='primary'
                    checked={patientsPerm}
                    disabled={patientsPerm}
                    className={classes.permissionCheckMarkNoPadding}
                    onChange={() => handlePatientsChange(!patientsPerm)} 
                    name="Patients" 
                  />}
                  </div>
                </Tooltip>
                <Tooltip
                  title={"Save contacts information to easily communicate with them via efax, SMS, or review their contact information."}
                  placement="right"
                  arrow
                >
                  <div 
                   className={modulePermissionsForm === 'Contacts' ? classes.moduleAccessSelected : classes.moduleAccess}
                   onClick={() => setModulePermissionsForm('Contacts')}
                  >
                    <div className={classes.iconAndLabel}>
                      <PermContactCalendarIcon
                        className={hasContacts ? classes.iconColor : classes.disabledIconColor}
                      />
                      <Typography className={!hasContacts && classes.disabledTextColor}>Contacts</Typography>
                    </div>
                    
                    {(hasPatients && hasContacts) ? <CheckBoxIcon 
                      checked={true} disabled className={classes.disabledCheck}
                    /> : <Checkbox 
                      size="small" 
                      color='primary'
                      checked={contactsPerm}
                      disabled={contactsPerm}
                      className={classes.permissionCheckMarkNoPadding} 
                      onChange={() => handleContactsChange(!contactsPerm)} 
                      name="Contacts" 
                    />}
                  </div>
                </Tooltip>
                <Tooltip
                  title="Communicate securely with your patients. Two-way messaging for patients to send information, and for the practice to send forms, communicate medical information and appointments."
                  placement="right"
                  arrow
                >
                  <div className={modulePermissionsForm === 'Secure Message' ? classes.moduleAccessSelected : classes.moduleAccess}
                    onClick={() => setModulePermissionsForm('Secure Message')}>
                    <div className={classes.iconAndLabel}>
                      <VpnKeyOutlined
                        className={classes.iconColor}
                      />
                      <Typography>Secure Message</Typography>
                    </div>
                    
                    <Checkbox 
                      size="small" 
                      color='primary'
                      checked={secureMessagingPerm}
                      disabled={!viewSecureMessages}
                      className={classes.permissionCheckMarkNoPadding}
                      onChange={() => handleSecureMessagingChange(!secureMessagingPerm)} 
                      name="Secure Messsaging" 
                    />
                  </div>
                </Tooltip>
                <Tooltip
                  title={hasTeleHealth ? (viewTelehealth ? 
                    'No App to download for your patients or internal staff. Easily join through ExamRoom Live and patients join through a link sent via text or email.' : 
                    'No App to download for your patients or internal staff. Easily join through ExamRoom Live and patients join through a link sent via text or email. Please contact Administrator for access to this module.') : 
                    'No App to download for your patients or internal staff. Easily join through ExamRoom Live and patients join through a link sent via text or email. Contact Support to add access to this module.'}
                  placement="right"
                  arrow
                >
                  <div 
                    className={modulePermissionsForm === 'Telehealth' ? classes.moduleAccessSelected : classes.moduleAccess}
                    onClick={() => setModulePermissionsForm('Telehealth')}
                  >
                    <div className={classes.iconAndLabel}>
                      <LocalHospitalIcon
                        className={!isAdmin ? classes.disabledIconColor : classes.iconColor}
                        />
                      <Typography className={(!isAdmin )&& classes.disabledTextColor}>TeleHealth</Typography>
                    </div>
                    <div style={{display: 'flex'}}>
                    <Checkbox 
                      size="small"
                      color='primary'
                      checked={isAdmin ? telehealthPerm : (isEditUserModal ? telehealthPerm : 0)}
                      disabled={!isAdmin}
                      className={classes.permissionCheckMarkNoPaddingTelehealth}
                      onChange={() => handleTelehealthChange(!telehealthPerm)} 
                      name="Telehealth" 
                    />
                      <Typography style={{marginLeft: 5, marginRight: 5, color: '#47AFEB',}}>+ </Typography>
                      <Typography >$25</Typography>
                    </div>
                    </div>

                </Tooltip>
              </div>
              <div className={classes.permissionContainer}>
                <Typography className={classes.permissionLabel}>
                  Permissions
                </Typography>
                {
                  modulePermissionsForm === 'Efax'
                    ? 
                  <div
                    className={classes.moduleGranularAccessContainer}
                  >
                    <div
                      className={classes.moduleGranularAccessItem}
                    >
                      <div style={{fontFamily: 'Arial,Helvetica,sans-serif', fontWeight: 400,}}>
                        Manage
                      </div>
                      <div
                        className={classes.moduleGranularAccessItemRightCheckboxWithHelpIcon}
                      >
                        <Checkbox 
                          size="small" 
                          color='primary'
                          checked={efaxSettingsPerm}
                          disabled={!viewEfax}
                          className={classes.permissionCheckMarkRightMargin}
                          onChange={() => handleEfaxSettingsChange(!efaxSettingsPerm)} 
                          name="Manage Efax" 
                        />
                        <Tooltip
                          title='User can purchase additional numbers, change display information and default number.'
                          placement="right"
                          arrow
                        >
                          <HelpIcon color='primary'/>
                        </Tooltip>
                      </div>
                      
                    </div>
                      <div
                        className={classes.moduleGranularAccessItem}
                      >
                        <Typography>
                          Notifications:
                        </Typography>
                        <div
                          className={classes.moduleGranularAccessItemRightCheckboxWithHelpIcon}
                        >
                        <Typography>
                          Email
                        </Typography>
                        <div style={{width: 24, height: 24}}/>
                        </div>
                      </div>
                      <div
                        className={classes.moduleGranularAccessItem}
                      >
                        <div style={{fontFamily: 'Arial,Helvetica,sans-serif', fontWeight: 400,}}>
                          New Fax Received
                        </div>
                        <div
                          className={classes.moduleGranularAccessItemRightCheckboxWithHelpIcon}
                        >
                          <Checkbox 
                            size="small" 
                            color='primary'
                            checked={newEfaxEmailNotif}
                            disabled={!viewEfax}
                            className={classes.permissionCheckMarkRightMargin}
                            onChange={() => handleNewEfaxNotifChange(!newEfaxEmailNotif)} 
                            name="Efax Notification" 
                          />
                          <div style={{width: 24, height: 24}}/>
                        </div>
                        
                      </div>
                  </div>
                    : 
                  (
                    modulePermissionsForm === 'Payment'
                    ? 
                    <div
                      className={classes.moduleGranularAccessContainer}
                    >
                      <div
                        className={classes.moduleGranularAccessItem}
                      >
                        <div style={{fontFamily: 'Arial,Helvetica,sans-serif', fontWeight: 400,}}>
                          Reports
                        </div>
                        <div
                          className={classes.moduleGranularAccessItemRightCheckboxWithHelpIcon}
                        >
                          <Checkbox 
                            size="small" 
                            color='primary'
                            checked={paymentReportsPerm}
                            disabled={!viewPayment}
                            className={classes.permissionCheckMarkRightMargin}
                            onChange={() => handlePaymentReportsChange(!paymentReportsPerm)} 
                            name="Payment Reports" 
                          />
                          <Tooltip
                            title='User can view reports.'
                            placement="right"
                            arrow
                          >
                            <HelpIcon color='primary'/>
                          </Tooltip>
                        </div>
                        
                      </div>
                    </div> 
                    : 
                    (
                      modulePermissionsForm === 'Account'
                      ?
                      <div
                        className={classes.moduleGranularAccessContainer}
                      >
                        <div
                          className={classes.moduleGranularAccessItem}
                        >
                          <div style={{fontFamily: 'Arial,Helvetica,sans-serif', fontWeight: 400,}}>
                            Add/Edit Users
                          </div>
                          <div
                            className={classes.moduleGranularAccessItemRightCheckboxWithHelpIcon}
                          >
                            <Checkbox 
                              size="small" 
                              color='primary'
                              checked={usersPerm}
                              className={classes.permissionCheckMarkRightMargin}
                              onChange={() => handleUsersPermChange(!usersPerm)} 
                              name="Add/Edit Users" 
                            />
                            <Tooltip
                              title='User can create other users.'
                              placement="right"
                              arrow
                            >
                              <HelpIcon color='primary'/>
                            </Tooltip>
                          </div>
                          
                        </div>
                        <div
                          className={classes.moduleGranularAccessItem}
                        >
                          <div style={{fontFamily: 'Arial,Helvetica,sans-serif', fontWeight: 400,}}>
                            Manage Services
                          </div>
                          <div
                            className={classes.moduleGranularAccessItemRightCheckboxWithHelpIcon}
                          >
                            <Checkbox 
                              size="small" 
                              color='primary'
                              checked={manageServicesPerm}
                              className={classes.permissionCheckMarkRightMargin}
                              onChange={() => handleManageServicesChange(!manageServicesPerm)} 
                              name="Manage Services" 
                            />
                            <Tooltip
                              title='User can update services, payment method, and Org Info.'
                              placement="right"
                              arrow
                            >
                              <HelpIcon color='primary'/>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      :
                      (
                        modulePermissionsForm === "Sms"
                        ?
                        <div
                          className={classes.moduleGranularAccessContainer}
                        >
                            <div
                              className={classes.moduleGranularAccessItem}
                            >
                              <Typography>
                                Notifications:
                              </Typography>
                              <Typography>
                                Email
                              </Typography>
                            </div>
                            <div
                              className={classes.moduleGranularAccessItem}
                            >
                              <div style={{fontFamily: 'Arial,Helvetica,sans-serif', fontWeight: 400,}}>
                                New SMS Received
                              </div>
                              <div
                                className={classes.moduleGranularAccessItemRightCheckboxWithHelpIcon}
                              >
                                <Checkbox 
                                  size="small" 
                                  color='primary'
                                  checked={newSmsEmailNotif}
                                  disabled={!viewSMS}
                                  className={classes.permissionCheckMarkRightMargin}
                                  onChange={() => handleNewSmsNotifChange(!newSmsEmailNotif)} 
                                  name="Sms Notification" 
                                />
                              </div>
                              
                            </div>
                        </div>
                        :
                        (modulePermissionsForm === 'Chat'? 
                        <div
                              className={classes.moduleGranularAccessContainer}
                            >
                              <div
                                className={classes.moduleGranularAccessItem}
                              >
                                <div style={{fontFamily: 'Arial,Helvetica,sans-serif', fontWeight: 400,}}>
                                  Edit/Delete Messages
                                </div>
                                <div
                                  className={classes.moduleGranularAccessItemRightCheckboxWithHelpIcon}
                                >
                                  <Checkbox 
                                    size="small" 
                                    color='primary'
                                    checked={chatEditDeletePerm}
                                    disabled={!viewChat}
                                    className={classes.permissionCheckMarkRightMargin}
                                    onChange={() => handleChatEditDeleteChange(!chatEditDeletePerm)} 
                                    name="chat permission" 
                                  />
                                  <Tooltip
                                    title='User can Edit/Delete all messages.'
                                    placement="right"
                                    arrow
                                  >
                                    <HelpIcon color='primary'/>
                                  </Tooltip>
                                </div>
                                
                              </div>
                            </div>
                        :
                        (modulePermissionsForm === 'Secure Message' ?
                        <div
                        className={classes.moduleGranularAccessContainer}
                      >
                        <div
                          className={classes.moduleGranularAccessItem}
                        >
                          <div style={{fontFamily: 'Arial,Helvetica,sans-serif', fontWeight: 400,}}>
                            Edit/Delete Messages
                          </div>
                          <div
                            className={classes.moduleGranularAccessItemRightCheckboxWithHelpIcon}
                          >
                            <Checkbox 
                              size="small" 
                              color='primary'
                              checked={secureChatEditDeletePerm}
                              disabled={!viewSecureMessages}
                              className={classes.permissionCheckMarkRightMargin}
                              onChange={() => handleSecureChatEditDeleteChange(!secureChatEditDeletePerm)} 
                              name="secure chat permission" 
                            />
                            <Tooltip
                              title='User can Edit/Delete all messages.'
                              placement="right"
                              arrow
                            >
                              <HelpIcon color='primary'/>
                            </Tooltip>
                          </div>
                          
                        </div>
                      </div>
                        :
                          (
                            modulePermissionsForm === 'Timeclock'
                            ?
                            <div
                              className={classes.moduleGranularAccessContainer}
                            >
                              <div
                                className={classes.moduleGranularAccessItem}
                              >
                                <div style={{fontFamily: 'Arial,Helvetica,sans-serif', fontWeight: 400,}}>
                                  Admin Reporting
                                </div>
                                <div
                                  className={classes.moduleGranularAccessItemRightCheckboxWithHelpIcon}
                                >
                                  <Checkbox 
                                    size="small" 
                                    color='primary'
                                    checked={timeClockReportsPerm}
                                    disabled={!viewTimeClock}
                                    className={classes.permissionCheckMarkRightMargin}
                                    onChange={() => handleTimeclockReportsChange(!timeClockReportsPerm)} 
                                    name="admin reporting" 
                                  />
                                  <Tooltip
                                    title='User can view all time clock entries.'
                                    placement="right"
                                    arrow
                                  >
                                    <HelpIcon color='primary'/>
                                  </Tooltip>
                                </div>
                                
                              </div>
                            </div>
                            :
                            <>  
                              <div className={classes.imageContainer}>
                                <img alt="mylogo" src='/ExamRoomLive_Logo_headerLogo.svg' className={classes.image}/>
                              </div>
                            </>
                          )
                        )
                       )
                      )
                    )
                  )
                  
                }
                
              </div>
            </div>
              
            <div className={classes.buttonContainer}>
              
              {isEditUserModal ? 
              (updateUser ? 
              <>
              <div style={{float: 'left'}}>
                <Button
                  variant='outlined'
                >
                  Archive User
                </Button>
                </div>
                <div style={{float: 'right'}}>
                <Button
                  className={classes.submitButton}
                  variant='contained'
                  onClick={handleSubmit}
                >
                  Save
                </Button>

              <Button
                variant='contained'
                color='secondary'
                className={classes.cancelButton}
                onClick={closeModal}
              >
                Cancel
              </Button>
              </div>
              </> :
              <>

                <div style={{float: 'left'}}>
                <Button
                  variant='outlined'
                  onClick={() => setIsArchivingUser(true)}
                >
                  Archive User
                </Button>
                </div>
              <div style={{float: 'right'}}>
                    <Button
                      className={classes.submitButton}
                      variant='contained'
                      onClick={handleSubmit}
                      disabled
                    >
                      Save
                    </Button>
              <Button
                variant='contained'
                color='secondary'
                className={classes.cancelButton}
                onClick={closeModal}
              >
                Cancel
              </Button>
              </div> 
              </>) : 
              (canAddNewUser ? 
              <>
              <div style={{float: 'left'}} >
                <Typography variant='subtitle1'style={{fontSize: 10}}>***Billing for your account will be updated to reflect these changes.</Typography>
              </div>
              <div style={{float: 'right'}}>
              <Button
                variant='contained'
                color='secondary'
                className={classes.cancelButton}
                onClick={closeModal}
              >
                Cancel
              </Button>
              </div> 
              <div style={{float: 'right'}}>
                <Button
                  className={classes.submitButton}
                  variant='contained'
                  onClick={() => {
                      handleNext()
                      calculateProation(billingDate)
                    }}
                  >
                   {loading ? (<CircularProgress color="primary" size="20px" />) : 'Add User'}
                </Button>
                </div>
              </> : 
              <>
              <div style={{float: 'left', marginBottom: isMobile ? 10 : 0}} >
                <Typography variant='subtitle1' style={{fontSize: 10}}>***Billing for your account will be updated to reflect these changes.</Typography>
              </div>
              <div style={{float: 'right'}}>
                <Button
                  variant='contained'
                  color='secondary'
                  className={classes.cancelButton}
                  onClick={closeModal}
                >
                  Cancel
                </Button>
              </div> 
              <div style={{float: 'right'}}>
                <Tooltip
                  title={`Please enter ${requiredTooltip}`}
                  placement="left"
                  arrow
                  >
                  <span>
                    <Button
                      className={classes.submitButton}
                      variant='contained'
                      onClick={handleNext}
                      disabled
                    >
                      {loading ? (<CircularProgress color="primary" size="20px" />) : 'Add User'}
                    </Button>
                  </span>
                </Tooltip>
                </div>

              </>)}             
            </div>
          </form>
      </div>
      </Dialog>)))}
    </>
  );
};

export default AddUserModal;
