import React, { useState, useEffect, useCallback } from 'react';
import TimeSheetDetails from './reportsTab/timesheetDetails';
import Session from '../../session';
import { ClockType, ConvertMiles, startAndEndOfWeek, WorkedTime } from './util';
import { toHHMMSS } from '../constant/timer';
import Currency from '../../utils/formatCurrency';
import Storage from './fileStore';
import { useAPI } from '../../utils/api';
import moment from 'moment';

import { makeStyles } from '@mui/styles';
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  CircularProgress,
  Backdrop,
  Tooltip
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRangePicker,LocalizationProvider } from '@mui/x-date-pickers-pro';
import { RunningWithErrorsOutlined,ScheduleSendOutlined,PublishedWithChanges } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tabs: {
    display: 'flex',
    color: '#707070',
    background: '#dff2fa',
    borderRadius: '5px',
    height: '25px',
    width: 'fit-content',
    margin: 'auto',
    border: '1px solid #e8e8e8'
  },
  tab: {
    background: 'transparent',
    border: 'none',
    display: 'flex',
    width: '150px',
    '& > div': {
      margin: 'auto',
      color: '#707070'
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.8'
    }
  },
  activetab : {
    background: '#9ed8f2',
    borderRadius: 5,
    border: 'none',
    display: 'flex',
    width: '150px',
    '& > div': {
      margin: 'auto',
      color: '#707070'
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.8'
    }
  },
  detailtable: {
    borderRadius: '5px',
    overflow: 'hidden',
    marginBottom: '25px',
  },
  topDetailtable: {
    borderRadius: '5px',
    overflow: 'hidden',
    marginTop: '55px',
    marginBottom: '35px',
    width: '43vh',
  },
  topTableContainer: {
    display: 'flex',  
    justifyContent: 'center',
    alignItems: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
  },
  topTableCellsLeft: {
    padding : '7px',
    paddingLeft: '15px',
    fontFamily: 'Helvetica',
  },
  topTableCellsRight: {
    padding : '7px',
    paddingRight: 0,
    fontFamily: 'Helvetica',
  },
  bottomTableHeaderCells: {
    fontFamily: 'Helvetica',
  },
  noHoverLightRow: {
    background: 'white',
  },
  tableRowLight: {
    color: '#707070',
    background: 'white',
    '&:hover': {
      background: '#dff2fa',
      cursor: 'pointer',
    }
  },
  tableRowDark: {
    color: '#707070',
    background: '#EBEBEB',
    '&:hover': {
      background: '#dff2fa',
      cursor: 'pointer',
    }
  },
  tableRowLightBlue: {
    background: '#dff2fa',
  },
  tableRowLightGreen: {
    background: '#caf7d3',
  },
  viewButton: {
    color: '#47AFEB',
    fontWeight: 'Bold',
  }
}));

const TabType = {
  Current: 'current',
  LastWeek: 'lastweek',
  Custom: 'custom',
};

const ReportsTab = () => {
  const [tab, setTab] = useState(TabType.Current);
  const [modelOpen, setModelOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [timeSheet, setTimesheet] = useState([]);
  const [tempTimeSheet, setTempTimesheet] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tabValue, setTabValue] = React.useState(0);
  
  const [entryDetails, setEntryDetails] = useState();
  const [totalTimeWorked, setTotalWorkedTime] = useState(0);
  const [totalMileage, setTotalMileage] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);

  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false)

  let weekBefore = new Date();
  weekBefore.setDate(weekBefore.getDate() - 7);

  const [value, setValue] = useState([weekBefore, Date.now()]);
  const api = useAPI();
  const classes = useStyles();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    if(width <= 768){
      setIsMobile(true)
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createTimeSheetRes = useCallback((data) => {
    const unique = list => {
      var arr = [];
      list.forEach(current => {
        if (current.TimeSheetClockEvents) {
          const submitted = current.TimeSheetClockEvents.some(x => x.event === ClockType.Submit);
          if (!submitted) {
            return;
          }
        }

        const [worked] = WorkedTime(current.TimeSheetClockEvents || []);
        let fileUlids = [];
        let clockInEvents = current.TimeSheetClockEvents.map(item => ({...item})).sort((a,b) => a.timeStamp - b.timeStamp).filter(a => a.event === ClockType.ClockIn)
        if (current.TimeSheetExpense && current.TimeSheetExpense.fileUlids
          && current.TimeSheetExpense.fileUlids.length > 0) {
            fileUlids = current.TimeSheetExpense.fileUlids;
        }
        console.log(current)
        arr.push({
          autoSubmitted: current.autoSubmitted || false,
          autoSubmitType: current.autoSubmitType,
          fileUlids,
          totalHours: worked,
          notes: current.notes,
          ulid: current.ulid,
          createdAt: current.createdAt,
          date: new Date(clockInEvents[0].timeStamp * 1000).toDateString(),
          miles: Number(current.mileage),
          edited: current.edited,
          expenses: Number(current.TimeSheetExpense && current.TimeSheetExpense.amount ? current.TimeSheetExpense.amount : '')
        });
      });

      return arr;
    }

    return unique(data);
  }, []);

  useEffect(() => {
    const getTimeSheet = async () => {
      const res = await api.getTimesheetsByUser({ulid: Session.UserId});
      const converted = createTimeSheetRes(res || []);
      console.log(res)
      setTimesheet(converted);
      setTempTimesheet(converted);
      setLoading(false);
    }

    getTimeSheet();
    return () => {
      Storage.clear();
    }
  }, [createTimeSheetRes, api]);

  useEffect(() => {
    let filtered = timeSheet;

    if (tab === TabType.Custom) {
      if (startDate) {
        filtered = filtered.filter(d => (new Date(d.date)) >= (new Date(startDate)));
      }

      if (endDate) {
        const end = new Date(endDate);
        end.setDate(end.getDate());
        filtered = filtered.filter(d => (new Date(d.date)) <= end);
      }
    } else if (tab === TabType.LastWeek) {
      const lastweek = new Date();
      lastweek.setDate(lastweek.getDate() - 7);
      const [monday, sunday] = startAndEndOfWeek(lastweek);
      filtered = filtered.filter(d => (new Date(d.date)) >= monday && (new Date(d.date) <= sunday));
    } else {
      const [monday, sunday] = startAndEndOfWeek();
      filtered = filtered.filter(d => new Date(d.date) >= monday && new Date(d.date) <= sunday);
    }
    filtered.sort((a,b) => new Date(a.date) - new Date(b.date))
    setTempTimesheet(filtered);

    let timeWorked = 0;
    let mileage = 0;
    let expenses = 0;
    filtered.map(x => {
      console.log(x)
      timeWorked += x.totalHours;
      mileage += x.miles;
      expenses += x.expenses;
      return x;
    });

    setTotalExpenses(expenses);
    setTotalMileage(mileage);
    setTotalWorkedTime(timeWorked);

    return () => { setTempTimesheet([]) }
  }, [tab, timeSheet, startDate, endDate]);

  const handleDetails = (current) => {
    setModelOpen(true);
    setEntryDetails(current);
  };

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
    setTab(TabType[Object.keys(TabType)[newValue]]);
  };

  const currentDate = new Date()

  const HoverAbleDiv = ({row, index}) => {
    const [hovered, setHovered] = useState(false);
    
    return (
      <TableBody>
        <TableRow
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          key={row.ulid} 
          onClick={() => handleDetails(row)} 
          className={index % 2 ? classes.tableRowLight : classes.tableRowDark} 
        >
          <TableCell className={classes.bottomTableHeaderCells} >
            <div style={{
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
            }}>
            {moment(row.date).format('dd l')}
            {console.log(row)}
            {row.autoSubmitted ? 
              <>
              {row.autoSubmitType === 1 ? 
                                <Tooltip
                                title={
                                  <>
                                    <p> You forgot to click 'Submit Time', so it was auto-submitted for you. </p>
                                  </>
                                }
                                placement="right" 
                                arrow
                              >
                                <ScheduleSendOutlined 
                                style={{
                                  color:'green',
                                  paddingLeft: '6px'
                                }} /> 
                              </Tooltip>
                              : 
                              <Tooltip
                              title={
                                <>
                                  <p> You Clocked in, but forgot to clock out. </p>
                                </>
                              }
                              placement="right" 
                              arrow
                            >
                           <RunningWithErrorsOutlined 
                                 style={{
                                   color:'red',
                                   paddingLeft: '6px'
                                 }} /> 
                            </Tooltip>
            
              }
              </>
              : ''
            }
                {row.edited === true && 
                  <Tooltip
                    title={
                      <>
                        <p>Edited by Admin</p>
                      </>
                    }
                    placement="right" 
                    arrow
                  >
                    <PublishedWithChanges 
                    style={{
                      color:'green',
                      paddingLeft: '6px'
                    }} /> 
                  </Tooltip>
                }
            </div>

          </TableCell>
          <TableCell className={classes.bottomTableHeaderCells} align={isMobile ? "left" : 'right'}>{toHHMMSS(row.totalHours)}</TableCell>
          {!isMobile && <TableCell className={classes.bottomTableHeaderCells} align="right">{ConvertMiles(row.miles)}</TableCell>}
          {!isMobile && <TableCell className={classes.bottomTableHeaderCells} align="right">{Currency(row.expenses || 0)}</TableCell>}
          <TableCell className={classes.bottomTableHeaderCells} align="right">
            {hovered
              ?
              <div className={classes.viewButton}>
                View
              </div>
              :
              <div className={classes.viewButton}>
                
              </div>
            }
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      <Tabs
        className={classes.tabs}
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="white"
        centered
      >
        <Tab className={tab === TabType.Current ? classes.activetab : classes.tab} label="Current" onClick={() => {setTab(TabType.Current); setTabValue(0);}}/>
        {!isMobile && <Tab className={tab === TabType.LastWeek ? classes.activetab : classes.tab} label="Last Week" onClick={() => {setTab(TabType.LastWeek);setTabValue(1);}}/>}        <Tab className={tab === TabType.Custom ? classes.activetab : classes.tab} label="Custom" onClick={() => {
          setTab(TabType.Custom);
          setTabValue(2);
          setStartDate(value[0]);
          setEndDate(value[1]);
          }}/>
      </Tabs>

      {tab === TabType.Custom && (
        <div style={{
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          border: "1px solid #e8e8e",
          paddingTop: "35px",
          paddingBottom: '-20px',
          borderRadius: "5px"
        }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={value}
              maxDate={currentDate}
              onChange={date => {
                setStartDate(date[0])
                setEndDate(date[1])
                setValue(date)}
              }
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} helperText="" onChange={(e,v) => console.log(e,v)}/>
                  {/* <DateRangeDelimiter> to </DateRangeDelimiter> */}
                  <Box sx={{ mx: 1 }}> to </Box>
                  <TextField {...endProps} helperText="" onChange={(e,v) => console.log(e,v)}/>
                </>
              )}
            />
          </LocalizationProvider>
        </div>
      )}

      <div className={classes.topTableContainer}>
        <Table className={classes.topDetailtable} aria-label="simple table">
            <TableRow className={classes.tableRowLightBlue}>
              <TableCell className={classes.topTableCellsLeft}>Time Worked:</TableCell>
              <TableCell className={classes.topTableCellsRight} align="center">{toHHMMSS(totalTimeWorked)}</TableCell>
            </TableRow>

            <TableRow className={classes.noHoverLightRow}>
              <TableCell className={classes.topTableCellsLeft}>Mileage:</TableCell>
              <TableCell className={classes.topTableCellsRight} align="center">{ConvertMiles(totalMileage)}</TableCell>
            </TableRow>

            <TableRow className={classes.tableRowLightGreen}>
              <TableCell className={classes.topTableCellsLeft}>Expenses:</TableCell>
              <TableCell className={classes.topTableCellsRight} align="center">{Currency(totalExpenses || 0)}</TableCell>
            </TableRow>
        </Table>
      </div>

      <TableContainer>
        <Table className={classes.detailtable} aria-label="simple table">
          <colgroup>
            <col style={{width:!isMobile ? "20%" : '50%'}}/>
            <col style={{width:!isMobile ? "20%" : '50%'}}/>
            <col style={{width:!isMobile ? "20%" : '0%'}}/>
            <col style={{width:!isMobile ? "20%" : '0%'}}/>
            <col style={{width:!isMobile ? "20%" : '0%'}}/>
          </colgroup>
          <TableHead>
            <TableRow className={classes.noHoverLightRow}>
              <TableCell className={classes.bottomTableHeaderCells} >Date</TableCell>
              <TableCell className={classes.bottomTableHeaderCells} align={isMobile ? "left" : 'right'}>Total Time</TableCell>
              {!isMobile && <TableCell className={classes.bottomTableHeaderCells} align="right">Miles</TableCell>}
              {!isMobile && <TableCell className={classes.bottomTableHeaderCells} align="right">Expenses</TableCell>}
              <TableCell className={classes.bottomTableHeaderCells} align="right"></TableCell>
            </TableRow>
          </TableHead>
          {tempTimeSheet.map((row, index) => (
            <HoverAbleDiv row={row} index={index}/>
          ))}
        </Table>
      </TableContainer>

      {modelOpen && 
        <TimeSheetDetails modalIsOpen={modelOpen} setModelOpen={val => setModelOpen(val)} details={entryDetails} />
      }
    </>
  );
};

export default React.memo(ReportsTab);
