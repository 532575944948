import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from "styled-components";

const Span = styled.span`
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
  font-size: 11px;
  margin: 0px;
`;

const ErrorToast = (data) => {
  if (data !== undefined) {
    toast.error(<Span>{data}</Span>);
  } else {
    toast.error(<Span>Error: Something went wrong.</Span>);
  }
}
export default ErrorToast;