import React, { useState, useEffect } from 'react';
import { getTime, getFullDuration } from '../../../utils/dateTimeFormat';
import { ClockType, CalculateTime } from '../util';
import { makeStyles } from '@mui/styles';
import {
  TableRow,
  TableCell,
  TableBody,
  Table
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  body: {
    color: '#707070',
    fontSize: 'small',
    borderBottom: 'none',
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      verticalAlign: 'top',
      textAlign: 'left',
      fontSize: '12px'
    },
  },
  status: {
    color: '#707070',
    fontSize: 'small',
    borderBottom: 'none',
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      width: '35%',
      verticalAlign: 'top',
      textAlign: 'left',
      fontSize: '12px'
    },
  },
  tableRow: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '5px',
    },
  },
}));


const ActivityTable = ({ events }) => {
    const [activities, setActivties] = useState([]);
    const classes = useStyles();
  
    useEffect(() => {
      let container = [];
      const list = events.sort((a,b) => a.timeStamp - b.timeStamp)
      .map((t, index) => {
        container.push(t);
  
        let event, status;
        if (index === 0) {
          event = 'Start Day:';
        } else {
          if (t.event === ClockType.ClockIn) {
            event = 'Clock in:';
          }
  
          if (t.event === ClockType.BreakStart) {
            event = 'Start Break:';
          }
    
          if (t.event === ClockType.BreakEnd) {
            event = 'End Break:';
            const breakTime = CalculateTime([events[index - 1], t]);
            status = getFullDuration(breakTime);
          }
    
          if (t.event === ClockType.ClockOut) {
            event = 'Clock Out:';
            status = `${getFullDuration(CalculateTime(container))} worked`;
            container = [];
          }
  
          if (t.event === ClockType.Submit) {
            event = 'Submit:';
          }
        }
        
        return {
          ulid: t.ulid,
          time: getTime(t.timeStamp * 1000),
          event,
          status
        }
      });
  
      setActivties(list);
    }, [events])
  
    return (
      <Table size='small' padding='none'>
        <TableBody>
          {activities.map(t => t && (
            <TableRow key={t.ulid} className={classes.tableRow}>
              <TableCell className={classes.body} align="left">{t.event || ' '}</TableCell>
              <TableCell className={classes.body} align="left">{t.time || ' '}</TableCell>
              <TableCell className={classes.status} align="left">{t.status || ' '}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
};

export default ActivityTable;