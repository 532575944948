import React, { useState, useEffect } from "react";
import { getFullDate, getDuration } from "../../../utils/dateTimeFormat";
import { S3Document, DisplayFile } from '../s3Document';
import Currency from "../../../utils/formatCurrency";
import { ConvertMiles } from "../util";
import { withStyles, makeStyles } from '@mui/styles';
import {
  Close as CloseIcon,
  RunningWithErrorsOutlined,
  ScheduleSendOutlined
} from "@mui/icons-material";
import {
  Tooltip,
  Grid,
  IconButton,
  Typography,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Dialog,
  Tab,
  Tabs
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  titleDate: {
    color: '#707070',
    fontSize: '22px'
  },
  tableData: {
    color: '#707070',
    margin: '0',
    fontSize: '11px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  tableCell: {
    color: '#707070',
    fontSize: '12px',
    padding: '15px',
    '&:hover': {
      background: '#dff2fa'
    },
    '&:active': {
      background: '#dff2fa'
    }
  },
  activityTable: {
    borderCollapse: 'collapse',
    fontSize: '11px',
    marginTop: 27,
    '& tbody tr': {
      width: '50%',
    },
    '& tbody tr td': {
      paddingBottom: '3px',
    },
  },
  dataWidth: {
    paddingLeft: '15px',
    width: '50%',
  },
  nonSelectedTab: {
    padding: '0 2px',
    margin: 0,
    height: '100%',
    borderBottom: '1px solid lightgray',
    maxWidth: '100%',
  },
  selectedTab: {
    padding: '0 2px',
    margin: 0,
    height: '100%',
    borderBottom: '1px solid lightgray',
    maxWidth: '100%',
    backgroundColor: 'rgb(223, 242, 250)'
  },
  dateText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography style={{paddingBottom: 0}} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const TimeSheetDetails = ({ details, modalIsOpen, setModelOpen }) => {
  const [current, setCurrent] = useState({});
  const [selectedFileUlid, setSelectedFileUlid] = useState('');
  const [receiptName, setReceiptName] = useState('');
  const [vertTab, setVertTab] = useState();
  const [hasReceipts, setHasReceipts] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setCurrent(details);
  }, [details]);

  const closeModal = () => setModelOpen(false);
  
  const handlePreviewFile = (id, fileUlid) => {
    const span = document.getElementById(id);
    const oldFileName = span.innerHTML;
    setReceiptName(oldFileName);
    setSelectedFileUlid(fileUlid);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    if(width <= 768){
      setIsMobile(true)
      // window.FreshworksWidget('hide');
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (current && current.fileUlids && current.fileUlids.length > 0) {
      setHasReceipts(true)
      setSelectedFileUlid(current.fileUlids[0]);
      setVertTab(0);
    }
  }, [current])

  return (
    <Dialog 
      open={modalIsOpen} 
      onClose={closeModal} 
      fullWidth={true}
      fullScreen={isMobile}
    >
      <DialogTitle onClose={closeModal}>Entry Details</DialogTitle>
      <MuiDialogContent style={{padding: '0 5px 16px 15px', height: selectedFileUlid ? '505px' : '300px'}}>
      <Grid container spacing={1}>

      <Grid item xs={12} sm={selectedFileUlid ? 6 : 12}>
        <div style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }}>
        <span className={classes.dateText}>{getFullDate(new Date(current.createdAt).getTime() / 1000)}</span>
        {current.autoSubmitted ?
              <>
                {current.autoSubmitType === 1 ? 
                    <Tooltip
                    title={
                      <>
                        <p> Employee forgot to click 'Submit Time', so it was auto-submitted for them. </p>
                      </>
                    }
                    placement="right" 
                    arrow
                  >
                    <ScheduleSendOutlined 
                    style={{
                      color:'green',
                      paddingLeft: '6px'
                    }} /> 
                  </Tooltip>
                : 
                  <Tooltip
                  title={
                    <>
                      <p> Employee Clocked in, but forgot to clock out. </p>
                    </>
                  }
                  placement="right" 
                  arrow
                >
                <RunningWithErrorsOutlined 
                  style={{
                    color:'red',
                    paddingLeft: '6px'
                  }} /> 
                </Tooltip>
                }
              </>
              : ''
            }
        </div>
        
        <hr/>
        <table className={classes.activityTable}>
          <tbody>
            <tr key={'hours'}>
              <td>Hours Worked:</td>
              <td className={classes.dataWidth}>{getDuration(current.totalHours >= 86400 ? 86400 : current.totalHours)}</td>
            </tr>
            <tr key={'miles'}>
              <td>Mileage:</td>
              <td className={classes.dataWidth}>{ConvertMiles(current.miles)} Miles</td>
            </tr>
            <tr key={'expenses'}>
              <td>Expenses:</td>
              <td className={classes.dataWidth}>{Currency(current.expenses || 0)}</td>
            </tr>
            <tr key={'notes'}>
              <div>Notes:</div>
              <td className={classes.dataWidth}>{current.notes}</td>
            </tr>
          </tbody>
        </table>

        {current.fileUlids && current.fileUlids.length > 0 ?

            <div style={{paddingTop: '20px'}}>
              <strong className="sub-header">Receipts: </strong>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  aria-label="Vertical tabs"
                  indicatorColor="primary"
                  style={{padding: 0, width: '100%'}}
                  value={vertTab}
                >
                  {current.fileUlids.map((file, index) => {
                    return (
                      <Tab
                        key={index}
                        className={(file === selectedFileUlid && vertTab === index) ? classes.selectedTab : classes.nonSelectedTab}
                        label={
                          <>
                            <div style={{
                              display: 'flex',
                              justifyContent: 'space-between', 
                              width: '100%',
                              height: '100%',
                            }}>
                              <S3Document style={{fontSize: '12px', paddingTop: '3px', paddingLeft: '10px', fontFamily: 'arial', textTransform: 'none'}} id={"s3file" + index} fileUlid={file} />
                            </div>
                          </>
                        }
                        onClick={() => {
                          setSelectedFileUlid(file);
                          setVertTab(index);
                          handlePreviewFile("s3file" + index, file);
                        }}
                      />
                    );
                  })}
                </Tabs>
            </div>

          : null
        }
          </Grid>
        {hasReceipts ? 
          <Grid item xs={12} sm={6} style={{ textAlign: 'center', margin: '25px 0'}}>
            <div style={{display: 'flex', maxHeight: '400px', minHeight: '420px', justifyContent: 'center'}}>
              {selectedFileUlid && 
                <DisplayFile docUlid={selectedFileUlid} docName={receiptName} />
              }
            </div>
          </Grid>
            : null
        }
        </Grid>
      </MuiDialogContent>
    </Dialog>
  );
}

export default TimeSheetDetails;
