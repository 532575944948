// Formatting
  // +1(###)###-####
const pipeDisplay = (initialValue) => {
  const cleaned = ('' + initialValue).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const number = ['(', match[2], ') ', match[3], '-', match[4]].join('')
    return number
  }
  return null
}
  // Converts INTs into FLOATs and formats to USD Amount
const Currency = value => {
  const numeric = parseFloat(value);
  return numeric.toLocaleString('USD', { style: 'currency', currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 });
}
//--//
// Check Format
  // Check if DOB Format is ##/##/#### and the parsed Date Timestamp is < NOW
const isValidDOB = (dob) => {
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;
  const datenow = Date.now()
  const dategiven = Date.parse(dob)
  const dateInPast = dategiven < datenow
  if (dob.match(regex) && dateInPast) {
    return true 
  } else {
    return false
  }
};
  // Check Email Formatting
const isValidEmail = (email) => {
  // RFC 5322 Compliant Email Regex
  // eslint-disable-next-line
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if ((typeof email === 'string') && email.match(regex)){
    return true
  } else {
    return false
  }
};
  // Check Phone Number Validation (/d{10})
const isValidPhoneNumber = (mobnum) => {
  const regex = /^\d{10}/
  if (mobnum.match(regex)) {
    return true
  } else {
    return false
  }
};

// Exports
module.exports = {
    //Format
    pipeDisplay,
    Currency,
    //FormatCheck
    isValidDOB,
    isValidEmail,
    isValidPhoneNumber,
}
