import {useEffect, useState} from 'react';
import app from 'firebase';
import { FIREBASECONFIG } from './config';
import {useAPI} from './utils/api';
import Session from "./session";

export const Firebase = () => {
  const [messaging, setMessaging] = useState();
  const [firebaseMessaging, setFirebaseMessaging] = useState(null);
  const api = useAPI();
  const token = Session.Token;

  //Initializes firebase
  useEffect(() => {
    async function firebaseInit() {
      try {
        app.initializeApp(FIREBASECONFIG);
        if(app.messaging.isSupported()){
          setMessaging(app.messaging);
        };
      } catch (err) {
        console.error('Firebase initialization error raised', err.stack);
      }
    }
    if (!app.apps.length) {
      firebaseInit();
    }
    // eslint-disable-next-line
  }, []);

  //sends webtoken to RDS for firebase notifications
  useEffect(() => {
    async function setWebToken() {
      try {
        const item = await askForPermissionToReceiveNotifications();
        if (item) {
          await api.updateWebToken(item);
        }
      } catch (err) {
        console.error('Firebase initialization error raised', err.stack);
      }
    };

    if (messaging && token) {
      
      setWebToken();
      messaging.onMessage(payload => {
        console.log('payload', payload);
        let path;

        if (payload.notification.click_action) {
          let url 
          if (process.env.REACT_APP_STAGE === 'prod') {
            url = 'https://app.examroomlive.com'
          } else {
            url = `https://${process.env.REACT_APP_STAGE}.examroomlive.com`
          }
          path = payload.notification.click_action.replace(url, '');
        };

        const notifdata = {
          path: path || '/',
          title: payload.notification.title || 'New Notification',
          body: payload.notification.body || 'New Notification', 
        };
    
        setFirebaseMessaging(notifdata);
      });
    };
    // eslint-disable-next-line
  }, [messaging, token]);

  // permissions for getting web token
  const askForPermissionToReceiveNotifications = async () => {
    try {
      await messaging.requestPermission();
      const token = await messaging.getToken();
      return token;
    } catch (error) {
      console.error(error);
    };
  };

  return firebaseMessaging;
};
